
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import 'material-icons/iconfont/material-icons.css';
import Tooltip from '@mui/material/Tooltip';
import { InputNumber } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from "@material-ui/core/styles";
import Collapse from '@mui/material/Collapse';
import {formLabelStyleBasic} from 'views/admin/Forms/Cost/SharedCostStyles.js'
import {
    selectCostSetting,
    getUniqueColumnValues,
    updateCostSettings,
    selectActiveModelSettings,
    toggleCostVehicleTypeDependence,
    selectDataSize
  } from 'store/reducers/inputReducer.js'


const infoIcon =  <span style={{margin:0, marginLeft:'0px', marginRight:'15px', marginTop:'0px'}}>
<i class={"helpIcon material-icons"}>info</i>
</span>

const MyInputNumber = (props) => {
    return (<>
        <InputNumber size="small" 
            style={{maxWidth:'200px'}}
            min={0} max={null} 
            defaultValue={props.multiplier} onChange={(newValue) => props.setMultiplier(parseFloat(newValue), props.vehicleType)} 
            step={0.1}
        />
    </>)
}

const CostMultiplierForm = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const multiplierSettings = useSelector(selectCostSetting({settingName:'costMultiplier', subSettingName: props.costMetric}));
    const autoCompute = useSelector(selectCostSetting({settingName: "autoCompute", subSettingName:props.costMetric}));
    const vehicleTypeDependence = useSelector(selectCostSetting({settingName:'vehicleTypeDependence'}));
    const vehicleTypes = useSelector(getUniqueColumnValues({tableName:'vehicle_types', columnName: 'Vehicle Type'}));
    const vehicleTypesActive = useSelector(selectActiveModelSettings).input.enabled['vehicle_types'];
    const numVehicleTypes = useSelector(selectDataSize('vehicle_types'));

    const setMultiplier = (newMultiplier, vehicleType) => {
        if (!vehicleType) {
            dispatch(updateCostSettings({
                settingName: 'costMultiplier',
                subsettingName: props.costMetric,
                subSubsettingName: 'all',
                value: newMultiplier,
            }));
        } else {
            dispatch(updateCostSettings({
                settingName: 'costMultiplier',
                subsettingName: props.costMetric,
                subSubsettingName: 'vehicleTypes',
                subSubSubsettingName: vehicleType,
                value: newMultiplier,
            }));
        }
    }

    const handleVehicleTypeDependenceChange = () => {
        dispatch(toggleCostVehicleTypeDependence());
      }

    return (<>
        
        <FormControl style={props.formControlStyle}>
        <FormLabel style={{...props.formLabelStyle}} id="distance-calculation-group-label">
            {props.costMetric === 'distance' && "Cost Multiplier"}
            {props.costMetric === 'duration' && "Duration Multiplier"}
        </FormLabel>
            <Stack direction={'column'} spacing={1} style={{paddingBottom:'2px', paddingTop:'5px', overflowY:'scroll'}}>

            {vehicleTypesActive && <>
            <Stack direction="row"  >
                <Tooltip title={numVehicleTypes < 1 ? "There must be at least one vehicle type to use this setting" : ""}>
                <FormControlLabel style={{...props.formControlLabelStyle, padding:'0px', height:'1.4rem'}} disabled={numVehicleTypes < 1}
                control={<Checkbox 
                sx={props.checkboxSx}
                size={'small'}
                disabled={numVehicleTypes < 1}
                defaultChecked={numVehicleTypes < 1 ? false : vehicleTypeDependence} 
                onChange={handleVehicleTypeDependenceChange}/>} 
                label="Costs vary by vehicle type"
                />
                </Tooltip>
                <Tooltip title='If the vehicle type impacts the travel costs between locations, check this box. 
                    This will add a row, Vehicle Type, to the Travel Costs table.' placement='top'>
                {infoIcon}
                </Tooltip>  
            </Stack>
            </>}

            <Collapse in={!vehicleTypeDependence && autoCompute} style={{margin:'0px', marginTop:'5px'}}>
            <Stack direction='row' >
                <FormLabel focused={false} sx={formLabelStyleBasic(theme)} id="distance-units-group-label">All Vehicles
                </FormLabel>
                <Tooltip title='The cost multiplier for the cost metric. For example, to incur a cost of $2 per mile, set the multiplier to 2.' placement='top'>
                    {infoIcon}
                </Tooltip>
                <MyInputNumber multiplier={multiplierSettings['all'] || 1} setMultiplier={setMultiplier} />
                </Stack>
            </Collapse>

            <Collapse in={(vehicleTypeDependence && vehicleTypes && autoCompute)} timeout={150} style={{margin:'0px', marginTop:'5px'}}>
            {vehicleTypes.map((vehicleType, idx) => {
            return(<>
                
                <Stack direction='row' style={{ padding:'5px', backgroundColor: 'inherit', borderRadius:'5px'}}>
                    <FormLabel focused={false} sx={formLabelStyleBasic(theme)}>
                        {vehicleType}
                    </FormLabel>
                    <Tooltip title={'The cost multiplier for all vehicles of type ' + vehicleType + '. For example, to incur a cost of $2 per mile, set the multiplier to 2.'} placement='top'>
                        {infoIcon}
                    </Tooltip>
                    <MyInputNumber multiplier={multiplierSettings['vehicleTypes'][vehicleType] || 1} setMultiplier={setMultiplier} vehicleType={vehicleType}/>
                </Stack>
                
                </>)
            })}
            </Collapse>

            </Stack>
        </FormControl>
    </>)
}

export default CostMultiplierForm;