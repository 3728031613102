const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']

/**
 * This function queries the AWS database to get the scenario results from the input scenario_id
 * @param {*} scenarioId The id for the scenario to pull 
 */

async function get_saved_scenario(scenarioId, scenarioName) {

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ scenario_id:scenarioId, scenario_name:scenarioName})
    };
    const response = await fetch(BASE_ROUTE + '/saved_scenario_by_id', requestOptions)
    const retJson = await response.json()
    return retJson;
}

export default get_saved_scenario