const themeColors = {
  white: {
    main: "#FFFFFF",
  },
  black: {
    light: "#12263F",
    main: "#000000",
  },
  transparent: {
    main: "transparent",
  },
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  primary: {
    main: "#00928b",
    light: "#b0d9d7",
    extraLight: '#e6fcfb',
    dark: "#172b4d",
    snackbar: "#7889e8",
    badgeBg: "#eaecfb",
    badgeBgHover: "#2a44db",
    badge: "#2643e9",
    inputCredit: "#324cdd",
    gradientEnd: '#49bf72',
    linkHover: '#8b0092'
  },
  primaryComplement: {
    main: "#fff4f4",
    light: "#fff4f4",
    dark: "#570004",    
  },
  secondary: {
    main: "#f7fafc",
    snackbar: "#f8fbfc",
    badgeBgHover: "#cadeeb",
    btnOutline: "#4385b1",
    btnActive: "#d2e3ee",
  },
  tertiary: {
    main: "#C23947",
    light: '#DC5361',
    dark: '#8F0614'
  },
  warning: {
    tableLight: "#fed3ca",
    tableLightHover: "#febeb1",
    light: "#f7c9be",
    extraLight: '#fcefeb',
    main: "#fb6340",
    snackbar: "#fc7c5f",
    badgeBg: "#fee6e0",
    badgeBgHover: "#f93305",
    badge: "#ff3709",
    gradientEnd: '#fbb140'
  },
  error: {
    light: "#f3a4b5",
    extraLight: '#fce6eb',
    main: "#f5365c",
    snackbar: "#f75676",
    badgeBg: "#fdd1da",
    badgeBgHover: "#e30b36",
    badge: "#f80031",
    dialogNotification: "#f56036",
    gradientEnd: '#f56036'
  },
  info: {
    main: "#11cdef",
    light: '#73e1f5',
    extraLight: '#e0faff',
    dark: '#0f7587',
    snackbar: "#37d5f2",
    badgeBg: "#aaedf9",
    badgeBgHover: "#0c9cb7",
    badge: "#03acca",
    gradientEnd: '#1171ef'
  },
  background: {
    default: "#f8f9fe",
  },
  text: {
    primary: "#525f7f",
  },
  dark: {
    tableBorder: "#1f3a68",
    tableHeadColor: "#4d7bca",
    tableHeadBgColor: "#1c345d",
    main: "#172b4d",
    light: '#8a9ebf',
    extraLight: '#e8f0fc',
    dark: "#0b1526",
    snackbar: "#3c4d69",
    badgeBg: "#4172c6",
    badgeBgHover: "#09111e",
    gradientEnd: '#3d78db'
  },
  success: {
    tableLightHover: "#afecd2",
    tableLight: "#c4f1de",
    main: "#2dce89",
    dark: '#00874d',
    light: '#a7dbc5',
    extraLight: '#ebfff6',
    snackbar: "#4fd69c",
    badgeBg: "#b0eed3",
    badgeBgHover: "#229c68",
    badge: "#1aae6f",
    gradientEnd: '#2dcecc'
  },
  droppedTrip: {
    main: '#b20000',
    cluster: '#7f0000',
    highlight: '#ffa3a3'
  },
  badgeGray: {
    backgroundColor: '#f4f5f7',
    color: '#525f7f',
    borderColorHover: '#525f7f'
  },
  valid: {
    main: '#50C878'
  },
  invalid: {
    main: '#7f0000'
  },
  facebook: {
    main: "#3b5999",
    dark: "#2d4474",
  },
  twitter: {
    main: "#1da1f2",
    dark: "#0c85d0",
  },
  google: {
    main: "#dd4b39",
    dark: "#c23321",
  },
  instagram: {
    main: "#e4405f",
    dark: "#d31e40",
  },
  pinterest: {
    main: "#bd081c",
    dark: "#8c0615",
  },
  youtube: {
    main: "#cd201f",
    dark: "#a11918",
  },
  vimeo: {
    main: "#04A0F0",
    dark: "#037fbe",
  },
  slack: {
    main: "#3aaf85",
    dark: "#2d8968",
  },
  dribbble: {
    main: "#ea4c89",
    dark: "#e51e6b",
  },
  github: {
    main: "#222222",
    dark: "#090909",
  },
};

export default themeColors;
