import React from "react";
// react plugin used to create datetimepicker
// plugin for drag-and-drop files
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from '@mui/icons-material/Delete';

import Grid from "@material-ui/core/Grid";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip from "@material-ui/core/Tooltip";
import componentStyles from "assets/theme/views/admin/data-entry-form.js";

const useStyles = makeStyles(componentStyles);

const FormOptionsButtonPanel = (props) => {
    const classes = { ...useStyles() };

    const getSubmitButtonClasses = () => {
        if (props.valid) {
          return {root: classes.buttonContainedSuccess}
        } else {
          return {root: classes.buttonOutlineError}
        }
    }
    

    return (
        <Grid container>
            <Grid item xs={12} sm={8} style={{padding:'5px'}}>
            <Tooltip title={props.addButtonTooltip}>
                <Button classes={getSubmitButtonClasses()} style={{padding:'5px'}} variant="outlined" onClick={() => props.submitForm()} fullWidth>
                    {props.title}
                </Button>
                </Tooltip>                  
            </Grid>

            {props.resetForm && 
            <Grid item xs={6} sm={4}  style={{padding:'5px', textAlign:'center'}}>
                <Tooltip title="Reset form">
                    <Button variant="contained"
                            style={{paddingRight:'5px', paddingLeft:'12px', paddingTop:'5px', paddingBottom:'5px'}}  
                            startIcon={<RestartAltIcon />}
                            classes={{root: classes.buttonContainedWarning}}
                            onClick={(event) => {event.stopPropagation(); props.resetForm()}}
                            size={'small'}
                            fullWidth
                    >
                    </Button>
                </Tooltip>                  
            </Grid>}
            {props.deleteRecord && <Grid item xs={6} sm={4}  style={{padding:'5px', textAlign:'center'}}>
            <Tooltip title="Delete record">
                <Button variant="contained"
                        style={{paddingRight:'5px', paddingLeft:'12px', paddingTop:'5px', paddingBottom:'5px'}}  
                        startIcon={<DeleteIcon />}
                        classes={{root: classes.buttonContainedError}}
                        onClick={ props.deleteRecord()}
                        size={'small'}
                        fullWidth
                />
            </Tooltip>      
            </Grid>}
        </Grid>
    )
}

export default FormOptionsButtonPanel;