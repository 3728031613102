import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsRailwayIcon from '@mui/icons-material/DirectionsRailway';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import ElectricMopedIcon from '@mui/icons-material/ElectricMoped';
import ElectricScooterIcon from '@mui/icons-material/ElectricScooter';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RoomIcon from '@mui/icons-material/Room';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Icon from '@mui/material/Icon';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RouteIcon from '@mui/icons-material/Route';
import PolylineIcon from '@mui/icons-material/Polyline';


const allIcons = ['Car', 'Airplane', 'Truck', 'Walk', 'Rail', 'Bus', 'Boat', 'Bicycle', 'EV', 'Moped', 'Scooter', 'E-bike'];

export default function getIconFromName(iconName, iconProps) {
    switch (iconName) {
        case 'Bus':
          return <DirectionsBusFilledIcon {...iconProps}></DirectionsBusFilledIcon>
        case 'Car':
            return <DirectionsCarIcon {...iconProps}></DirectionsCarIcon>
        case 'Rail':
          return <DirectionsRailwayIcon {...iconProps}></DirectionsRailwayIcon>
        case 'Boat':
            return <DirectionsBoatIcon {...iconProps}></DirectionsBoatIcon>
        case 'Delivery':
          return <DeliveryDiningIcon {...iconProps}></DeliveryDiningIcon>
        case 'E-bike':
            return <ElectricBikeIcon {...iconProps}></ElectricBikeIcon>
        case 'Scooter':
          return <ElectricScooterIcon {...iconProps}></ElectricScooterIcon>
        case 'EV':
            return <ElectricCarIcon {...iconProps}></ElectricCarIcon>
        case 'Moped':
          return <ElectricMopedIcon {...iconProps}></ElectricMopedIcon>
        case 'Bicycle':
            return <PedalBikeIcon {...iconProps}></PedalBikeIcon>
        case 'Airplane':
          return <AirplanemodeActiveIcon {...iconProps}></AirplanemodeActiveIcon>
        case 'Walk':
            return <DirectionsWalkIcon {...iconProps}></DirectionsWalkIcon>
        case 'Truck':
            return <LocalShippingIcon {...iconProps}/>;
        case 'LocationPin':
          return <RoomIcon {...iconProps}/>;
        case "Trip":
          const srcTrip = require("assets/img/icons/trip.svg").default;
          return <>
          <Icon style={{verticalAlign:'baseline', height:'100%', width:'100%'}}>
          <img src={srcTrip}  height={'100%'} width={'100%'} style={{'verticalAlign':'baseline'}}/>
          </Icon>
          </>
        case "Cost":
          return <AttachMoneyIcon {...iconProps}/>
        case "Optimizer":
          return <PlayArrowIcon {...iconProps}/>
        case "VehicleType":
          const srcVehicleType = require("assets/img/icons/vehicle_type.svg").default;
          return <>
          <Icon style={{verticalAlign:'baseline', height:'100%', width:'100%'}}>
          <img src={srcVehicleType}  height={'100%'} width={'100%'} style={{'verticalAlign':'baseline'}}/>
          </Icon>
          </>
        case "traveling_salesman":
          const src_traveling_salesman = require("assets/img/icons/traveling_salesman_icon.svg").default;
          return <>
          <Icon style={{textAlign:'center', height:'120%', width:'120%', marginBottom:'5px', margin:'-5px'}}>
          <img src={src_traveling_salesman}  height={'100%'} width={'100%'}/>
          </Icon>
          </>
        case "vehicle_routing":
          return <>
          <PolylineIcon {...iconProps}/>
          </>
        case "vr_pickup_deliveries":
          return <>
          <RouteIcon {...iconProps}/>
          </>
        default:
            throw new Error('Unexpected icon name input: Received: ' + String(iconName));
      }
}

export function getAllIcons() {
    
    var retList = [];
    for (var i = 0; i < allIcons.length; i++) {
        const icon = getIconFromName(allIcons[i]);
        retList.push(icon)
    }
    return {'icons': retList, 'iconNames': allIcons};
}

export function getIdxFromName(iconName) {
    return allIcons.indexOf(iconName);
}