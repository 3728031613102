
const colors = {
    'AliceBlue': '#F0F8FF',
    'AntiqueWhite': '#FAEBD7',
    'Aqua': '#00FFFF',
    'Aquamarine': '#7FFFD4',
    'Azure': '#F0FFFF',
    'Beige': '#F5F5DC',
    'Bisque': '#FFE4C4',
    'Black': '#000000',
    'BlanchedAlmond': '#FFEBCD',
    'Blue': '#0000FF',
    'BlueViolet': '#8A2BE2',
    'Brown': '#A52A2A',
    'BurlyWood': '#DEB887',
    'CadetBlue': '#5F9EA0',
    'Chartreuse': '#7FFF00',
    'Chocolate': '#D2691E',
    'Coral': '#FF7F50',
    'CornflowerBlue': '#6495ED',
    'Cornsilk': '#FFF8DC',
    'Crimson': '#DC143C',
    'Cyan': '#00FFFF',
    'DarkBlue': '#00008B',
    'DarkCyan': '#008B8B',
    'DarkGoldenRod': '#B8860B',
    'DarkGray': '#A9A9A9',
    'DarkGreen': '#006400',
    'DarkKhaki': '#BDB76B',
    'DarkMagenta': '#8B008B',
    'DarkOliveGreen': '#556B2F',
    'DarkOrange': '#FF8C00',
    'DarkOrchid': '#9932CC',
    'DarkRed': '#8B0000',
    'DarkSalmon': '#E9967A',
    'DarkSeaGreen': '#8FBC8F',
    'DarkSlateBlue': '#483D8B',
    'DarkSlateGray': '#2F4F4F',
    'DarkTurquoise': '#00CED1',
    'DarkViolet': '#9400D3',
    'DeepPink': '#FF1493',
    'DeepSkyBlue': '#00BFFF',
    'DimGray': '#696969',
    'DodgerBlue': '#1E90FF',
    'FireBrick': '#B22222',
    'FloralWhite': '#FFFAF0',
    'ForestGreen': '#228B22',
    'Fuchsia': '#FF00FF',
    'Gainsboro': '#DCDCDC',
    'GhostWhite': '#F8F8FF',
    'Gold': '#FFD700',
    'GoldenRod': '#DAA520',
    'Gray': '#808080',
    'Green': '#008000',
    'GreenYellow': '#ADFF2F',
    'HoneyDew': '#F0FFF0',
    'HotPink': '#FF69B4',
    'IndianRed': '#CD5C5C',
    'Indigo': '#4B0082',
    'Ivory': '#FFFFF0',
    'Khaki': '#F0E68C',
    'Lavender': '#E6E6FA',
    'LavenderBlush': '#FFF0F5',
    'LawnGreen': '#7CFC00',
    'LemonChiffon': '#FFFACD',
    'LightBlue': '#ADD8E6',
    'LightCoral': '#F08080',
    'LightCyan': '#E0FFFF',
    'LightGoldenRodYellow': '#FAFAD2',
    'LightGray': '#D3D3D3',
    'LightGreen': '#90EE90',
    'LightPink': '#FFB6C1',
    'LightSalmon': '#FFA07A',
    'LightSeaGreen': '#20B2AA',
    'LightSkyBlue': '#87CEFA',
    'LightSlateGray': '#778899',
    'LightSteelBlue': '#B0C4DE',
    'LightYellow': '#FFFFE0',
    'Lime': '#00FF00',
    'LimeGreen': '#32CD32',
    'Linen': '#FAF0E6',
    'Magenta': '#FF00FF',
    'Maroon': '#800000',
    'MediumAquaMarine': '#66CDAA',
    'MediumBlue': '#0000CD',
    'MediumOrchid': '#BA55D3',
    'MediumPurple': '#9370DB',
    'MediumSeaGreen': '#3CB371',
    'MediumSlateBlue': '#7B68EE',
    'MediumSpringGreen': '#00FA9A',
    'MediumTurquoise': '#48D1CC',
    'MediumVioletRed': '#C71585',
    'MidnightBlue': '#191970',
    'MintCream': '#F5FFFA',
    'MistyRose': '#FFE4E1',
    'Moccasin': '#FFE4B5',
    'NavajoWhite': '#FFDEAD',
    'Navy': '#000080',
    'OldLace': '#FDF5E6',
    'Olive': '#808000',
    'OliveDrab': '#6B8E23',
    'Orange': '#FFA500',
    'OrangeRed': '#FF4500',
    'Orchid': '#DA70D6',
    'PaleGoldenRod': '#EEE8AA',
    'PaleGreen': '#98FB98',
    'PaleTurquoise': '#AFEEEE',
    'PaleVioletRed': '#DB7093',
    'PapayaWhip': '#FFEFD5',
    'PeachPuff': '#FFDAB9',
    'Peru': '#CD853F',
    'Pink': '#FFC0CB',
    'Plum': '#DDA0DD',
    'PowderBlue': '#B0E0E6',
    'Purple': '#800080',
    'RebeccaPurple': '#663399',
    'Red': '#FF0000',
    'RosyBrown': '#BC8F8F',
    'RoyalBlue': '#4169E1',
    'SaddleBrown': '#8B4513',
    'Salmon': '#FA8072',
    'SandyBrown': '#F4A460',
    'SeaGreen': '#2E8B57',
    'SeaShell': '#FFF5EE',
    'Sienna': '#A0522D',
    'Silver': '#C0C0C0',
    'SkyBlue': '#87CEEB',
    'SlateBlue': '#6A5ACD',
    'SlateGray': '#708090',
    'Snow': '#FFFAFA',
    'SpringGreen': '#00FF7F',
    'SteelBlue': '#4682B4',
    'Tan': '#D2B48C',
    'Teal': '#008080',
    'Thistle': '#D8BFD8',
    'Tomato': '#FF6347',
    'Turquoise': '#40E0D0',
    'Violet': '#EE82EE',
    'Wheat': '#F5DEB3',
    'White': '#FFFFFF',
    'WhiteSmoke': '#F5F5F5',
    'Yellow': '#FFFF00',
    'YellowGreen': '#9ACD32'
};

export const reverseColors = {
    '#F0F8FF': 'AliceBlue',
    '#FAEBD7': 'AntiqueWhite',
    '#00FFFF': 'Aqua',
    '#7FFFD4': 'Aquamarine',
    '#F0FFFF': 'Azure',
    '#F5F5DC': 'Beige',
    '#FFE4C4': 'Bisque',
    '#000000': 'Black',
    '#FFEBCD': 'BlanchedAlmond',
    '#0000FF': 'Blue',
    '#8A2BE2': 'BlueViolet',
    '#A52A2A': 'Brown',
    '#DEB887': 'BurlyWood',
    '#5F9EA0': 'CadetBlue',
    '#7FFF00': 'Chartreuse',
    '#D2691E': 'Chocolate',
    '#FF7F50': 'Coral',
    '#6495ED': 'CornflowerBlue',
    '#FFF8DC': 'Cornsilk',
    '#DC143C': 'Crimson',
    '#008B8B': 'DarkCyan',
    '#B8860B': 'DarkGoldenRod',
    '#A9A9A9': 'DarkGray',
    '#006400': 'DarkGreen',
    '#BDB76B': 'DarkKhaki',
    '#8B008B': 'DarkMagenta',
    '#556B2F': 'DarkOliveGreen',
    '#FF8C00': 'DarkOrange',
    '#9932CC': 'DarkOrchid',
    '#8B0000': 'DarkRed',
    '#E9967A': 'DarkSalmon',
    '#8FBC8F': 'DarkSeaGreen',
    '#483D8B': 'DarkSlateBlue',
    '#2F4F4F': 'DarkSlateGray',
    '#00CED1': 'DarkTurquoise',
    '#9400D3': 'DarkViolet',
    '#FF1493': 'DeepPink',
    '#00BFFF': 'DeepSkyBlue',
    '#696969': 'DimGray',
    '#1E90FF': 'DodgerBlue',
    '#B22222': 'FireBrick',
    '#FFFAF0': 'FloralWhite',
    '#228B22': 'ForestGreen',
    '#FF00FF': 'Fuchsia',
    '#DCDCDC': 'Gainsboro',
    '#F8F8FF': 'GhostWhite',
    '#FFD700': 'Gold',
    '#DAA520': 'GoldenRod',
    '#808080': 'Gray',
    '#008000': 'Green',
    '#ADFF2F': 'GreenYellow',
    '#F0FFF0': 'HoneyDew',
    '#FF69B4': 'HotPink',
    '#CD5C5C': 'IndianRed',
    '#4B0082': 'Indigo',
    '#FFFFF0': 'Ivory',
    '#F0E68C': 'Khaki',
    '#E6E6FA': 'Lavender',
    '#FFF0F5': 'LavenderBlush',
    '#7CFC00': 'LawnGreen',
    '#FFFACD': 'LemonChiffon',
    '#ADD8E6': 'LightBlue',
    '#F08080': 'LightCoral',
    '#E0FFFF': 'LightCyan',
    '#FAFAD2': 'LightGoldenRodYellow',
    '#D3D3D3': 'LightGray',
    '#90EE90': 'LightGreen',
    '#FFB6C1': 'LightPink',
    '#FFA07A': 'LightSalmon',
    '#20B2AA': 'LightSeaGreen',
    '#87CEFA': 'LightSkyBlue',
    '#778899': 'LightSlateGray',
    '#B0C4DE': 'LightSteelBlue',
    '#FFFFE0': 'LightYellow',
    '#00FF00': 'Lime',
    '#32CD32': 'LimeGreen',
    '#FAF0E6': 'Linen',
    '#FF00FF': 'Magenta',
    '#800000': 'Maroon',
    '#66CDAA': 'MediumAquaMarine',
    '#0000CD': 'MediumBlue',
    '#BA55D3': 'MediumOrchid',
    '#9370DB': 'MediumPurple',
    '#3CB371': 'MediumSeaGreen',
    '#7B68EE': 'MediumSlateBlue',
    '#00FA9A': 'MediumSpringGreen',
    '#48D1CC': 'MediumTurquoise',
    '#C71585': 'MediumVioletRed',
    '#191970': 'MidnightBlue',
    '#F5FFFA': 'MintCream',
    '#FFE4E1': 'MistyRose',
    '#FFE4B5': 'Moccasin',
    '#FFDEAD': 'NavajoWhite',
    '#000080': 'Navy',
    '#FDF5E6': 'OldLace',
    '#808000': 'Olive',
    '#6B8E23': 'OliveDrab',
    '#FFA500': 'Orange',
    '#FF4500': 'OrangeRed',
    '#DA70D6': 'Orchid',
    '#EEE8AA': 'PaleGoldenRod',
    '#98FB98': 'PaleGreen',
    '#AFEEEE': 'PaleTurquoise',
    '#DB7093': 'PaleVioletRed',
    '#FFEFD5': 'PapayaWhip',
    '#FFDAB9': 'PeachPuff',
    '#CD853F': 'Peru',
    '#FFC0CB': 'Pink',
    '#DDA0DD': 'Plum',
    '#B0E0E6': 'PowderBlue',
    '#800080': 'Purple',
    '#663399': 'RebeccaPurple',
    '#FF0000': 'Red',
    '#BC8F8F': 'RosyBrown',
    '#4169E1': 'RoyalBlue',
    '#8B4513': 'SaddleBrown',
    '#FA8072': 'Salmon',
    '#F4A460': 'SandyBrown',
    '#2E8B57': 'SeaGreen',
    '#FFF5EE': 'SeaShell',
    '#A0522D': 'Sienna',
    '#C0C0C0': 'Silver',
    '#87CEEB': 'SkyBlue',
    '#6A5ACD': 'SlateBlue',
    '#708090': 'SlateGray',
    '#FFFAFA': 'Snow',
    '#00FF7F': 'SpringGreen',
    '#4682B4': 'SteelBlue',
    '#D2B48C': 'Tan',
    '#008080': 'Teal',
    '#D8BFD8': 'Thistle',
    '#FF6347': 'Tomato',
    '#40E0D0': 'Turquoise',
    '#EE82EE': 'Violet',
    '#F5DEB3': 'Wheat',
    '#FFFFFF': 'White',
    '#F5F5F5': 'WhiteSmoke',
    '#FFFF00': 'Yellow',
    '#9ACD32': 'YellowGreen'
};

export default colors;