
function isNumeric(str) {
    return  !/[^0-9./+/-]/g.test(str);
  }

export default function numericValidator (args) {
    // const newValue = parseFloat(args.newValue);
    const newValue = args.newValue;
    const columnName = args.columnName;
    if (!isNumeric(String(newValue))) {
      return {
        valid: false,
        message: 'Input should be numeric for column: ' + columnName
      };
    }

    var requirePositive = false;
    if (args['requirePositive']) {
        requirePositive = true
    }
    if (requirePositive) {
        if (newValue <= 0) {
            return {
                valid: false,
                message: 'Input must be greater than zero for column: ' + columnName
            }
        }
    }

    var requireNonNegative = false;
    if (args['requireNonNegative']) {
        requireNonNegative = true
    }
    if (requireNonNegative) {
        if (newValue < 0) {
            return {
                valid: false,
                message: 'Input must be greater than or equal to 0 for column: ' + columnName
            }
        }
    }

    var requireInteger = false;
    if (args['requireInteger']) {
        requireInteger = true
    }
    if (requireInteger) {
        if (parseInt(newValue) != newValue) {
            return {
                valid: false,
                message: 'Input must be an integer for column: ' + columnName
            }
        }
    }

    return {valid: true}
  }
