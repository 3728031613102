/*
This component is used to edit or add a single row of data. It uses the ag-Grid library 
to display the data in a horizontal table format. The table is editable, and the user 
can change the values of the cells. 
*/


import React, { useState} from "react";

import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import 'ag-grid-enterprise';

import colors from 'views/admin/Tables/Ag-grid/colors.js';
import { parseColor } from 'functions/general/color_conversion.js';

import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-balham.css"; // Theme
import 'views/admin/Tables/Ag-grid/ag-theme-balham-single.css'

import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import {
  selectRow,
  selectTableSettings,
  getTableDropdownValues,
} from 'store/reducers/inputReducer';
import modifyInput from 'functions/general/input_modifiers.js';

import { getAgGridType } from 'views/admin/Tables/Ag-grid/utils.js';

import 'material-icons/iconfont/material-icons.css';

const allStaticTableSettings = require('store/data/table_settings_static.json')

const AgDataEntryForm = (props) => {
  
  const data = useSelector(selectRow(props.tableName, props.uuid, true), isEqual).value;
  const [entries, setEntries] = useState({...data});
  const tableSettings = useSelector(selectTableSettings(props.tableName));
  const staticTableSettings = allStaticTableSettings[props.tableName];
  const dropdownOptions = useSelector(getTableDropdownValues(props.tableName));

  // defines the column definitions for the labels
  const colDefs = {'Header': 
    {
      field: 'Header',
      headerName: 'Header',
      minWidth: 120,
      width: 120,
      flex: 1,
      wrapText: true,
      autoHeight: true,
      type: 'text',
      editable: false,
      suppressCellFocus: true,
      suppressCellSelection: true,
      cellStyle: params => {
        return {
          fontWeight: 'bold',
          textAlign: 'right',
          border: 'none'
        };
      }
    }
  };

  // defines the column definitions for the values
  const keys = Object.keys(data);
  for (let colName of keys) {
      if (tableSettings['isViewable'][colName]) {
          const columnWidth = 140;
          const dataType = staticTableSettings['dataType'][colName];
          const type = getAgGridType(dataType, colName, staticTableSettings, dropdownOptions);
          const headerTooltip = staticTableSettings['headerTooltip'] && staticTableSettings['headerTooltip'][colName];
          colDefs[colName] = {
              'field': 'Value',
              'headerName': colName,
              minWidth: columnWidth * 0.8,
              width: columnWidth,
              flex: 0,
              'editable': true,
              'sortable': false,
              'wrapText': false,
              valueSetter: (params) => {
                updateEntries(params, dataType, colName);
                return true;
              },
              cellStyle: params => {
                return {
                  textAlign: 'left'
                };
              },
              headerTooltip: headerTooltip,
              ...type
          }
      }
    }
  
  const updateEntries = (params, dataType, field) => {
    /**
     * Updates the entries based on the new value
     * @param {Object} params - The params object from ag-Grid
     * @param {string} dataType - The data type of the field
     * @param {string} field - The field name. Must be passed directly because
     *  only the header name is passed in params.
     */
    var newValue = params.newValue;
    var newFormEntries = {...entries};
    if (dataType === 'color') {
      if (newValue in colors)
        newValue = colors[newValue];
      else { 
        if (!parseColor(newValue)) {
          newValue = null;
        }
      }
    }
    newValue = modifyInput(newValue, dataType);

    newFormEntries[field] = newValue;
    props.setEntries(newFormEntries);
    setEntries(newFormEntries);
    params.data.Value = newValue;
    
  }


  return (
    
    <>
      <div style={{ height: "200px", width: "100%", minWidth:'240px'}}>
        <div
            className={
            "ag-theme-balham-single"
            }
            style={{ width: '100%', height: '30px'}}
        >
          {
          Object.keys(entries).map((colName, idx) => {
            if (tableSettings['isViewable'][colName]) {
              return <AgGridReact 
                key={idx} 
                rowData={[
                  {'Header': staticTableSettings['columnHeader'] && staticTableSettings['columnHeader'][colName] || colName,
                  'Value': entries[colName],
                  'UUID': entries['UUID']}
                ]}
                columnDefs={[colDefs['Header'], colDefs[colName]]}
                defaultColDef={{
                  flex: 1,
                  resizable: true,
                  editable: true,
                }}
                headerHeight={0}
                tooltipShowDelay={100}
                autoSizeAllColumns={true}
                singleClickEdit={true}
                stopEditingWhenCellsLoseFocus={true}
              />
            }
          })}
        </div>
      </div>
    
    </>
  );
  
};



export default AgDataEntryForm;