import {useState} from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import { useTheme } from "@material-ui/core/styles";
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ExitExampleModeDialog from 'views/admin/Notifications/ExitExampleModeDialog.js'

import {
    clearTableData,
    getInExampleMode,
    selectIsResultsTable
} from 'store/reducers/inputReducer.js'


function ClearTableButton(props) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [exitDialogOpen, setExitDialogOpen] = useState(false);
    const inExampleMode = useSelector(getInExampleMode);
    const isOutputTable = useSelector(selectIsResultsTable)

    const handleButtonClick = (event) => {
        event.stopPropagation();
        if (inExampleMode && !isOutputTable) {
            setExitDialogOpen(true);
        } else {
            dispatch(clearTableData({tableName:props.tableName}));
        }
    }

    const exitExampleModeDialogClose = (success=true) => {
        if (success) {
            dispatch(clearTableData({tableName:props.tableName}));
        }
        setExitDialogOpen(false);
    }

    return (<>
        <Tooltip title={'Clear table'}>
            <IconButton id='clear-table-icon-button' onClick={(event) => handleButtonClick(event)}>
                <ClearAllIcon style={{color:theme.palette.warning.main}} />
            </IconButton>
        </Tooltip>
        <ExitExampleModeDialog open={exitDialogOpen} onClose={exitExampleModeDialogClose}/>
    </>)
}


export default ClearTableButton