import {useState} from 'react';
import { useTheme } from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import TuneIcon from '@mui/icons-material/Tune';
import CostSettingsDialog from 'views/admin/Components/Dialogs/CostSettingsDialog.js';


function CostSettingsButton(props) {
    const [open, setOpen] = useState(false);

    const theme = useTheme();


    const handleClose = () => {
        setOpen(false);
    };



    const handleButtonClick = (event) => {
        event.stopPropagation();
        setOpen(true);
    }


    const handleCancel = () => {
        setOpen(false);
    }
    

    return (<>

        <Tooltip title={'Cost configurations'}>
            <IconButton id='cost-settings-icon-button' onClick={(event) => handleButtonClick(event)}>
                <TuneIcon style={{color:theme.palette.tertiary.main}} />
            </IconButton>
        </Tooltip>
        <CostSettingsDialog open={open} onClose={handleClose} handleCancel={handleCancel}/>
        
        
        </>)
}


export default CostSettingsButton