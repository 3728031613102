import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CardInfoBadges from "components/Cards/Index/CardInfoBadges.js";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import RouteIcon from '@mui/icons-material/Route';
import componentStyles from "assets/theme/components/headers/index-header.js";

const useStyles = makeStyles(componentStyles);

const IndexHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <Box
        className={classes.header}
        position="relative"
        paddingTop="3rem"
        paddingBottom="8rem"
        id='IndexHeader-Box-1'
        style={{flex: 1}}
      >
        <Container maxWidth="xl">
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} >
                <Box paddingRight="3rem" paddingTop='1.5rem'>
                  <Typography
                    variant="h1"
                    component="h1"
                    className={classes.typographyH1}
                  >
                    Optimiciti Routing & Scheduling
                  </Typography>
                  <Typography
                    variant="h2"
                    component="h2"
                    className={classes.typographyH2}
                  >
                    A customizable tool for solving routing and scheduling problems.
                  </Typography>
                  <Box
                    component="p"
                    color={theme.palette.white.main}
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1rem"
                    marginBottom="1rem"
                  >
                    Traveling salesman, vehicle routing, capacitated vehicle routing, vehicle routing with 
                    pickup and dropoff windows, multiple vehicle routing, and more! Choose a template from the
                    boxes on the right, or use our guided wizard to start building your solution.
                  </Box>
                  <Box marginTop="3rem">
                    <Button
                      component={Link}
                      variant="contained"
                      to="/model-builder"
                      classes={{ root: classes.buttonRoot }}
                    >
                      Model Builder
                    </Button>

                  </Box>
                </Box>
              </Grid>
              <Grid container xs={12} sm={12} md={12} lg={6} xl={6} id='right-side-main' style={{display:'inline-flex', paddingLeft:'10px'}}>
                <Grid item xs={12} md={12} xl={6} id='right-side-left' style={{paddingRight:'0px', paddingBottom:'0px'}}>
                  <Grid container xs={12} md={12} style={{paddingRight:'0px', paddingBottom:'0px'}}>
                    <Grid item xs={12} md={12} style={{padding:'0px', paddingBottom:'15px'}}>
                      <CardInfoBadges
                        subtitle="In this problem, one or more vehicles must be routed to visit a set of locations. Optionally, the vehicles may have limited capacity and the locations may have time constraints."
                        title="Vehicle Routing"
                        icon={RouteIcon}
                        color="blue"
                        href="models/vehicle-routing"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} style={{padding:'0px', paddingBottom:'15px'}}>
                      <CardInfoBadges
                        icon={RouteIcon}
                        color="orange"
                        title="Examples"
                        subtitle="Not sure where to get started? Check out our library of examples and use cases."
                        href="/use-cases"
                        buttonLabel='View Examples'
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} xl={6} style={{paddingRight:'0px', paddingBottom:'0px'}}>
                  <Grid container xs={12} md={12} style={{paddingRight:'0px', paddingBottom:'0px'}}>
                    <Grid item xs={12} md={12} style={{padding:'0px', paddingBottom:'15px'}}>
                      <CardInfoBadges
                        icon={RouteIcon}
                        color="green"
                        title="Delivery routing"
                        subtitle="In this model, one or more vehicles are routed to complete trips between origins and destinations. Optionally, the vehicles may have limited capacity and the locations may have time constraints."
                        href="models/pickup-dropoff"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box
          position="absolute"
          zIndex="100"
          height="70px"
          top="auto"
          bottom="0"
          pointerEvents="none"
          left="0"
          right="0"
          width="100%"
          overflow="hidden"
          transform="translateZ(0)"
        >
          <Box
            bottom="0"
            position="absolute"
            pointerEvents="none"
            component="svg"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <Box
              component="polygon"
              fill={theme.palette.dark.main}
              points="2560 0 2560 100 0 100"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IndexHeader;
