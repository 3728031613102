import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import TableViewIcon from '@mui/icons-material/TableView';
import DataObjectIcon from '@mui/icons-material/DataObject';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import {
    selectDownloadType, 
    updateGeneralSetting
} from 'store/reducers/inputReducer.js'

export default function SimpleDialog(props) {
  const { onClose, open } = props;

    const downloadType = useSelector(selectDownloadType);
    const dispatch = useDispatch()

  const handleClose = () => {
      if (downloadType) {
        onClose();
      }

  };

  const handleCancel = () => {
    dispatch(updateGeneralSetting({settingName: 'downloadTypeKeepSelection', value: false}))
    props.handleCancel()
  }

  const handleListItemClick = (event, value) => {
    // onClose(value);
    event.stopPropagation();
    dispatch(updateGeneralSetting({settingName: 'downloadType', value: value}))
  };

  const handleCheckboxChange = (event) => {
      const checked =  event.target.checked
      dispatch(updateGeneralSetting({settingName: 'downloadTypeKeepSelection', value: checked}))
  }

  return (
    <Dialog onClose={handleClose} open={open} onClick={(event) => event.stopPropagation()}>
      <DialogTitle>Select export format</DialogTitle>
      <DialogContent>
      <Stack direction='column' >
        <Stack direction="row" spacing={2} style={{padding:'5px'}}>
                <Button variant={downloadType == 'json' ? "contained": "outlined"} startIcon={<DataObjectIcon />}
                onClick={(event) => handleListItemClick(event, 'json')}
                >
                    .json
                </Button>

                <Button variant={downloadType == 'csv' ? "contained": "outlined"}  startIcon={<TableViewIcon />}
                onClick={(event) => handleListItemClick(event, 'csv')}
                >
                    .csv
                </Button>

                <Button variant={downloadType == 'xlsx' ? "contained": "outlined"} startIcon={<TableViewIcon />}
                onClick={(event) => handleListItemClick(event, 'xlsx')}
                >
                    .xlsx
                </Button>
            </Stack>
            <FormControlLabel control={<Checkbox defaultChecked={false} onChange={handleCheckboxChange}/>} label="Remember selection" />
            <Grid container >
                <Grid item xs={12} sm={8}>
                    <Button variant={downloadType ? "contained": "outlined"}
                        onClick={(event) => {event.stopPropagation(); handleClose()}} fullWidth
                        >
                        Download
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} padding={'0px'}>
                    <Button variant={"contained"}
                            onClick={(event) => {event.stopPropagation(); handleCancel()}} color='error'
                            fullWidth 
                            >
                            Cancel
                    </Button>                
                </Grid>
            </Grid>
        </Stack>
        </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};


