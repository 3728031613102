const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']

async function join_email_list({name='', email='', source='', message='', organization=''}) {
    /**
     * This function adds a user to the email list, along with additional information.
     * 
     * @param {string} name - The name of the user.
     * @param {string} email - The email of the user.
     * @param {string} source - The source of the user.
     * @param {string} message - The message of the user.
     * @param {string} organization - The organization of the user.
     */

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ 
            'name': name,
            'email': email,
            'source':source,
            'message':message,
            'organization':organization
        })
    };
    var response
    const fetchStr = BASE_ROUTE + '/join_email_list'
    try {
        response = await fetch(fetchStr, requestOptions)
        if (!response.ok) {
            throw 'Invalid response received from join_email_list'
        }
        const retJson = await response.json()
        return retJson
    } catch (err) {
        throw err
    }
}

export default join_email_list