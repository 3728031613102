
import React, {useState, useEffect} from "react";
import classnames from "classnames";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from 'react-redux';
import {submitMessage} from 'store/reducers/inputReducer.js'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";


const About = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [firstMount, setFirstMount] = useState(true);
  const [nameFocused, setNameFocused] = useState(null);
  const [emailFocused, setEmailFocused] = useState(null);
  
  useEffect(()=>{
    if (firstMount) {
      setFirstMount(false);
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      // this.refs.main.scrollTop = 0;
    }
  })

  const dispatchSubmitMessage = () => {
    dispatch(submitMessage({email: email, name: name, message: message}))
    setEmail('');
    setName('');
    setMessage('')
  }

    return (
      <>
          <div className="position-relative" >
            <section className="section section-lg section-shaped" style={{paddingTop:'4rem', paddingBottom:'1rem !important'}}>
              <div className="shape shape-style-1 shape-default" style={{borderBottom:'2px solid white'}}>
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="8">
                      <h1 className="display-3 text-white">
                        No-code optimization with examples and guides
                      </h1>
                      <p className="lead text-white">
                        Includes pre-built models for routing, scheduling, pickup and drop-offs, and more. Check out our models below, or use an example to get started.
                      </p>
                      <div className="btn-wrapper" style={{display:'flex', justifyContent: 'flex-start'}}>
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="secondary"
                          href="/models/vehicle-routing"
                          onClick={submitMessage}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span>
                          <span >Vehicle Routing</span>
                        </Button>
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="secondary"
                          href="/models/pickup-dropoff"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span>
                          <span >Pickup and Delivery Routing</span>
                        </Button>
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="warning"
                          href="use-cases"
                        >
                          {/* <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span> */}
                          <span >Examples</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
          </div>

          {/* <section className="section section-lg" style={{paddingTop:'0rem', paddingBottom:'2rem',}}>
            <Container style={{marginBottom:'0px important!'}}>
              <Row className="justify-content-center text-center" style={{marginBottom:'0px !important'}}>
                <Col lg="8">
                  <h2 className="display-3" style={{margin:'0px'}}>The Team</h2>
                </Col>
              </Row>
              <Row>
                <p className="description mt-3" style={{marginBottom:'0px !important'}}>
                            Optimiciti is a small team of data scientists who believe that many common routing, scheduling, and other optimization problems
                            should be solveable on an ad-hoc basis by non-technical users. No need for expensive consultants with long delivery times. We know
                            because we used to be those consultants.
                </p>
              </Row>
              <Row style={{paddingTop:'10px'}}>
                <Col className="mb-5 mb-lg-0" lg="6" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={'https://optimiciti-images.s3.us-east-2.amazonaws.com/about/dan.jpg'}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Dan Kinn</span>
                        <small className="h6 text-muted">Optimization Lead</small>
                      </h5>
                    </div>
                  </div>
                </Col>

              </Row>
            </Container>

          </section> */}

          <section style={{paddingTop:'0rem', paddingBottom:'0rem', textAlign:'center',
            background:"linear-gradient(87deg," + theme.palette.warning.main + "," + theme.palette.warning.gradientEnd + ")"}}>
          {/* <div className="separator separator-bottom separator-skew"> */}
          <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    style={{strokeWidth:1, 
                      fill: theme.palette.white.main}} 
                    points="0 0 2560 100 2560 0"
                  />
                </svg>
                

            <Grid container style={{padding:'2rem', display:'inline-flex', maxWidth:'1040px', alignItems: 'top'}}
              spacing={2}
            >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={8} style={{width:'100%'}}>
                  <Card className="bg-gradient-secondary shadow" >
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Get in touch</h4>
                      <p className="mt-0" style={{marginBottom:'10px'}}>
                      Have some feedback for us? Suggestion? Customization request? We want to hear from you!
                      </p>
                      <FormGroup

                      >
                        <InputGroup className="input-group-alternative">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          <Input
                            placeholder="Your name"
                            type="text"
                            onFocus={() => setNameFocused(true) }
                            onBlur={() => setNameFocused(false) }
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: emailFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          <Input
                            placeholder="Email address"
                            type="email"
                            onFocus={e => setEmailFocused(true)}
                            onBlur={e => setEmailFocused(false)}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="name"
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                          value={message}
                          onChange={(event) => setMessage(event.target.value)}
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          onClick={dispatchSubmitMessage}
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                    <Card className="bg-gradient-secondary shadow" style={{'height': '100%'}}>
                      <CardBody className="p-lg-5" style={{'height': '100%'}}>
                        
                        <h4 className="mb-1">Optimiciti, Inc.</h4>
                        <div>
                        {/* <LocationOnIcon /> */}
                        <p className="mt-0">
                        <span>6151 Central Avenue</span><br />
                        <span>Indianapolis, Indiana 46220</span><br />
                        <span>United States</span><br />
                        </p>
                        </div>
                        <p className="mt-0">
                        <a href = "mailto: info@optimiciti.com">info@optimiciti.com</a>
                        </p>
                        <FormGroup
                          className={classnames("mt-5", {
                            focused: nameFocused
                          })}
                        >    
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Grid>
              </Grid>
          </section>

      </>
    );
};


export default About;