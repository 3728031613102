import {useRef} from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import MyButton from 'views/admin/Components/MyComponents/MyButton.js'
import { Link } from 'react-router-dom'

import {
    setActiveMode,
    selectActiveModelType
} from 'store/reducers/inputReducer.js'

var modelPages = require('store/data/model-pages.json');

function ExitExampleModeButton(props) {
    const navigateRef = useRef(null);
    const dispatch = useDispatch();
    const activeModelType = useSelector(selectActiveModelType);

    const clickAction = (event) => {
        event.stopPropagation();
        dispatch(setActiveMode({activeMode:'modeling'}));
        navigateRef.current.click();
    }


    const buttonProps = { onClick: (event) => clickAction(event)}

    return (<>
        <MyButton label='Exit Example Mode' variant='contained' color='warning' {...props}
        buttonProps={{...props.buttonProps, ...buttonProps}} 
        />
        <Link to={modelPages[activeModelType]} style={{display:'none'}} ref={navigateRef}/>
    </>)
}


export default ExitExampleModeButton