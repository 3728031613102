
import numericValidator from 'views/admin/Forms/functions/numeric_validator.js'
import latitudeValidator from 'views/admin/Forms/functions/latitude_validator.js'
import longitudeValidator from 'views/admin/Forms/functions/longitude_validator.js'
import uniqueValidator from 'views/admin/Forms/functions/unique_validator';
import colorString from 'color-string';


const VALID_ERROR_TYPES = ['warning', 'error', 'dropdown_mismatch']
class StandardValidationError {
    constructor(type, message, columnName, rowNumber, value) {
        if (VALID_ERROR_TYPES.includes(type)) {
            this.type = type
        } else {
            throw new Error("Error in StandardValidationError: expecting type to be in " + VALID_ERROR_TYPES + ". Received: " + type);
        }
        this.message = message;

        if (!columnName) {
            throw new Error ('columnName argument not received for StandardValidationError')
        }
        this.columnName = columnName;

        if (!rowNumber) {
            throw new Error ('rowNumber argument not received for StandardValidationError')
        }
        this.rowNumber = parseInt(rowNumber);
        this.value = value;
      }
}


//option second input table arg, since this function may be used to
//add data on to an existing table, in which case you would pass the 
//existing table as well as the new rows that have already been
//validatated
export default function validateInputRow(args) {
    const row = args['row'];
    const existingData = args['existingData']; //should be an array of arrays;
    const tableSettings = args['tableSettings'];
    const rowNumber = args['rowNumber'];
    const allData = args['allData']; // need all data to validate dropdowns;
    const mismatches = args['mismatches'] || {}

    const newRow = {...row}
    const validationMessages = [];

    const validationErrors = {'warning': {'required': []}, 'error': [], 'mismatches': []}
    const newMismatches = {...mismatches} // use this to keep sets of the mismatches
    for (const [colName, val] of Object.entries(row)) {
        const validationType = tableSettings['validation'][colName];
        const required = tableSettings['isRequired'][colName];
        const dataType = tableSettings['dataType'][colName];

        if (dataType === 'dropdown') {
            const dropdownSource = tableSettings['dropdownSource'][colName];
            var uniqueValues;
            const dataField = dropdownSource['dataField']
            const dataColumn = dropdownSource['column']
            uniqueValues = [...new Set(allData[dataField].map(row => row[dataColumn]))];
            if (uniqueValues.includes(val)) {
            } else {
                if (!newMismatches[dataField]) {
                    newMismatches[dataField] = {}
                }
                if (!newMismatches[dataField][dataColumn]) {
                    newMismatches[dataField][dataColumn] = {newValues: new Set(), sourceColumn: colName}
                }
                newMismatches[dataField][dataColumn]['newValues'].add(val);
            }

        }

        if (required & !val) {
            const requiredError = new StandardValidationError('warning', 'Required entry not provided', colName, rowNumber, val);
            validationErrors['warning']['required'].push(requiredError);
            continue;
        } else if (!val || !validationType) {
            continue;
        }

        const validationArgs = {
            newValue: val,
            columnName: colName,
        }

        var validation;
        if (validationType === 'non-negative integer') {
            validationArgs['requireNonNegative'] = true;
            validationArgs['requireInteger'] = true;
            validation = numericValidator(validationArgs)
        }

        else if (validationType === 'latitude') {
            validation = latitudeValidator(validationArgs)
        }

        else if (validationType === 'longitude') {
            validation = longitudeValidator(validationArgs)
        }

        else if (validationType === 'numeric') {
            validationArgs['requireNonNegative'] = false;
            validationArgs['requireInteger'] = false;
            validation = numericValidator(validationArgs)
        }

        else if (validationType === 'non-negative') {
            validationArgs['requireNonNegative'] = true;
            validationArgs['requireInteger'] = false;
            validation = numericValidator(validationArgs)
        }

        else if (validationType === 'positive') {
            validationArgs['requirePositive'] = true;
            validationArgs['requireInteger'] = false;
            validation = numericValidator(validationArgs)
        }

        else if (validationType === 'unique') {
            validationArgs['row'] = row
            validationArgs['entered'] = false;
            existingData.map((data, idx) => {
                validationArgs['data'] = data;
                validation = uniqueValidator(args);
            })
        }

        else if (validationType === 'color') {
            const color = colorString.to.hex(colorString.get(val).value);
            newRow[colName] = color;
            validation = {valid: true, message:''}
        }

        else if (validationType) {
            validation = {valid: true}
        }

        if (!validation.valid) {
            validationMessages.push(validation);
        }
        
    };

    return {row: newRow, validationErrors: validationErrors, mismatches: newMismatches};
}