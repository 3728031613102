import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ColorBarTooltip from "./ColorBarTooltip";
import CustomColorPicker from "views/admin/Forms/colorbar/CustomColorPicker.js"
import get_contrast_color from 'functions/general/color_contrast.js'

class ColorBarTooltipButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            textColor: get_contrast_color(this.props.color || '#1ABC9C')
        }
        this.handleColorChange = this.handleColorChange.bind(this);
        this.getColor = this.getColor.bind(this);
    }

    handleColorChange(newColor) {
        this.setState({color:newColor});
        this.setState({textColor:get_contrast_color(newColor)});
        if (this.props.handleColorChange) {
            this.props.handleColorChange(newColor);
        }
    }

    getColor() {
        return this.state.color;
    }
   

    render() { return <>
        <ColorBarTooltip
          content={<Grid container style={{padding:'20px'}}><CustomColorPicker color={this.props.color} setColor={this.handleColorChange} 
            onClose={() => this.setState({open:false})}/> </Grid>}
          open={this.state.open}
          placement="bottom"
          onClose={() => this.setState({open:false})}
          
        >
            <Button style={{width:'80%', height:this.props.rowHeight || null, marginTop:'-1px',
                padding:'1px',
                backgroundColor: this.props.color,
                color: get_contrast_color(this.props.color),
                opacity: '.8',
                borderColor: get_contrast_color(this.props.color),
                transform:'none',
                "&:hover":{
                    backgroundColor: this.props.color,
                    opacity: '1',
                    transform:'none'
                },
                "&:disabled":{
                    backgroundColor: this.props.color
                }}} 
                variant="outlined"  id="copy-tooltip"
                onClick={() => this.setState({open:!this.state.open})}>
            {this.props.text}</Button>
        </ColorBarTooltip>
    </>
    }
}

export default React.memo(ColorBarTooltipButton)