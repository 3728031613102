import React, {useState} from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

import Notifications from "@material-ui/icons/Notifications";
import LoginModal from 'views/admin/Notifications/LoginModal.jsx';
import componentStyles from "assets/theme/views/admin/notifications.js";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesSnackbar from "assets/theme/components/snackbar.js";
import componentStylesDialog from "assets/theme/components/login-dialog.js";


const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesSnackbar = makeStyles(componentStylesSnackbar);
const useStylesButtons = makeStyles(componentStylesButtons);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const LoginRequiredDialog = (props) => {
  const classes = {
    ...useStyles(),
    ...useStylesButtons(),
    ...useStylesSnackbar(),
    ...useStylesDialog(),
  };
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);

  return (
    <>

        <Dialog
          open={props.open}
          TransitionComponent={Transition}
          onClose={() => props.handleClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          classes={{
            paper: classes.dialogNotification,
          }}
        >
          <DialogContent>
            <Box
              textAlign="center"
              paddingTop="1rem"
              paddingBottom="1rem"
            >
              <Box
                component={Notifications}
                width="3em!important"
                height="3em!important"
              ></Box>
              <Typography
                variant="h4"
                component="h4"
                className={classes.dialogHeading}
              >
                Warning!
              </Typography>
              <Typography variant="body2" component="p">
                {props.message || "You must be logged in to access this page."}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {props.handleClose(); setLoginDialogOpen(true)}}
              color="secondary"
              variant="contained"
            >
              Log-in
            </Button>
            <Button
              component={Box}
              onClick={() => props.handleClose()}
              color="secondary"
              marginLeft="auto!important"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <LoginModal open={loginDialogOpen} closeForm={() => {setLoginDialogOpen(false); props.handleClose()}} />
    </>
  );
};

export default LoginRequiredDialog;
