import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import TripLayerToggleCheckbox from 'views/admin/Maps/MapFilters/TripLayerToggleCheckbox.js';
import LocationLayerToggleCheckbox from 'views/admin/Maps/MapFilters/LocationLayerToggleCheckbox.js';
import ResultsLayerToggleCheckbox from 'views/admin/Maps/MapFilters/ResultsLayerToggleCheckbox.js';
import DropLayerToggleCheckbox from 'views/admin/Maps/MapFilters/DropLayerToggleCheckbox.js'

const LayerFilterGroup  = (props) => {

    return (

        <FormGroup style={{display:'inline-block', margin:'0px'}}>
          <TripLayerToggleCheckbox />
          <LocationLayerToggleCheckbox />
          <ResultsLayerToggleCheckbox />
          <DropLayerToggleCheckbox />
        </FormGroup>
    
    );
}

export default LayerFilterGroup;
