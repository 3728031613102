import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from "react-infinite-scroller";
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import Tooltip from "@material-ui/core/Tooltip";
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TextField from '@mui/material/TextField';
import Box from "@material-ui/core/Box";
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Card from "@material-ui/core/Card";
import Stack from '@mui/material/Stack';
// import Collapse from '@mui/material/Collapse';
import CardHeader from '@mui/material/CardHeader';
import componentStyles from "assets/theme/components/cards/dashboard/card-table-summary2.js";
import componentStylesTypography from "assets/theme/components/typography.js";
import convertSecondsToText from 'functions/general/convert_seconds_to_text.js';

import LogInRequiredListItem from 'components/Cards/Cards/LogInRequiredListItem.jsx';
import timeSince from 'functions/general/time_since.js';
import get_run_summaries_by_user from 'functions/server/get_run_summaries_by_user.js';
import {
  fetchPreviousTrial,
  selectOptimizationRuns,
  deleteOptimizationResult,
  selectIsLoggedIn,
  selectUserId
} from 'store/reducers/inputReducer';

import updateRunName from 'functions/server/update_run_name.js'

const useStyles = makeStyles(componentStyles);
const useStylesTypography = makeStyles(componentStylesTypography);

const FETCH_LIMIT = 10;

/**
 * helper function that generates each individual list item. A bit hacky in that I had to pull in the vehicle type data regardless
 * of the table name. Might be good to pull the vehicle type icon into a separate component to avoid doing this.
 * @param {*} props must have tableName, uuid
 */
function SummaryListItem(props) {

  const classes = {...useStyles(), ...useStylesTypography()};
  const headerValue = props.headerValue || ''
  const theme = useTheme();

  const buttonStyle = {
    color: theme.palette.warning.main,
    background: 'inherit',
    pointerEvents: 'all',
    boxShadow: 'none',
    '&:hover':{
        color: theme.palette.white.main,
        backgroundColor: theme.palette.warning.main,
        pointerEvents: 'all',
    }
  }
  

  return (
    <ListItem alignItems="flex-start" style={{ paddingLeft:'6px', paddingRight:'2px' }} classes={{root: classes.listItemPrimary}}>
      <ListItemText style={{margin:'0px'}}
      primary={props.headerName + " " + headerValue}
      secondary={
          <React.Fragment>
              <div>
              {Object.keys(props.subFields).map((subField, subFieldIdx) => {
              return (
                  <>
                  <div style={{'marginTop':'-5px'}}>
                  <Box
                      component="small"
                      fontSize="80%"
                      fontWeight="600"
                      color={theme.palette.gray[600]}
                      className={classes.textUppercase}
                  >
                      {subField + ": "}
                  </Box>
                  <Box
                      component="small"
                      fontSize="80%"
                      fontWeight="300"
                      color={theme.palette.gray[600]}
                      className={classes.textUppercase}
                  >
                    {props.subFields[subField]}
                  </Box>
                  </div>
                  </>)})}
              </div>
          </React.Fragment>
          
              
          }
          />
          <div style={{background:'inherit', marginRight:'2px', marginTop:'2px'}}>
            <Stack direction='column'>
            <Tooltip title='Load saved results' >
              <IconButton aria-label="load-scenario" sx={buttonStyle} onClick={(event) => {
                  event.stopPropagation();
                  props.dispatch(fetchPreviousTrial({trialId: props.trialId}))
              }} size='small'>
                <SystemUpdateAltIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title='Delete saved results' >
              <IconButton aria-label="delete-trial" sx={buttonStyle} onClick={(event) => {
                  event.stopPropagation();
                  props.dispatch(deleteOptimizationResult({run_id: props.trialId}));
              }} size='small'>
                <DeleteOutlineIcon />
                </IconButton>
            </Tooltip>
            </Stack>
          </div>

      </ListItem>
  )

}

/**
 * This component generates the scrollable list items for each element in the table 
 * @param {*} props : must have tableName
 */
function ResultsSummaryListItems(props) {
  const theme = useTheme()
  const classes = {...useStyles(), ...useStylesTypography()};
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeData, setActiveData] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState([]);
  const optimizationRuns = useSelector(selectOptimizationRuns);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userId = useSelector(selectUserId)
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editValue, setEditValue] = useState('');
  const [runNames, setRunNames] = useState([])
  const [in_, setIn] = useState(false);


    const handleClose = (idx) => {
      setAnchorEl(null);
      var popoverOpen = new Array(data.length).fill(false);
      setPopoverOpen(popoverOpen);
      if (editValue && editValue != '') {
        const runId = Object.keys(activeData[idx])[0]
        updateRunName(runId, editValue);
        setEditValue('');
        runNames[idx] = editValue;
      }
    };

    const handleCancel = () => {
      setAnchorEl(null);
      var popoverOpen = new Array(data.length).fill(false);
      setPopoverOpen(popoverOpen);      
    }

    const handleEditTextChange = (event) => {
      setEditValue(event.target.value);
    };

    const buttonStyle = {
      height: '1rem',
      width: '1rem',
      marginLeft: '5px',
      color: theme.palette.warning.main,
      background: 'inherit',
      pointerEvents: 'all',
      boxShadow: 'none',
      '&:hover':{
          color: theme.palette.white.main,
          backgroundColor: theme.palette.warning.main,
          pointerEvents: 'all',
      }
    }

  useEffect(() => {
    handleFetch({ _limit: FETCH_LIMIT });
  }, [props.collapsed, data]);

  useEffect(() => {
    setInitialized(false);
  }, [optimizationRuns])


  const editClick = (event, idx) => {
    var newPopoverOpen = [...popoverOpen];
    if (!newPopoverOpen[idx]) {
      setAnchorEl(event.currentTarget);
    }
    newPopoverOpen[idx] = !newPopoverOpen[idx];
    setPopoverOpen(newPopoverOpen);
  }

  const getResultSummaries = () => {
    try {
      get_run_summaries_by_user(userId).then(
        (res) => {
          setData(res)
          var newRunNames = [];
          res.map((item, idx) => {
            newRunNames.push(item[Object.keys(item)[0]]['run_name'])
          })
          setRunNames(newRunNames)
          var popoverOpen = new Array(res.length).fill(false);
          setPopoverOpen(popoverOpen);
          setErrorMessage(null);
        }).catch(error => {setData([]); setErrorMessage('Unable to load previous trials at this time.')});
    }        
    catch (error) {
      setData([]);
      setPopoverOpen([]);
    }
  }


  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      getResultSummaries();
    }
  })


  useEffect(() => {
    getResultSummaries();
  }, [userId])


  function handleFetch(params) {
    setActiveData(data.slice(0,params['_limit']));
  }

  const getButtonStyle = () => {
    if (in_) {
      return {transform:'rotate(180deg)', position:'absolute', top:'2px', right:'2px'}
    }
    return {position:'absolute', top:'2px', right:'2px'}
  }

  const cachedButtonStyle = {position:'absolute', right:'32px', top:'2px'};

  return (<>
  {!props.collapsed && <Card classes={{ root: classes.collapsibleCardPrimary}} style={{padding:'0px'}}>
    <CardHeader  
    sx={{'&	.MuiCardHeader-content': {display:'inline-flex'}}}
    style={{padding:'8px', display:'inline-flex'}} classes={{root: classes.cardHeaderPrimary}} subheader={<div>Saved Results
    <div>
      <Tooltip title={'Refresh'} >
        <IconButton
            onClick={() => getResultSummaries()}
            variant="contained"
            size="small"
            classes={{
            root: classes.buttonRoot,
            }}
            style={cachedButtonStyle}
        >
            <CachedIcon />
        </IconButton>
      </Tooltip>
    <IconButton
        onClick={() => {setIn(!in_)}}
        variant="contained"
        size="small"
        classes={{
        root: classes.buttonRoot,
        }}
        style={getButtonStyle()}
    >
        <ExpandCircleDownIcon />
    </IconButton></div></div>} />

    <Collapse in={in_}>
      {!isLoggedIn && <LogInRequiredListItem />}
      {isLoggedIn && <>
      {!props.collapsed && errorMessage && <>
      <Box
          component="p"
          marginBottom="0"
          fontWeight="300"
          lineHeight="1.7"
          fontSize="1rem"
          marginTop="0"
          color={theme.palette.error.main}
      >
      {errorMessage}
    </Box></>}
  {!props.collapsed && activeData.length > 0 && 
  <div style={{maxHeight: '300px', overflowY:'scroll'}} id='summary-list-items-div'>

      <InfiniteScroll
        pageStart={0}
        loadMore={() => handleFetch({ _limit: (activeData && activeData.length + FETCH_LIMIT) || FETCH_LIMIT})}
        hasMore={activeData && data && (activeData.length < data.length)}
        useWindow={false}
        loader={
          <div key="loading" className="loader">
            Loading ...
          </div>
        }
      >
          <Divider />
          {activeData.map((resultsSummary, idx) => {
            const trialId = Object.keys(resultsSummary)[0];
            const objective = resultsSummary[trialId]['summary']['Objective'];
            const objectiveUnits = resultsSummary[trialId]['summary']['Objective Unit'];
            // const runName = resultsSummary[trialId]['run_name']
            var objectiveStr = objective;
            if (objectiveUnits) {
              objectiveStr += ' ' + objectiveUnits;
            }
            return (
              <>
              <SummaryListItem
              // key={runNames[idx]} 
              headerName={timeSince(new Date(parseFloat(resultsSummary[trialId]['run_datetime_unix']) * 1000)) + ' ago'} dispatch={dispatch} trialId={trialId}
              subFields={
                {
                'Name':<><div style={{display:'inline-flex'}}>{runNames[idx]}    
                <IconButton aria-label="load-scenario" sx={buttonStyle} size='xs' aria-describedby={'edit-name-' + trialId} onClick={((event) => editClick(event, idx))}>
                <EditIcon style={{height:'0.6rem', width: '0.6rem'}} />
                </IconButton></div></>,
                'Date': new Date(parseFloat(resultsSummary[trialId]['run_datetime_unix']) * 1000).toLocaleDateString(),
                'Solve time': convertSecondsToText(0.0005),
                'Locations': resultsSummary[trialId]['summary']['Location Count'],
                'Objective': objectiveStr}}
                />
                <Popover
                  id={'edit-name-' + trialId}
                  open={popoverOpen[idx]}
                  anchorEl={anchorEl}
                  onClose={() => handleClose(idx)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <div style={{padding:'15px', maxWidth:'300px'}}> <TextField
                            id="outlined-multiline-flexible"
                            label="Trial Name"
                            multiline
                            maxRows={4}
                            value={editValue}
                            size='small'
                            onChange={handleEditTextChange}
                            fullWidth
                          />
                      <Grid container style={{margin:'0px', paddingTop:'10px'}}>
                        <Grid item xs={12} sm={8} style={{padding:'0px', paddingRight:'5px'}}>
                          <Button variant={true ? "contained": "outlined"}
                              onClick={(event) => {event.stopPropagation(); handleClose(idx)}} fullWidth
                              >
                              Save and close
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{padding:'0px'}}>
                          <Button variant={"contained"}
                                  onClick={(event) => {event.stopPropagation(); handleCancel()}} color='error'
                                  fullWidth 
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>          
                  </div>
                </Popover>
                
              <Divider />
              </>
            )
            })}
      </InfiniteScroll>
      </div>
    }
    </>}
    </Collapse>
    </Card>}
    </>
  );
}

export default ResultsSummaryListItems;
