

export const handleExitFullScreen = () => {
    // use this function to exit full screen map mode to show a dialog
    // TODO: ideally this wouldn't be necessay as dialog would be shown in full screen mode
    console.log('handleExitFullScreen call')
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
    if (fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        console.log('exiting fullscreen')
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
        console.log('exiting fullscreen')
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
        console.log('exiting fullscreen')
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
        console.log('exiting fullscreen')
      }
    }
  };