import NewFormEntryButton from 'views/admin/Forms/NewFormEntryButton.js';

const allStaticTableSettings = require('store/data/table_settings_static.json')

const NoContentFound = (props) => {
    const dropdownSource = allStaticTableSettings[props.tableName]['dropdownSource'][props.columnName];
    const referenceName = allStaticTableSettings[dropdownSource.dataField]['referenceName'];

    return (<>
    <div style={{display:'inline-flex', flexDirection:'row', verticalAlign:'bottom', alignContent:'baseline'}}>
        <div style={{display:'flex', alignItems:'center'}}>{"No " + referenceName + " entered"}</div>
    <NewFormEntryButton dataTable={dropdownSource.dataField} useBadge={false} badgeLabel={'hello'} badgeProps={{}} onOpenForm={props.onOpenForm}/>
    </div>
    </>)
}

export default NoContentFound;