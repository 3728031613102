
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch } from "react-redux";

import MyInput from 'views/admin/Components/MyComponents/MyInput.js';
import MyButton from 'views/admin/Components/MyComponents/MyButton.js'
import dialogStyles from 'assets/theme/components/dialog.js'
import componentStyles from "assets/theme/views/admin/components.js";
import ImageUploadIcon from "views/admin/Buttons/ImageUploadIcon";
import { submitBugReport } from "store/reducers/inputReducer";

const useStylesComponent = makeStyles(componentStyles);
const useStylesDialog = makeStyles(dialogStyles);


function BugDialog (props) {

    const classes = { ...useStylesDialog(), ...useStylesComponent()};
    const dispatch = useDispatch();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFileNames, setSelectedFileNames] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [email, setEmail] = useState('');
    const [bugMessage, setBugMessage] = useState('');

    const handleSubmitReport = () => {
      dispatch(submitBugReport({
        user_email:email, bug_message:bugMessage, bug_files:selectedFiles, bug_filenames:selectedFileNames
      }))
      setSubmitted(true);
    }

    const handleClose = () => {
      props.handleClose();
      setSubmitted(false);
      setBugMessage('');
      setSelectedFiles([]);
      setSelectedFileNames([]);
    }

    return (
      <div>
        <Dialog open={props.open} onClose={props.handleClose} className={classes.dialogTitle}>
          <DialogTitle>Bug report</DialogTitle>
          <DialogContent>
            {!submitted && <><DialogContentText style={{paddingBottom:'5px'}}>
              Enter a brief description of the bug. If possible, please include instructions on how to re-create the bug. We'll receive your bug report and take any necessary action to fix the bug.
            </DialogContentText>
            <MyInput label={<>Email<span>
                                <i class="material-icons helpIcon" 
                                >info</i></span></>}
                                type='email' 
                                textfieldProps={{value:email, onChange:event => {setEmail(event.target.value)}}}/>
            <MyInput textfieldProps={{type:'text', multiline:true, rows:6, 
                value:bugMessage, onChange:event => {setBugMessage(event.target.value)}}} 
                label={'Bug Description'}
              />
            <ImageUploadIcon
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              selectedFileNames={selectedFileNames}
              setSelectedFileNames={setSelectedFileNames}
            />
          </>}
          {submitted && 
            <div style={{display:'flex', flexDirection:'row'}}>
              <div style={{paddingRight:'5px'}}>
                <CheckCircleIcon color='success' style={{height:'3rem', width:'3rem'}}/>
              </div>
              <div style={{display:'flex', flexDirection:'column'}}>
                <DialogContentText style={{paddingBottom:'5px', fontWeight:600}}>Thank you for your submission!</DialogContentText>
                <DialogContentText style={{paddingBottom:'5px', fontSize:'0.8rem'}}>Our engineers will review your bug report and take any necessary action to fix the bug.</DialogContentText>
              </div>
            </div>
          }
          </DialogContent>
          <DialogActions>
            {!submitted && <>
            <MyButton label={'Cancel'} color={'warning'} variant='contained' 
              buttonProps={{
                onClick:() => props.handleClose(),
                size:'small'
            }}           
            />
            <MyButton label={'Submit report'} color={'success'} variant='contained' 
              buttonProps={{
                onClick:() => {handleSubmitReport()},
                size:'small'
            }}           
            />
            </>}
            {submitted &&
            <MyButton label={'Close'} color={'success'} variant='contained'
              buttonProps={{  
                onClick:() => handleClose(),
                size:'small' 
            }}
            />}
          </DialogActions>
        </Dialog>
      </div>
    );
  }

export default BugDialog