/*
This component is used to display a table of data. It uses the ag-Grid library. 
The table is editable, and the user can change the values of the cells.
*/

import React, {useState, useCallback} from "react";

import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import 'ag-grid-enterprise';

import colors from 'views/admin/Tables/Ag-grid/colors.js';

import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "views/admin/Tables/renderers/renderer.css";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { Tooltip } from 'react-tooltip'

import { parseColor } from 'functions/general/color_conversion.js';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectTableData,
  updateTableMulti,
  selectTableSettings,
  selectDownloadType,
  toggleTableSetting,
  updateTableSetting,
  getTableDropdownValues,
  addRow,
  deleteRow,
  selectDisabledTableSettings
} from 'store/reducers/inputReducer';
import table_to_excel, {table_to_json} from 'functions/server/format_table_request.js'
import {generateUUID} from 'store/functions/geoJsonFunctions.js';

import { getAgGridType } from "./utils";

import 'material-icons/iconfont/material-icons.css';

import NewFormEntryDialog from 'views/admin/Forms/NewFormEntryDialog.js';
import RowOptionsRenderer from "../renderers/RowOptionsRenderer";
import RowStatusRenderer from 'views/admin/Tables/renderers/RowStatusRenderer.jsx';
import MyBadge from 'views/admin/Components/MyComponents/MyBadge.js';
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY);
require('datejs');

const allStaticTableSettings = require('store/data/table_settings_static.json')

const CLIPBOARD_INDICATOR = generateUUID(); // this is used to indicate that the bulk update is in progress
const CLIPBOARD_END_INDICATOR = '_END'; // this is used to indicate that the bulk update is complete


const ToggleBadges = ({data, tableSettings, staticTableSettings, disabledSettings, toggleFunc, tableName}) => {
  const badges = []
  data && data[0] && staticTableSettings['columnName'].map((colName) => {
    if (disabledSettings && disabledSettings[colName]) {}
    else if (tableSettings['isViewable'][colName]) {
        if (staticTableSettings['isToggleable'] && staticTableSettings['isToggleable'][colName] && staticTableSettings['isToggleable'][colName]['correspondingColumn']) {
          const columnHeader = staticTableSettings['columnHeader'] && staticTableSettings['columnHeader'][colName] || colName;
          const onClick = () => toggleFunc(colName);
          const checked = staticTableSettings['isToggleable'][colName]['isPrimary'];
          const badge =  <MyBadge badgeProps={{pill: true, onClick:onClick, 
                              color:'gray', badgeContent:columnHeader,
                              style:{'paddingLeft': '10px', paddingBottom:'3px', position:'relative', marginLeft:'5px'}}}
                              color={checked ? 'success-rel' : 'gray'}/>

          badges.push(badge);
          }
        }
      });
    if (badges.length === 0) {
      return <></>
    }
    return <div id={'filter-badge-container-' + tableName} 
            style={{display:'flex', alignItems: 'center'}}>
              <span style={{fontSize: '0.8rem'}}>Enable / Disable columns: </span>{badges}
            </div>
}


const DataEntryTable = (props) => {
  const theme = useTheme();
  const data = useSelector(selectTableData(props.tableName), isEqual);
  const downloadType = useSelector(selectDownloadType);
  const tableSettings = useSelector(selectTableSettings(props.tableName));
  const staticTableSettings = allStaticTableSettings[props.tableName];
  const disabledSettings = useSelector(selectDisabledTableSettings(props.tableName));
  const [tableMounted, setTableMounted] = useState(false); 
  const [newFormEntryOpen, setNewFormEntryOpen] = useState(false);
  const dropdownOptions = useSelector(getTableDropdownValues(props.tableName));
  const [rowStatusText, setRowStatusText] = useState('');
  const DEFAULT_BULK_UPDATE = {tableName: props.tableName, newValues: [], updateColumnNames:[], uuids:[]}
  const [bulkUpdates, setBulkUpdates] = useState(DEFAULT_BULK_UPDATE);

  const onDelete = (value) => {
    dispatch(deleteRow({'uuid':value, 'tableName':props.tableName}));
  }

  const onAdd = (insertLoc, insertUuid) => {
    dispatch(addRow({'uuid':generateUUID(), 'tableName':props.tableName, 'newRow':{}, 'insertLoc': insertLoc, 'insertUuid': insertUuid}))
  }

  const onCopy = (copiedUuid) => {
    const uuid = generateUUID()
    var rowValues = {...data.find(row => row['UUID'] === copiedUuid)}
    rowValues['UUID'] = uuid;
    dispatch(addRow({
      'uuid': uuid, 
      'tableName':props.tableName, 
      newRow: rowValues, 
      'insertLoc': 'below', 
      'insertUuid': copiedUuid
    }))
  }

  const setRowStatusTextByIndex = (row) => {
    if (data[row]['Validation Message'] instanceof Array) {
      setRowStatusText(data[row]['Validation Message'].join('\r\n'))
    } else {
      setRowStatusText(data[row]['Validation Message'])
    }
  }

  const colDefs = [];
    if (!props.transpose && !staticTableSettings.isOutputTable) {
      colDefs.push({
        'field': null,
        rowDrag: true,
        multiSelect: true,
        editable: false,
        width: 25,
        maxWidth: 25,
        minWidth: 25,
        suppressMenu: true,
        resizable: false,
        sortable: false,
        headerName: ''
      });
      colDefs.push({
        'field': 'Valid',
        'cellRenderer': RowStatusRenderer,
        'headerName': '',
        'editable': false,
        'sortable': false,
        'filter': false,
        width: 25,
        maxWidth: 25,
        minWidth: 25,
        resizable: false,
        suppressMenu: true,
        cellRendererParams: {
          tooltipText:rowStatusText,
          setTooltipText: setRowStatusTextByIndex 
        }
    });
    }
    if (!props.transpose && !staticTableSettings.isOutputTable) {
      colDefs.push({
          'field': 'UUID',
          'cellRenderer': RowOptionsRenderer,
          'headerName': '',
          'editable': false,
          'sortable': false,
          'filter': false,
          width: 90,
          maxWidth: 90,
          minWidth: 90,
          resizable: false,
          suppressMenu: true,
          cellRendererParams: {
            onDelete: onDelete,
            onCopy: onCopy,
            onAdd: onAdd
          }
      });
    }

    const defaultVal = 100;
    data && data[0] && staticTableSettings['columnName'].map((colName) => {
        
        if (disabledSettings && disabledSettings[colName]) {}
        else if (tableSettings['isViewable'][colName]) {
            const isEditable = staticTableSettings['isEditable'][colName];
            var cellStyles = {};
            if (!isEditable) {
              cellStyles = {'cellStyle': {color: 'gray', 'background-color': '#d3d3d33d'}};
            }
            const isSortable = staticTableSettings['isSortable'][colName];
            const columnHeader = staticTableSettings['columnHeader'] && staticTableSettings['columnHeader'][colName] || colName;
            const columnWidth = tableSettings['columnWidth'] && tableSettings['columnWidth'][colName] || defaultVal;
            const type = getAgGridType(staticTableSettings['dataType'][colName], colName, staticTableSettings, dropdownOptions, isEditable);
            const headerTooltip = staticTableSettings['headerTooltip'] && staticTableSettings['headerTooltip'][colName];
            colDefs.push({'field': colName,
                          'headerName': columnHeader,
                          width: columnWidth,
                          flex: 0,
                          'editable': isEditable,
                          'sortable': isSortable,
                          'wrapText': true,
                          'valueSetter': (params) => {onChange(params); return true},
                          headerTooltip: headerTooltip,
                          ...type,
                          ...cellStyles
              })
        }
    });
    if (props.transpose) {

    }

  
  const dispatch = useDispatch();

  const downloadFile = (gridApi) => {
    
    const includeCols = [];
    const columnDefs = gridApi.getColumnDefs();
    for (let i = 0; i < columnDefs.length; i++) {
      const columnName = columnDefs[i].colId;
      if (!['Valid', 'UUID'].includes(columnName)) {
        includeCols.push(columnName);
      }
    }
    const dataOptions = {skipHeader: false, columnKeys: includeCols}
    if (downloadType.toUpperCase() === 'CSV' || downloadType.toUpperCase === '.CSV') {
      gridApi.exportDataAsCsv({'fileName':props.tableName + '.csv', ...dataOptions});
    }

    else if (downloadType.toUpperCase() === 'JSON' || downloadType.toUpperCase === '.JSON') {
        const csvData = gridApi.getDataAsCsv(dataOptions);
        table_to_json(csvData, props.tableName + '.json', 'json')
    }

    else {
      const csvData = gridApi.getDataAsCsv(dataOptions);
      table_to_excel(csvData, props.tableName + '.xlsx')

    }
  }

  const onChange = (params) => {
    const uuid = params.data['UUID'];
    var newValue = params.newValue;
    var isBulkUpdate = false;
    var submitBulk = false;
    if (Array.isArray(newValue)) {
      if (newValue.length > 1) {
      if (newValue[newValue.length - 2] === CLIPBOARD_INDICATOR) {
        isBulkUpdate = true;
        if (newValue[newValue.length - 1] === CLIPBOARD_END_INDICATOR) {
        submitBulk = true;
        }
        newValue = newValue[0];
      }  
      }
    }

    const field = params.colDef.field;
    if (staticTableSettings['dataType'][field] === 'color') {
      if (newValue in colors)
        newValue = colors[newValue];
      else { 
        if (!parseColor(newValue)) {
          newValue = null;
        }
      }
    }
    
    if (isBulkUpdate) {
      bulkUpdates['newValues'].push(newValue);
      bulkUpdates['updateColumnNames'].push(field);
      bulkUpdates['uuids'].push(uuid);
      if (submitBulk) {
        dispatch(updateTableMulti(bulkUpdates));
        setBulkUpdates(DEFAULT_BULK_UPDATE);
      }
    } else {
      const updates = {tableName: props.tableName, newValues: [newValue], updateColumnNames:[field], uuids:[uuid]};
      dispatch(updateTableMulti(updates));
    }
  }

  const onColumnResized = useCallback((input) => {
    /**
     * When columns are resized, we need to save that information in 
     * table settings so that the next time the table is loaded, the
     * columns are the same size as they were when the user left.
     */

    if (input && input.finished && input.column) {
      const field = input.column.colDef.field
      const width = input.column.actualWidth
      dispatch(updateTableSetting({tableName: props.tableName, settingName: 'columnWidth', columnName: field, newValue: width}))
    }
  }, []);


  const onGridReady = (event) => {
    if (!tableMounted) {
        setTableMounted(true);
        if (props.downloadOnMount) {
          downloadFile(event.api);
      }
    } else {
    }
  };


  const toggleColumns = (columnName) => {
    const columnOff = columnName
    const columnOn = staticTableSettings['isToggleable'][columnOff]['correspondingColumn']
    dispatch(toggleTableSetting({tableName: props.tableName, settingName: 'isViewable', columnName:[columnOn, columnOff]}))

  }


  function processDataFromClipboard (params) {
    const clipboardData = params.data;
    // for each entry in params.data, add the clipboard indicator to the end of the array
    clipboardData.forEach((row, row_idx) => {
      row = [row[0], CLIPBOARD_INDICATOR];
      if (row_idx == clipboardData.length - 1) {
        row.push(CLIPBOARD_END_INDICATOR);
      }
    });
    return clipboardData;
  }

  return (
    <>
    {data.length > 0 && 
      
      <div style={{ minHeight: "240px", width: "100%", height: '200px'}}>
        <ToggleBadges data={data} toggleFunc={toggleColumns} tableSettings={tableSettings} 
                  staticTableSettings={staticTableSettings} disabledSettings={disabledSettings} 
                  tableName={props.tableName}
        />
        <div
            className={
            "ag-theme-balham"
            }
            style={{ width: '100%', minHeight: '200px', height: 'calc(100% - 20px)'}}
        >
            <AgGridReact
            onGridReady={(event) => onGridReady(event)}
            rowData={data}
            columnDefs={colDefs}
            rowDragManaged={true}
            defaultColDef={{
              flex: 1,
              resizable: true,
              editable: true,
              wrapHeaderText: true,
              autoHeaderHeight: true,
            }}
            tooltipShowDelay={100}
            autoSizeAllColumns={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            enableRangeSelection={true}
            onColumnResized={
              (props) => {
                try {
                  onColumnResized(props);
                } catch (error) {
                  console.log('error resizing columns: ', error)
                }
              }
            }
            processDataFromClipboard={(params) => processDataFromClipboard(params)}
            />
        </div>
      </div>}
    {data.length === 0 && 
        <Box
        component="p"
        marginBottom="0"
        fontWeight="300"
        lineHeight="1.7"
        fontSize="1rem"
        marginTop="0"
        color={theme.palette.primary.main}
      >
        There are no entries yet in this table.&nbsp;
        <Link
            color="inherit"
            component='button'
            underline='hover'
            style={{verticalAlign:'inherit'}}
            onClick={(e) => {e.preventDefault(); setNewFormEntryOpen(true)}}
          >Click here
        </Link> to add data.
        <NewFormEntryDialog tableName={props.tableName} open={newFormEntryOpen} setOpenForm={setNewFormEntryOpen}/>

      </Box>
    }
    <Tooltip multiline={true} className={'tooltip'} id={"header-tooltip"} />  
    </>
  );  
};

export default DataEntryTable