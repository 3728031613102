
import { useState, useEffect } from "react";
import { Layer, Source} from "react-map-gl";
import {useDispatch, useSelector} from 'react-redux';
import {tripProps, arrowProps, pointProps} from 'views/admin/Maps/geomapping/Layers/common_props.js';
import {generateUUID} from 'store/functions/geoJsonFunctions.js';
import {addRow, selectMapMode} from 'store/reducers/inputReducer.js';

const AddTripLayer = (props) => {
    const [firstClickInfo, setFirstClickInfo] = useState(null);
    const mapMode = useSelector(selectMapMode)
    const dispatch = useDispatch();

    var data;
    if (mapMode !== 'addTrips') {data = {}}
    
    else if (firstClickInfo && props.mouseCoords && props.mouseCoords.hoverPointLongitude) {
        data =  {
        "type": "FeatureCollection",
        "features": [
            {type:'Feature', properties:{'type_': 'start'}, geometry:{type:'Point', coordinates:[firstClickInfo && firstClickInfo.longitude, firstClickInfo && firstClickInfo.latitude]}},
            {type:'Feature', properties:{'type_': 'tentative-line'}, 
                geometry:{type:'LineString', 
                coordinates:[
                    [firstClickInfo && firstClickInfo.longitude, firstClickInfo && firstClickInfo.latitude],
                    [props.mouseCoords && props.mouseCoords.longitude, props.mouseCoords && props.mouseCoords.latitude]]}},
            {type:'Feature', properties:{'type_': 'end'}, geometry:{type:'Point', 
                coordinates:props.mouseCoords && props.mouseCoords.hoverPointLatitude && [props.mouseCoords.hoverPointLongitude, props.mouseCoords.hoverPointLatitude]}}         
        ]
    }} 
    
    else if (firstClickInfo) {
        data =  {
        "type": "FeatureCollection",
        "features": [
            {type:'Feature', properties:{'type_': 'start'}, geometry:{type:'Point', coordinates:[firstClickInfo && firstClickInfo.longitude, firstClickInfo && firstClickInfo.latitude]}},
            {type:'Feature', properties:{'type_': 'start'}, 
                geometry:{type:'LineString', 
                coordinates:[
                    [firstClickInfo && firstClickInfo.longitude, firstClickInfo && firstClickInfo.latitude],
                    [props.mouseCoords && props.mouseCoords.longitude, props.mouseCoords && props.mouseCoords.latitude]]}},
            {type:'Feature', properties:{'type_': 'tentative-point'}, geometry:{type:'Point', coordinates:[props.mouseCoords && props.mouseCoords.longitude, props.mouseCoords && props.mouseCoords.latitude]}},       
        ]
    }}
    else {
        data = {
        "type": "FeatureCollection",
        "features": []
    }};

    const startPointLayer = {
        ...pointProps,
        id: 'start-point',
        source: 'add-trip-data',
        filter: ['==', 'start', ['get', 'type_']],
        paint: {
          ...pointProps.paint,
          'circle-color': '#11b4da',
        }
      };

      const endPointLayer = {
        ...pointProps,
        id: 'end-point',
        source: 'add-trip-data',
        // filter: ['all', [['==', '$type', 'Point'], ['==', 'type_', 'start']]],
        filter: ['==',  'end', ['get', 'type_']],
        paint: {
          ...pointProps.paint,
          'circle-color': '#00ff00',
          'circle-radius': 12,
        }
      };

      const tentativePointLayer = {
        ...pointProps,
        id: 'tentative-point',
        source: 'add-trip-data',
        // filter: ['all', [['==', '$type', 'Point'], ['==', 'type_', 'start']]],
        filter: ['==',  'tentative-point', ['get', 'type_']],
        paint: {
          ...pointProps.paint,
          'circle-color': '#000000',
          'circle-radius': 6,
        }
      };

    const tripLayerProps = {
        ...tripProps,
        id: 'add-trip-tentative-line',
        source: 'add-trip-data',
        filter: ['all', ['==', '$type', 'LineString']],
        paint: {
            ...tripProps.paint,
          'line-color': '#11b4da',
        }
      }
  
      const arrowLayerProps = {
          ...arrowProps,
        id: 'arrow-layer',
        source: 'add-trip-tentative-arrow',
        filter: ['==', '$type', 'LineString'],
        paint: {
            ...arrowProps.paint,
          "icon-color": '#11b4da',
        }
      }

      useEffect(() => {
        if (props.lastClickInfo && firstClickInfo) {
            const uuid = generateUUID();
            const fromUuid = firstClickInfo.uuid;
            const toUuid = props.lastClickInfo.uuid;

            var newRow = {
                'From Location Latitude': firstClickInfo.latitude,
                'From Location Longitude': firstClickInfo.longitude,
                'To Location Latitude': props.lastClickInfo.latitude,
                'To Location Longitude': props.lastClickInfo.longitude,
                'From UUID': fromUuid,
                'To UUID': toUuid}
            
            dispatch(addRow({newRow:newRow, tableName: 'trips', uuid:uuid}));
            setFirstClickInfo(null);
        }
        else {
            setFirstClickInfo(props.lastClickInfo);
        }
      }, [props.lastClickInfo])


    return (<>
        <Source
        id="add-trip-data"
        type="geojson"
        data={data}
        >
        <Layer {...tripLayerProps} />
        <Layer {...arrowLayerProps} />
        <Layer {...endPointLayer} />
        <Layer {...startPointLayer} />
        <Layer {...tentativePointLayer} />
        </Source>
    </>)
}

export default AddTripLayer;