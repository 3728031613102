import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useTheme } from "@material-ui/core/styles";
import DialogTitle from '@mui/material/DialogTitle';
import React from "react";
import Slide from "@material-ui/core/Slide";
import getDataTable from 'views/admin/Tables/functions/get_data_table.js'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const allStaticTableSettings = require('store/data/table_settings_static.json')

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });


/**
 * Dialog that shows the DataEntry table with title and description.
 * @param {*} props : tableName (required); onClose (required); open (required)
 */
const TableDialog = (props) => {
    const staticTableSettings = allStaticTableSettings[props.tableName];
    const theme = useTheme();

    return (
        <Dialog
        maxWidth="md"
        open={props.open}
        isOpen={true}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        TransitionProps={{ unmountOnExit: true }}
        >
            <Box display="flex" alignItems="center">
                <Box flexGrow={1} >        
                    <DialogTitle id="alert-dialog-title" style={{paddingBottom:'5px'}}>
                        {staticTableSettings['title']}
                    </DialogTitle>
                </Box>
                <Box>
                    <IconButton color={theme.palette.error.main} onClick={props.onClose}>
                          <CloseIcon />
                    </IconButton>
                </Box>
          </Box>

        <DialogContent style={{paddingTop:'0px'}}>
        <Box
            component="p"
            marginBottom="0"
            fontWeight="300"
            lineHeight="1.7"
            fontSize="1rem"
            marginTop="0"
            color={theme.palette['primary'].main}
            paddingBottom='10px'
            >
            {staticTableSettings['shortDescription']}
        </Box>

            {getDataTable(props.tableName, false, true, true)}
        </DialogContent>
    </Dialog> 
    )
}

export default TableDialog;