/**
 * This component is used within the CostSettingsTabbedForm component to display the 
 * cost settings for a given cost metric (duration, distance, cost). It allows the user
 * to select the cost metric, the method for calculating the cost, and the units for 
 * the cost.
 */

import { useTheme } from "@material-ui/core/styles";
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';

import {FormControlLabel} from '@material-ui/core'
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import 'material-icons/iconfont/material-icons.css';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from "react-redux";
import {
  selectCostSetting,
  updateGeneralSetting,
  updateCostSettings
} from 'store/reducers/inputReducer.js'
import CostMultiplierForm from 'views/admin/Forms/CostMultiplierForm.jsx'
import OptimizationChoice from "views/admin/Forms/Cost/OptimizationChoice.jsx"
import {
    radioSx,
    checkboxSx,
    infoIcon,
    formControlStyle,
    formControlLabelStyle,
    formLabelStyle
} from 'views/admin/Forms/Cost/SharedCostStyles.js'


const costSettingParams = require('store/data/cost_setting_params.json')

const CostSettings = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch(); 
    const costSettings = costSettingParams[props.costMetric]
    const costMetric = useSelector(selectCostSetting({settingName:'costMetric'}));
    const autoCompute = useSelector(selectCostSetting({settingName: "autoCompute", subSettingName:props.costMetric}));
    const distanceMethod = useSelector(selectCostSetting({settingName:costSettings['methodName']}));
    const units = useSelector(selectCostSetting({settingName:costSettings['unitsName']}));

  
    const handleMethodChange = (event, value) => {
    dispatch(updateGeneralSetting({settingName:'costs', subSettingName:costSettings['methodName'], value: value}));
    }

    const handleUnitsChange = (event, value) => {
        dispatch(updateGeneralSetting({settingName:'costs', subSettingName:costSettings['unitsName'], value: value}));
      }


    const handleAutoComputeChange = (event, type_, reverse) => {
    var checked = event.target.checked
    if (reverse) {
        checked = !checked
    }
    dispatch(updateCostSettings({"settingName":'autoCompute', subsettingName:type_, value:checked}));
    }

    return (<>

        <Stack direction='column' spacing={1} >
            {props.toggleChoice && <OptimizationChoice 
                costMetric={props.costMetric}
                optimizationLabel={costSettings['optimizationLabel']}
                tooltipTitle={costSettings['tooltipTitle']}
            />}
            <Stack direction='column' style={{...formControlStyle(theme), padding:'8px'}} spacing={1}>
                <Stack direction="column" spacing={2} id={props.costMetric + '-stack1'} >
                    <FormControl style={{display:'inline-flex', flexDirection:'row', margin:'0px', marginBottom:'-10px'}}>
                    <FormControlLabel style={{...formControlLabelStyle, marginRight:'0px'}} 
                        control={<Checkbox checked={autoCompute} sx={checkboxSx(theme)}
                        onChange={(event) => handleAutoComputeChange(event, props.costMetric)}/>} 
                        label={costSettings.autoComputeLabel} />
                    <Tooltip title={costSettings.autoComputeTooltip} placement='top'>
                        {infoIcon}
                    </Tooltip>

                    </FormControl>
                    <FormControl style={{display:'inline-flex', flexDirection:'row', margin:'0px', marginBottom:'-10px'}}>
                    <FormControlLabel style={{...formControlLabelStyle, marginRight:'0px'}} 
                        control={<Checkbox checked={!autoCompute} sx={checkboxSx(theme)}
                        onChange={(event) => handleAutoComputeChange(event, props.costMetric, true)}/>} 
                        label={costSettings.manualComputeLabel} />
                    <Tooltip title={costSettings.manualComputeTooltip} placement='top'>
                        {infoIcon}
                    </Tooltip>
                    </FormControl>
                </Stack>

        <Collapse in={autoCompute}>
        <Stack direction='row' spacing={1}>
                <FormControl style={formControlStyle(theme)}>
                    <Stack direction='row'>
                        <FormLabel style={{...formLabelStyle(theme)}} id={costMetric + "-calculation-group-label"}>Cost calculation method</FormLabel>
                    </Stack>    
                    <RadioGroup
                        row
                        aria-labelledby={costMetric + "-calculation-group-label"}
                        name="row-radio-buttons-group"
                        onChange={handleMethodChange}
                        defaultValue={distanceMethod}
                        style={{margin:'0px'}}
                        size='xs'
                    >
                        {costSettings.methodTypes && Object.keys(costSettings.methodTypes).map((methodType, idx) => {
                            return (
                            <>
                                <FormControlLabel style={formControlLabelStyle}
                                    value={methodType}
                                    control={<Radio sx={radioSx(theme)}/>} 
                                    label={costSettings.methodTypes[methodType]['label']} 
                                />
                                <Tooltip title={costSettings.methodTypes[methodType]['tooltip']} placement='top'>
                                {infoIcon}
                                </Tooltip>    
                            </>)
                        })}
                    </RadioGroup>
                </FormControl>

                {distanceMethod && costSettings['methodTypes'][distanceMethod]['useUnits'] && <FormControl style={formControlStyle(theme)}>
                <FormLabel style={{margin:'0px', ...formLabelStyle(theme)}}  id={props.costMetric + "-units-group-label"}>Units</FormLabel>
                    <RadioGroup
                    row
                    aria-labelledby={props.costMetric + "-units-group-label"}
                    name="row-radio-buttons-group"
                    onChange={handleUnitsChange}
                    defaultValue={units}
                    style={{margin:'5px'}}
                    >
                        {costSettings.units && Object.keys(costSettings.units).map((unit, idx) => {
                            return (<>
                            <FormControlLabel value={unit} control={<Radio sx={radioSx(theme)} />} label={costSettings.units[unit]['label']} />
                            </>)
                        })}
                    </RadioGroup>
                </FormControl>}
        </Stack>
        </Collapse>
        <Collapse in={autoCompute} unmountOnExit >
            <Stack direction='column'>
                <CostMultiplierForm 
                    costMetric={props.costMetric}
                    formControlStyle={formControlStyle(theme)}
                    formLabelStyle={formLabelStyle(theme)}
                    formControlLabelStyle={formControlLabelStyle}
                    checkboxSx={checkboxSx(theme)}/>
            </Stack>
        </Collapse>
    </Stack>
    </Stack>
  </>)
}

export default CostSettings