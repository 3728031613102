import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Map from "views/admin/Maps/geomapping/SimpleMapQuickRender4.jsx";

import componentStyles from "assets/theme/components/cards/dashboard/card-map.js";

const useStyles = makeStyles(componentStyles);
const MIN_MAP_WIDTH = 300;

function CardMap(props) {
  const classes = useStyles();


  return (
    <>
      <Card
        classes={{
          root: classes.cardRoot + " " + classes.cardRootBgGradient,
        }}
        style={{padding:'0px', minWidth: MIN_MAP_WIDTH + 'px', ...props.cardStyle}}
      >

        <CardContent style={{padding:'0px'}}>
            <Map containerHeight={props.minHeight} heightChangeKey={props.heightChangeKey}></Map>
        </CardContent>
      </Card>
    </>
  );
}

export default CardMap;
