import { useTheme } from "@material-ui/core/styles";  
import { Layer, Source} from "react-map-gl";
import { useSelector, shallowEqual } from 'react-redux';
import {
    selectFilteredData
} from 'store/reducers/inputReducer.js';
import {pointProps, clusterProps} from 'views/admin/Maps/geomapping/Layers/common_props.js';


const PointLayer = (props) => {
    const data = useSelector(selectFilteredData({'excludeTrips': true, 'excludeResults':true, 'exludeNulls': true}, shallowEqual)).geoJsonData;

    const theme = useTheme();
    const clusterLayerProps = {
      ...clusterProps,
      id: 'clusters',
      source: 'data-cluster-enabled',
      paint: {
        ...clusterProps.paint,
        'circle-color': theme.palette.white.main
      }
    };

    const clusterCountLayer = {
      id: 'cluster-count',
      type: 'symbol',
      source: 'data-cluster-enabled',
      filter: ['all', ['has', 'point_count']],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      },
      paint: {
        'text-color': theme.palette.primary.main
      }
    };

    
    const unclusteredPointLayer = {
      ...pointProps,
      id: 'unclustered-point',
      source: 'data-cluster-enabled',
      filter: ['all', ['!', ['has', 'point_count']]],
      paint: {
        ...pointProps.paint,
        'circle-color': ['get', "colorHex"]
      }
    };


    return (<>
          <Source
            id="data-cluster-enabled"
            type="geojson"
            data={data}
            cluster={true}
            clusterMaxZoom={25}
            clusterRadius={8}
          >
            <Layer {...clusterLayerProps} />
            <Layer {...clusterCountLayer} />
            <Layer {...unclusteredPointLayer} />
          </Source>
    </>)
}

export default PointLayer;