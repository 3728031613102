import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import Checkbox from '@mui/material/Checkbox';


const StyledCheckbox = withStyles((theme) => ({
    root: {
      "& .MuiSvgIcon-root": {
        fill: props => props.disabled ? theme.palette.gray[600] : theme.palette.primary.main,
        "&:hover": {
          backgroundColor: props => props.disabled ? theme.palette.gray[300] : 'inherit',
        }
      },
      "&$checked": {
        "& .MuiIconButton-label": {
        //   position: "relative",
          zIndex: 0,
          border: "1px solid #bbbbbb",
          borderRadius: 3
        },
    //     "& .MuiIconButton-label:after": {
    //       content: '""',
    //       left: 4,
    //       top: 4,
    //       height: 15,
    //       width: 15,
    //       position: "absolute",
    //       backgroundColor: "green",
    //       zIndex: -1,
    //       borderColor: "transparent"
    //     }
    //   },
    //   "&:not($checked) .MuiIconButton-label": {
    //     position: "relative",
    //     zIndex: 0,
    //     border: "1px solid #bbbbbb",
    //     borderRadius: 3
      },
      "&:not($checked) .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "inherit",
        zIndex: -1,
        borderColor: "transparent"
      }
    },
    checked: {}
  }))(Checkbox);

function MyCheckbox (props) {
  const tooltipTitle = props.tooltipTitle || ''

    return (<>

        <Tooltip title={tooltipTitle}>
            <StyledCheckbox {...props.checkboxProps} />
        </Tooltip>

      </>)
}

export default MyCheckbox