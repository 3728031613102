import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from '@mui/material/Chip';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom'

import componentStyles from "assets/theme/components/cards/profile/card-profile.js";
import componentStylesCardImg from "assets/theme/components/card-img.js";
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import { useDispatch } from "react-redux";
import { 
  fetchSavedScenario
} from 'store/reducers/inputReducer.js'

const useStyles = makeStyles(componentStyles);
const useStylesCardImg = makeStyles(componentStylesCardImg);
var modelPages = require('store/data/model-pages.json');

const getIcon = (iconName, style={}) => {
  if (iconName == 'airplane') {
    return <ConnectingAirportsIcon style={style}/>
  }

  if (iconName == 'bicycle') {
    return <PedalBikeIcon style={style}/>
  }

  if (iconName == 'scooter') {
    return <DeliveryDiningIcon style={style}/>
  }

  if (iconName == 'van') {
    return <AirportShuttleIcon style={style}/>
  }

  if (iconName == 'truck') {
    return <LocalShippingIcon style={style}/>
  }

  if (iconName == 'car') {
    return <DirectionsCarIcon style={style}/>
  }

}


function CardUseCase(props) {
  const classes = { ...useStyles(), ...useStylesCardImg() };
  const navigateRef = React.useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme();


  const buildFromScratchClick = (event) => {
    event.stopPropagation();
    dispatch(fetchSavedScenario({scenarioName: props.useCase.scenarioName, fromScratch:true, modelMode:true}))
    navigateRef.current.click();
  }

  return (
    <>
      <Card classes={{ root: classes.cardRoot }} style={{backgroundColor:'white'}}>
        <img
          alt="..."
          src={props.useCase.bannerImage || require("assets/img/theme/img-1-1000x600.jpg").default}
          className={classes.cardImgTop}
        />
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={4}>
          </Grid>
        </Grid>
        <CardHeader
          style={{
            border:"0 !important",
            textAlign:"center",
            padding:"0px"
            }}
          subheader={
            <Box 
                 id='card-use-case-header-box'
                 display="flex" 
                 justifyContent="space-around"
                 padding='5px !important'
                 paddingBottom='10px !important'
                 style={{'gap':'5px'}}
            >
              <Button
                variant="contained"
                size="small"
                classes={{ root: classes.buttonRootInfo }}
                href={"/examples/" + props.useCase.scenarioName}
              >
                Explore this model
              </Button>
              {getIcon(props.useCase.icon, {
                color: theme.palette.primary.main,
                borderRadius: '50%',
                border: "5px solid " + theme.palette.primary.main,
                height: '70px',
                width: '70px',
                marginTop: '-35px',
                backgroundColor: 'white',
              })}
              <Button
                variant="contained"
                size="small"
                classes={{ root: classes.buttonRootDark }}
                onClick={buildFromScratchClick}
              >
                Build from scratch
              </Button>
            </Box>
          }
        ></CardHeader>
        <Box
          component={CardContent}
          classes={{ root: classes.cardContentRoot }}
          paddingTop="0!important"
        >
          <Box textAlign="center">
            <Typography variant="h3">
              {props.useCase.title}
            </Typography>
            <Box
              component={Typography}
              variant="h5"
              fontWeight="300!important"
              display="flex"
              alignItems="left"
              justifyContent="left"
              textAlign={'left'}
            >
              {props.useCase.briefDescription}
            </Box>
            <Box>
              <Box component={Typography} variant="h5" textAlign='left' style={{marginBottom:'0px'}}>
                  {"Model Used"}
              </Box>
              <Box component={Typography} variant="h5" fontWeight="300!important" textAlign='left'>
                  {props.useCase.modelUsedVerbose}
              </Box>
            </Box>
            <Box>
              <Box component={Typography} variant="h5" textAlign="left" style={{marginBottom:'0px'}}>
                  {"Constraints"}
              </Box>

              <List sx={{ listStyleType: 'disc', pl: 4, paddingTop:'0px' }}>
                  {props.useCase.constraints.map((constraint, idx) => {return (
                    <ListItem sx={{ display: 'list-item', paddingTop: '0px', paddingBottom:'0px'}} >
                      <ListItemText primary={constraint} style={{marginBottom:'0px', marginTop:'0px'}}
                        primaryTypographyProps={{...theme.typography, ...theme.typography.h5, fontWeight: '300', marginBottom:'0px'}}/>
                    </ListItem>)
                  })}
              </List>
            </Box>
          </Box>
          <Box style={{display:'flex', gap:'5px'}}>
            {props.useCase.tags.map((label, index) => {
              const isFiltered = props.filteredTags.indexOf(label) > -1;
              return (<Chip
              label={label}
              color={isFiltered ? 'primary' : 'default'}
              onDelete={isFiltered ? (event) => {event.stopPropagation(); props.removeFilter(label)} : null}
              onClick={() => props.addFilter(label)}
              />)
            })}
          </Box>
        </Box>
      </Card>
      <Link to={modelPages[props.useCase.modelUsed]} style={{display:'none'}} ref={navigateRef}/>
    </>
  );
}

export default CardUseCase;