import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import {
  selectGeneralSettings,
  updateFilter,
  selectFilterState,
  selectDataSize
} from 'store/reducers/inputReducer'; 


/**
 * checkbox that toggles the drop layer on/off
 * @param {*} props 
 */
const DropLayerToggleCheckbox  = (props) => {
    const dropsLength = useSelector(selectDataSize('drops'));
    const filterState = useSelector(selectFilterState('dropLayerVisible'))
    const mapMode = useSelector(selectGeneralSettings)['mapping']['mapMode'];
    const dispatch = useDispatch();
    const disabled = !dropsLength || dropsLength == 0;

    const handleClick = () => {
        dispatch(updateFilter({newValue:!filterState, filterName:'dropLayerVisible'}));
    }

    return (
      <Tooltip title={disabled ? 'No drops to show' : ''} placement="top" PopperProps={{
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, -20],
                },
            },
        ],
    }}>
        <FormGroup style={{display:'inline-block', margin:'0px'}}>
          {mapMode !== 'trips' && 
            <FormControlLabel label={'Show Drops'} style={{display:'inlineBlock', marginBottom:'0px'}}
            control={<Checkbox checked={filterState} onClick={handleClick} disabled={disabled}/>} 
            />}
        </FormGroup>
      </Tooltip>
    );
}

export default DropLayerToggleCheckbox;
