import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    marginBottom: '0px',
    margin:'0px'
  },
  cardHeader: {
    backgroundColor: "initial",
  },
  // containerRoot: {
  //   [theme.breakpoints.up("md")]: {
  //     paddingLeft: "30px",
  //     paddingRight: "30px",
  //   },
  // },
  mb0: {
    marginBottom: "0!important",
  },
  borderWarning: {
    borderColor: theme.palette.warning.main + "!important",
  },
  borderSuccess: {
    borderColor: theme.palette.success.main + "!important",
  },
  textWarning: {
    color: theme.palette.warning.main + "!important",
  },
  buttonContainedPrimary: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    // marginTop: '10px',
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
  buttonContainedSuccess: {
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      borderColor: theme.palette.success.dark,
    },
  },
  buttonContainedError: {
    // marginTop: '10px',
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
    },
  },
  buttonContainedWarning: {
    // marginTop: '10px',
    backgroundColor: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
      borderColor: theme.palette.warning.dark,
    },
  },
  buttonOutlineSuccess: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  buttonOutlineError: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
  buttonOutlineWarning: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    },
  },
  formGroup: {
    margin: '0px'
  },
  formLabel: {
    margin: '5px'
  },
  helperTextDiv: {
    overflow:'hidden',
    whiteSpace:'nowrap',
    "&:hover": {
      overflow: 'visible',
    },
  },
  labelText: {
    // fontFamily: theme.typography.fontFamily,
    fontFamily: "Open Sans,sans-serif",
    fontSize: '14px',
  },
  textEntry: {
    fontFamily: "Open Sans,sans-serif",
    // fontFamily: theme.typography.fontFamily,
    color: theme.palette.gray[900],
    backgroundColor: "initial",
    border: 0,
    boxShadow: "none",
    padding: "0",
  }
});

export default componentStyles;
