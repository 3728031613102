
import { Layer, Source} from "react-map-gl";
import { useSelector, shallowEqual } from 'react-redux';
import {
    selectFilteredData,
    selectTripLayerEnabled
  } from 'store/reducers/inputReducer.js';
import {tripProps, arrowProps} from 'views/admin/Maps/geomapping/Layers/common_props.js';


const TripLayer = (props) => {
    // const tripLayerVisible = useSelector(selectGeoJsonFilters, shallowEqual).tripLayerVisible;
    // const activeModelSettings = useSelector(selectActiveModelSettings, shallowEqual);
    // const tripLayerEnabled = activeModelSettings && activeModelSettings['mapping']['trip_editor'];
    const tripLayerEnabled = useSelector(selectTripLayerEnabled);
    const data = useSelector(selectFilteredData({'excludeTrips': !(tripLayerEnabled), 'excludeResults':true}, shallowEqual)).geoJsonData;


    const tripLayerProps = {
        ...tripProps,
        id: 'trips',
        source: 'data-cluster-disabled',
        filter: ['all', ['==', '$type', 'LineString']],
        paint: {
            ...tripProps.paint,
          'line-color': ['get', "colorHex"],
        }
      }
  
      const arrowLayerProps = {
          ...arrowProps,
        id: 'arrow-layer',
        source: 'data-cluster-disabled',
        filter: ['==', '$type', 'LineString'],
        paint: {
            ...arrowProps.paint,
          "icon-color": ['get', 'colorHex']
        }
      }


    return (<>
        <Source
        id="trip-data"
        type="geojson"
        data={data}
        >
        <Layer {...tripLayerProps} />
        {/* <Layer {...arrowLayerProps} /> */}
        </Source>
    </>)
}

export default TripLayer;