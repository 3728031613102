import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import SimpleLink from 'views/admin/Components/MyComponents/SimpleLink.js'
import Tooltip from '@mui/material/Tooltip';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Stack from '@mui/material/Stack';
import { useTheme } from "@material-ui/core/styles";
const allStaticTableSettings = require('store/data/table_settings_static.json')

const stepZeroIconStyle = {width:'5rem', height:'5rem'};

export default function MismatchWarningDialog(props) {
  const { onClose, value: valueProp, open } = props;
  const [values, setValues] = React.useState([]);
  const [step, setStep] = React.useState(0);
  const radioGroupRef = React.useRef(null);
  const staticTableSettings = allStaticTableSettings
  const theme = useTheme();

  React.useEffect(() => {
    if (!open) {
        setValues(valueProp);
    }
  }, [valueProp, open]);


  const handleCancel = () => {
    onClose('cancel');
  };

  const handleOk = () => {
    onClose(values);
  };

  const handleChange = (event) => {
    const newValues = [...values];
    newValues.splice(step-1,4, event.target.value)
    setValues(newValues);
  };

  React.useEffect(() => {
    const newValues = [];
    for (var i = 0; i < props.mismatches.length; i++) {
        newValues.push(null);
    }
    setValues(newValues);
  }, [props.mismatches])


  var mismatchTable; 
  var mismatchColumn;
  var mismatchNewValues;
  var mismatchSourceColumn;
  var rowsImpacted;
  var valueLink;
  var tooltipHtml;
  var valueTooltip;
  if (props.mismatches && step > 0) {
    mismatchTable = props.mismatches[step-1]['tableName'];
    mismatchColumn = props.mismatches[step-1]['columnName'];
    mismatchNewValues = props.mismatches[step-1]['newValues'];
    mismatchSourceColumn = props.mismatches[step-1]['sourceColumn'];
    rowsImpacted = props.data.filter((obj) => {return mismatchNewValues.has(obj[mismatchSourceColumn])}).length;

    valueLink = <SimpleLink text="values" color={theme.palette.primary.main} hoverColor={theme.palette.primary.linkHover}/>;
    tooltipHtml =         <>
                                <List
                                    sx={{ width: '100%', maxWidth: 360}}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    style={{
                                    position: 'relative',
                                    overflow: 'auto',
                                    maxHeight: 400,
                                    overflowY:'scroll',
                                    padding:'0px',
                                    border:'4px solid' + theme.palette.primary.main,
                                    borderRadius: '5px',
                                    '& ul': { padding: 0 },}}
                                    >
                                                <li>
                                                    <ul style={{padding:'0px', margin:'0px', backgroundColor:'white'}}>
                                                        <ListSubheader style={{lineHeight: '30px', fontSize: '16px', fontWeight: '700'}}>{`New ${mismatchSourceColumn}s`}</ListSubheader>
                                                        {Array.from(mismatchNewValues).map((item) => (
                                                        <ListItem style={{paddingTop:'0px', paddingBottom:'0px'}}>
                                                            <ListItemText style={{fontSize:'0.5rem'}} primary={`${item}`} />
                                                        </ListItem>
                                                        ))}
                                                    </ul>
                                                    </li>
                                    </List>
                                </>

    valueTooltip = <Tooltip  style={{padding:'0px'}} componentsProps={{ tooltip: { style:{backgroundColor:'inherit'} } }} placement={'top'}
    title={tooltipHtml}><span style={{padding:'0px'}}>{valueLink}</span></Tooltip>
    }


  return (<>
      {props.mismatches && 
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435, maxWidth:700 } }}
      keepMounted={false}
      open={props.mismatches && open}
      onClick = {(event) => event.stopPropagation()}

    >
      <DialogTitle style={{backgroundColor: props.mismatches.length > 0 ? theme.palette.warning.light : theme.palette.success.light, 
        color: props.mismatches.length > 0 ? theme.palette.warning.main: theme.palette.success.dark, 
            fontWeight:700, paddingTop:'10px', paddingBottom:'10px'}}>
          <Grid container >
                <Grid item xs={12} md={6}>
                {props.mismatches && props.mismatches.length > 0 && <><WarningAmberIcon  style={{paddingRight:'5px', height:'2rem', width:'2rem'}}/>Table Upload Warning</>}
                {props.mismatches && props.mismatches.length === 0 && <><CheckCircleOutlineIcon  style={{paddingRight:'5px', height:'2rem', width:'2rem'}}/>Table Upload Report</>}
                </Grid>
                <Grid item xs={12} md={6} style={{whiteSpace:'nowrap'}}>
                    <Stack direction='row' spacing={2} style={{float:'right'}}>
                    <Stack direction='column' style={{fontSize: '0.7rem', lineHeight: '0.8rem'}}>
                        {props.fileName && <Stack direction='row'>Uploaded File:&nbsp;<span style={{fontWeight:500, overflow:'hidden'}}>{props.fileName}</span></Stack>}
                        {props.targetTable && <Stack direction='row'>Target Table:&nbsp;<span style={{fontWeight:500}}>{props.targetTable}</span></Stack>}
                    </Stack>
                    <Stack direction='column' style={{fontSize: '0.7rem', lineHeight: '0.8rem'}}>
                        {props.data && <Stack direction='row'>Uploaded Rows:&nbsp;<span style={{fontWeight:500, overflow:'hidden'}}>{props.data.length}</span></Stack>}
                        {props.data && <Stack direction='row'>Rows With Warnings:&nbsp;<span style={{fontWeight:500}}>{props.data.length}</span></Stack>}
                    </Stack>           
                    </Stack>
                </Grid>
          </Grid>
        </DialogTitle>
      <DialogContent dividers style={{minHeight:'200px'}}>
        {step === 0 && <>
            {values.length === 0 && <Stack direction='row'>
            <CheckCircleOutlineIcon color='success' style={{...stepZeroIconStyle}}/>
            File uploaded successfully.
            </Stack>
            }
            {values.length > 0 && <Stack direction='row'>
            <WarningAmberIcon color='warning' style={{...stepZeroIconStyle}}/>
            <div>
            File uploaded with {values.length} warnings. Action required. Click through dialog for details.
            </div>
            </Stack>
            }
            </>
        
        }
        {step > 0 && <>
        <DialogContentText>
            Uploaded data in the {mismatchSourceColumn} field contained new {valueTooltip}
                &nbsp;for the {mismatchColumn} of the {staticTableSettings[mismatchTable]['title']} table.
                 Would you like to add these {valueTooltip} to {staticTableSettings[mismatchTable]['title']}?
          </DialogContentText>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="mismatch-warning"
          name="mismatch-warning"
          value={values[step-1]}
          onChange={handleChange}
        >
        <FormControlLabel
            value={'add'}
            key={'add'}
            control={<Radio />}
            label={<div>Add {valueTooltip} to {staticTableSettings[mismatchTable]['title']}</div>}
        />
        <FormControlLabel
            value={'remove'}
            key={'remove'}
            control={<Radio />}
            label={<div>Remove uploaded rows containing new {valueTooltip} and proceed with upload. This will affect {rowsImpacted} rows.</div>}
        />
        </RadioGroup>
        </>}
      </DialogContent>
      
      <DialogActions style={{justifyContent:'space-evenly'}}>
          <Grid container style={{display:'inline-flex'}}>
            <Grid item xs={12} sm={4}>
                {props.mismatches.length > 0 && <Button style={{float:'left', backgroundColor: theme.palette.primary.main}} onClick={() => setStep(step => step -1)} disabled={step === 0} variant='contained'>Back</Button>}
                {props.mismatches.length === 0 && <Button autoFocus onClick={handleCancel} style={{backgroundColor: theme.palette.error.main}} variant='contained'>
                Cancel upload
                </Button>}
            </Grid>
            <Grid item xs={12} sm={4} style={{textAlign:'center'}}>
                {props.mismatches.length > 0 &&<Button autoFocus onClick={handleCancel} style={{backgroundColor: theme.palette.error.main}} variant='contained'>
                Cancel upload
                </Button>}
            </Grid>
            <Grid item xs={12} sm={4}>
                {props.mismatches.length === 0 && <Button style={{backgroundColor: theme.palette.success.main, float:'right'}} onClick={() => handleOk()} variant='contained'>Close</Button>}
                {props.mismatches.length > 0 && <>
                {step < props.mismatches.length && <Button style={{float:'right', backgroundColor: theme.palette.primary.main}} onClick={() => setStep(step => step + 1)} disabled={step === props.mismatches.length} variant='contained'>Next</Button>}
                {step === props.mismatches.length && <Button style={{float:'right', backgroundColor: theme.palette.success.main}} onClick={() => handleOk()}  variant='contained'>Submit</Button>}
                </>}
            </Grid>
        </Grid>

      </DialogActions>
    </Dialog>}
    </>

  );
}
