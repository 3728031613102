// import {useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import CircleIcon from '@mui/icons-material/Circle';
import Tooltip from "@material-ui/core/Tooltip";
import { useTheme } from "@material-ui/core/styles";

const ClusterCard = (props) => {
    const theme = useTheme();
    return <><ul style={{ listStyleType: 'none', margin: 0, padding: 0, maxHeight:'200px', maxWidth:'200px',
    overflow:'scroll', paddingRight:'5px', paddingLeft:'5px', display:'inline-flex', flexDirection:'column'}}>
    {props.items.map((item, idx) => {
        const isResult = item.properties.isResult;
        const title = item.properties['Location Name'] || item.properties.name;
        const action = item.properties.Action
        return (
                <li style={{borderTop: idx !== 0 ? '1px solid ' + theme.palette.primary.main : null, minWidth:'100px', display:'inline-flex'}}>
                    {<Tooltip title={'Vehicle ' + item.properties.vehicleId}>
                    <IconButton aria-label="delete" size="small" disabled={true}
                    style={{height:'1rem', width:'1rem', float:'right', marginLeft:'5px', top:'2px'}}
                    >
                        <CircleIcon fontSize="inherit" style={{color: item.properties.colorHex}}/>
                    </IconButton>
                    </Tooltip>}
                    <Tooltip title={title} >
                        <div style={{display:'inline-flex', flexDirection:'column', flex:'auto', 
                            maxHeight:'10rem', overflowY:'scroll', 
                            maxWidth:'180', maxHeight:'200px'}}>
                            <div style={{fontWeight:'700', width:'calc(100% - 20px)', minWidth:'100px', maxHeight:'1.25rem', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>

                                {isResult && item.properties.Stop + ') '}
                                    {title}
                            </div>
                            {action && 
                                (<ul style={{listStyleType: 'disc', paddingLeft:'5px', fontWeight:'normal', 
                                    fontSize:'0.7rem', lineHeight:'0.7rem', paddingBottom:'0.5rem'}}>
                                    {action.map((elem, idx) => {
                                    return <li style={{marginLeft:'10px'}}><span style={{marginLeft:'-5px'}}>{elem}</span></li>
                                    })} 
                                </ul>)}
                        </div>
                   </Tooltip>
                   {!isResult && 
                    <div id='cluster-card-edit-icon' style={{'textAlign':'end'}}>
                        <Tooltip title='View more / edit'>
                            <IconButton aria-label="delete" size="small" color={'warning'}
                            style={{height:'1rem', width:'1rem', float:'right', marginLeft:'5px', top:'2px'}}
                            onClick={() => props.clusterEditorHandler(item)}
                            >
                                <ReadMoreIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </div>
                }

                </li>
    )})}
    </ul>
    </>
}

export default ClusterCard