const componentStyles = (theme) => ({
    buttonUnselected: {
        opacity: "0.8",
        backgroundColor: theme.palette.white.main,
        '&:hover': {
            opacity: "1.0",
            backgroundColor: theme.palette.dark.main,
            color: theme.palette.white.main,  
        }
    },
    buttonSelected: {
        opacity: "1.0",
        backgroundColor: theme.palette.dark.main,
        color: theme.palette.white.main,
        '&:hover': {
            opacity: "1.0",
            backgroundColor: theme.palette.dark.main,
            color: theme.palette.white.main,  
        }
    },
    buttonDisabled: {
        opacity: "1.0",
        backgroundColor: theme.palette.gray[500],
        color: theme.palette.white.main,
        '&:hover': {
            opacity: "1.0",
            backgroundColor: theme.palette.gray[500],
            color: theme.palette.white.main,  
        }        
    }
});

export default componentStyles;