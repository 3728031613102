var parse = require("color-parse");

const componentToHex = (input) => {
  const hex = input.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(rgb) {
  return "#" + componentToHex(rgb[0]) + componentToHex(rgb[1]) + componentToHex(rgb[2]);
}

export const parseColor = (color) => {
  if (!color) {
    return {'hex': null, 'rgb': null}
  }
  const parsedColor = parse(color);
  if (!parsedColor.space) {
    return null
  }
  const rgb = [parsedColor.values[0], parsedColor.values[1], parsedColor.values[2]];
  const hex = rgbToHex(rgb);
  return {'hex': hex, 'rgb': rgb}
}


//https://stackoverflow.com/a/5624139
export default function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;

    // return result ? {
    //   r: parseInt(result[1], 16),
    //   g: parseInt(result[2], 16),
    //   b: parseInt(result[3], 16)
    // } : null;
  }