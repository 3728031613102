import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
// import Grid from "@material-ui/core/Grid";
import OptimizeButton from "views/admin/Components/OptimizeButton";
import Typography from "@material-ui/core/Typography";
import MyButton from 'views/admin/Components/MyComponents/MyButton.js'

import { useTheme } from "@material-ui/core/styles";


import ClearAllTableDataButton from 'views/admin/Buttons/ClearAllTableDataButton.jsx';
import ExitExampleModeButton from 'views/admin/Buttons/ExitExampleModeButton.jsx';
import SaveInstanceButton from 'views/admin/Buttons/SaveInstanceButton.jsx';
import RenameScenarioButton from 'views/admin/Buttons/RenameScenarioButton.jsx';
import { useSelector } from 'react-redux';
import {
  selectScenarioInfo,
  selectActiveModelSettings,
  getInExampleMode
} from 'store/reducers/inputReducer.js'

import { Stack } from "@mui/material";


function CardDataTables(props) {
  const theme = useTheme();
  const scenarioInfo = useSelector(selectScenarioInfo);
  const problemConfigs = useSelector(selectActiveModelSettings);
  const inExampleMode = useSelector(getInExampleMode);
  return (
    <>
      <Card id='cardDataTables-card'
        style={{
          overflowX:'scroll',
          maxWidth:'100%',
          margin:'0px',
          padding:'10px',
          boxShadow:'none',
          ...props.cardStyle}}
      >
        <CardHeader
          style={{'padding': '5px', lineHeight:'1.5rem', paddingBottom:'0px', paddingTop:'0px'}}
          subheader={
              <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                <Stack direction='column' style={{flex: 1, paddingRight:'10px'}}>
                  <Box
                    component={Typography}
                    variant="h4"
                    marginBottom="0!important"
                    hidden={props.collapsed}
                    style={{display:'inline-flex', whiteSpace:'nowrap'}}
                  >
                    <Box component="span">Data Manager</Box>
                  </Box>
                  <div style={{display:'inline-flex'}}>
                    <Box
                    component="small"
                    fontSize="0.8rem"
                    fontWeight="600"
                    color={theme.palette.gray[600]}
                    whiteSpace={'nowrap'}
                    >
                    {"Scenario: " }
                        {scenarioInfo && scenarioInfo['scenarioName'] && " " + scenarioInfo['scenarioName']}
                        {""}
                  
                    </Box>
                    &nbsp;{scenarioInfo && !scenarioInfo.lastSaved && !inExampleMode &&
                    <Box
                      component="small"
                      fontSize="0.8rem"
                      color={theme.palette['warning'].main}
                      whiteSpace={'nowrap'}
                      >
                        {" (unsaved)"}
                    </Box>}
                  </div>
                  
                </Stack>

                <Stack direction='row' spacing={1} useFlexGap
                  style={{display:'inline-flex', marginBottom:"0!important", alignItems:'baseline', flexWrap: 'wrap', alignItems:'center'}}>
                    <OptimizeButton simple={true} spinnerStyle={{left:0, top:0}} 
                      iconProps={{height:'4rem', width:'4rem', innerBoxHeight:'2rem', innerBoxWidth:'2rem'}}/>
                    {!inExampleMode &&<MyButton label='Load Scenario' variant='contained' 
                        color='info' buttonProps={{onClick:props.openSavedScenarioDrawer}}
                        buttonStyle={{whiteSpace:'nowrap'}}
                        />}
                  
                    {!inExampleMode && <RenameScenarioButton />}
                    {!inExampleMode && <SaveInstanceButton />}
                    {!inExampleMode && <ClearAllTableDataButton buttonProps={{style:{'paddingRight': '0px'}}}/>}
                    {inExampleMode && <ExitExampleModeButton />}
                  </Stack>
              </div>
          }
        ></CardHeader>
        <CardContent style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    paddingTop:'0px',
                    paddingRight:'0px',
                    paddingLeft:'0px'}}
        >
          <Box alignItems="center" marginBottom="0!important" style={{overflowX:'scroll'}}>
            {problemConfigs['input']['order'].map((tableName, idx) => {
                    return props.dataTableComponents[tableName];
              })}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default CardDataTables;
