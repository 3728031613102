export const existsInTable = (data, tableName, columnNames, searchValues) => {
  if (columnNames.length !== searchValues.length) {
    throw ('Expecting columnNames and searchValues to have the same length.')
  }
  var tableData;
  if (!tableName) {
    tableData = data
  } else {
    tableData = data[tableName]
  }

  for (var i = 0; i < tableData.length; i++) {
    for (var j = 0; j < columnNames.length; j++) {
      const columnName = columnNames[j];
      const searchValue = searchValues[j];
      if (searchValue !== tableData[i][columnName]) {
        break;
      }
      if (j === columnNames.length - 1) {
        return {exists: true, index:i}
      }
    }
  }
  return {exists: false};
}

export const findAllEntriesInTable = (data, tableName, columnNames, searchValues, matchTypes=null) => {
  var idcs = [];
  if (columnNames.length != searchValues.length) {
    throw ('Expecting columnNames and searchValues to have the same length.')
  }
  for (var i = 0; i < data[tableName].length; i++) {
    for (var j = 0; j < columnNames.length; j++) {
      const columnName = columnNames[j];
      const searchValue = searchValues[j];
      if (!searchValue) {}
      else if (matchTypes && matchTypes[j] && matchTypes[j] === 'in') {
        if (!data[tableName][i][columnName]) {break}
        if (data[tableName][i][columnName].indexOf(searchValue) < 0) {
          break;
        }
      }
      else {
        if (searchValue != data[tableName][i][columnName]) {
          break;
        }
      }
      if (j === columnNames.length - 1) {
        idcs.push(i);
      }
    }
  }
  return idcs;
}

//gets the value in returnColumn that matches the criteria in searchValues
export const getValueAt = (data, tableName, columnNames, searchValues, returnColumn) => {
  const existanceCheck = existsInTable(data, tableName, columnNames, searchValues);
  if (existanceCheck.exists) {
    var tableData;
    if (!tableName) {
      tableData = data;
    } else {
      tableData = data[tableName]
    }
    const value = tableData[existanceCheck.index][returnColumn]
    return {exists:true, value:value}
  } else {
    return {exists:false}
  }
}

export const getRowAt = (data, tableName, columnNames, searchValues) => {

  const existanceCheck = existsInTable(data, tableName, columnNames, searchValues);
  if (existanceCheck.exists) {
    const value = data[tableName][existanceCheck.index]
    return {exists:true, value:value}
  } else {
    return {exists:false}
  }  
}

export const addUuidsToTrip = (data, tripRow) => {
  const fromLocation = tripRow['From Location'];
  const toLocation = tripRow['To Location'];

  const fromUuid = getValueAt(data, 'locations', ['Location Name'], [fromLocation], 'UUID').value;
  const toUuid = getValueAt(data, 'locations', ['Location Name'], [toLocation], 'UUID').value;
  
  tripRow['From UUID'] = fromUuid;
  tripRow['To UUID'] = toUuid;

  return tripRow  
}


export const addCoordsToTrip = (data, tripRow, lookupTable) => {
  //this function adds the to/from coordinates to the trip row based on the location names
  const fromLocation = tripRow['From Location'];
  const toLocation = tripRow['To Location'];


  const fromLat = getValueAt(data, lookupTable, ['Location Name'], [fromLocation], 'Latitude');
  const fromLng = getValueAt(data, lookupTable, ['Location Name'], [fromLocation], 'Longitude');
  const toLat = getValueAt(data, lookupTable, ['Location Name'], [toLocation], 'Latitude');
  const toLng = getValueAt(data, lookupTable, ['Location Name'], [toLocation], 'Longitude');
  
  tripRow['From Location Latitude'] = fromLat.value;
  tripRow['From Location Longitude'] = fromLng.value;
  tripRow['To Location Latitude'] = toLat.value;
  tripRow['To Location Longitude'] = toLng.value;

  return tripRow
}

//deletes entries in data where colName == value. data should be a 
//list of json objects
export const deleteWhere = (data, tableName, colName, value) => {
  var removeIndices = [];
  data[tableName].map((elem, idx) => {
    if (elem[colName] == value) {
      removeIndices.unshift(idx);
    }
  });
  if (tableName === 'locations'){
  }
  removeIndices.map((elem) => {
    data[tableName].splice(elem, 1);
  })

  return data
}
