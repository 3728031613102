/**
 * This provides a dialog for the cost settings. All of the actual work
 * is done in the CostSettingsTabbedForm component.
 */

import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';

import CostSettingsTabbedForm from 'views/admin/Forms/Cost/CostSettingsTabbedForm';


export default function CostSettingsDialog(props) {
  const { open } = props;

  const handleClose = () => {
    props.onClose();
  };

  return (
    <>
    <Dialog onClose={handleClose} onClick={(event) => event.stopPropagation()} open={open}>
      <CostSettingsTabbedForm onClose={handleClose}/>
    </Dialog>
    </>
  );
}

CostSettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};


