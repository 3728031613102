
import { Layer, Source} from "react-map-gl";
import { useSelector, shallowEqual } from 'react-redux';
import {
    selectFilteredData,
  } from 'store/reducers/inputReducer.js';
import {tripProps, arrowProps} from 'views/admin/Maps/geomapping/Layers/common_props.js';


const ResultTripLayer = (props) => {
    const resultTrips = useSelector(selectFilteredData({
      'excludeLineStrings': false,
      'resultsOnly':true,
      'excludePoints': true,
      name:'resultTrips',
      'filterTime': true
    }), shallowEqual).geoJsonData;

    const resultTripLayerProps = {
      ...tripProps,
      id: 'result-trips',
      source: 'results-cluster-disabled',
      filter: ['==', '$type', 'LineString'],
      paint: {
        ...tripProps.paint,
        'line-color': ['get', "colorHex"],
      },
      layerIndex:0
    }

    const resultTripArrowLayerProps = {
      ...arrowProps,
      id: 'result-arrow-layer',
      source: 'results-cluster-disabled',
      filter: ['==', '$type', 'LineString'],
      paint: {
        ...arrowProps.paint,
        "icon-color": ['get', "colorHex"]
      }
    }

    return (<>
          <Source
            id="results-cluster-disabled"
            type="geojson"
            data={resultTrips}
            cluster={false}
          >
            <Layer {...resultTripLayerProps} />
            {/* <Layer {...resultTripArrowLayerProps} /> */}
          </Source>
    </>)
}

export default ResultTripLayer;