import XLSX from 'xlsx';

//https://stackoverflow.com/a/57060809/11342048

const importFile = (f) => {
    return new Promise((resolve, reject) => {
        var fr = new FileReader();  
        fr.onload = (e) => {
        var contents = processExcel(e.target.result);
        resolve ( contents )
        };
        fr.onerror = reject;
        fr.readAsText(f, 'UTF-8');
    });
    }


function processExcel(data) {
  var workbook = XLSX.read(data, {
    type: 'string',
    encoding: "ascii",
    stringsAsFactors: 'F',
  });

  var returnData = to_json(workbook);
  return returnData;
};

function to_json(workbook) {
  var result = {};
  workbook.SheetNames.forEach(function(sheetName) {
    var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
      header: 1
    });
    if (roa.length) result[sheetName] = roa;
  });
  return JSON.stringify(result, 2, 2);
};

export default importFile;