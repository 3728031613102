import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import Tooltip from "@material-ui/core/Tooltip";

const MapZoomToFitButton = React.memo(function MapZoomToFitButton(props) {
    return (
        <Tooltip title='Pan to all locations'>
            <Button style={{zIndex:'3'}}
            size="small" variant="contained" onClick={props.updateBounds}>
                <Box
                component={CenterFocusStrongIcon}
                position="relative"
                
                ></Box>
            </Button>
        </Tooltip>
    );
});

export default MapZoomToFitButton;