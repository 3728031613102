/* eslint-disable react/jsx-no-target-blank*/
import React from "react";
// import { useLocation, Link } from "react-router-dom";
import 'react-tooltip/dist/react-tooltip.css'
import { useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Container from "@material-ui/core/Container";

// @material-ui/icons components
import Sidebar from "components/Sidebar/Sidebar.js";
// core components
import IndexHeader from "components/Headers/IndexHeader.js";
import AdminFooter from "components/Footers/AdminFooter.js";

import adminComponentStyles from "assets/theme/layouts/admin.js";
import componentStyles from "assets/theme/views/index.js";
import buttonStyles from "assets/theme/components/button.js";
import MainNavbar from "components/Navbars/MainNavbar";
import routes from "routes.js";
const useStyles = makeStyles(componentStyles);
const useStylesButton = makeStyles(buttonStyles);
const useStylesAdmin = makeStyles(adminComponentStyles);


export default function Index() {
  const classes = { ...useStylesButton(), ...useStyles(), ...useStylesAdmin() };
  const location = useLocation();
  const [sidebarOpenResponsive, setSidebarOpenResponsive] = React.useState(
    false
  );
  React.useEffect(() => {

  }, [location]);
  return (
    <>
      <Box display="flex" style={{height:'100%'}} id='Index-Box-1'>
        <Sidebar
          routes={routes}
          openResponsive={sidebarOpenResponsive}
          closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
          logo={{
            innerLink: "/index",
            imgSrc: "https://optimiciti-images.s3.us-east-2.amazonaws.com/brand/optimiciti_teal_clear-cropped.svg",
            imgAlt: "...",
          }}
        />
      
        <Box position="relative" flex="1" className={classes.mainContent} id='Index-Box-2'
          style={{'height:':'100%', display: 'flex', flexDirection: 'column', minWidth:'600px', overflowX:'scroll'}}
        >
          <MainNavbar id='Index-MainNavbar'/>
          <IndexHeader id='Index-IndexHeader'/>

          <Container
              id='Index-Container-1'
              maxWidth={false}
              component={Box}
              classes={{ root: classes.containerRoot }}
            >
              <AdminFooter />
            </Container>
        </Box>

      </Box>
    </>
  );
}
