import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import { createTheme, MantineProvider } from '@mantine/core';
import "@mantine/core/styles.css";
import theme from "assets/theme/theme.js";

import { GoogleOAuthProvider } from '@react-oauth/google';
import { SnackbarProvider } from 'notistack';

import { Provider } from 'react-redux';
import store from "store/store.js";

import AdminLayout from "layouts/Admin.js";
import ModelLayout from "layouts/Models.js";
import Index from "views/Index.js";
import * as amplitude from '@amplitude/analytics-browser';

amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, {"autocapture":true});
const mantineTheme = createTheme({})

ReactDOM.render(
  <ThemeProvider theme={theme}>
  <Provider store={store}>
  
    <CssBaseline /> {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <BrowserRouter>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_OATH_CLIENT}>
      <SnackbarProvider maxSnack={3}  anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
      <MantineProvider theme={mantineTheme}>
        <Switch>
          <Route path="/index" render={(props) => <Index {...props} />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/pricing" render={(props) => <AdminLayout {...props} />} />
          <Route path="/api" render={(props) => <AdminLayout {...props} />} />
          <Route path="/about" render={(props) => <AdminLayout {...props} />} />
          <Route path="/careers" render={(props) => <AdminLayout {...props} />} />
          <Route path="/model-builder" render={(props) => <AdminLayout {...props} />} />
          <Route path="/use-cases" render={(props) => <AdminLayout {...props} />} />
          <Route path="/terms-of-use" render={(props) => <AdminLayout {...props} />} />
          <Route path="/privacy-policy" render={(props) => <AdminLayout {...props} />} />
          <Route path="/models" render={(props) => <ModelLayout {...props} />} />
          <Route path="/examples" render={(props) => <ModelLayout {...props} />} />
          {/* <Route path="/sitemap.xml" render={(props) => <Index {...props} />} /> */}
          <Redirect from="*" to="/index" />
        </Switch>
      </MantineProvider>
        </SnackbarProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
    </Provider>
  </ThemeProvider>
  ,
  document.querySelector("#root")
);
