
import { Layer, Source} from "react-map-gl";
import { useSelector, shallowEqual } from 'react-redux';
import {
    selectFilteredData,
    selectActiveModelSettings,
    selectGeoJsonFilters,
  } from 'store/reducers/inputReducer.js';
import {tripProps, arrowProps} from 'views/admin/Maps/geomapping/Layers/common_props.js';
var globalConfigs = require('store/data/global_configs.json');

const TripHighlightLayer = (props) => {
    const tripLayerVisible = useSelector(selectGeoJsonFilters, shallowEqual).tripLayerVisible;
    const activeModelSettings = useSelector(selectActiveModelSettings, shallowEqual);
    const tripLayerEnabled = activeModelSettings && activeModelSettings['mapping']['trip_editor'];
    const data = useSelector(selectFilteredData({'excludeTrips': !(tripLayerVisible && tripLayerEnabled), 'excludeResults':true}, shallowEqual)).geoJsonData;
  
      const tripHighlightLayerProps = {
        ...tripProps,
        id: 'trips-highlighted',
        source: 'data-cluster-disabled',
        filter: ['==', ['get', 'uuid'], props.selectedUuid],
        paint: {
          ...tripProps.paint,
          'line-color': ['get', 'colorHighlightHex'],
          'line-width': {
            'base': 3,
            'stops': [
              [1, 3],
              [8, 4],
              [15, 7],
              [22, 9]
            ]
          }
        },
      }

      // highlights the trips emanating from a point when that point is clicked or hovered.
      const tripFromHighlightLayer = {
        ...tripProps,
        id: 'trips-from-highlighted',
        source: 'data-cluster-disabled',
        filter: ['all', ['==', ['get', 'fromUuid'], props.selectedUuid], ['has', 'fromUuid']],
        paint: {
          ...tripProps.paint,
          'line-color': globalConfigs['mapping']['colors']['trip-hover'],
          'line-width': {
            'base': 3,
            'stops': [
              [1, 3],
              [8, 4],
              [15, 7],
              [22, 9]
            ]
          }
        },
      }

      const arrowFromHighlightLayerProps = {
        ...arrowProps,
      id: 'trips-from-arrow-layer',
      source: 'data-cluster-disabled',
      filter: ['all', ['==', ['get', 'fromUuid'], props.selectedUuid], ['has', 'fromUuid']],
      paint: {
          ...arrowProps.paint,
        "icon-color": globalConfigs['mapping']['colors']['trip-hover']
      }
    }


    return (<>
        <Source
        id="trip-data-highlight"
        type="geojson"
        data={data}
        >
        <Layer {...tripHighlightLayerProps} />
        <Layer {...tripFromHighlightLayer} />
        <Layer {...arrowFromHighlightLayerProps} />
        </Source>
    </>)
}

export default TripHighlightLayer;