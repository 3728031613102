import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Link } from "react-router-dom";

import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

import Notifications from "@material-ui/icons/Notifications";
import componentStyles from "assets/theme/views/admin/notifications.js";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesSnackbar from "assets/theme/components/snackbar.js";
import componentStylesDialog from "assets/theme/components/dialog.js";
import {
  setActiveMode,
  selectActiveModelType
} from 'store/reducers/inputReducer';
import { handleExitFullScreen } from 'views/admin/Notifications/utils.js';

import { useDispatch, useSelector } from 'react-redux';
var modelPages = require('store/data/model-pages.json');
const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesSnackbar = makeStyles(componentStylesSnackbar);
const useStylesButtons = makeStyles(componentStylesButtons);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const ExitExampleModeDialog = (props) => {
  const classes = {
    ...useStyles(),
    ...useStylesButtons(),
    ...useStylesSnackbar(),
    ...useStylesDialog(),
  };
  const modelType = useSelector(selectActiveModelType);
  const dispatch = useDispatch();
  const linkRef = React.useRef(null);

  const onContinue = () => {
    dispatch(setActiveMode({activeMode: 'modeling'}));
    linkRef.current.click();
    props.onClose(true);
  }

  props.open && handleExitFullScreen();

  return (
    <>

        <Dialog
          open={props.open}
          TransitionComponent={Transition}
          onClose={() => props.onClose(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          classes={{
            paper: classes.dialogNotification,
          }}
        >
          <DialogContent>
            <Box
              textAlign="center"
              paddingTop="1rem"
              paddingBottom="1rem"
            >
              <Box
                component={Notifications}
                width="3em!important"
                height="3em!important"
              ></Box>
              <Typography
                variant="h4"
                component="h4"
                className={classes.dialogHeading}
              >
                Warning!
              </Typography>
              <Typography variant="body2" component="p">
                {"You must leave example mode to add or edit data. Press continue to confirm or cancel to stay in example mode."}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => onContinue()}
              color="secondary"
              variant="contained"
            >
              Continue
            </Button>
            <Button
              component={Box}
              onClick={() => props.onClose(false)}
              color="secondary"
              marginLeft="auto!important"
            >
              Cancel
            </Button>
          </DialogActions>
          <Link hidden={true} ref={linkRef} to={modelPages[modelType]} />
        </Dialog>
    </>
  );
};

export default ExitExampleModeDialog;
