import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
// import {

// } from 'store/reducers/geoJsonFilterReducer';
import {
  selectTableData,
  updateFilter,
  selectFilterState,
  selectActiveResultsTableName
} from 'store/reducers/inputReducer';



const ResultsLayerToggleCheckbox  = (props) => {
    const activeResultsTable = useSelector(selectActiveResultsTableName);
    const tableData = useSelector(selectTableData(activeResultsTable));
    const filterState = useSelector(selectFilterState('resultsLayerVisible'))
    const disabled = !tableData || tableData.length == 0;
    const dispatch = useDispatch();


    const handleClick = () => {
        dispatch(updateFilter({newValue:!filterState, filterName:'resultsLayerVisible'}));
    }

    return (
        <Tooltip title={disabled ? 'No results to show' : ''} placement="top" PopperProps={{
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, -20],
                    },
                },
            ],
        }}>
        <FormGroup style={{display:'inline-block', margin:'0px'}}>
   
            <FormControlLabel label={'Show Results'} style={{display:'inlineBlock', marginBottom:'0px'}}
            control={<Checkbox checked={filterState} onClick={handleClick} disabled={disabled}/>} 
            />
        </FormGroup>
        </Tooltip>
  
    );
}

export default ResultsLayerToggleCheckbox;
