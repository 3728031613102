/**
 * This is the component for the pricing page. See the CardPricingGradientStarter,
 * CardPricingGradientProfessional, and CardPricingGradientCustom components for 
 * the individual pricing card information.
 */

import React from "react";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// @material-ui/icons components
import Business from "@material-ui/icons/Business";
import Check from "@material-ui/icons/Check";

// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import CardPricingGradientStarter from "components/Cards/Pricing/CardPricingGradientStarter.js";
import CardPricingGradientCustom from "components/Cards/Pricing/CardPricingGradientCustom.js";
import CardPricingGradientProfessional from "components/Cards/Pricing/CardPricingGradientProfessional.js";
import componentStyles from "assets/theme/views/auth/pricing.js";
import cardGroupStyles from "assets/theme/components/cards/card-group.js";
import buttonsStyles from "assets/theme/components/button.js";

const useStyles = makeStyles(componentStyles);
const useStylesCardGroup = makeStyles(cardGroupStyles);
const useStylesButtons = makeStyles(buttonsStyles);

function Pricing() {
  const classes = {
    ...useStyles(),
    ...useStylesCardGroup(),
    ...useStylesButtons(),
  };
  const theme = useTheme();
  const tableRows = [
    {
      FIELD1: "Traveling Salesman",
      FIELD2: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Vehicle Routing",
      FIELD2: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Pickup/dropoff windows",
      FIELD2: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Automatic distance calculations",
      FIELD2: "Limited to 10 locations",
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Save and recall models",
      FIELD2: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "REST API",
      FIELD2: "-",
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
  ];
  return (
    <>
      <AuthHeader title="Choose the best plan for your business" />
      <Container id='pricing-container1' width='100%' 
        style={{background: theme.palette.dark.main, maxWidth:'none'}}>
        <Container
          id='pricing-container2'
          component={Box}
          maxWidth="xl"
          marginTop="-8rem"
          paddingBottom="3rem"
          position="relative"
          zIndex="101"
        >
          <Box 
          id='pricing-box1'
          component={Grid} 
          container
          justifyContent="center">
            <Grid item xs={12} lg={10} style={{maxWidth:'100%', flexBasis:'100%'}}>
              <Box
                id='pricing-box2'
                marginBottom="3rem"
                className={clsx(classes.cardGroup, classes.flexMdRow)}
              >
                <CardPricingGradientStarter />
                <CardPricingGradientProfessional />
                <CardPricingGradientCustom />
              </Box>
            </Grid>
          </Box>
          <Box
            display="flex"
            paddingLeft="1rem"
            paddingRight="1rem"
            marginTop="3rem"
            className={classes.justifyContentLgCenter}
            color={theme.palette.white.main}
            alignItems="center"
          >
            <div>
              <Avatar className={classes.avatarRoot}>
                <Box
                  component={Business}
                  color={theme.palette.primary.main}
                  width="1.5rem!important"
                  height="1.5rem!important"
                />
              </Avatar>
            </div>
            <Grid item xs={12} lg={6}>
              <Box
                component="p"
                fontWeight="300"
                lineHeight="1.7"
                marginBottom="1rem"
                marginTop="1rem"
              >
                <strong>7-day money back guarantee</strong> on our Professional plans. No questions asked.
              </Box>
            </Grid>
          </Box>
          <Grid container component={Box} justifyContent="center">
            <Grid item xs={12} lg={10}>
              <TableContainer component={Box} marginTop="3rem">
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell algin="left" className={classes.tableCellHead}>
                        Features
                      </TableCell>
                      <TableCell align="center" className={classes.tableCellHead}>
                        Starter
                      </TableCell>
                      <TableCell align="center" className={classes.tableCellHead}>
                        Professional
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRows.map((prop, key) => (
                      <TableRow key={key} className={classes.tableRowBody}>
                        <TableCell algin="left" className={classes.tableCellBody}>
                          {prop.FIELD1}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellBody}
                        >
                          {prop.FIELD2}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellBody}
                        >
                          {prop.FIELD3}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
}

export default Pricing;
