const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']

//json file should have locations and costss
async function submit_message({name='', email='', message=''}) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ 'name': name, 'email': email, 'message':message})
    };
    var response
    try {
        response = await fetch(BASE_ROUTE + '/submit_message', requestOptions)
        if (!response.ok) {
            throw 'Invalid response received from submit message'
        }
        const retJson = await response.json()
        return retJson
    } catch (err) {
        throw err
    }
    

}

export default submit_message