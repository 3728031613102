function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }


//input hex is a string of form: #feabcd
//https://stackoverflow.com/a/1855903/11342048
const get_contrast_color = (hex) => 
    {
        // const rgb = color.substring(4, color.length-1)
        //  .replace(/ /g, '')
        //  .split(',');

        const rgb = hexToRgb(hex);

        // Counting the perceptive luminance - human eye favors green color... 
        var retValue = 'black';
        try {
            const luminance = ( 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b)/255;

            if (luminance > 0.5)
                {retValue =  'black'}
            else
                {retValue =  'white'}
        }
        catch {}
        return retValue;

};

export default get_contrast_color