import React from 'react';
import { withStyles } from "@material-ui/core/styles";
// import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from '@mui/material/FormControlLabel';

import Radio from '@mui/material/Radio';

const StyledRadioBox = withStyles((theme) => ({
  root: {

    "& span": {
      padding:'0px'
    },
    "& .MuiSvgIcon-root": {
      fill: props => props.disabled ? theme.palette.gray[600] : theme.palette.primary.main,
      padding: '0px',
      "&:hover": {
        backgroundColor: props => props.disabled ? theme.palette.gray[300] : 'inherit',
      }
    },
    "&$checked": {
      padding:'3px',
      paddingRight:'3px',
      "& .MuiIconButton-label": {
      //   position: "relative",
        zIndex: 0,
        border: "1px solid #bbbbbb",
        borderRadius: 3
      },

    },
    "&:not($checked)": {
      padding:'3px',
      paddingRight:'3px'
    }
    ,
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 10,
      width: 10,
      position: "absolute",
      backgroundColor: "inherit",
      zIndex: -1,
      borderColor: "transparent"
    }
  },
  checked: {}
}))(Radio);

function MyCheckbox (props) {
  const tooltipTitle = props.tooltipTitle || ''

    return (<>

        <Tooltip title={tooltipTitle}>
        <FormControlLabel
            control={<StyledRadioBox id='styled-radio-box' {...props.radioProps}/>}
            label={"Label"}
            labelPlacement="end"
            style={{marginBottom:'0px', fontSize:'0.8rem'}}
            {...props.formControlProps}
          />
        </Tooltip>

      </>)
}

export default MyCheckbox