import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// @material-ui/icons components

// core components
import MainNavbar from 'components/Navbars/MainNavbar.js';
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import componentStyles from "assets/theme/layouts/admin.js";

const useStyles = makeStyles(componentStyles);

const validLayouts = ['/admin', '/pricing', '/api', '/use-cases', '/about', '/careers', '/model-builder', '/terms-of-use', '/privacy-policy'];

const Admin = () => {
  const classes = useStyles();
  const location = useLocation();
  const [sidebarOpenResponsive, setSidebarOpenResponsive] = React.useState(
    false
  );

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (validLayouts.includes(prop.layout)) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <>
      <Box display="flex" id='Admin-Box1' height='100%'>
        <Sidebar
          routes={routes}
          openResponsive={sidebarOpenResponsive}
          closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
          logo={{
            innerLink: "/index",
            imgSrc: "https://optimiciti-images.s3.us-east-2.amazonaws.com/brand/optimiciti_teal_clear-cropped.svg",
            imgAlt: "...",
          }}
        />
        <Box 
          id='Admin-Box2'
          position="relative" 
          flex="1" className={classes.mainContent} 
          style={{minWidth:'600px', overflowX:'scroll', height:'100%', display:'flex', flexDirection:'column'}}
        >
          <MainNavbar></MainNavbar>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/index" />
          </Switch>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
          >
            <AdminFooter />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Admin;
