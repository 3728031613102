import numericValidator from 'views/admin/Forms/functions/numeric_validator.js'
// require("views/admin/Tables/functions/numeric_validator.js");


export default function longitudeValidator (args) {
  const newValue = args.newValue;
  const numericValidation = numericValidator({...args});
  if (!numericValidation.valid) {
    return numericValidation
  }
  if ((newValue >= -180 && newValue <= 180) ) {
    return {valid: true}
  }
  return {
    valid: false,
    message: 'Longitude input must be between -180 and 180'
  }  
}