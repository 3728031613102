import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import updateRunName from 'functions/server/update_run_name.js'


const EditScenarioButton = (props) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [editValue, setEditValue] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();

    const buttonStyle = {
        color: theme.palette.warning.main,
        background: theme.palette.white.main,
        pointerEvents: 'all',
        boxShadow: 'none',
        '&:hover':{
            color: theme.palette.white.main,
            backgroundColor: theme.palette.warning.main,
            pointerEvents: 'all',
        },
        ...props.buttonStyle
      }

    const handleClose = () => {
        setAnchorEl(null);
        setPopoverOpen(false);
        if (editValue && editValue != '') {
          updateRunName(props.scenarioId, editValue);
          setEditValue('');
        }
      };

    const handleCancel = () => {
    setAnchorEl(null);
    setPopoverOpen(false);      
    }

    const handleEditTextChange = (event) => {
        setEditValue(event.target.value);
    };

  const editClick = (event) => {
    if (!popoverOpen) {
      setAnchorEl(event.currentTarget);
    }
    setPopoverOpen(!popoverOpen);
  }

    return (<>
    <Tooltip title='Edit scenario name'>
    <IconButton aria-label="load-scenario" sx={buttonStyle} size='xs' aria-describedby={'edit-name-' + props.scenarioId} onClick={((event) => editClick(event))}>
        <EditIcon style={{height:'0.6rem', width: '0.6rem'}} />
    </IconButton>
    </Tooltip>
    <Popover
        id={'edit-name-' + props.scenarioId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
        }}
    >
        <div style={{padding:'15px', maxWidth:'300px'}}> <TextField
                id="outlined-multiline-flexible"
                label="Trial Name"
                multiline
                maxRows={4}
                value={editValue}
                size='small'
                onChange={handleEditTextChange}
                fullWidth
                />
            <Grid container style={{margin:'0px', paddingTop:'10px'}}>
            <Grid item xs={12} sm={8} style={{padding:'0px', paddingRight:'5px'}}>
                <Button variant={true ? "contained": "outlined"}
                    onClick={(event) => {event.stopPropagation(); handleClose()}} fullWidth
                    >
                    Save and close
                </Button>
            </Grid>
            <Grid item xs={12} sm={4} style={{padding:'0px'}}>
                <Button variant={"contained"}
                        onClick={(event) => {event.stopPropagation(); handleCancel()}} color='error'
                        fullWidth 
                >
                Cancel
                </Button>
            </Grid>
            </Grid>          
        </div>
    </Popover>
    </>
    )
}




export default EditScenarioButton;