import {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MyButton from 'views/admin/Components/MyComponents/MyButton.js'

import {
    saveScenario,
    selectScenarioInfo,
    updateGeneralSetting,
    selectIsLoggedIn
} from 'store/reducers/inputReducer.js'


import LoginRequiredDialog from 'views/admin/Notifications/LoginRequiredDialog.js';
import ScenarioNameDialog from 'views/admin/Components/Dialogs/ScenarioNameDialog.js'


function SaveInstanceButton(props) {
    // const state = useSelector(selectState);
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const scenario = useSelector(selectScenarioInfo)
    const [dialogOpen, setDialogOpen] = useState(false);
    const dispatch = useDispatch();

    const handleButtonClick = (event) => {
        if (scenario && (!scenario.scenarioName || !scenario.lastSaved)) {
            setDialogOpen(true);
        } else {
            onValidSave();
        }
        event.stopPropagation();
    }

    const onValidSave = (saveName) => {
        if (saveName) {
            dispatch(saveScenario({scenarioName: saveName}));
        } else {
            dispatch(saveScenario({scenarioName: scenario.scenarioName, userId: 'test'}));
        }
    }

    const handleSaveAndClose = (scenarioNameEntry) => {
        dispatch(updateGeneralSetting({settingName: 'scenario', subSettingName:'scenarioName', value: scenarioNameEntry}));
        dispatch(updateGeneralSetting({settingName: 'scenario', subSettingName:'lastSaved', value: new Date()}));
        setDialogOpen(false);
        onValidSave(scenarioNameEntry);
    }

    const handleCancel = () => {
        setDialogOpen(false);
    }

    const buttonProps = { onClick: handleButtonClick}

    return (<>
        <MyButton label='Save Scenario' variant='contained' color='warning'
        buttonProps={buttonProps} buttonStyle={{whiteSpace:'nowrap'}}
        />
        {isLoggedIn && <ScenarioNameDialog open={dialogOpen && isLoggedIn} handleSaveAndClose={handleSaveAndClose} handleCancel={handleCancel}/>}
        {!isLoggedIn && <LoginRequiredDialog 
            open={dialogOpen && !isLoggedIn}
            handleClose={() => setDialogOpen(false)}
            message={'You must be logged in to save scenario data.'}
        />}
    </>)
}


export default SaveInstanceButton