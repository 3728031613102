import React, {useState, useRef} from "react";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import ColumnMapping from "views/admin/Tables/column-mapping/ColumnMapping.jsx"
import importFile from "views/admin/Tables/column-mapping/functions/read-input-data.js";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import MyBadge from 'views/admin/Components/MyComponents/MyBadge.js';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import validateInputRow from 'views/admin/Tables/InputValidators/InputValidator.js';
import MismatchWarningDialog from 'views/admin/Tables/column-mapping/MismatchWarningDialog.js'
import { useDispatch, useSelector } from 'react-redux';
import store from 'store/store.js'
import {
  addRow,
  selectAllTableSettings,
  selectActiveInputTables,
  decipherUploadedTravelCosts,
  selectColumnMappingSettings,
} from 'store/reducers/inputReducer';


import componentStyles from "assets/theme/views/admin/notifications.js";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesSnackbar from "assets/theme/components/snackbar.js";
import componentStylesDialog from "assets/theme/components/dialog.js";
import { generateUUID } from "store/functions/geoJsonFunctions";
const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesSnackbar = makeStyles(componentStylesSnackbar);
const useStylesButtons = makeStyles(componentStylesButtons);

const allStaticTableSettings = require('store/data/table_settings_static.json')

//http://jsfiddle.net/dirtyd77/LzLcZ/144/
const acceptFileTypes = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" ;


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });


const flattenDict = (mismatchDict) => {
    /**
     * flattens the mismatch dictionary so that it is of the form:
     * [{tableName: x, columnName: y, newValues: z}]
     */
    const flattenedDict = [];
    if (mismatchDict) {
        for (const [tableName, tableMismatches] of Object.entries(mismatchDict)) {
            for (const [columnName, columnNewValues] of Object.entries(tableMismatches)) {      
                flattenedDict.push({'tableName': tableName, 'columnName': columnName, ...columnNewValues});
            }
        }
      }
    return flattenedDict
}

const ColumnMappingButton = (props) => {
    const classes = {
      ...useStyles(),
      ...useStylesButtons(),
      ...useStylesSnackbar(),
      ...useStylesDialog(),
    };
    const activeInputTables = useSelector(selectActiveInputTables);
    const tableSettings = useSelector(selectAllTableSettings);
    const staticTableSettings = allStaticTableSettings;
    const columnMappingSettings = useSelector(selectColumnMappingSettings);
    const columnMappingClearExisting = columnMappingSettings && columnMappingSettings.clearExistingData;
    const dispatch = useDispatch();
    const [file, setFile] = useState(null)
    const [fileContents, setFileContents] = useState(null);
    const [sheets, setSheets] = useState(null);
    const [currentSheet, setCurrentSheet] = useState(0);
    const [sourceColumns, setSourceColumns] = useState([]);
    const [targetTable, setTargetTable] = useState(props.tableName)
    const [mismatchDialogOpen, setMismatchDialogOpen] = useState(false);
    const [mismatches, setMismatches] = useState(null);
    const [data, setData] = useState(null);
    const [openForm, setOpenForm] = useState(false);



    const handleCloseForm = () => {
        setOpenForm(false);
    };
      
    const theme = useTheme();

    const inputFileRef = useRef(null)


    const onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();
        var fileName = event.target.files[0];
        getContentsFromFileName(fileName)
        inputFileRef.current.value = '';
        setOpenForm(true)
    }

    const getContentsFromFileName = (fileName) => {
        setFile(fileName)

        importFile(fileName).then((contents) =>  {
            const parsedContents = JSON.parse(contents);
            setFileContents(parsedContents);
            const sheets = Object.keys(parsedContents);
            setSheets(sheets);
            setCurrentSheet(0);
            setSourceColumns(parsedContents[sheets[0]][0]);
        });
    }

    const handleClose = () => {
        setFile(null);
        setFileContents(null);
        setSheets(null);
        setCurrentSheet(0);
        setSourceColumns([]);
        setOpenForm(false);
        setMismatchDialogOpen(false);
        document.getElementsByTagName("body")[0].style.overflow = 'auto'
    }

    const onCancel = (event) => {
        event.stopPropagation();
        setOpenForm(false);
        setMismatchDialogOpen(false);
        handleClose();
    }

    const handleAddDataDispatch = (newData, tableName) => {
        var resolvedTableName = tableName
        if (!resolvedTableName) {
            resolvedTableName = targetTable
        }
        const uuids = [];
        newData.map(() => {
            const uuid = generateUUID();
            uuids.push(uuid);
        })

        dispatch(addRow({newRow:newData, tableName: resolvedTableName, uuid:uuids, multiInsert:true, clearExisting:columnMappingClearExisting, fromUpload:true}))
    }

    /**
     * creates the data rows necessary to add rows from the mismatch values found when a table is added with
     * new dropdown values. mismatchValues should be a set
     */
    const addMismatchItems = (tableName, columnName, mismatchValues) => {
        
        const mismatchData = [];
        Array.from(mismatchValues).map((val, idx) => {
            const dataRow = {}
            dataRow[columnName] = val
            mismatchData.push(dataRow);
        })
        handleAddDataDispatch(mismatchData, tableName);
    }

    /**
     * handles the return from when the MismatchWarningDialog component returns. If value="add", then actions are taken to 
     * add the mismatch items to the appropriate table and update the mismatch dialog. 
     * @param {*} values a list of ['add', 'remove'] items. If null, then action will be cancelled.
     */
    const handleMismatchReturn = (values) => {
        if (values === 'cancel') {
            handleClose();
            return;
        }
        if (mismatches.length > 0 && !values) {
            handleClose();
            return
        }

        var newData = [...data];
        if (mismatches.length === 0) {}
        else {
            values.map((action, idx) => {
                if (action === 'add') {
                    const iterMismatches = mismatches[idx]
                    const mismatchTable = iterMismatches['tableName'];
                    const mismatchColumn = iterMismatches['columnName'];
                    const mismatchNewValues = iterMismatches['newValues'];
                    addMismatchItems(mismatchTable, mismatchColumn, mismatchNewValues);
                }

                if (action === 'remove') {
                    const iterMismatches = mismatches[idx]
                    const sourceColumn = iterMismatches['sourceColumn'];
                    const mismatchNewValues = iterMismatches['newValues'];
                    newData = newData.filter((obj) => {return !mismatchNewValues.has(obj[sourceColumn])});
                    setData(newData);
                }
            });
        }

        handleAddDataDispatch(newData, targetTable);
        handleClose();
        
    }


    const onSubmitPress = (entries) => {
        setOpenForm(false);

        var warnings = [];
        var contentColumnsUsed = {}
        for (const [myColumnName, sourceColumnName] of Object.entries(entries)) {

            if (staticTableSettings[targetTable]['isRequired'][myColumnName] && (!sourceColumnName || sourceColumnName === '')) {
                warnings.push('Required field: ' + myColumnName + ' not mapped. Optimization cannot run without this field.')
            }

            if (sourceColumnName) {      
                const sourceIdx = sourceColumns.indexOf(String(sourceColumnName))
                contentColumnsUsed[myColumnName] = sourceIdx
            }
        }
        
        var newData = []
        const state = store.getState().inputData.present;
        var newMismatches = {}
        fileContents[sheets[currentSheet]].map((item, idx) => {
            if (idx > 0) {
                var dataRow = {}
                Object.keys(contentColumnsUsed).map((key) => {
                    const columnIdx = contentColumnsUsed[key];
                    dataRow[key] = item[columnIdx];
                })

                const existingData = [newData];
                if (!columnMappingClearExisting) {
                    existingData.push(state.data[targetTable])
                }
                const validationResponse = validateInputRow({
                    row:dataRow, 
                    tableSettings: staticTableSettings[targetTable], 
                    existingData: existingData,
                    tableName: targetTable,
                    rowNumber: idx,
                    clearExisting:columnMappingClearExisting, 
                    allData: state.data,
                    mismatches: newMismatches 
                })
                newData.push(validationResponse['row']);
                newMismatches = validationResponse['mismatches'];
            }
        });

        const flattenedMismatches = flattenDict(newMismatches)
        setMismatches(flattenedMismatches);


        if (props.tableName === 'travel_costs') {
            dispatch(decipherUploadedTravelCosts({uploadedData:newData, overwrite:true}))
            return;
        }

        setData(newData);
        setOpenForm(false);
        setMismatchDialogOpen(true);

    }


    const handleSelectChange = (event) => {
        event.stopPropagation();
        setTargetTable(event.target.value);
        handleClose();
      };

    const onIconClick = (event) => {
        event.stopPropagation();
        // if (activeResultsShape > 0) {
        //     const warningMessage = 'Adding new data will clear results from your latest optimization run. You can still access your prior optimization run using the "Previous Trials" selection box, below.'
        //     dispatch(setWarningMessage({message:warningMessage, show:true}));
        // } else {
            inputFileRef.current.click();
        // }
    }


    return ( <>
                <input type='file' id='file' ref={inputFileRef} style={{display: 'none'}} onClick={(e) => { e.stopPropagation()}}
                    onChange={(e) => {e.stopPropagation(); onChangeFile(e)}} accept={acceptFileTypes}/>
                    {props.useBadge &&
                        <MyBadge  {...props.badgeProps} badgeProps={{pill: true, 
                            onClick:(event) => {onIconClick(event)}, 
                            style:{...props.badgeProps.style, zIndex:'0'}}}
                            >
                            {props.label}
                        </MyBadge>}
                        {!props.useBadge && 
                              <Tooltip title={'Upload from file'}>
                              <IconButton id='upload-from-file-icon-button' onClick={(event) => { onIconClick(event)}}>
                                  <FileUploadIcon style={{color:theme.palette.primary.main}}/>
                              </IconButton>
                            </Tooltip>
                        }

                {openForm && <Dialog
                disableBackdropClick
                maxWidth="sm"
                open={openForm}
                TransitionComponent={Transition}
                keepMounted={false}
                onClose={handleCloseForm}
                onClick={(event) => event.stopPropagation()}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                >
                <Box component={DialogContent} padding="0!important">
                <Card className={classes.dialogCardForm}>
                    <CardHeader
                    className={classes.cardHeader}
                    title={
                        <Box
                        fontSize="80%"
                        fontWeight="400"
                        component="small"
                        color={theme.palette.gray[600]}
                        >
                        Match the columns from your uploaded spreadsheet to the corresponding column names in the optimizer input table
                        </Box>
                    }
                    subheader={
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Target Table</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={targetTable}
                                value={targetTable}
                                label="Target Table"
                                onChange={handleSelectChange}
                            >
                                {activeInputTables.map((tableName) => 
                                    <MenuItem value={tableName}>{staticTableSettings[tableName][['title']]}</MenuItem>
                                )}
                            </Select>
                            </FormControl>
                        </Box>}
                    titleTypographyProps={{
                        component: Box,
                        textAlign: "center",
                        marginBottom: "1rem!important",
                        marginTop: ".5rem!important",
                        fontSize: "1rem!important",
                    }}>

                    </CardHeader>
                    <CardContent style={{paddingTop:'10px'}}
                    >

                    <Grid>
                        <ColumnMapping 
                            sourceData={sourceColumns} 
                            onSubmitPress={onSubmitPress}
                            onCancel={onCancel}
                            targetTable={targetTable}
                            > 
                        </ColumnMapping>
                    </Grid>
                    </CardContent>
                </Card>
                </Box>
                </Dialog>}
                {mismatchDialogOpen && <MismatchWarningDialog 
                    open={mismatchDialogOpen} 
                    mismatches={mismatches} 
                    onClose={handleMismatchReturn} data={data}
                    fileName={file.name}
                    targetTable={targetTable}
                    />}
            </>
    )
}

export default React.memo(ColumnMappingButton);