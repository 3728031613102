/**
 * Takes as input a number, representing seconds, and converts it to a more user-friendly
 * text format (i.e. 0.32 seconds becomes < 1 second)
 */
const convertSecondsToText = (seconds) => {
    if (seconds < 1) {
        return "<1 second"
    } else if (seconds < 120) {
        return Math.round(seconds) + ' seconds'
    } else {
        return "~" + Math.round(seconds / 60) + ' minutes'
    }
}

export default convertSecondsToText