/*!
This component is the terms of use page for the website. It includes the terms of use for the website.
*/
import React from "react";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";


class Policy extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <main ref="main" style={{'overflow':'scroll'}}>

            <Container style={{marginTop:'20px', marginBottom:'20px'}}>
              <Row className="justify-content-center mt-20">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Optimiciti Terms of Use</h4>
                      {this.props.policyInfo['contents'].map((section, index) => (
                        <div key={index}>
                          <p className="mt-0" style={{fontWeight:500, marginBottom:'0px'}}>
                            {index + 1}{". "}{section.sectionTitle}
                          </p>
                          <p className="mt-0" style={{marginBottom:'10px'}}>
                            {section.sectionContents}
                            {section.address && 
                              section.address.map((line) => {
                                return (<div>{line}<br/></div>)
                              })
                            }
                          </p>
                        </div>
                      ))}
                      <p className="mt-0" style={{fontWeight:500, marginBottom:'0px'}}>
                      {this.props.policyInfo['effectiveDate'] && "Effective Date: " + this.props.policyInfo['effectiveDate']}
                      </p>


                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
        </main>
      </>
    );
  }
}

export default Policy;
