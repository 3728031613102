import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MyButton from 'views/admin/Components/MyComponents/MyButton.js'



const WizardActionButtons = (props) => {

    return (<>
    <Box sx={{ mb: 2 }} style={{marginTop:'10px'}}>

        <Stack direction='row' spacing={1} >
        {props.handleNext && <MyButton
            disabled={false}
            variant="contained"
            color='primary'
            label='Continue'
            buttonProps={{
            disabled: false,
            onClick:props.handleNext,
            }}
        />}

        {props.handleBack && <MyButton
        label='Back'
        color='warning'
        variant='contained'
        buttonProps={{
            disabled:false,
            onClick:props.handleBack,
        }}
        />}

        {props.handleBuildModel && <MyButton
            label='Build Model'
            color='success'
            variant='contained'
            buttonProps={{
            disabled:false,
            onClick:props.handleBuildModel,
            }}
        />}
        </Stack>
    </Box>
    </>)

}

export default WizardActionButtons;