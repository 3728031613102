import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import componentStyles from "assets/theme/views/admin/buttons.js";
import componentStylesButtons from "assets/theme/components/button.js";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);



const MyButton = (props) => {
  /**
   * This is a custom button component that can be used throughout the app.
   * It is a wrapper around the Material UI Button component.
   * It has a few extra features:
   * - It can be styled with a gradient
   * - It can be styled with a tooltip
   * - It can be styled with a padding
   * 
   * It takes the following props:
   * - label: the text to display on the button
   * - variant: the variant of the button (contained, outlined, gradient)
   * - color: the color of the button (primary, default, info, success, error, warning)
   * - padding: the padding of the button
   * - tooltipTitle: the title of the tooltip
   * - buttonProps: any other props to pass to the button
   * - fullWidth: whether the button should be full width
   * - buttonStyle: any other styles to pass to the button
   * 
   */

  const variant = props.variant || 'contained';
  const color = props.color || 'primary';
  const padding = {padding: props.padding} || {padding:'5px'}
  const tooltipTitle = props.tooltipTitle || ''
  const classes = { ...useStyles(), ...useStylesButtons() };

  var divStyle = {...padding};
  if (props.fullWidth) {
    divStyle['width'] = '100%'
  }

  const buttonStyle = props.buttonStyle || {padding:'5px'}
  if (!buttonStyle['padding']) {
    buttonStyle['padding'] = '5px'
  };
  if (props.fullWidth) {
    buttonStyle['width'] = '100%'
  }

  const getButtonClassName = (variant, color) => {
    if (variant == 'contained') {
      if (color == 'primary') {
        return {root: classes.buttonContainedPrimary}
      }
      if (color == 'default') {
        return {root: classes.buttonContainedDefault}
      }
      if (color == 'info') {
        return {root: classes.buttonContainedInfo}
      }
      if (color == 'success') {
        return {root: classes.buttonContainedSuccess}
      }
      if (color == 'error') {
        return {root: classes.buttonContainedError}
      }
      if (color == 'warning') {
        return {root: classes.buttonContainedWarning}
      }
    }
  
    if (variant == 'outlined') {
      if (color == 'primary') {
        return {root: classes.buttonOutlinePrimary}
      }
      if (color == 'default') {
        return {root: classes.buttonOutlineDefault}
      }
      if (color == 'info') {
        return {root: classes.buttonOutlineInfo}
      }
      if (color == 'success') {
        return {root: classes.buttonOutlineSuccess}
      }
      if (color == 'error') {
        return {root: classes.buttonOutlineError}
      }
      if (color == 'warning') {
        return {root: classes.buttonOutlineWarning}
      }
    }

    if (variant == 'gradient') {
      if (color == 'primary') {
        return {root: classes.buttonContainedPrimaryGradient}
      }
      if (color == 'default') {
        return {root: classes.buttonContainedDefaultGradient}
      }
      if (color == 'info') {
        return {root: classes.buttonContainedInfoGradient}
      }
      if (color == 'success') {
        return {root: classes.buttonContainedSuccessGradient}
      }
      if (color == 'error') {
        return {root: classes.buttonContainedErrorGradient}
      }
      if (color == 'warning') {
        return {root: classes.buttonContainedWarningGradient}
      }
    }
  }

  return (<>

    <Tooltip title={tooltipTitle}>
      <div style={divStyle}>
        <Button fullWidth={props.fullWidth} classes={getButtonClassName(variant, color)} {...props.buttonProps} startIcon={props.startIcon}
        style={buttonStyle}>
          {props.label}
        </Button>
      </div>
    </Tooltip>
    
    </>)
};

export default MyButton;
