import {WebMercatorViewport} from '@deck.gl/core';

const GLOBAL_CONFIGS = require('store/data/global_configs.json');


function getFeatureColor(f) {
  // if (f.properties.isDepot) {
  //   return [255, 0, 0]
  // }
  if (f.properties.Color) {
    return hexToRgb(f.properties.Color);
  }
  return f.properties.defaultColor;
}

function getLineColor(f) {
  // if (f.properties.isDepot && f.properties.isDepot.length > 0) {
  //   return GLOBAL_CONFIGS.mapping.colors['depot-outline']
  // }
  return f.properties.defaultColor;
}  


function getHighlightColor(f) {
  if (f.object) {
    // return [128, 201, 197];
    if (f.object.properties.Color) {
      const brightColorHex = increase_brightness(hexToRgb(f.object.properties.Color), 50);
      return hexToRgb(brightColorHex);
    }
    else {
      return f.object.properties.colorHover;
    }

  }
}

// #############################
const hexToRgb = (input) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  const retArray = [];
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    // check to see if it is an rgb instead
    var rgb = input;
    rgb = rgb.replace(/[^\d,.]/g, '').split(',');
    retArray.push(parseInt(rgb[0]));
    retArray.push(parseInt(rgb[1]));
    retArray.push(parseInt(rgb[2]));
    return retArray;
  }

  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase();
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];

  retArray.push(parseInt(first, 16));
  retArray.push(parseInt(second, 16));
  retArray.push(parseInt(last, 16));
  return retArray
};


function increase_brightness(color, percent) {
  // strip the leading # if it's there
  var r;
  var g;
  var b;
  try {
    r = color[0];
    g = color[1];
    b = color[2];
  } catch (error) {
    var hex = color.replace(/^\s*#|\s*$/g, '');

    if(hex.length == 3){
        hex = hex.replace(/(.)/g, '$1$1');
    }

    r = parseInt(hex.substr(0, 2), 16);
    g = parseInt(hex.substr(2, 2), 16);
    b = parseInt(hex.substr(4, 2), 16);
  }

  return '#' +
     ((0|(1<<8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
     ((0|(1<<8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
     ((0|(1<<8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
}

export const layerConfigs = {
  getFillColor: (d) => getFeatureColor(d),
  getLineColor: (d) => getLineColor(d),
  getSize: d => 25,
  sizeScale: 2,
  pointRadiusScale: 5,
  pointRadiusMinPixels: 8,
  pointRadiusMaxPixels: 8,
  lineWidthScale: 2,
  lineWidthMinPixels: 1,
  getLineWidth: 1,
  getTentativeFillColor: () => GLOBAL_CONFIGS.mapping.colors.tentative,
  getTentativeLineColor: () => GLOBAL_CONFIGS.mapping.colors.tentative,
  autoHighlight: true,
  highlightColor: (d) => getHighlightColor(d)
}

export function getTooltip(object, layer) {
  //gets the tooltip for the mouseover. The display depends on whether it is a point or line string.

  
  if (object) {

    if (layer.props.isResultsIcon) {
      return object.properties.fromLoc
    }

    if (layer.props.isDrop) {
      return object['Location Name'] + ' (dropped)'
    }

    if (object.geometry) {
      if (object.geometry.type == 'Point') {
        return object.properties.name
      } 
      
      else if (object.geometry.type == 'LineString') {
        if (object.properties.fromLoc) {
          if (object.properties.toLoc) {
            return (
              `From: ${object.properties.fromLoc}
              To: ${object.properties.toLoc}`
            )
 
          }
        }  
      } 
    }


    
    else {
      return
    }
  }
}


//fits the bounds of the viewstate such that all points fit into the view.
export const fitBounds = (geoJson, global=true) => {
  const RECT_LAT_INDEX = 1;
  const RECT_LON_INDEX = 0;

  var latMin;
  var latMax;
  var lonMin;
  var lonMax;
  if (global) {
    latMin = 90;
    latMax = -90;
    lonMin = 180;
    lonMax = -180;
  } else {
    latMin = geoJson['features'][0].geometry.coordinates[RECT_LAT_INDEX];
    latMax = geoJson['features'][0].geometry.coordinates[RECT_LAT_INDEX];
    lonMin = geoJson['features'][0].geometry.coordinates[RECT_LON_INDEX];
    lonMax = geoJson['features'][0].geometry.coordinates[RECT_LON_INDEX];      
  }

  

  geoJson['features'].map((elem, idx) => {
    if (elem.geometry.type == 'Point') {
      const coord = elem.geometry.coordinates
      
      if (coord[RECT_LAT_INDEX] < latMin) latMin = coord[RECT_LAT_INDEX];
      if (coord[RECT_LAT_INDEX] > latMax) latMax = coord[RECT_LAT_INDEX];
      if (coord[RECT_LON_INDEX] < lonMin) lonMin = coord[RECT_LON_INDEX];
      if (coord[RECT_LON_INDEX] > lonMax) lonMax = coord[RECT_LON_INDEX];
    }
    
  });

  const bounds = [
    [lonMin, latMax],
    [lonMax, latMin],
  ];

  return bounds;
}

export function getComponentBounds(deckRef) {
  var height = 0;
  var width = 0;
  if (deckRef.current) {
    height = deckRef.current.clientHeight;
    width = deckRef.current.clientWidth;
  }
  return {'height': height, 'width': width}
}

export default function updateBounds (deckRef, viewState, setViewState, geoJson, global=true, maxZoom=12) {

  const componentBounds = getComponentBounds(deckRef);
  const bounds = fitBounds(geoJson, global);
  

  var newViewState;
  if (global) {
    const { latitude, longitude, zoom } = new WebMercatorViewport({ width: componentBounds.width, height: componentBounds.height }).fitBounds(bounds, { padding: { top: 100, bottom: 100, left: 100, right: 100 } })
    const newLatitude = latitude;
    const newLongitude = longitude;
    const newZoom = zoom > maxZoom ? maxZoom : zoom;
    newViewState = { ...viewState,
      latitude: newLatitude,
      longitude: newLongitude,
      zoom: newZoom
    };
  }

  else {
    // const { latitude, longitude } = new WebMercatorViewport({ width: componentBounds.width, height: componentBounds.height }).fitBounds(bounds, { padding: { top: 100, bottom: 100, left: 100, right: 100 } })
    
    const newLatitude = (bounds[0][1] + bounds[1][1]) / 2
    const newLongitude = (bounds[0][0] + bounds[1][0]) / 2;
    const newZoom = getZoom({width: componentBounds.width, height: componentBounds.height, bounds, padding: { top: 100, bottom: 100, left: 100, right: 100 } }).zoom
    const newTarget = [newLongitude, newLatitude, 0]
    newViewState = { ...viewState,
        target: newTarget,
        zoom: newZoom
      };
  }

  setViewState(newViewState)

}


/**
 * * An object describing the padding to add to the bounds.
 * @typedef {Object} PaddingObject
 * @property {Number} top - Padding from top in pixels to add to the given bounds
 * @property {Number} bottom - Padding from bottom in pixels to add to the given bounds
 * @property {Number} left - Padding from left in pixels to add to the given bounds
 * @property {Number} right - Padding from right in pixels to add to the given bounds
 */

/**
 * Returns map settings {latitude, longitude, zoom}
 * that will contain the provided corners within the provided width.
 * Only supports non-perspective mode.
 * @param {Number} width - viewport width
 * @param {Number} height - viewport height
 * @param {Array} bounds - [[lon, lat], [lon, lat]]
 * @param {Array} minExtent - The width/height of the bounded area will never be smaller than this
 * @param {Number|PaddingObject} [padding] - The amount of padding in pixels
 *  to add to the given bounds. Can also be an object with top, bottom, left and right
 *  properties defining the padding.
 * @param {Array} [offset] - The center of the given bounds relative to the map's center,
 *    [x, y] measured in pixels.
 * @returns {Object} - latitude, longitude and zoom
 * 
 * DK: modified from https://github.com/uber-archive/viewport-mercator-project/tree/master/src
 */
export function getZoom({
  width,
  height,
  bounds,
  minExtent = 0,  // 0.01 would be about 1000 meters (degree is ~110KM)
  maxZoom = 40, // ~x4,000,000 => About 10 meter extents
  // options
  padding = 0,
  offset = [0, 0]
}) {
  const [[west, south], [east, north]] = bounds;

  if (Number.isFinite(padding)) {
    const p = padding;
    padding = {
      top: p,
      bottom: p,
      left: p,
      right: p
    };
  } else {
  }

  const viewport = {
    width,
    height,
    longitude: 0,
    latitude: 0,
    zoom: 0
  };


  const nw = [west, north];
  const se = [east, south];

  // width/height on the Web Mercator plane
  const size = [
    Math.max(Math.abs(se[0] - nw[0]), minExtent),
    Math.max(Math.abs(se[1] - nw[1]), minExtent)
  ];

  const targetSize = [
    width - padding.left - padding.right - Math.abs(offset[0]) * 2,
    height - padding.top - padding.bottom - Math.abs(offset[1]) * 2
  ];


  // scale = screen pixels per unit on the Web Mercator plane
  const scaleX = targetSize[0] / size[0];
  const scaleY = targetSize[1] / size[1];

  // Find how much we need to shift the center
  const offsetX = (padding.right - padding.left) / 2 / scaleX;
  const offsetY = (padding.bottom - padding.top) / 2 / scaleY;

  const center = [
    (se[0] + nw[0]) / 2 + offsetX,
    (se[1] + nw[1]) / 2 + offsetY
  ];

  const zoom = viewport.zoom + Math.log2(Math.abs(Math.min(scaleX, scaleY)));

  return {
    zoom: Math.min(zoom, maxZoom)
  };
}
