import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tooltip from "@material-ui/core/Tooltip";
import React, {useEffect, useState} from "react";
import componentStyles from "assets/theme/views/admin/maps/map-mode-selector.js";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from 'react-redux';

import ExitExampleModeDialog from 'views/admin/Notifications/ExitExampleModeDialog.js'
import {
    selectActiveModelSettings,
    selectGeneralSetting,
    selectFormCanAddRow,
    updateGeneralSetting,
    updateFilter,
    getInExampleMode,
    selectActiveResultsShape,
} from 'store/reducers/inputReducer.js'
import { addNotification } from "store/reducers/inputReducer";

const useStyles = makeStyles(componentStyles);


/**
 * Group of buttons used to change which Map mode is shown (View/Edit, Add Locations, Add Trips)
 * @param {*} props, which includes an onClick function to close the parent component when a button is 
 *  clicked
 */
const MapModeSelector  = (props) => {
    const dispatch = useDispatch();
    const classes = { ...useStyles() };
    const modelSettings = useSelector(selectActiveModelSettings)
    const mapMode = useSelector(selectGeneralSetting('mapping')).mapMode;
    const canAddTrips = useSelector(selectFormCanAddRow('trips'));
    const inExampleMode = useSelector(getInExampleMode);
    const activeResults = useSelector(selectActiveResultsShape) > 0;
    const [lastClicked, setLastClicked] = useState('location');
    const [exitExampleDialogOpen, setExitExampleDialogOpen] = useState(false);

    const getStyle = (id, enabled) => {
        if (!enabled) {
            return 
        }
        else {
            if (mapMode == id) {
                return classes.buttonSelected
            } else {
                return classes.buttonUnselected
            }
        }
    }

    const updateMapMode = (mapMode) => {

        dispatch(updateGeneralSetting({settingName:'mapping', subSettingName: 'mapMode', value: mapMode})); 
        if (mapMode == 'trips') {
            dispatch(updateFilter({newValue:true, filterName:'tripLayerVisible'}));
            dispatch(updateFilter({newValue:true, filterName:'locationLayerVisible'}));
        }
    }
    
    useEffect(() => {
        if (canAddTrips.valid) {} 
        if (mapMode == 'trips') {
            dispatch(updateGeneralSetting({settingName:'mapping', subSettingName: 'mapMode', value: 'view'})); 
        }
    }, [canAddTrips])

    const onAddLocationClick = (event) => {
        event.stopPropagation();
        setLastClicked('location');
        if (inExampleMode) {
            setExitExampleDialogOpen(true);
        }
        else {
            updateMapMode('location');
        }
        props.onClick();
    }

    const exitExampleModeDialogClose = (success=true) => {
        if (success) {
            updateMapMode(lastClicked);
        }
        setExitExampleDialogOpen(false);
    }

    const onAddTripModeClick = (event) => {
        setLastClicked('addTrips');
        event.stopPropagation();
        if (inExampleMode) {
            setExitExampleDialogOpen(true);
        }
        else if (activeResults) {
            dispatch(addNotification({
                notificationMsg: 'Unable to add trips with active results.',
                notificationType:'warning',
                notificationAction: 'setMapMode',
                notificationActionPayload: {'mapMode': 'addTrips', resetMapLayers: true, 'clearResults': true},
                proceedText: 'Clear results and proceed',
                isNonRoutine: true,
            }))
        } else {
            updateMapMode('addTrips');
        }
        props.onClick();
    }

    return (<>
            <div style={{zIndex:'3'}}>
            <ButtonGroup
            variant="contained"
            color="secondary"
            aria-label="contained-secondary-button-group"
            orientation='vertical'
            >
                <Button className={getStyle('view', true)} 
                onClick={(event) => {event.stopPropagation(); updateMapMode('view'); props.onClick()}}>
                    View / Edit
                </Button>
                {modelSettings && modelSettings['mapping']['location_editor'] &&
                <Button className={getStyle('location', true)} onClick={(event) => onAddLocationClick(event)}>
                    Add Locations
                </Button>
                }
            
                {modelSettings && modelSettings['mapping']['trip_editor'] &&
                <Tooltip title={canAddTrips.valid ? '' : 'Unable to add trips until there is at least one location'}>
                <Button disabled={!canAddTrips.valid} className={getStyle('addTrips', canAddTrips.valid)}  
                onClick={onAddTripModeClick}>
                    Add Trips
                </Button>
                </Tooltip>
                }
            </ButtonGroup>
            </div>
            <ExitExampleModeDialog open={exitExampleDialogOpen} onClose={exitExampleModeDialogClose} />
        </>
    )

}

export default MapModeSelector