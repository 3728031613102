const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']

//json file should have locations and costss
async function submit_bug_report({user_email='', bug_message='', bug_files=[], bug_filenames=[]}) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ 'user_email': user_email, 'bug_message': bug_message, 'bug_files':bug_files, 'bug_filenames': bug_filenames})
    };
    var response
    try {
        response = await fetch(BASE_ROUTE + '/submit_bug_report', requestOptions)
        if (!response.ok) {
            throw 'Invalid response received from submit message'
        }
        const retJson = await response.json()
        return retJson
    } catch (err) {
        throw err
    }
    

}

export default submit_bug_report