import React, {useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { useSelector, useDispatch } from 'react-redux';
import {addNotification, selectIsLoggedIn, login} from 'store/reducers/inputReducer.js'

import Fade from "@material-ui/core/Fade";
import componentStyles from "assets/theme/views/admin/notifications.js";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesSnackbar from "assets/theme/components/snackbar.js";
import componentStylesDialog from "assets/theme/components/dialog.js";
const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesSnackbar = makeStyles(componentStylesSnackbar);
const useStylesButtons = makeStyles(componentStylesButtons);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



const LoginModal = (props) => {

    const isLoggedIn = useSelector(selectIsLoggedIn);
    const [testState, setTestState] = useState(true);
    const dispatch = useDispatch();

    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useStylesSnackbar(),
        ...useStylesDialog(),
      };
      const theme = useTheme();

      const responseGoogle = (response) => {
         const userObject = jwt_decode(response.credential);
        //  const { name, sub, picture } = userObject;
         dispatch(login({newLogin: userObject, loginSource:'Google'})); 
         setTestState(!testState)
         dispatch(addNotification({notificationType:'success', notificationMsg:'Login successful'}));
         props.closeForm()
       }

    return (<>
    <Fade in={props.open}>
        <Dialog
        maxWidth="xs"
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.closeForm()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        >
        <Box component={DialogContent} padding="0!important">
        <Card className={classes.dialogCardForm}>
            <CardHeader
            className={classes.cardHeader}
            title={
                <Box
                fontSize="100%"
                fontWeight="600"
                component="medium"
                color={theme.palette.gray[600]}
                >
                Sign in with Google
                </Box>
            }
            titleTypographyProps={{
                component: Box,
                textAlign: "center",
                marginBottom: "1rem!important",
                marginTop: ".5rem!important",
                fontSize: "1rem!important",
            }}
            subheader={
                <Box textAlign="center">
                    {!isLoggedIn &&
                    <Box id='google-login-box' textAlign='center' style={{marginRight:'calc(50% - 100px)', marginLeft:'calc(50% - 100px)'}}>
                        <GoogleLogin
                        onError={() => {}}
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        />
                    </Box>}
                </Box>
            }
            ></CardHeader>
            <CardContent
            className={classes.dialogCardContentForm}
            >

            </CardContent>
        </Card>
        </Box>
        </Dialog>
        </Fade>
        </>)
    }

export default LoginModal