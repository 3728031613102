import React, {useState} from "react";
// import {
//     Badge
//   } from "reactstrap";
// import Popover from '@mui/material/Popover';
import { Popover } from 'antd';
import List from '@mui/material/List';
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import Typography from "@material-ui/core/Typography";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import MyBadge from 'views/admin/Components/MyComponents/MyBadge.js'
import MyGradientIcon from 'views/admin/Components/MyComponents/MyGradientIcon.js'
import TableSummaryListItems from 'components/Cards/Cards/TableSummaryListItems.js';
import componentStyles from "assets/theme/components/cards/dashboard/card-table-summary.js";
import componentStylesTypography from "assets/theme/components/typography.js";
import { useSelector } from 'react-redux';

import {selectTableIsEmpty} from 'store/reducers/inputReducer.js'
import getIconFromName from 'views/admin/Forms/icon_selector/icon_mapping.js'
import NewFormEntryButton from "views/admin/Forms/NewFormEntryButton";
import OpenTableDialogButton from 'views/admin/Forms/OpenTableDialogButton.js';
import ColumnMappingButton from "views/admin/Tables/column-mapping/ColumnMappingButton";

const useStyles = makeStyles(componentStyles);
const useStylesTypography = makeStyles(componentStylesTypography);

const allStaticTableSettings = require('store/data/table_settings_static.json')


function getIcon (functionName) {
  var icon;
  if (functionName === 'vehicleType') {
    icon = getIconFromName('VehicleType');
  }

  if (functionName === 'location') {
      icon = getIconFromName('LocationPin');
  }

  if (functionName === 'trip') {
      icon = getIconFromName('Trip');
  }

  if (functionName === 'travelCosts') {
      icon = getIconFromName('Cost');
  }

  if (functionName == 'vehicle') {
    icon = getIconFromName('Car');
  }

  return icon;
}

const getBorderClassName = (color, isHovered) => {
    if (color == 'orange' || color == 'warning') {
      if (isHovered) {
        return 'collapsibleCardWarningHover'
      }
        return 'collapsibleCardWarning'
    }
    if (color == 'green' || color == 'success') {
      if (isHovered) {
        return 'collapsibleCardSuccessHover'
      }
        return 'collapsibleCardSuccess'
    }
    if (color == 'blue' || color == 'info') {
      if (isHovered) {
        return 'collapsibleCardInfoHover'
      }
        return 'collapsibleCardInfo'
    }
    if (color == 'red' || color == 'error') {
      if (isHovered) {
        return 'collapsibleCardErrorHover'
      }
        return 'collapsibleCardError'
    }
    if (color == 'navy' || color == 'primary') {
      if (isHovered) {
        return 'collapsibleCardPrimaryHover'
      }
        return 'collapsibleCardPrimary'
    }
}

const CollapsedTableSummary = (props) => {
  const classes = {...useStyles(), ...useStylesTypography()};
  const staticTableSettings = allStaticTableSettings[props.tableName];

  return (
      <Popover
      overlayInnerStyle={{padding:'0px', 
        border:'2px solid ' + props.borderColor,
        backgroundColor: props.backgroundColor,
        borderRadius:'10px',
        padding:'2px'
      }}
      placement="right"
      content={
          <div id='popover' 
            style={{backgroundColor:'inherit', display:'flex', flexDirection:'column', boxShadow:'none', padding:'0px', 
          marginRight:'-20px', marginTop:'-10px', marginBottom:'-10px'}}>
            {staticTableSettings['allowAddRow'] &&
            <NewFormEntryButton  
            useBadge={true} dataTable={props.tableName} badgeProps={{classes:{root: classes[getBorderClassName(staticTableSettings['summary']['color'])]},
              color:'gray', badgeContent:'Add', style:{'paddingLeft': '10px', marginLeft:'-8px', paddingBottom:'3px'}}}/>
            }
          <OpenTableDialogButton useBadge={true} dataTable={props.tableName}
            badgeProps={{classes:{root: classes[getBorderClassName(staticTableSettings['summary']['color'])]},
            color:'gray', badgeContent:'Open Table', style:{'paddingLeft': '10px', paddingBottom:'3px'}}}/>
          <ColumnMappingButton tableName={props.tableName} useBadge={true} badgeProps={{color:'gray', badgeContent:'Upload', style:{'paddingLeft': '10px', marginLeft:'-8px', zIndex:'0'}}}/>
          </div>
      }>
      <span> {/* Icon for table */}
      <MyBadge innerComponent={<MyGradientIcon id='table-summary-icon'
        icon={getIcon(staticTableSettings.summary['headerIconName'])} 
        color={staticTableSettings['summary']['color']} 
        innerBoxWidth='2rem' innerBoxHeight='2rem'/>}  
      />
      </span>
      </Popover>
  )
}

const CollapsedTableSummaryCard = (props) => {
  const classes = {...useStyles(), ...useStylesTypography()};
  const staticTableSettings = allStaticTableSettings[props.tableName];

  return (
    <Card classes={{root: classes['collapsibleCard_' + staticTableSettings['summary']['color']]}}>
      <CardHeader classes={{ root: classes['collapsibleCardHeader_' + staticTableSettings['summary']['color']]}}
      subheader={
      <div style={{display:'block', overflowX:'hidden', whiteSpace:'nowrap', textAlign:'center'}}
          id='card-table-summary-outer-div'>
          <CollapsedTableSummary borderColor={props.borderColor} backgroundColor={props.backgroundColor} tableName={props.tableName}
          />
      </div>
      }>
      </CardHeader>
    </Card>
  )
}

const ExpandedTableSummaryCard = (props) => {
  const classes = {...useStyles(), ...useStylesTypography()};
  const staticTableSettings = allStaticTableSettings[props.tableName];
  const [in_, setIn] = useState(false);

  return (
    <Card classes={{root: classes['collapsibleCard_' + staticTableSettings['summary']['color']]}}>
      <CardHeader classes={{ root: classes['collapsibleCardHeader_' + staticTableSettings['summary']['color']]}}     
      subheader={
      <div style={{display: 'flex', gap: '10px', overflowX:'hidden', whiteSpace:'nowrap'}}      
        id='card-table-summary-outer-div'>
          <ExpandedTableSummary {...props} in_={in_} setIn={setIn}/>
      </div>
      }>

      </CardHeader>
        <Collapse in={in_}>
            {in_ && 
            <CardContent style={{padding:'0px', paddingTop: '0px', paddingBottom:'0px'}}>
            <Divider />
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', paddingTop:'0px'}}>
                <TableSummaryListItems tableName={props.tableName} />
                  
              </List>
          </CardContent>
          }
        </Collapse>
      </Card>
  )
}

const ExpandedTableSummary = (props) => {
  const classes = {...useStyles(), ...useStylesTypography()};
  const staticTableSettings = allStaticTableSettings[props.tableName];
  const tableIsEmpty = useSelector(selectTableIsEmpty(props.tableName));
  

  const getButtonStyle = () => {
    if (props.in_) {
      return {transform:'rotate(180deg)', position:'absolute', top:'2px', right:'2px'}
    }
    return {position:'absolute', top:'2px', right:'2px'}
  }

  return (<>
    <span>
    <MyBadge innerComponent={<MyGradientIcon 
      icon={getIcon(staticTableSettings.summary['headerIconName'])} 
      color={staticTableSettings.summary['color']} 
      innerBoxWidth='2rem' innerBoxHeight='2rem'/>}  
    />
    </span>
    <div  id='table-summary-outer-div-uncollapsed'>
        <div id='table-summary-inner-div-uncollapsed'>
          <Typography style={{marginLeft:'2px'}} id='summary-card-title'>
            {staticTableSettings.summary['summaryTitle']}
          </Typography>
        <div id='badge-div' style={{'whiteSpace': 'break-spaces'}}>
          {staticTableSettings['allowAddRow'] &&
            <NewFormEntryButton useBadge={true} dataTable={props.tableName} badgeProps={{color:'gray', badgeContent:'Add'}}/>
          }
          <OpenTableDialogButton useBadge={true} dataTable={props.tableName}
            badgeProps={{color:'gray', badgeContent:'Open Table', style:{'paddingLeft': '10px'}}}
            />
          <ColumnMappingButton tableName={props.tableName} useBadge={true} 
            badgeProps={{color:'gray', badgeContent:'Upload', style:{'paddingLeft': '0px'}}}
          />
        </div>
        </div>
        {!tableIsEmpty && <Box >
        <IconButton
            onClick={() => {props.setIn(!props.in_)}}
            variant="contained"
            size="small"
            classes={{
            root: classes.buttonRoot,
            }}
            style={getButtonStyle()}
        >
            <ExpandCircleDownIcon />
        </IconButton>
        </Box>}
    </div>
    </>
    )
}


export default function CardTableSummary(props) {
  const staticTableSettings = allStaticTableSettings[props.tableName];
  
  const theme = useTheme();

  const popupBorderColor = theme.palette[staticTableSettings['summary']['color']] &&
                           theme.palette[staticTableSettings['summary']['color']].main || 'black'
  const popupBackgroundColor = theme.palette[staticTableSettings['summary']['color']] && 
                               theme.palette[staticTableSettings['summary']['color']].light || 'white'
  
  return (
    <>
    <Box style={{padding:'0px', margin:'0px'}}>
    {props.collapsed && <CollapsedTableSummaryCard {...props} borderColor={popupBorderColor} backgroundColor={popupBackgroundColor}/>}
    </Box>
    <Box style={{padding:'0px', margin:'0px'}}>
    {!props.collapsed && <ExpandedTableSummaryCard {...props}/>}
    </Box>
    </>
  )
}
