import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

// core components
import componentStyles from "assets/theme/components/cards/index/card-info-badges.js";
import badgeStyles from "assets/theme/components/badge.js";
import boxShadows from "assets/theme/box-shadow.js";

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);

function MyGradientIcon(props) {
  const classes = { ...useStyles(), ...useStylesBadge() };
  const theme = useTheme();

  const innerBoxWidth = props.innerBoxWidth || '1.5rem'
  const innerBoxHeight = props.innerBoxHeight || '1.5rem'

  const getIcon = () => {
    var icon = <></>;
    if (props.icon && typeof props.icon === "string") {
      icon = <Box onClick={props.onClick} component="i" fontSize="1.25rem" className={props.icon} margin='-2px' id='my-gradient-icon-string-box'/>
    } 
    else if (props.icon && typeof props.icon === "object") {
      icon =  <Box
        // onClick={props.onClick}
        width={innerBoxWidth}
        height={innerBoxHeight}
        margin='-2px'
        id='my-gradient-icon-object-box'
        >{props.icon}{props.iconStyleComponent}</Box>
    }
    return icon
  }

  const icon = getIcon();

  return (
    <>
        <Box
        color={theme.palette.white.main}
        padding="12px"
        textAlign="center"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="50%"
        width={props.height || "3rem"}
        height={props.height || "3rem"}
        // marginBottom="1.5rem"
        paddingTop='1rem'
        paddingBottom="1rem"
        boxShadow={boxShadows.boxShadow}
        className={clsx(classes.iconWrapper, classes[props.color + "BgGradient"])}
        onClick={props.onClick}
        >
        {icon}

        </Box>
    </>
  );
}

MyGradientIcon.defaultProps = {
  color: "red",
  badges: [],
};

MyGradientIcon.propTypes = {
  icon: PropTypes.oneOfType([
    // i.e. an icon name from Nucleo Icons - e.g. ni ni-atom
    // // or an icon name from Font Awesome - e.g. fa fa-heart
    PropTypes.string,
    // i.e. a component from @material-ui/icons
    PropTypes.object,
  ]),
  color: PropTypes.oneOf(["red", "green", "blue", "orange", "primary", 'error', 'warning', 'success', 'info']),

};

export default MyGradientIcon;
