const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']

//json file should have locations and costss
async function get_scenario_summaries_by_user(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ user_id: user_id })
    };
    var response
    try {
        response = await fetch(BASE_ROUTE + '/scenario_summaries_by_user', requestOptions)
        if (!response.ok) {
            throw 'Invalid response received from get_run_summaries_by_user'
        }
        var retJson = await response.json()        
        return retJson
    } catch (err) {
        throw err
    }
    

}

export default get_scenario_summaries_by_user