import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import MyCheckbox from 'views/admin/Components/MyComponents/MyCheckbox';
import { useTheme } from "@material-ui/core/styles";
import {
    updateTimeSettings,
    selectTimeSetting
} from 'store/reducers/inputReducer';

const allStaticTableSettings = require('store/data/table_settings_static.json')

const TableUseTimeWindowsCheck = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const tableTimeSettings = allStaticTableSettings[props.tableName]['timeWindows'];
    const timeUseTimeWindows = useSelector(selectTimeSetting("useTimeWindows", props.tableName));

    const handleUseTimeWindowsChange = () => {
        dispatch(updateTimeSettings({settingNames:['useTimeWindows'], tableName:props.tableName, settingValues:[!timeUseTimeWindows]}));
      }


    return    <>
    { (!(timeUseTimeWindows === null || timeUseTimeWindows === undefined)) && <Stack direction="row" spacing={2} style={{padding:'5px'}}>
      <FormControlLabel style={{marginBottom:'-10px', marginTop:'-10px'}} 
        control={<MyCheckbox
          checkboxProps={{
          checked:timeUseTimeWindows,
          onChange:handleUseTimeWindowsChange,
          style:{color:theme.palette.primary.main}
          }}/>}
      label={tableTimeSettings && tableTimeSettings['description']}/>
    </Stack>}
  </>
}

export default TableUseTimeWindowsCheck;