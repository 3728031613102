import {useTheme} from "@material-ui/core/styles";
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from "react-redux";
import { 
    selectColumnIsViewable,
    updateTableSetting
} from "store/reducers/inputReducer";

const staticTableSettings = require('store/data/table_settings_static.json')

const ColumnChoiceCheckbox = (props) => {
    const isViewable = useSelector(selectColumnIsViewable(props.tableName, props.columnName));
    const columnHeader = staticTableSettings[props.tableName]['columnHeader'][props.columnName]
    const columnToggleItems = staticTableSettings[props.tableName]['isToggleable'][props.columnName]
    const theme = useTheme();
    const dispatch = useDispatch();

    const handleCheckboxChange = (event) => {
        const correspondingColumn = columnToggleItems['correspondingColumn']
        const columnNames = [props.columnName, correspondingColumn]
        const newValues = [event.target.checked, !event.target.checked]
        dispatch(updateTableSetting({tableName: props.tableName, settingName: 'isViewable', newValue: newValues, columnName: columnNames}))
    }

    return (
        <>
        <Stack direction={'row'} spacing={0}>

                    <div style={{display:'inline-flex', flexDirection:'row', paddingTop:'5px', paddingBottom:'5px'}}>
                        <Checkbox checked={isViewable} style={{color:theme.palette.primary.main}} onChange={handleCheckboxChange} />
                        <div style={{minWidth:'100px', maxWidth:'100px', fontWeight:500, paddingLeft:'10px', paddingRight:'10px', display:'flex', alignItems:'top'}}>{columnHeader || props.columnName}</div>
                        <div style={{fontStyle: 'italic', display:'flex', alignItems:'top'}}>{columnToggleItems['wizardDescriptionShort']}</div>
                    </div>

        </Stack>     
        </>
    )
}

export default ColumnChoiceCheckbox;