import moment from 'moment';

function getMax(arr, colName) {
    if (!arr) {return {colName: 1}}
    if (arr.length == 0) {return {colName: 1}}
    var max;
    for (var i=0 ; i<arr.length ; i++) {
      if (max == null || parseInt(arr[i][colName]) > parseInt(max[colName]))
          max = arr[i];
    }
    return max;
  }

const modifyInput = (inputValue, dataType, data, columnName) => {
    /**
     * Modifies the input value based on the data type
     * @param {string} inputValue - The input value
     * @param {string} dataType - The data type
     * @param {array} data - The data, only required if the data type is 'id'
     * @param {string} columnName - The column name, only required if the data type is 'id'
     */
    var newValue = inputValue;
    if (dataType == 'time') {
        const momentTime = moment(inputValue, 'hh:mm a')
        if (momentTime.isValid()) {
            newValue = momentTime.format('hh:mm a');
        } else {
            newValue = null;
        }
      }
      if (dataType == 'id') {
        if (!data) {
            throw new Error('Data is not defined')
        }
        if (!columnName) {
            throw new Error('columnName is not defined')
        }
        if (data.length == 0) {
          newValue = 1;
        } else {
          const maxRow = getMax(data, columnName)
          newValue = maxRow[columnName] + 1;
        }
      }
      if (dataType == 'multi-dropdown') {
        if (typeof inputValue === 'string' || inputValue instanceof String) {
          var newValue = inputValue.split(',')
          newValue = newValue.map(element => element.trim())
        }
      }
      if (dataType == 'string') { 
        inputValue = newValue.toString();
      }
      return newValue
}

export default modifyInput;