import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector, useDispatch } from 'react-redux';


import {
  selectGeneralSettings,
  updateFilter,
  selectFilterState
} from 'store/reducers/inputReducer'; 


const LocationLayerToggleCheckbox  = () => {

    const filterState = useSelector(selectFilterState('locationLayerVisible'))
    const mapMode = useSelector(selectGeneralSettings)['mapping']['mapMode'];
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(updateFilter({newValue:!filterState, filterName:'locationLayerVisible'}));
    }

    return (

        <FormGroup style={{display:'inline-block', margin:'0px'}}>
          
          {mapMode == 'trips' && 
            <FormControlLabel label={'Show Locations'} style={{display:'inlineBlock', marginBottom:'0px'}}
            control={<Checkbox checked={true} disabled={true} onClick={handleClick} />} 
            />
          }
          {mapMode !== 'trips' && 
            <FormControlLabel label={'Show Locations'} style={{display:'inlineBlock', marginBottom:'0px'}}
            control={<Checkbox checked={filterState} onClick={handleClick} />} 
            />}
        </FormGroup>
  
    );
}

export default LocationLayerToggleCheckbox;