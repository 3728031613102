import React from "react";
import Container from '@mui/material/Container';
import DataEntryForm from "views/admin/Forms/DataEntryForm.js"

class MapEditorOverlay extends React.Component {

    constructor(props) {
        super(props);
        var initialOpenState = false;
        if (props.open) {
            initialOpenState = true;
        }
        this.state = {
            open: initialOpenState,
            rowId: null,
            tableName: props.tableName
        }
        this.setOpen = this.setOpen.bind(this);
        this.title = this.getTitle.bind(this);

    }

    setOpen({open}) {
        this.setState({open:open});
    }

    setRowId(uuid) {
        this.setState({rowId: uuid});
    }

    setTableName(tableName) {
        this.state.tableName = tableName;
    }

    getTitle() {
        if (this.state.tableName == 'locations') {
            return 'Edit location'
        } else {
            return 'Edit trip'
        }
    }

    render() {
        return <>
            {this.state.open && 
            <Container maxWidth={'xs'} style={{paddingLeft:'0px', paddingRight:'0px', paddingTop:'5px', margin:'0px', 
                zIndex:'9', position:'absolute'}}>
                
                <div style={{position:'relative', top:'0px'}}>
                <DataEntryForm 
                    title={this.getTitle()} 
                    key={this.state.rowId} 
                    tableName={this.state.tableName} 
                    uuid={this.state.rowId}
                    onClose={() => this.setState({open:false})}
                    onSubmit={() => this.setState({open:false})}
                    onDelete={() => {this.setOpen({open:false})}}
                    setOpen={this.setOpen}
                    backgroundColor='white'
                    skinny={true}
                    containerStyle={{maxWidth:'300px'}}
                    />
                </div>
                    
            </Container>
            }

        </>
    }

}



export default MapEditorOverlay