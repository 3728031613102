import React, {useState, useEffect} from "react";
import IconButton from '@mui/material/IconButton';
import {getAllIcons, getIdxFromName} from 'views/admin/Forms/icon_selector/icon_mapping.js';
import { useTheme } from "@material-ui/core/styles";

var diff = 0 //pixel offset for row height
var padding = 10 

const IconItem  = React.memo(function WrappedComponent (props) {
    const theme = useTheme();
    const [selected, setSelected] = useState(props.selectedIdx === props.idx);

    useEffect(() => {
        if (props.selectedIdx == props.idx) {
            setSelected(true);
        } else {
            setSelected(false);
        }
    }, [props.selectedIdx])

    const getStyle = () => {
        if (selected) {
            const style = {backgroundColor:theme.palette.primary.main,
                "&:hover": {
                    backgroundColor:theme.palette.primary.dark
                }}
            return style
        }
        else {
            return {}
        }
    }

    return <>
    {/* <IconButton aria-label="Example" onClick={() => props.onClick(props.idx)} classes={selected ? {root: classes.button} : {root: classes.buttonSelected}}> */}
    <IconButton aria-label="Example" onClick={(event) => {event.stopPropagation(); props.onClick(event, props.idx)}} style={{...getStyle(), height:props.height, width:props.width, padding:padding + 'px'}}>
    {props.icon}
    </IconButton>
    </>

})




class IconSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIdx: (!props.iconName) ? 0 : getIdxFromName(props.iconName)
        }
        this.allIcons = getAllIcons();
        this.onIconClick = this.onIconClick.bind(this)
        this.getSelectedIdx = this.getSelectedIdx.bind(this);
        this.getSelectedIcon = this.getSelectedIcon.bind(this);
        this.getSelectedIconName = this.getSelectedIconName.bind(this);
        this.height = (props.rowHeight - diff) + 'px'
        this.width = (props.rowHeight - diff) + 'px'
        this.style = {flexWrap:'wrap', height:'100%'};
        if (props.padding) {
            this.style = {...this.style, padding:props.padding}
        }
    }

    getSelectedIcon() {
        return this.allIcons.icons[this.state.selectedIdx]
    }

    getSelectedIconName() {
        return this.allIcons.iconNames[this.state.selectedIdx]
    }

    getSelectedIdx() {
        return this.allIcons[this.state.selectedIdx];
    }

    onIconClick(event, idx) {
        this.setState({selectedIdx: idx});
        event.stopPropagation();
        // const event = {target:{value:this.allIcons.iconNames[idx]}}
        this.props.handleInputChange(this.allIcons.iconNames[idx]);
    }

    render() {
        return (<>
        <div id='icon-selector-div' style={this.style}>
        {this.allIcons.icons.map((elem, idx) => 
            <IconItem icon={elem} idx={idx} selectedIdx={this.state.selectedIdx} onClick={this.onIconClick} height={this.height} width={this.width}></IconItem>)}
        </div>
        </>
        
        )
    }
}

export default React.memo(IconSelector);