import React, { useCallback, Fragment, useEffect } from 'react';
import WarningDialogActionRequired from 'views/admin/Notifications/WarningDialogActionRequired.js'
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import hexToRgb from "assets/theme/hex-to-rgb.js";
import { useSnackbar } from 'notistack';
import {
    selectNotifications,
    clearNotifications
  } from 'store/reducers/inputReducer';
import { useSelector, useDispatch } from 'react-redux';


const NotificationManager = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const theme = useTheme();
    const notifications = useSelector(selectNotifications());

    const handleClickWithAction = useCallback((message, variant) => {
        enqueueSnackbar(message, {
            variant: variant,
            action: (key) => (
                <Fragment>
                    <Box
                        component={IconButton}
                        padding="10px"
                        onClick={() => {closeSnackbar(key); dispatch(clearNotifications());}}
                    >
                        <Box
                        component="span"
                        color={
                            "rgba(" + hexToRgb(theme.palette.white.main) + ",.9)"
                        }
                        >
                        ×
                        </Box>
                    </Box>
                </Fragment>
            )
        });
    }, [enqueueSnackbar, closeSnackbar]);

    useEffect(() => {
        if (notifications && notifications.length > 0 && !notifications[0].action) {
            notifications.map((notification, elem) => {
                handleClickWithAction(notification.message, notification.type);
            })
            dispatch(clearNotifications());
        }
        
    }, [notifications]);

    return (
    <>{<WarningDialogActionRequired />}</>
        
    );
};

export default NotificationManager;
