
/**
 * https://stackoverflow.com/a/3177838/11342048
 * This function gets a nicely readable time since.
 * @param {*} date 
 */
function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
    var plural = true;
  
    var retValue;
    if (interval > 1) {
      retValue = Math.floor(interval) + " year"
      if (Math.floor(interval) == 1) {
        plural = false
      }
    }
    else {
      interval = seconds / 2592000;
      if (interval > 1) {
        retValue = Math.floor(interval) + " month"
        if (Math.floor(interval) == 1) {
          plural = false
        }
      }
      else {
        interval = seconds / 86400;
        if (interval > 1) {
          retValue = Math.floor(interval) + " day"
          if (Math.floor(interval) == 1) {
            plural = false
          }
        }
        else {
          interval = seconds / 3600;
          if (interval > 1) {
            retValue = Math.floor(interval) + " hour"
            if (Math.floor(interval) == 1) {
              plural = false
            }
          }
          else {
            interval = seconds / 60;
            if (interval > 1) {
              retValue = Math.floor(interval) + " minute"
              if (Math.floor(interval) == 1) {
                plural = false
              }
            }
            else {
              retValue = Math.floor(seconds) + " second"
              if (Math.floor(interval) == 1) {
                plural = false
              }
            }
          }
        }
      }
    }
    if (plural) {
      retValue += 's';
    }
    return retValue
  }

export default timeSince