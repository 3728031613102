import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import CardContent from "@material-ui/core/CardContent";
import Grid from '@mui/material/Grid';

import componentStyles from "assets/theme/components/cards/dashboard/card-map-options.js";


import CostSettings from "views/admin/Forms/Cost/CostSettings.jsx";
import { selectCostSetting } from 'store/reducers/inputReducer';

const useStyles = makeStyles(componentStyles);

export default function ModelSettingsCard() {
    const classes = useStyles();
    const costMetric = useSelector(selectCostSetting({settingName:'costMetric'})); 

    return (<>
    <Grid container spacing={1}>
        <Grid item xs={12} lg={12} style={{maxWidth:'600px'}}>
            {costMetric && 
                <CardContent classes={{ root: classes.collapsibleCardContent }} >
                    <CostSettings costMetric={costMetric}/>
                </CardContent>
            }
        </Grid>
    </Grid>
    </>
    );
}