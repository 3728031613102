import { useDispatch } from 'react-redux';
import { useTheme } from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';


import {
    fetchTravelCosts
} from 'store/reducers/inputReducer'


function AutoPopulateCostMatrixButton(props) {
    const dispatch = useDispatch();
    const theme = useTheme();

    const travelCostsClickAction = (event) => {
        event.stopPropagation();
        dispatch(fetchTravelCosts())
      }

    return (<>
        <Tooltip title={'Populate cost matrix'}>
        <IconButton id='autopopulate-icon-button' onClick={(event) => travelCostsClickAction(event)}>
            {true &&
            <TableChartOutlinedIcon style={{color:theme.palette.info.main}} />
            }
            {false &&
            <TableChartOutlinedIcon style={{color:theme.palette.info.main}} />}
        </IconButton>
    </Tooltip>
    </>)
}


export default AutoPopulateCostMatrixButton