import React from 'react'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { connect } from 'react-redux'
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@mui/material/IconButton';


var UndoRedo = ({ canUndo, canRedo, onUndo, onRedo }) => (

    <>
        <button onClick={onUndo} disabled={!canUndo}>
          Undo
        </button>
        <button onClick={onRedo} disabled={!canRedo}>
          Redo
        </button>
    </>
)

export var ConnectedUndoIcon = ({canUndo, canRedo, onUndo, onRedo, buttonStyleActive, buttonStyleInactive}) => (
    <>
        <Tooltip title={canUndo ? 'Undo last' : 'Nothing in the undo queue'}>
            <IconButton aria-label="info" onClick={onUndo} sx={canUndo ? buttonStyleActive : buttonStyleInactive}>
                <UndoIcon />
            </IconButton>
        </Tooltip>
    </>
)

export var ConnectedRedoIcon = ({canUndo, canRedo, onUndo, onRedo, buttonStyleActive, buttonStyleInactive}) => (
    <>
        <Tooltip title={canRedo ? 'Redo' : 'Nothing in the redo queue'}>
            <IconButton aria-label="info" onClick={onRedo} sx={canRedo ? buttonStyleActive : buttonStyleInactive}>
                <RedoIcon />
            </IconButton>
        </Tooltip>
    </>
)


/* ... */
const mapStateToProps = state => {
  return {
    canUndo: state.inputData.past.length > 0,
    canRedo: state.inputData.future.length > 0
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUndo: () => dispatch(UndoActionCreators.undo()),
    onRedo: () => dispatch(UndoActionCreators.redo())
  }
}

UndoRedo = connect(mapStateToProps, mapDispatchToProps)(UndoRedo)
ConnectedUndoIcon = connect(mapStateToProps, mapDispatchToProps)(ConnectedUndoIcon)
ConnectedRedoIcon = connect(mapStateToProps, mapDispatchToProps)(ConnectedRedoIcon)

export default UndoRedo;
