/*
This file contains utility functions for the ag-grid components.
*/

import ColourCellRenderer from 'views/admin/Tables/Ag-grid/ColorCellRenderer.jsx';
import colors, {reverseColors} from 'views/admin/Tables/Ag-grid/colors.js';

import {
    AgGridMultiSelectEditor
  } from "./AgGridMultiSelectEditor";


export const getAgGridType = (dataType, columnName, tableSettings, dropdownOptions, isEditable = true) => {
    /**
     * This function maps the data type of a column in a table to the corresponding type in ag-Grid.
     * @param {string} dataType - The data type of the column in the table.
     * @param {string} columnName - The name of the column in the table (for dropdowns)
     * @param {Object} tableSettings - The settings for the table
     * @param {Object} dropdownOptions - The dropdown options for the column (for dropdowns)
     */

    if (!dataType) { // safest to return text if no data type is provided
      return {'type': 'text'}
    }
    if (dataType === 'date') {
        return {'type': 'date'};
      }

      if (dataType === 'time') {
        return {
          valueFormatter: function (params) {
            if (params.value) {

              const parsedDate = Date.parse('1/1/2023 ' + params.value);
              if (parsedDate) {
                return parsedDate.toString('h:mm tt');
              } else { 
                return ''
              }
            } return ''
          }
        }
      }

      if (dataType === 'boolean') {
        return {
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          editable: isEditable,
          cellClass: 'cellCenter' 
        }
      }

      if (dataType.substring(0, 5) === 'float') {
        var precision = null;
        if (dataType.length > 5) {
          precision = parseInt(dataType.substring(5));
        }
        const cellEditorParams = {
          'precision': precision,
          'showStepperButtons': precision ? true : false
        };
        if (tableSettings['validation'] && tableSettings['validation'][columnName] && tableSettings['validation'][columnName].includes('non-negative')) {
          cellEditorParams['min'] = 0;
        }
        if (tableSettings['validation'] && tableSettings['validation'][columnName] && tableSettings['validation'][columnName] === 'latitude') {
          cellEditorParams['min'] = -90;
          cellEditorParams['max'] = 90;
        }
        if (tableSettings['validation'] && tableSettings['validation'][columnName] && tableSettings['validation'][columnName] === 'longitude') {
          cellEditorParams['min'] = -180;
          cellEditorParams['max'] = 180;
        }
        return {
          'type': 'number',
          'cellEditor': 'agNumberCellEditor',
          'cellEditorParams': {
            ...cellEditorParams
          }
        }
      }

    if (dataType === 'integer') {
      const cellEditorParams = {
          'precision': 0,
          'step': 1,
          'showStepperButtons': true
        };
        if (tableSettings['validation'] && tableSettings['validation'][columnName] && tableSettings['validation'][columnName].includes('non-negative')) {
          cellEditorParams['min'] = 0;
        }
        return {
            'type': 'number',
            'cellEditor': 'agNumberCellEditor',
            'cellEditorParams': {
                ...cellEditorParams
            }
        }
    }

    if (dataType === 'multi-dropdown') {
        return {
            cellEditor: AgGridMultiSelectEditor,
            editable: isEditable,
            cellEditorParams: {
            options: dropdownOptions[columnName],
            },
            valueFormatter: (params) => {
                if (params && params.value && params.value.length > 0) {
                    return params.value.join(", ");
                } else {
                    return "";
                }
            },
            cellRenderer: (params) => {
              if (!dropdownOptions[columnName] | (!dropdownOptions[columnName].length)) {
                return "No options available"
              }
              if (params.value) {
                  if (params.value.length <= 0) {
                      return (
                         "Select"
                      );
                  } else {
                      return <>{params.value.join(", ")}</>
                  }
              }
              else {
                return (
                  "Select"
               );
            }
        }
      }
    }

    else if (dataType === 'dropdown') {
        return {
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
                values: dropdownOptions[columnName],
                cellRenderer: 'agRichSelectCellRenderer',
                filterList: true,
                searchType: 'match',
                allowTyping: true,
                valueListMaxHeight: 220,
            }
        }
    }

    else if (dataType === 'color') {
      return {
        cellRenderer: ColourCellRenderer,
        cellRendererParams: {'reverseColors': reverseColors},
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: Object.keys(colors),
          cellRenderer: ColourCellRenderer,
          filterList: true,
          searchType: 'match',
          allowTyping: true,
          valueListMaxHeight: 220,
          cellEditorPopup: true,
        }
      }
    }

    else if (dataType === 'text') {
      return {'type': 'text'}
    }

    else if (dataType == 'html') {
      return {
        'cellRenderer': function (params) {
          return params.value ? <div dangerouslySetInnerHTML={{__html: params.value }}></div> : '';
        }
      }
    }


    else {
    }
  }
