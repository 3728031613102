// import {useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
// import CircleIcon from '@mui/icons-material/Circle';
import Tooltip from "@material-ui/core/Tooltip";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import {
    selectTripsAtPoint,
    selectGeoJsonByUuid
} from 'store/reducers/inputReducer.js';

const IconEditorButton = (props) => {

    return (<>
        <Tooltip title='View more / edit'>
            <IconButton aria-label="delete" size="small" color={props.color || 'warning'}
            style={{height:'1rem', width:'1rem', float:'right', marginLeft:'5px', top:'2px'}}
            onClick={() => props.openEditor(props.item)}
            >
                <ReadMoreIcon fontSize="inherit" />
            </IconButton>
        </Tooltip>
    </>
    )
}


/**
 * This component is displayed when a point is clicked at the trip layer is visible. It will show the location (with the option to edit),
 * plus all trips coming To and From, with the option to add new trips To and From
 * @param {*} props 
 */
const ClusterCardTripOrigin = (props) => {
    const theme = useTheme();
    const trips = useSelector(selectTripsAtPoint({uuid: props.selectedUuid}));
    const location = useSelector(selectGeoJsonByUuid({uuid:props.selectedUuid}));
    if (!location) {
        props.onDelete();
        return (<></>);
    }
    return <>
    <div style={{display:'flex', flexDirection:'column', paddingRight:'5px', paddingLeft:'5px'}}>
        <div style={{borderBottom:'1.5px solid ' + theme.palette.dark.main}}>
        <b>{location.properties['Location Name']}</b><IconEditorButton item={location} {...props}  />
        </div>
    <ul style={{ listStyleType: 'none', margin: 0, padding: 0, maxHeight:'200px', maxWidth:'200px',
    overflow:'scroll', paddingLeft:'5px', display:'inline-flex', flexDirection:'column'}}>
    {trips.map((item, idx) => {
        if (!item.properties) return (<></>)
        if (!item.properties.type == 'trip') {}
        else {
            const title = item.properties.name;
            return (
                    <li style={{borderTop: idx !== 0 ? '1px solid ' + theme.palette.primary.main : null, minWidth:'100px', display:'inline-flex'}}>
                        <Tooltip title={title} >
                            <div style={{width:'calc(100% - 20px)', maxHeight:'1.25rem', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                                    {title}
                            </div>
                       </Tooltip>
                       <IconEditorButton item={item} {...props}/>
                    </li>
                )
            }
    })}
        <li style={{borderTop: '1px solid ' + theme.palette.primary.main, minWidth:'100px', display:'inline-flex'}}>

        </li>
    </ul>
    </div>
    </>
}

export default ClusterCardTripOrigin