import React, {useState, useRef, useEffect} from "react";
// @material-ui/core components
import { useTheme } from "@material-ui/core/styles";  
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// core components
import Card from "@material-ui/core/Card";
import CardMap from "components/Cards/Dashboard/CardMap.js";
import CardAddData from "components/Cards/Dashboard/CardAddData.js";
import CardDataTables from "components/Cards/Dashboard/CardDataTables.js";
// import CardMapOptions from 'components/Cards/Dashboard/CardMapOptions.js';
// import CardMapOptionsCollapsedHorizontal from 'components/Cards/Dashboard/CardMapOptionsCollapsedHorizontal.js';
import CardSavedResults from 'components/Cards/Dashboard/CardSavedResults.js'; 
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Tooltip } from "@mui/material";
import {  Drawer } from "antd";

import OptimizationCompletedDialog from 'views/admin/Notifications/OptimizationCompletedDialog.js'
import NotificationManager from 'views/admin/Notifications/NotificationManager.jsx'
import 'assets/theme/views/admin/dashboard.css'
import DataEntryTableAccordion from "views/admin/Tables/DataEntryTableAccordion.js";
import {
  updateActiveModelType,
  getInExampleMode,
  selectModelSettings,
  setActiveMode,
  fetchSavedScenario
} from 'store/reducers/inputReducer';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';


function AddDataCardRenderer (props)  {

  const collapseAction = () => {
    props.collapseAction(!props.collapsed);
  }

  return (<>
    <div style={{minWidth:props.minWidth + 'px', height:'100%'}} id='add-data-card-renderer-outer-div'>
    <CardAddData collapseAction={() => collapseAction()}
      collapsed={props.collapsed}
      cardStyle={{...props.cardStyle}}></CardAddData>
    </div>
  </>
  )
}

// function MapOptionsCardRendered (props)  {

//   const collapseAction = () => {
//     props.collapseAction(!props.collapsed);
//   }

//   return (<>
//     <div style={{minWidth:props.minWidth + 'px', height:'100%'}}>
//       {(!props.collapsed) && 
//       <CardMapOptions collapseAction={() => collapseAction()} cardStyle={props.cardStyle}></CardMapOptions>
//       }
//       {props.collapsed && 
//       <CardMapOptionsCollapsedHorizontal collapseAction={() => collapseAction()} cardStyle={props.cardStyle}></CardMapOptionsCollapsedHorizontal>
//     }
//     </div>
//   </>
//   )
// }


function Dashboard(props) {
  const theme = useTheme();
  const addDataCollapsedWidth = 95;
  const addDataExpandedWidth = 295;
  const mapOptionsCollapsedWidth = 50;
  // const mapOptionsExpandedWidth = 225;
  const savedResultsExpandedWidth = 300;
  const problemConfigs = useSelector(selectModelSettings);
  const inExampleMode = useSelector(getInExampleMode); 
  const [addDataCardWidth, setAddDataCardWidth] = useState(inExampleMode ? 0 : addDataExpandedWidth);
  const [mapOptionsWidth, setMapOptionsWidth] = useState(mapOptionsCollapsedWidth);

  const [addDataCollapsed, setAddDataCollapsed] = useState(false);
  // const [mapOptionsCollapsed, setMapOptionsCollapsed] = useState(true);
  const [dataTableComponents, setDataTableComponents] = useState({})
  const widthRef = useRef(null);
  const dispatch = useDispatch();

  const borderedCardStyle = {padding:'5px', margin:'5px', border:'1px solid ' + theme.palette.primary.main} 
  const minMapHeight = 510;

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const showDrawer = () => {
    setDrawerOpen(true);
  };

  const onDrawerClose = () => {
    setDrawerOpen(false);
  };

  const containerStyle = {
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'center',
    background: 'token.colorFillAlter',
    border: `1px solid ${theme.palette}`,
    borderRadius: '.375rem',
  };


  const getDataTableComponents = () => {
    var dataTableComponents = {};
    problemConfigs[props.modelType]['input']['order'].map((tableName, idx) => {
      var visible = problemConfigs[props.modelType]['input']['visible'][tableName]
      dataTableComponents[tableName] = <div hidden={!visible} style={{paddingBottom:'0px'}}><DataEntryTableAccordion tableName={tableName} /></div>
    })
    
    setDataTableComponents(dataTableComponents);
  }


  const addDataCollapseAction = (newCollapseState) => {
    setAddDataCardWidth(newCollapseState ? addDataCollapsedWidth : addDataExpandedWidth)
    setAddDataCollapsed(oldState => !oldState);

  }

  // const mapOptionsCollapseAction = (newCollapseState) => {
  //   setMapOptionsWidth(newCollapseState ? mapOptionsCollapsedWidth : mapOptionsExpandedWidth)
  //   setMapOptionsCollapsed(newCollapseState);
  // }


  useEffect( () => {
    dispatch(updateActiveModelType({modelType:props.modelType}));
    if (props.isExample) {
      dispatch(setActiveMode({activeMode: 'example'}));
      if (props.scenarioName) {
        dispatch(fetchSavedScenario({scenarioName:props.scenarioName}));
      }
    } else {
      dispatch(setActiveMode({activeMode: 'model'})); 
    }
  }, []);

  useEffect( () => {
    getDataTableComponents();
  }, [props.modelType])

  useEffect(() => {
    if (inExampleMode) {
      setAddDataCardWidth(0)
    }
    else {
      if (addDataCollapsed) {setAddDataCardWidth(addDataCollapsedWidth)}
      else {setAddDataCardWidth(addDataExpandedWidth)}

    }
  }, [inExampleMode])

  return (
    <>
      <Container
        id={'dashboard-container'}
        maxWidth={false}
        component={Box}
        marginBottom="0px"
        style={{
                marginBottom:'0px',
                padding:'10px',
                backgroundColor: inExampleMode ? theme.palette.dashboard.background.exampleMode : theme.palette.dashboard.background.main
              }}
        
      >
        <Card style={{paddingRight:'0px', paddingLeft:'0px', marginBottom:'0px'}}>
          <Card 
            style={{ padding:'2px', backgroundColor:theme.palette.card.background.main,
                  borderRadius: ".375rem", border:'2px solid', 
                  boxShadow:'none', height:'550px', marginBottom:'10px',
                  borderColor:theme.palette.gray[300]}}
            >
              <Grid container style={{marginBottom:'0px', height:'100%', padding:'0px', margin:'0px'}} ref={widthRef}>
                <Grid item xs={12} style={{marginBottom:'0px', padding:'0px', height:'100%'}}>
                  
                  <div id='main-card-row' style={{display: 'flex', 'flexDirection': 'row', height:'100%'}}>
                  {false && !inExampleMode && 
                  <div id='add-data-card' style={{width:addDataCardWidth + 'px', height:'100%', flex:0}}>
                    <AddDataCardRenderer cardStyle={borderedCardStyle} 
                      collapseAction={addDataCollapseAction}
                      collapsed={addDataCollapsed}
                      minWidth={addDataCardWidth} />
                  </div>
                  }
                  <div id='card-map' style={{flex: 1}}>
                    <CardMap minHeight={minMapHeight + 10} cardStyle={borderedCardStyle} heightChangeKey={mapOptionsWidth + addDataCardWidth}/>
                  </div>
                  {/* <div id='map-options-card' style={{width:mapOptionsWidth + 'px', height:'100%', flex:0}}>
                    <MapOptionsCardRendered collapseAction={mapOptionsCollapseAction} collapsed={mapOptionsCollapsed} minWidth={mapOptionsWidth} cardStyle={borderedCardStyle}/>
                  </div> */}
                </div>

              </Grid>
            </Grid>
          </Card>
          <Grid container style={{...containerStyle, marginLeft:'0px', marginRight:'0px', backgroundColor:theme.palette.card.background.main,borderRadius: ".375rem",}}>
            <Grid item xs={12} style={{border:'2px solid', borderColor:theme.palette.gray[300], borderRadius: ".375rem",
                                      marginBottom:'0px', paddingLeft:'0px', paddingRight:'0px'}}>
              <div id='second-card-row' style={{display: 'flex', 'flexDirection': 'row', height:'100%'}}>
                <div id='card-data-tables' style={{width:'100%'}}>
                  <CardDataTables 
                    cardStyle={{...borderedCardStyle, padding:'10px'}} 
                    dataTableComponents={dataTableComponents}
                    minHeight={minMapHeight}
                    openSavedScenarioDrawer={showDrawer}
                    />
                </div>
                {
                        <Drawer
                        title={
                        <div style={{display:'inline-flex', alignItems:'start'}}>
                          <div>Saved Items</div>
                          <Tooltip title="Close saved items panel" arrow>
                            <IconButton
                              onClick={() => {setDrawerOpen(false)}}
                              variant="contained"
                              size="small"
                              style={{position:'absolute', right:'5px', top:'5px', color:theme.palette.warning.main}}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </Tooltip>
                        </div>}
                        placement="right"
                        closable={false}
                        onClose={onDrawerClose}
                        open={drawerOpen}
                        getContainer={false}
                        width={savedResultsExpandedWidth}
                      >
                           <CardSavedResults />
                           
                      </Drawer>

                }
              </div>
            </Grid>
          </Grid>
        </Card>
        <NotificationManager />
        <OptimizationCompletedDialog />
      </Container>

    </>
  );
}

export default Dashboard;
