import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

import Notifications from "@material-ui/icons/Notifications";

import componentStyles from "assets/theme/views/admin/notifications.js";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesSnackbar from "assets/theme/components/snackbar.js";
import componentStylesDialog from "assets/theme/components/dialog.js";

import {
  selectNotifications,
  clearNotifications,
  addRow,
  decipherTravelCostsAfterWarning,
  updateActiveModelType,
  clearTableData,
  clearAllTableData,
  setMapMode
} from 'store/reducers/inputReducer';

import { useSelector, useDispatch } from 'react-redux';

import { handleExitFullScreen } from 'views/admin/Notifications/utils.js';
const useStyles = makeStyles(componentStyles);
const useStylesDialog = makeStyles(componentStylesDialog);
const useStylesSnackbar = makeStyles(componentStylesSnackbar);
const useStylesButtons = makeStyles(componentStylesButtons);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


/**
 * This notification will get triggered when a row is added and there are results shown on the table.
 * It is a warning that the prior results will be cleared out.
 */

const WarningDialogActionRequired = () => {
  const classes = {
    ...useStyles(),
    ...useStylesButtons(),
    ...useStylesSnackbar(),
    ...useStylesDialog(),
  };


  const dispatch = useDispatch();
  const warningNotifications = useSelector(selectNotifications('warning'));
  
  const handleCloseNotification = () => {
    
    if (warningNotifications && warningNotifications.length > 0 && warningNotifications[0].action === 'addRow') {
      dispatch(addRow({...warningNotifications[0].payload, clearResults: true, resetMapLayers:true}));
    }
    else if (warningNotifications && warningNotifications.length > 0 && warningNotifications[0].action === 'decipherUploadedTravelCostPayload') {
      dispatch(decipherTravelCostsAfterWarning({...warningNotifications[0].payload}));
    }
    else if (warningNotifications && warningNotifications.length > 0 && warningNotifications[0].action === 'updateActiveModelType') {
      dispatch(updateActiveModelType({...warningNotifications[0].payload, clearResults: true, resetMapLayers:true}));
    }   

    else if (warningNotifications && warningNotifications.length > 0 && warningNotifications[0].action === 'clearTableData') {
      dispatch(clearTableData({...warningNotifications[0].payload, clearResults: true, resetMapLayers:true}));
    }

    else if (warningNotifications && warningNotifications.length > 0 && warningNotifications[0].action === 'clearAllTableData') {
      dispatch(clearAllTableData({...warningNotifications[0].payload, clearResults: true, resetMapLayers:true}));
    }

    else if (warningNotifications && warningNotifications.length > 0 && warningNotifications[0].action === 'setMapMode') {
      dispatch(setMapMode({...warningNotifications[0].payload}));
    }

    dispatch(clearNotifications({type:'warning'}));
  };

  const handleCancel = () => {
    if (warningNotifications && warningNotifications.length > 0 && warningNotifications[0].action === 'updateActiveModelType') {
      dispatch(updateActiveModelType({...warningNotifications[0].cancelPayload, clearResults: false, resetMapLayers:false}));
    }
    dispatch(clearNotifications({type:'warning'}));

  };

  warningNotifications && warningNotifications.length > 0 && handleExitFullScreen();

  return (
    <>

        <Dialog
          id='warning-dialog-action-required'
          open={warningNotifications && warningNotifications.length > 0}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseNotification}
          style={{zIndex: 1400}}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          classes={{
            paper: classes.dialogNotification,
          }}
        >
          <DialogContent>
            <Box
              textAlign="center"
              paddingTop="1rem"
              paddingBottom="1rem"
            >
              <Box
                component={Notifications}
                width="3em!important"
                height="3em!important"
              ></Box>
              <Typography
                variant="h4"
                component="h4"
                className={classes.dialogHeading}
              >
                Warning!
              </Typography>
              <Typography variant="body2" component="p">
                {warningNotifications && warningNotifications.length > 0 && warningNotifications[0].message}

              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseNotification}
              color="secondary"
              variant="contained"
            >
             {warningNotifications && warningNotifications.length > 0 && warningNotifications[0].proceedText || 'Proceed'}
            </Button>
            <Button
              component={Box}
              onClick={handleCancel}
              color="secondary"
              marginLeft="auto!important"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
};

export default WarningDialogActionRequired;
