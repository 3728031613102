/**
 * This is the component for the custom pricing card. This component is used in the
 * Pricing.js page to display the enterprise pricing card.
 */

import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LanguageIcon from '@mui/icons-material/Language';
import PricingIcon from 'components/Cards/Pricing/PricingIcon.js'
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import FunctionsIcon from '@mui/icons-material/Functions';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TuneIcon from '@mui/icons-material/Tune';
import Notes from "@material-ui/icons/Notes";
import SchemaIcon from '@mui/icons-material/Schema';

// internal components
import CaptureEmailDialog from "views/admin/Components/Dialogs/CaptureEmailDialog";

// core components
import componentStyles from "assets/theme/components/cards/pricing/card-pricing-gradient.js";
import buttonsStyles from "assets/theme/components/button.js";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(buttonsStyles);

const icons = [
  <FunctionsIcon />, 
  <BatteryChargingFullIcon  fontSize='small' />,
  <LanguageIcon  fontSize='small' />,
  <Notes />,
  <SaveAltIcon fontSize='small'/>, 
  <AutoFixHighIcon  fontSize='small' />,
  <SchemaIcon  fontSize='xs' />,
  <TuneIcon  fontSize='xs' />,
  <TuneIcon  fontSize='xs' />
]

const subtitles = [
  'All models included',
  'No limits on problem size',
  'Unlimited solves on our web app (up to 10 solves per minute)',
  'Access to online documentation and forum support',
  'Save unlimited models for later use',
  'Auto population travel times',
  'Access to our REST API',
  'Custom problem formulations',
  'Custom constraints'
]

function CardPricingGradientProfessional() {
  const classes = {
    ...useStyles(),
    ...useStylesButtons(),
  };
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  }

  const handleClose = () => {
    setDialogOpen(false);
  }

  return (
    <>
      <Card className={clsx(classes.cardRoot, classes.cardRootGradientSuccess)} style={{paddingRight:'0rem'}}>
        <CardHeader
          title="Custom"
          className={classes.bgInherit}
          titleTypographyProps={{
            className: classes.titleWhite,
            component: "h4",
            variant: "h4",
          }}
          style={{paddingBottom:'0px', borderRadius: '0px 5px 0px 0px'}}
        ></CardHeader>
        <CardContent className={classes.cardContentRoot} style={{paddingLeft:'3rem', paddingTop:'0px', paddingRight:'3rem'}}>
          <Box className={classes.display1} color={theme.palette.white.main}>
            Varies
          </Box>
          <Box component="span" color={theme.palette.white.main}>
            Contact sales today
          </Box>
          <Box
            component="ul"s
            paddingLeft="0"
            className={classes.listStyleNone}
            margin="1.5rem 0"
          >
          {icons.map((icon, idx) => {
            return <PricingIcon style={{padding:'0px'}} icon={icon} subtitle={subtitles[idx]} />
          })}
        </Box>
          <Box marginBottom="1rem" textAlign="center">
            <Button
                variant="contained"
                classes={{
                  root: classes.buttonRootWhite,
                }}
                onClick={() => handleClickOpen()}
              >
                Contact Sales
              </Button>
          </Box>
        </CardContent>
      </Card>
      <CaptureEmailDialog open={dialogOpen} handleClose={handleClose} 
        title={'Enterprise Solutions'} source='Enterprise Solutions'
        content={'Enter your email and our sales team will contact you regarding our enterprise solutions'}
        useOrganization={true}
        useMessage={true}
        />
    </>
  );
}

export default CardPricingGradientProfessional;
