import * as React from 'react';
import { Tooltip } from 'antd';


function RowOptionsRenderer (props) {
    return (
      <div class='rowOptionsRenderer'>
        <div class="dataTableRowOptionsTooltip" >
        <Tooltip title="Copy row" placement="top">
        <i class="material-icons dataTableCopy" onClick={() => props.onCopy(props.value)} style={{fontSize:'inherit'}}>content_copy</i>
        <span />
        </Tooltip>
        </div>
        <div class="dataTableRowOptionsTooltip">
          <Tooltip title="Add row above" placement="top">
          <i class="material-icons dataTableRowUp" onClick={() => props.onAdd('above', props.value)} style={{fontSize:'inherit'}}>arrow_circle_up</i>
          <span />
          </Tooltip>
        </div>
        <div class="dataTableRowOptionsTooltip">
          <Tooltip title="Add row below" placement="top">
          <i class="material-icons dataTableRowDown" onClick={() => props.onAdd('below', props.value)} style={{fontSize:'inherit'}}>arrow_circle_down</i>
          <span />
          </Tooltip>
        </div>
        <div class="dataTableRowOptionsTooltip">
          <Tooltip title="Delete row" placement="top">
            <i class="material-icons dataTableDelete" onClick={() => props.onDelete(props.value)} style={{fontSize:'inherit'}}>delete</i>
            <span />
          </Tooltip>
        </div>
      </div>
    );
}

export default React.memo(RowOptionsRenderer);