
import { Layer, Source} from "react-map-gl";
import { useSelector, shallowEqual } from 'react-redux';
import {
    selectFilteredData,
  } from 'store/reducers/inputReducer.js';
import {pointProps, clusterProps} from 'views/admin/Maps/geomapping/Layers/common_props.js';
import { useTheme } from "@material-ui/core/styles";  

const ResultPointLayer = (props) => {
  const theme = useTheme();
  const resultPoints = useSelector(selectFilteredData({
    'excludeLineStrings': true,
    'resultsOnly':true,
    'excludePoints':false,
    'excludeDrops': true,
    'filterTime': true,
    name:'resultPoints',
  }), shallowEqual).geoJsonData;
  const resultPointClusteredLayerProps = {
    ...clusterProps,
    id: 'clustered-result-point',
    source: 'results-cluster-enabled',
    filter: ['all', ['has', 'point_count']],
    paint: {
      ...clusterProps.paint,
      'circle-color': theme.palette.primary.main,
    },
  }

  const resultPointUnclusteredLayerProps = {
    ...pointProps,
    id: 'unclustered-result-point',
    source: 'results-cluster-enabled',
    filter: ['all', ['!', ['has', 'point_count']]],
    paint: {
      ...pointProps.paint,
      'circle-color': ['get', 'colorHex'],
    },
  };

  const unclusteredPointHighlightLayerProps = {
    ...pointProps, 
    id: 'unclustered-result-point-highlight',
    source: 'results-cluster-enabled',
    filter: ['all', ['!', ['has', 'point_count']], ['==', ['get', 'uuid'], props.selectedUuid]],
    paint: {
      ...pointProps.paint,
      'circle-color': ['get', "colorHoverHex"],
      'circle-stroke-width': 2,
      'circle-stroke-color': ['get', "colorHex"]
    }
  };
  
  const resultUnclusteredTextLayer = {
    id: 'result-leg-text',
    type: 'symbol',
    source: 'results-cluster-enabled',
    filter: ['!', ['has', 'point_count']],

    layout: {
      'text-field': ['get', 'Stop'],
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
      'text-anchor': 'center'
    },
    paint: {
      'text-color': '#000000'
    },
    // "layerIndex": 3
  };



  const resultClusteredTextLayer = {
    id: 'result-cluster-text',
    type: 'symbol',
    source: 'results-cluster-enabled',
    filter: ['all', ['has', 'point_count']],
    layout: {
      'text-field': '({point_count_abbreviated})',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
      'text-anchor': 'center'
    },
    paint: {
      'text-color': theme.palette.primary.light
    },
    "layerIndex": 3
  };


    return (<>
        <Source
          id="results-cluster-enabled"
          type="geojson"
          data={resultPoints}
          cluster={true}
          clusterMaxZoom={25}
          clusterRadius={2}
        >
          <Layer {...resultPointClusteredLayerProps} />
          <Layer {...resultPointUnclusteredLayerProps} />
          <Layer {...unclusteredPointHighlightLayerProps} />
          <Layer {...resultUnclusteredTextLayer} />
          <Layer {...resultClusteredTextLayer} />

        </Source>
    </>)
}

export default ResultPointLayer;