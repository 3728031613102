import React from 'react';
import { TextField } from '@mui/material' 
import { useTheme } from "@material-ui/core/styles";  


function MyInput (props) {
  const theme = useTheme();
  const [themeColor, setThemeColor] = React.useState(null)
  const [fontColor, setFontColor] = React.useState(null)

  React.useEffect(() => {
    setThemeColor(theme.palette.primary.main);
  }, [themeColor])

  React.useEffect(() => {
    setFontColor(theme.palette.gray[600]);
  }, [fontColor])

    return (<>
        <TextField
            autoFocus
            margin="dense"
            id={themeColor}
            label={props.label}
            type={props.type}
            fullWidth
            variant="outlined"
            color='primary'
            size="small"
            sx={{
              // border: '2px solid green',
              // '& .MuiSlider-thumb': {
              //   borderRadius: '1px',
              // },
              color:`${fontColor}`,
              '& .MuiInputLabel-root': {
                color:'inherit',
              },
              '& .MuiInputBase-input-MuiOutlinedInput-input': {
                color:'pink',
              },
              '& .Mui-focused': {
                color:`${themeColor}`,
                '& .MuiOutlinedInput-notchedOutline': {
                  backgroundColor:'inherit',
                  border: `2px solid ${themeColor}`,
                }
              },
              '& .MuiOutlinedInput-input': {
                color:`${fontColor}`,
              }
              
            }}
            // multiline
            {...props.textfieldProps}
        />

  </>)
}

export default MyInput