import React from "react";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import ResultsSummaryListItems from 'components/Cards/Cards/ResultsSummaryListItems.js';
import SavedScenarioListItems from 'components/Cards/Cards/SavedScenarioListItems.js';


export default function CardSavedResults(props) {

  return (
    <>
      <Card style={{margin:'0px', height:'100%', alignItems: 'inherit'}}>

        <CardContent style={{padding:'0px', paddingBottom:'0px', overflowX:'hidden'}}>
          <div style={{display: 'flex', flexDirection: 'column', height:'100%'}}>
            <div style={{flexGrow: 1}}>

            <Box
              component="p"
              fontWeight="300"
              lineHeight="1.7"
              fontSize="1rem"
              marginTop="0"
            >
              <Box style={{padding:'0px', marginBottom:'10px'}}>
                <SavedScenarioListItems collapsed={false}/>
              </Box>
              <ResultsSummaryListItems collapsed={false} />
            </Box>
            </div>

          </div>
          

        </CardContent>
      </Card>
    </>
  );
}
