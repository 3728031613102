const staticTableSettings = require('store/data/table_settings_static.json')

export const getCorrespondingDropdownColumns = (tableName, columnName) => {
    /**
     * this function checks to see if the columnName is associated with any dropdown 
     * lists from other table/columns
     */
    const correspondingItems = new Set()
    for (const [altTableName, tableSettings] of Object.entries(staticTableSettings)) {
        if (tableSettings['dataType']) {
            for (const [altColumnName, dataType] of Object.entries(tableSettings['dataType'])) {
                if (dataType === 'dropdown' || dataType === 'multi-dropdown') {
                    const dropdownSource = tableSettings['dropdownSource'][altColumnName];
                    if (dropdownSource.dataField === tableName && dropdownSource.column === columnName) {
                        correspondingItems.add(altTableName)
                    }
                }
            }
        }
    }
    return Array.from(correspondingItems);
}