import React from "react";
// import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

// core components
import componentStyles from "assets/theme/components/cards/pricing/card-pricing-gradient.js";
import buttonsStyles from "assets/theme/components/button.js";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(buttonsStyles);

function PricingIcon(props) {
  const classes = {
    ...useStyles(),
    ...useStylesButtons(),
  };
  const theme = useTheme();

  return (
        // <Box
        //   component="ul"
        //   paddingLeft="0"
        //   className={clsx(classes.cardRoot, classes.listStyleNone)}
        //   margin="1.5rem 0"
        // >
          <Box
            component="li"
            paddingTop=".5rem"
            paddingBottom=".5rem"
            display="flex"
            alignItems="center"
          >
            {props.icon &&
            <div className={classes.iconWhite}>
              {props.icon}
            </div>
            }           
            <Box paddingLeft=".5rem" color={theme.palette.white.main} textAlign='left'>
              {props.subtitle}
            </Box>
          </Box>
        // </Box>       
  );
}

export default PricingIcon;
