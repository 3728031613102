import {
    generateUUID
  } from 'store/functions/geoJsonFunctions.js';

const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']


//input should just be the inputReducer state
async function saveInstance(state, instanceName, userId, scenarioId) {
    if (!scenarioId) {
        scenarioId = generateUUID();
    }
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({'input_object': state, 'instance_name': instanceName, 'user_id': userId, 'instance_id': scenarioId})
    };

    var response
    try {
        response = await fetch(BASE_ROUTE + '/save_instance', requestOptions)
        if (!response.ok) {
            throw 'Invalid response received from get_run_summaries_by_user'
        }

    } catch (err) {
        throw err
    }

}

export default saveInstance