import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';


import componentStyles from "assets/theme/components/cards/dashboard/card-map-options.js";
import { selectToggleableColumns } from "store/reducers/inputReducer";
import ColumnChoiceCheckbox from 'views/admin/Wizard/ColumnChoiceCheckbox.jsx'
const useStyles = makeStyles(componentStyles);

const allStaticTableSettings = require('store/data/table_settings_static.json')

export default function WizardColumnChoices(props) {
    const toggleColumns = useSelector(selectToggleableColumns(props.modelType));
    const staticTableSettings = allStaticTableSettings;
    const classes = useStyles();
    const theme = useTheme();

    return (<>
    <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
            <Card classes={{ root: classes.collapsibleCard }}>
                <CardHeader classes={{ root: classes.collapsibleCardHeader }}      
                titleTypographyProps={{ component: "h3", variant: "h3" }}
                title="Select whether the following optional features apply to your data." 
                > 
                
                </CardHeader>
                <CardContent classes={{ root: classes.collapsibleCardContent }} >
                    <Grid container spacing={1}>
                    {toggleColumns && Object.keys(toggleColumns).map((tableName) => {
                        const columnNames = toggleColumns[tableName];
                        return (<>
                        <Grid item xs={12} lg={12} xl={12}>
                            <div style={{fontWeight: 600, fontSize:'1rem', borderBottom:'1px solid ' + 
                                theme.palette.primary.main}}>{staticTableSettings[tableName]['title']}</div>
                            <Stack direction="column" spacing={1}  >
                                {columnNames.map((columnName) => {
                                    return (
                                        <div style={{borderBottom: '0.5px solid ' + theme.palette.gray[400]}}>
                                            <ColumnChoiceCheckbox tableName={tableName} columnName={columnName}/>
                                        </div>
                                    )
                                })} 
                            </Stack>
                        </Grid>
                        </>)
                    })}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>

    </Grid>
    </>
    );
}