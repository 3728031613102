import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import React from "react";
import { useDispatch } from 'react-redux';
import { useTheme } from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import {
    downloadSampleTable
  } from 'store/reducers/inputReducer';
// This file creates a button that allows the user to download a sample CSV file for the table that
// can then be used to upload data to the table.


function TimeSettingsButton(props) {
    const dispatch = useDispatch();
    const theme = useTheme();

    const handleButtonClick = (event) => {
        console.log("dispatching")
        console.log('props.tableName', props.tableName);
        dispatch(downloadSampleTable({'tableName': props.tableName}));
        event.stopPropagation();

    }

    return (<>
        <Tooltip title={'Download sample file'}>
            <IconButton id='cost-settings-icon-button' onClick={(event) => handleButtonClick(event)} size='small'>
                <DownloadOutlinedIcon style={{color:theme.palette.primary.main}} />
            </IconButton>
        </Tooltip>
        
        </>)
}


export default TimeSettingsButton