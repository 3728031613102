import React, {useState, useEffect} from "react";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from "@material-ui/core/styles"; 
import Box from "@material-ui/core/Box";
import DataEntryTable from 'views/admin/Tables/Ag-grid/DataEntryTable.js'
import DataTableStatusIcon from 'views/admin/Tables/DataTableStatusIcon.js';

import { useSelector } from 'react-redux';
import {
  selectActiveModelSettings,
  selectDataSize,
  selectTableData,
  getFetchingTravelCosts,
  selectIsResultsTable
} from 'store/reducers/inputReducer';
import "views/admin/Tables/DataEntryTable.css"
import DataEntryTableSummary from 'views/admin/Tables/DataEntryTableSummary.js';

import componentStyles from "assets/theme/views/admin/typography.js";
import componentStylesTypography from "assets/theme/components/typography.js";
import componentStylesCard from "assets/theme/components/cards/dashboard/card-accordion.js";

const useStyles = makeStyles(componentStyles);
const useStylesTypography = makeStyles(componentStylesTypography);
const useStylesCard = makeStyles(componentStylesCard)

const allStaticTableSettings = require('store/data/table_settings_static.json')

const AccordionStatusIcon = (props) => {

  const tableData = useSelector(selectTableData(props.tableName));
  const isOutputTable = useSelector(selectIsResultsTable(props.tableName))
  const modelSettings = useSelector(selectActiveModelSettings);
  const isLoadingTravelCosts = useSelector(getFetchingTravelCosts)

  const checkAllValid = () => {
    var retBoolean = true;
    var warning = false;
    const warningRows = [];
    const invalidRows = [];
    for (var i = 0; i < tableData.length; i++) {
      const valid = tableData[i]['Valid']
      if (!valid) {
        retBoolean = false;
        invalidRows.push(i+1)
      }
      if (valid && valid === 'warning') {
        warning = true;
        warningRows.push(i+1)
      }
    }
    return {valid: retBoolean, invalidRows:invalidRows, warning: warning, warningRows:warningRows}
  }

  var tooltip = null;
  if (modelSettings['input']['minimumRows']) {
    const minRows = modelSettings['input']['minimumRows'][props.tableName] || 0;
    if (minRows) {
      if (tableData.length < minRows) {
        
        tooltip = 'Minimum row requirement not satisfied. This table requires at least ' + minRows
        if (minRows === 1) {
          tooltip += ' row to run optimization.'
        } else {
          tooltip += ' rows to run optimization.'
        }
        return <DataTableStatusIcon {...props} status={'error'} tooltip={tooltip}/>
      }
    }
  }

  const allValidResponse = checkAllValid();
  var status = 'valid';
  if (props.tableName === 'travel_costs' && isLoadingTravelCosts) {
    status = 'loading'
  }
  else if (!allValidResponse.valid) {
    tooltip = 'Data entry errors on ' + allValidResponse.invalidRows.length + ' rows. View table for details.'
    status = 'error'
  }
  else if (allValidResponse.warning) {
    tooltip = 'Data entry warnings on ' + allValidResponse.warningRows.length + ' rows. View table for details.'
    status = 'warning'
  }


  return <DataTableStatusIcon {...props} status={isOutputTable ? 'valid' : status} tooltip={tooltip}/>
}

const DataEntryTableAccordion = function DataEntryTableAccordion(props) {
  const classes = { ...useStyles(), ...useStylesTypography(), ...useStylesCard()};
  const theme = useTheme();
  const activeModelSettings = useSelector(selectActiveModelSettings);
  const tableDataSize = useSelector(selectDataSize(props.tableName))
  const tableTitle = allStaticTableSettings[props.tableName]['title'];
  const [downloadDateTime, setDownloadDateTime] = useState(null);
  const cardColor = allStaticTableSettings[props.tableName]['summary']['color'];

  const [dataTable, setDataTable] = useState(<></>)
  const [disabled, setDisabled] = useState(null);
  const [isEmptyResults, setIsEmptyResults] = useState(false);

  const accordionSummarySx = {
    '.MuiAccordionSummary-content': { margin: '0px' },
    '.MuiAccordionSummary-content.Mui-expanded': {margin: '0px'}
  };

  const accordionSummaryStyle = {
    paddingLeft:'0px', 
    paddingRight:'0px',
    display: 'flex'
  }

  const getDisabledState = () => {
    var enabled;
    enabled = activeModelSettings['input']['enabled'][props.tableName];
    if (activeModelSettings['input']['enabledOnlyWithOutput'][props.tableName]) {
      if (tableDataSize > 0) {
        setIsEmptyResults(false)
        return false
      } else {
        setIsEmptyResults(true)
        return true
      }
    } else {
      return !enabled
    }
  }

  const downloadFile = () => {
    /**
     * This function creates a new DataEntryTable with the prop downloadOnMount,
     * which indicates that the table will immediately download when it is loaded.
     */
    const unixDateTime = Math.floor(Date.now() / 1000);
    const newDataTable = <DataEntryTable {...props} tableNum={unixDateTime} downloadOnMount={true}></DataEntryTable>
    setDataTable(newDataTable);
    setDownloadDateTime(unixDateTime)
  }

  useEffect(() => {
    const disabledState = getDisabledState();
    setDisabled(disabledState);
  }, [activeModelSettings, tableDataSize])

  return (<>
    <div key={downloadDateTime} hidden={true}>{dataTable}</div> 
      <Accordion disabled={disabled} 
      
              style={{borderRadius: ".375rem", boxShadow:'none'}} 
                  
                  TransitionProps={{ unmountOnExit: true }}
                  classes={{root:classes['accordionSummary_' + cardColor]}}
                  >
        {!disabled && 
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={accordionSummarySx}
          disableGutters={true}
          style={accordionSummaryStyle}
        >
          <div style={{padding:'8px', display:'inline-flex', 'width':'175px'}}>
            <AccordionStatusIcon tableName={props.tableName} status={'disabled'} style={{padding:'8px'}}/>
            <Box
                paddingLeft='10px'
                component="small"
                fontSize="100%"
                fontWeight="600"
                color={theme.palette.gray[700]}
                whiteSpace={'nowrap'}
              >
                {tableTitle}
            </Box>
          </div>
          <div style={{display:'inline-flex', paddingLeft:'8px', paddingRight:'8px', flex:1}}>
            <DataEntryTableSummary tableName={props.tableName} downloadFile={downloadFile}></DataEntryTableSummary>
          </div>
        </AccordionSummary>}

        {disabled && !(activeModelSettings['input']['enabledOnlyWithOutput'][props.tableName]) && 
        <AccordionSummary          
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          sx={accordionSummarySx}
          style={accordionSummaryStyle}
          id="panel1bh-header">         
          
            <Grid item xs={4} lg={2} style={{padding:'8px', display:'inline-flex'}}>
              <DataTableStatusIcon status={'disabled'} style={{padding:'8px'}}/>
              <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="600"
                  color={theme.palette.gray[700]}
                >
                  {tableTitle}
              </Box>
          </Grid>
            <Grid item xs={8} lg={9} style={{padding:'8px', display:'inline-flex'}}>
            This table is not needed for this model
          </Grid>
          </AccordionSummary>}

          {disabled && (activeModelSettings['input']['enabledOnlyWithOutput'][props.tableName]) && 
        <AccordionSummary       
          sx={accordionSummarySx}   
          style={accordionSummaryStyle}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header">        
            <div style={{padding:'8px', display:'inline-flex', 'width':'175px'}}>
            <DataTableStatusIcon status={'temporarily_disabled'} />
            <Box  
                  id='accordion-table-title-box'
                  component="small"
                  fontSize="100%"
                  fontWeight="600"
                  paddingLeft={'10px'}
                  whiteSpace={'nowrap'}
                  color={theme.palette.gray[700]}
            >
              {tableTitle}
            </Box>
          </div>
          <div style={{padding:'8px', display:'inline-flex', 'flex': 1}}>
            {!isEmptyResults && <>Results not yet available. Run optimization to see results.</>}
            {isEmptyResults && <>This table is empty</>}
          </div>
          </AccordionSummary>}

      <AccordionDetails 
      >
      {<DataEntryTable {...props} downloadOnMount={false} source='accordion'></DataEntryTable>}
      </AccordionDetails>
      
    </Accordion>                            
    </>
  );
};

export default DataEntryTableAccordion;