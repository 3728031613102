import {useState} from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MyMuiButton from 'views/admin/Components/MyComponents/MyMuiButton.jsx'

export default function ScenarioNameDialog(props) {
  const { onClose, selectedValue, open } = props;
  const [textEntry, setTextEntry] = useState('')
  const [textChanged, setTextChanged] = useState(false);

  const handleSaveAndClose = () => {
    if (textEntry) {
      props.handleSaveAndClose(textEntry);
    } else {
      setTextChanged(true);
    }
  };

  const handleCancel = () => {
    props.handleCancel();
  }

  const handleTextEntryChange = (event) => {
    setTextEntry(event.target.value);
    setTextChanged(true);
  };

  return (
    <Dialog onClose={handleCancel} onClick={(event) => event.stopPropagation()} 
      open={open} maxWidth={'xs'}>
      <DialogTitle style={{paddingBottom:'10px'}}>Enter a name for this scenario</DialogTitle>
      <DialogContent style={{paddingTop:'10px'}}>

      <TextField
        id="outlined-multiline-flexible"
        label="Scenario Name"
        multiline
        maxRows={4}
        value={textEntry}
        size='small'
        onChange={handleTextEntryChange}
        fullWidth
        error={textEntry ? false : textChanged ? true : false}
        helperText={textEntry ? null : textChanged ? "Scenario name cannot be empty": null}
      />


          <Stack direction='column' style={{paddingTop:'20px'}}>
            <Grid container >
                <Grid item xs={12} sm={8}>
                    <MyMuiButton variant={textEntry ? "contained": "outlined"}
                        onClick={(event) => {event.stopPropagation(); handleSaveAndClose()}} fullWidth
                        >
                        Save and close
                    </MyMuiButton>
                </Grid>
                <Grid item xs={12} sm={4} padding={'0px'}>
                    <MyMuiButton variant={"contained"}
                            onClick={(event) => {event.stopPropagation(); handleCancel()}} color='error'
                            fullWidth 
                    >
                      Cancel
                    </MyMuiButton>                
                </Grid>
            </Grid>
        </Stack>
        </DialogContent>
    </Dialog>
  );
}

ScenarioNameDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};


