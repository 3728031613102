import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import { 
  clearNotifications,
  selectNotifications,
  selectScenarioName,
  selectScenarioId
} from 'store/reducers/inputReducer.js';
import { useSelector, useDispatch } from 'react-redux';
import { ActionCreators as UndoActionCreators } from 'redux-undo'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Stack from '@mui/material/Stack';
import { useTheme } from "@material-ui/core/styles";

import EditScenarioButton from 'views/admin/Buttons/EditScenarioButton.jsx'

const stepZeroIconStyle = {width:'5rem', height:'5rem'};

function OptimizationCompletedDialog(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const notifications = useSelector(selectNotifications('optimization'))
  const scenarioName = useSelector(selectScenarioName);
  const scenarioId = useSelector(selectScenarioId)
  const theme = useTheme();
  const dispatch = useDispatch();


  const handleCancel = () => {
    dispatch(clearNotifications({'type':'optimization'}))
    dispatch(UndoActionCreators.undo());
  };

  const handleOk = () => {
    dispatch(clearNotifications({'type':'optimization'}))
  };


  return (<>
      {notifications && notifications.length > 0 && 
    <Dialog
      sx={{ '& .MuiDialog-paper': { maxHeight: 435, maxWidth: 700} }}
      maxWidth="md"
      keepMounted={false}
      open={notifications.length > 0}
      onClick = {(event) => event.stopPropagation()}

    >
      <DialogTitle style={{backgroundColor: notifications && notifications[0].type === 'error' ? theme.palette.warning.light : theme.palette.success.light, 
        color: notifications && notifications[0].type === 'error' ? theme.palette.warning.main: theme.palette.success.dark, 
            fontWeight:700, paddingTop:'10px', paddingBottom:'10px'}}>
          <Grid container >
                <Grid item xs={12} sm={6}>
                {notifications && notifications[0].type === 'error' && <><WarningAmberIcon  style={{paddingRight:'5px', height:'2rem', width:'2rem'}}/>Optimization Report</>}
                {notifications && notifications[0].type === 'success' && <><CheckCircleOutlineIcon  style={{paddingRight:'5px', height:'2rem', width:'2rem'}}/>Table Upload Report</>}
                </Grid>
                <Grid item xs={12} sm={6} style={{whiteSpace:'nowrap'}}>
                    <Stack direction='row' spacing={2} style={{float:'right'}}>
                    <Stack direction='column' style={{fontSize: '0.7rem', lineHeight: '0.8rem'}}>
                        {notifications && notifications[0].type === 'success' && <Stack direction='row'>Scenario Name:&nbsp;
                          <span style={{fontWeight:500, overflow:'hidden'}}>{scenarioName}
                            <EditScenarioButton scenarioId={scenarioId} buttonStyle={{width:'1rem', height:'1rem', background:'inherit'}}/>
                          </span></Stack>}
                    </Stack>

                    </Stack>
                </Grid>
          </Grid>
        </DialogTitle>
      <DialogContent dividers style={{minHeight:'200px'}}>
          <>
            {notifications && notifications[0].type === 'success' && <Stack direction='row'>
            <CheckCircleOutlineIcon color='success' style={{...stepZeroIconStyle}}/>
            Optimization completed successfully
            </Stack>
            }
            {notifications && notifications[0].type === 'error' && <Stack direction='row'>
            <WarningAmberIcon color='warning' style={{...stepZeroIconStyle}}/>
            <div>
            Optimization did not terminate successfully.
            </div>
            </Stack>
            }
            </>


      </DialogContent>
      
      <DialogActions style={{justifyContent:'space-evenly'}}>
          <Grid container style={{display:'inline-flex'}}>
            <Grid item xs={12} sm={6}>
                {notifications && notifications[0].type === 'success' && <Button autoFocus onClick={handleCancel} style={{backgroundColor: theme.palette.error.main}} variant='contained'>
                Clear results
                </Button>}
            </Grid>

            <Grid item xs={12} sm={6}>
                {<Button style={{float:'right', backgroundColor: theme.palette.success.main}} onClick={() => handleOk()}  variant='contained'>Okay</Button>}
            </Grid>
        </Grid>

      </DialogActions>
    </Dialog>}
    </>

  );
}

export default OptimizationCompletedDialog;