import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import 'material-icons/iconfont/material-icons.css';
import DataEntryForm from "views/admin/Forms/DataEntryForm.js"


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });


const NewFormEntryDialog = (props) => {

    const handleCloseForm = () => {
        props.setOpenForm(false);
    };

    const handleDialogClick = e => {
        e.stopPropagation();
    }; 

    return (
        <>
            <Dialog
                maxWidth="sm"
                open={props.open}
                TransitionComponent={Transition}
                keepMounted={true}
                onClose={handleCloseForm}
                onClick={handleDialogClick}
                onBackdropClick={handleDialogClick}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}
            >
                <DialogContent style={{ padding: '0px', display: 'flex' }}>
                    <DataEntryForm tableName={props.tableName} onClose={handleCloseForm}></DataEntryForm>
                </DialogContent>
            </Dialog>
        </>
    )
}


export default NewFormEntryDialog;