import React, {useState} from "react";

import Button from "@material-ui/core/Button";
import 'material-icons/iconfont/material-icons.css';
import MyBadge from 'views/admin/Components/MyComponents/MyBadge.js';
import TableDialog from 'views/admin/Components/Dialogs/TableDialog.jsx'

const ICONS = {
    'locations': 'place',
    'trips': 'follow_the_signs',
    'vehicles': 'directions_car',
    'vehicle_types': 'commute',
}

const ICON_TEST = {
    'locations': 'Add new location',
    'trips': 'Add new trip',
    'vehicles': 'Add new vehicle',
    'vehicle_types': 'Add new vehicle type',
}

class FormIcon extends React.Component {

    render() {return <i class="material-icons" style={{fontSize:'inherit'}}>{ICONS[this.props.dataTable]}</i>}
}

const OpenTableDialogButton = (props) => {

    var includeLabel = true;
    if ('includeLabel'in props) {
        includeLabel = props.includeLabel
    }
    const icon = <FormIcon dataTable={props.dataTable}></FormIcon>

    const [openForm, setOpenForm] = useState(false);
    const handleClickOpenForm = (event) => {
        event.stopPropagation();
        setOpenForm(true);
    };
    const handleCloseForm = () => {
        setOpenForm(false);
      };

    var buttonProps = props.buttonProps || {}
    if (!buttonProps.hasOwnProperty('color')) {
        buttonProps['color'] = "primary"
    }
    if (!buttonProps.hasOwnProperty('variant')) {
        buttonProps['variant'] = "contained"
    }
    if (!buttonProps.hasOwnProperty('size')) {
        buttonProps['size'] = "small"
    }

        return (<>
        {props.useBadge &&
            <MyBadge  {...props.badgeProps} badgeProps={{pill: true, onClick:(event) => handleClickOpenForm(event), style:{...props.badgeProps.style, zIndex:'0'}}}>
                {props.badgeLabel}
            </MyBadge>}
        {!props.useBadge && 
        <Button  onClick={(event) => handleClickOpenForm(event)} {...buttonProps}>
            {icon}
            {includeLabel && ICON_TEST[props.dataTable]}
        </Button>
        }
        <TableDialog tableName={props.dataTable} onClose={handleCloseForm} open={openForm}/>

        </>
    )
}

export default OpenTableDialogButton;