import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import MyCheckbox from 'views/admin/Components/MyComponents/MyCheckbox';
// import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MyRadio from 'views/admin/Components/MyComponents/MyRadio';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';

import {
    updateTimeSettings,
    selectTimeSetting,
    changeTimeUnit
} from 'store/reducers/inputReducer';

const TableUseTimeWindowsCheck = (props) => {
    const dispatch = useDispatch();
    const timePeriodSpansDays = useSelector(selectTimeSetting("timePeriodSpansDays"));

    const timeUnit = useSelector(selectTimeSetting("timeUnit"));

    const handleTimePeriodSpanChange = (event, value) => {
      dispatch(updateTimeSettings({settingNames:['timePeriodSpansDays'], tableName:props.tableName, settingValues: [!timePeriodSpansDays]}));
    }

    const handleTimeUnitChange = (event, value) => {
      dispatch(changeTimeUnit({newTimeUnit: value}));
    }

    return    <>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="distance-calculation-group-label"
            name="row-radio-buttons-group"
            onChange={handleTimeUnitChange}
            value={timeUnit}
            style={{margin:'0px'}}
          >
            <MyRadio formControlProps={{value: 'clock', label: 'Clock time (i.e. 11:00 am)'}}/>
            <MyRadio formControlProps={{value: 'integer', label: 'Integer (i.e. 660 minutes)'}}/>
            
          </RadioGroup>
        </FormControl>

        <Collapse in={timeUnit == 'clock'} unmountOnExit>
            <Stack direction="row" spacing={2} style={{padding:'5px'}}>
            <FormControlLabel 
            control={<MyCheckbox checkboxProps={{
              defaultChecked:timePeriodSpansDays,
              onChange:handleTimePeriodSpanChange}}/>}
            label="Time periods span multiple days"/>
          </Stack>        
        </Collapse>
  </>
}

export default TableUseTimeWindowsCheck;