
import { Layer, Source} from "react-map-gl";
import { useSelector, shallowEqual } from 'react-redux';
import {
    selectFilteredData,
    selectGeoJsonFilter
  } from 'store/reducers/inputReducer.js';
import {pointProps, clusterProps} from 'views/admin/Maps/geomapping/Layers/common_props.js';
import { useTheme } from "@material-ui/core/styles";  
import {tripProps} from 'views/admin/Maps/geomapping/Layers/common_props.js';

const ResultDropLayer = (props) => {
  const theme = useTheme();
  const dropPoints = useSelector(selectFilteredData({'dropsOnly': true}), shallowEqual).geoJsonData;
  const dropLayerVisible = useSelector(selectGeoJsonFilter('dropLayerVisible'));

  const resultPointClusteredLayerProps = {
    ...clusterProps,
    id: 'clustered-result-drop',
    source: 'results-drops',
    filter: ['all', ['has', 'point_count']],
    paint: {
      ...clusterProps.paint,
      'circle-color': theme.palette.droppedTrip.cluster,
    },
  }

  const resultPointUnclusteredLayerProps = {
    ...pointProps,
    id: 'unclustered-result-drop',
    source: 'results-drops',
    filter: ['all', ['!', ['has', 'point_count']]],
    paint: {
      ...pointProps.paint,
      'circle-color': theme.palette.droppedTrip.main,
    },
  };

  const unclusteredPointHighlightLayerProps = {
    ...pointProps, 
    id: 'unclustered-result-drop-highlight',
    source: 'results-drops',
    filter: ['all', ['!', ['has', 'point_count']], ['==', ['get', 'uuid'], props.selectedUuid]],
    paint: {
      ...pointProps.paint,
      'circle-color': theme.palette.droppedTrip.highlight,
      'circle-stroke-width': 2,
      'circle-stroke-color': theme.palette.droppedTrip.main
    }
  };
  
  const resultUnclusteredTextLayer = {
    id: 'result-drop-text',
    type: 'symbol',
    source: 'results-drops',
    filter: ['!', ['has', 'point_count']],

    layout: {
      'text-field': 'D',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
      'text-anchor': 'center'
    },
    paint: {
      'text-color': '#000000'
    },
  };



  const resultClusteredTextLayer = {
    id: 'result-drop-text',
    type: 'symbol',
    source: 'results-drops',
    filter: ['all', ['has', 'point_count']],
    layout: {
      'text-field': '({point_count_abbreviated})',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
      'text-anchor': 'center'
    },
    paint: {
      'text-color': theme.palette.error.main
    },
  };

  const resultDroppedTripLayerProps = {
    ...tripProps,
    id: 'result-dropped-trips',
    source: 'results-drops',
    filter: ['==', '$type', 'LineString'],
    paint: {
      ...tripProps.paint,
      'line-color': ['get', "colorHex"],
    },
    layerIndex:0
  }


    return (<>
        <Source
          id="results-drops"
          type="geojson"
          data={dropPoints}
          cluster={true}
          clusterMaxZoom={25}
          clusterRadius={2}
        >
          {dropLayerVisible && <>
          <Layer {...resultPointClusteredLayerProps} />
          <Layer {...resultPointUnclusteredLayerProps} />
          <Layer {...unclusteredPointHighlightLayerProps} />
          <Layer {...resultUnclusteredTextLayer} />
          <Layer {...resultClusteredTextLayer} />
          <Layer {...resultDroppedTripLayerProps} />
          </>}
        </Source>
    </>)
}

export default ResultDropLayer;