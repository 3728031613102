import {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MyButton from 'views/admin/Components/MyComponents/MyButton.js'

import {
    renameScenario,
    saveScenario,
    selectScenarioInfo,
    updateGeneralSetting,
    selectIsLoggedIn
} from 'store/reducers/inputReducer.js'
import LoginRequiredDialog from 'views/admin/Notifications/LoginRequiredDialog.js';
import RenameScenarioDialog from 'views/admin/Components/Dialogs/RenameScenarioDialog.js'


function RenameScenarioButton(props) {
    const scenario = useSelector(selectScenarioInfo);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const [dialogOpen, setDialogOpen] = useState(false);
    const dispatch = useDispatch();

    const handleButtonClick = (event) => {
        setDialogOpen(true);
        event.stopPropagation();
    }

    const onValidSave = (newName) => {
        if (scenario.scenarioId) {
             dispatch(renameScenario({scenarioId: scenario.scenarioId, newName:newName}));
        } else {
            dispatch(saveScenario({scenarioName:newName}));
        }

    }

    const handleSaveAndClose = (scenarioNameEntry) => {
        dispatch(updateGeneralSetting({settingName: 'scenario', subSettingName:'scenarioName', value: scenarioNameEntry}));
        dispatch(updateGeneralSetting({settingName: 'scenario', subSettingName:'lastSaved', value: new Date()}));
        setDialogOpen(false);
        onValidSave(scenarioNameEntry);
    }

    const handleCancel = () => {
        setDialogOpen(false);
    }

    const buttonProps = { onClick: handleButtonClick}

    return (<>
        <MyButton label='Rename Scenario' variant='contained' color='primary' 
        buttonProps={buttonProps} buttonStyle={{whiteSpace:'nowrap'}}
        />
        {isLoggedIn && <RenameScenarioDialog open={dialogOpen && isLoggedIn} handleSaveAndClose={handleSaveAndClose} handleCancel={handleCancel}/>}
        {!isLoggedIn && <LoginRequiredDialog 
            open={dialogOpen && !isLoggedIn} 
            handleClose={() => setDialogOpen(false)}
            message={'You must be logged in to save scenario data.'}
        />}
    </>)
}


export default RenameScenarioButton