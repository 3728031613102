import {useState} from 'react';
import {  useDispatch } from 'react-redux';
import MyButton from 'views/admin/Components/MyComponents/MyButton.js'
import ExitExampleModeDialog from 'views/admin/Notifications/ExitExampleModeDialog.js'
import { useSelector } from 'react-redux';
import {
    clearAllTableData
} from 'store/reducers/inputReducer.js'
import {
    getInExampleMode
  } from 'store/reducers/inputReducer.js'


function ClearAllTableDataButton(props) {
    const dispatch = useDispatch();
    const [exitDialogOpen, setExitDialogOpen] = useState(false);
    const inExampleMode = useSelector(getInExampleMode);

    const clickAction = (event) => {
        event.stopPropagation();
        if (inExampleMode) {
            setExitDialogOpen(true);
        }
        else {
            dispatch(clearAllTableData({}));
        }
      }

    const exitExampleModeDialogClose = (success=true) => {
        setExitDialogOpen(false);
    }

    const buttonProps = { onClick: (event) => clickAction(event)}

    return (<>
        <MyButton label='Clear All Tables' variant='contained' color='error' {...props}
        buttonProps={{...props.buttonProps, ...buttonProps}} buttonStyle={{whiteSpace:'nowrap'}}
        />
        <ExitExampleModeDialog open={exitDialogOpen} onClose={exitExampleModeDialogClose}/>
    </>)
}


export default ClearAllTableDataButton