/**
 * This function sends a POST request to the server to download a sample table. The
 * server will respond with a CSV file that will get automatically downloaded to
 * the user's system.
 */

const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']

//json file should have locations and costss
async function download_sample_table(json) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ json })
    };
    const response = await fetch(BASE_ROUTE + '/download_sample_table', requestOptions)
    const buffer = await response.arrayBuffer();
    const blob = new Blob([buffer], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = json.tableName + '.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

export default download_sample_table