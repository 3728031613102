/**
 * This component is a button that allows the user to filter the layers on the map.
 */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Popover from '@mui/material/Popover';
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import Tooltip from "@material-ui/core/Tooltip";
import VehicleFilterCheckboxes from 'views/admin/Maps/MapFilters/VehicleFilterCheckboxes.js';

import { useSelector } from 'react-redux';

import componentStyles from "assets/theme/components/cards/dashboard/card-add-data.js";

import {
  selectActiveModelSettings,
  selectActiveResultsShape,
  selectFilterState
} from 'store/reducers/inputReducer.js'

const useStyles = makeStyles(componentStyles);

const MAX_WIDTH = '250px'

export default function VehicleFilterButton(props) {
  /**
   * Card that contains the options for the map view when the side panel
   * is collapsed horizontally. This includes the ability to toggle vehicle
   * visibility and layer visibility.
   */
  const modelSettings = useSelector(selectActiveModelSettings);
  const numResults = useSelector(selectActiveResultsShape);
  const resultsLayerVisible = useSelector(selectFilterState('resultsLayerVisible'));
  const classes = useStyles();
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
          {numResults > 0 && resultsLayerVisible && modelSettings['input']['enabled']['vehicles'] && 
            <div style={{textAlign:'center'}}>
              <Tooltip title='Layer toggles'>
                  <Button style={{zIndex:'3'}}
                    size="small" variant="contained" onClick={handleClick} aria-describedby={id}>
                        <Box
                        component={DirectionsCarIcon}
                        position="relative"
                        
                        ></Box>
                    </Button>
                </Tooltip>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Card classes={{ root: classes.cardRoot }} style={{margin:'0px', maxWidth: MAX_WIDTH}}>
                  <CardHeader
                    style={{padding: '5px'}}
                    className={classes.cardHeader}
                    title="Toggle Vehicles"
                    titleTypographyProps={{
                      component: Box,
                      marginBottom: "0!important",
                      variant: "h3",
                    }}
                  ></CardHeader>
                  <CardContent style={{padding: '5px'}}>
                    <VehicleFilterCheckboxes />

                  </CardContent>

                </Card>
              </Popover>
            </div>}
    </>
  );
}
