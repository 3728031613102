import {useState, useEffect}from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import MyMuiButton from 'views/admin/Components/MyComponents/MyMuiButton.jsx'
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import {
  selectGeneralSetting,
  updateGeneralSetting,
  updateTimeSettings,
  selectTimeSetting
} from 'store/reducers/inputReducer.js'

const staticTableSettings = require('store/data/table_settings_static.json')

export default function TimeSettingsDialog(props) {
  const { open } = props;

    const theme = useTheme();
    const ogCostMetricsSelector = useSelector(selectGeneralSetting("costs"));
    const [ogCostMetrics, setOgCostMetrics] = useState(null);
    const [ogCostMetricsSet, setOgCostMetricsSet] = useState(false);
    const costMetric = useSelector(selectGeneralSetting("costs")).costMetric;
    const timeUnit = useSelector(selectTimeSetting("timeUnit"));
    const dayUnit = useSelector(selectTimeSetting('dayUnit'));
    const timePeriodSpansDays = useSelector(selectTimeSetting("timePeriodSpansDays"));
    const usePickupEnd = useSelector(selectTimeSetting("usePickupEnd", props.tableName));
    const usePickupStart = useSelector(selectTimeSetting("usePickupStart", props.tableName));
    const usePickupExact = useSelector(selectTimeSetting("usePickupExact", props.tableName));
    const useDropoffEnd = useSelector(selectTimeSetting("useDropoffEnd", props.tableName));
    const useDropoffStart = useSelector(selectTimeSetting("useDropoffStart", props.tableName));
    const useDropoffExact = useSelector(selectTimeSetting("useDropoffExact", props.tableName));
    const timeUseTimeWindows = useSelector(selectTimeSetting("useTimeWindows", props.tableName));

    const tableTimeSettings = staticTableSettings[props.tableName]['timeWindows']

    const dispatch = useDispatch()

  const handleClose = () => {
    setOgCostMetricsSet(false);
    props.onClose();
  };

  const handleCancel = () => {
    dispatch(updateGeneralSetting({settingName: 'costs', value: ogCostMetrics}));
    props.onClose();
  }


const handleTimeUnitChange = (event, value) => {
  var columnNames = [];
  var columnValues = [];
  if (value == 'integer') {
    if (tableTimeSettings && tableTimeSettings['pickupExact']) {
      columnNames.push(tableTimeSettings['pickupExact']['columnNameInt'])
      if (usePickupExact) {
        columnValues.push(true)
        columnNames.push(tableTimeSettings['pickupExact']['columnNameDate'])
        columnValues.push(false)
        columnNames.push(tableTimeSettings['pickupExact']['columnNameTime'])
        columnValues.push(false)
      } else {
        columnValues.push(false)
      }
    }

    if (tableTimeSettings && tableTimeSettings['pickupStart']) {
      columnNames.push(tableTimeSettings['pickupStart']['columnNameInt'])
      if (usePickupStart) {  
        columnValues.push(true)
      } else {
        columnValues.push(false)
      }
    }

    if (tableTimeSettings && tableTimeSettings['pickupEnd']) {
      columnNames.push(tableTimeSettings['pickupEnd']['columnNameInt'])
      if (usePickupEnd) {
        columnValues.push(true)
      } else {
        columnValues.push(false)
      }
    }

    if (tableTimeSettings && tableTimeSettings['dropoffExact']) {
      columnNames.push(tableTimeSettings['dropoffExact']['columnNameInt'])
      if (useDropoffExact) {
        columnValues.push(true)
        columnNames.push(tableTimeSettings['dropoffExact']['columnNameDate'])
        columnValues.push(false)
        columnNames.push(tableTimeSettings['dropoffExact']['columnNameTime'])
        columnValues.push(false)
      } else {
        columnValues.push(false)
      }
    }

    if (tableTimeSettings && tableTimeSettings['dropoffStart']) {
      columnNames.push(tableTimeSettings['dropoffStart']['columnNameInt'])
      if (useDropoffStart) {  
        columnValues.push(true)
      } else {
        columnValues.push(false)
      }
    }

    if (tableTimeSettings && tableTimeSettings['dropoffEnd']) {
      columnNames.push(tableTimeSettings['dropoffEnd']['columnNameInt'])
      if (useDropoffEnd) {
        columnValues.push(true)
      } else {
        columnValues.push(false)
      }
    }
  }
  

  if (value == 'clock') {
    if (tableTimeSettings && tableTimeSettings['pickupExact']) {
      columnNames.push(tableTimeSettings['pickupExact']['columnNameTime'])
      if (usePickupExact) {
        columnValues.push(true)
        columnNames.push(tableTimeSettings['pickupExact']['columnNameInt'])
        columnValues.push(false)
      } else {
        columnValues.push(false)
      }
    }

    if (tableTimeSettings && tableTimeSettings['pickupStart']) {
      columnNames.push(tableTimeSettings['pickupStart']['columnNameTime'])
      if (usePickupStart) {  
        columnValues.push(true)
      } else {
        columnValues.push(false)
      }
    }

    if (tableTimeSettings && tableTimeSettings['pickupEnd']) {
      columnNames.push(tableTimeSettings['pickupEnd']['columnNameTime'])
      if (usePickupEnd) {
        columnValues.push(true)
      } else {
        columnValues.push(false)
      }
    }

    if (tableTimeSettings && tableTimeSettings['dropoffExact']) {
      columnNames.push(tableTimeSettings['dropoffExact']['columnNameTime'])
      if (useDropoffExact) {
        columnValues.push(true)
        columnNames.push(tableTimeSettings['dropoffExact']['columnNameInt'])
        columnValues.push(false)
      } else {
        columnValues.push(false)
      }
    }

    if (tableTimeSettings && tableTimeSettings['dropoffStart']) {
      columnNames.push(tableTimeSettings['dropoffStart']['columnNameTime'])
      if (useDropoffStart) {  
        columnValues.push(true)
      } else {
        columnValues.push(false)
      }
    }

    if (tableTimeSettings && tableTimeSettings['dropoffEnd']) {
      columnNames.push(tableTimeSettings['dropoffEnd']['columnNameTime'])
      if (useDropoffEnd) {
        columnValues.push(true)
      } else {
        columnValues.push(false)
      }
    }
  }
  
  dispatch(updateTimeSettings({columnNames: columnNames, columnValues: columnValues, settingNames:['timeUnit'], settingValues:[value], tableName:props.tableName}));
}

const handleDayUnitChange = (event, value) => {
  dispatch(updateTimeSettings({settingNames:['dayUnit'], settingValues: [value], tableName:props.tableName}))
}

const handleUseTimeWindowsChange = (event, value) => {
  dispatch(updateTimeSettings({settingNames:['useTimeWindows'], tableName:props.tableName, settingValues:[!timeUseTimeWindows]}));
}

const handleTimePeriodSpanChange = (event, value) => {
  dispatch(updateTimeSettings({settingNames:['timePeriodSpansDays'], tableName:props.tableName, settingValues: [!timePeriodSpansDays]}));
}

const handleTimeWindowChange = (timeWindow, val) => {
  dispatch(updateTimeSettings({settingNames:[timeWindow], tableName:props.tableName, settingValues: [!val]}));
  var columnName;
  var offColumnName;
  if (tableTimeSettings) {
    if (timeWindow == 'usePickupEnd') {
      if (timeUnit == 'clock') { 
        columnName = tableTimeSettings['pickupEnd']['columnNameTime'];
        offColumnName = tableTimeSettings['pickupExact'] && [tableTimeSettings['pickupExact']['columnNameTime']];
      }
      else {
        columnName = tableTimeSettings['pickupEnd']['columnNameInt'];
        offColumnName = tableTimeSettings['pickupExact'] && [tableTimeSettings['pickupExact']['columnNameInt']];
      }
      if (!val) {
        if (!offColumnName) {
          dispatch(updateTimeSettings({settingNames:['usePickupExact'], tableName:props.tableName, settingValues: [false]}));
        }
        if (offColumnName) {
          dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [false], columnNames: offColumnName, settingNames:['usePickupExact'], settingValues:[false]}));
        }
      }
    }

   if (timeWindow == 'usePickupStart') {
      if (timeUnit == 'clock') {
        columnName = tableTimeSettings['pickupStart']['columnNameTime'];
        offColumnName = tableTimeSettings['pickupExact'] && [tableTimeSettings['pickupExact']['columnNameTime']];
      }
      else {
        columnName = tableTimeSettings['pickupStart']['columnNameInt'];
        offColumnName = tableTimeSettings['pickupExact'] && [tableTimeSettings['pickupExact']['columnNameInt']];
      }
      if (!val) {
        if (!offColumnName) {
          dispatch(updateTimeSettings({settingNames:['usePickupExact'], tableName:props.tableName, settingValues: [false]}));
        }
        if (offColumnName) {
          dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [false], columnNames: offColumnName, settingNames:['usePickupExact'], settingValues:[false]}));
        }
      }
    }

    if (timeWindow == 'usePickupExact') {
      if (timeUnit == 'clock') {
        columnName = tableTimeSettings['pickupExact']['columnNameTime'];
        offColumnName = [tableTimeSettings['pickupStart']['columnNameTime'], tableTimeSettings['pickupEnd']['columnNameTime']];
      }
      else { 
        
        columnName = tableTimeSettings['pickupExact']['columnNameInt'];
        offColumnName = [tableTimeSettings['pickupStart']['columnNameInt'], tableTimeSettings['pickupEnd']['columnNameInt']];
      }
      if (!val) {
        dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [false, false], columnNames: offColumnName, settingNames:['usePickupStart', 'usePickupEnd'], settingValues:[false, false]}));
      }
    }

    if (timeWindow == 'useDropoffEnd') {
      if (timeUnit == 'clock') { 
        columnName = tableTimeSettings['dropoffEnd']['columnNameTime'];
        offColumnName = tableTimeSettings['dropoffExact'] && [tableTimeSettings['dropoffExact']['columnNameTime']];
      }
      else {
        columnName = tableTimeSettings['dropoffEnd']['columnNameInt'];
        offColumnName = tableTimeSettings['dropoffExact'] && [tableTimeSettings['dropoffExact']['columnNameInt']];
      }
      if (!val) {
        if (!offColumnName) {
          dispatch(updateTimeSettings({settingNames:['useDropoffExact'], tableName:props.tableName, settingValues: [false]}));
        }
        if (offColumnName) {
          dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [false], columnNames: offColumnName, settingNames:['useDropoffExact'], settingValues:[false]}));
        }
      }
    }
    
    if (timeWindow == 'useDropoffStart') {
      if (timeUnit == 'clock') {
        columnName = tableTimeSettings['dropoffStart']['columnNameTime'];
        offColumnName = tableTimeSettings['dropoffExact'] && [tableTimeSettings['dropoffExact']['columnNameTime']];
      }
      else {
        columnName = tableTimeSettings['dropoffStart']['columnNameInt'];
        offColumnName = tableTimeSettings['dropoffExact'] && [tableTimeSettings['dropoffExact']['columnNameInt']];
      }
      if (!val) {
        if (!offColumnName) {
          dispatch(updateTimeSettings({settingNames:['useDropoffExact'], tableName:props.tableName, settingValues: [false]}));
        }
        if (offColumnName) {
          dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [false], columnNames: offColumnName, settingNames:['useDropoffExact'], settingValues:[false]}));
        }
      }
    }

    if (timeWindow == 'useDropoffExact') {
      if (timeUnit == 'clock') {
        columnName = tableTimeSettings['dropoffExact']['columnNameTime'];
        offColumnName = [tableTimeSettings['dropoffStart']['columnNameTime'], tableTimeSettings['dropoffEnd']['columnNameTime']];
      }
      else { 
        columnName = tableTimeSettings['dropoffExact']['columnNameInt'];
        offColumnName = [tableTimeSettings['dropoffStart']['columnNameInt'], tableTimeSettings['dropoffEnd']['columnNameInt']];
      }
      if (!val) {
        dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [false, false], columnNames: offColumnName, settingNames:['useDropoffStart', 'useDropoffEnd'], settingValues:[false, false]}));
      }
    }

    dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [!val], columnNames: [columnName]}))
  }
  
  }

  useEffect(() => {
    if (!ogCostMetricsSet) {
      setOgCostMetricsSet(true);
      setOgCostMetrics(JSON.parse(JSON.stringify(ogCostMetricsSelector)))
    }
  })


  var topDivStyle = {backgroundColor:theme.palette.white.main, border:'solid ' + theme.palette.primary.main, borderWidth: '2px 2px 2px 2px', borderRadius:'1rem 1rem 1rem 1rem', margin:'15px 15px 15px 15px'}
  if (tableTimeSettings && (tableTimeSettings['dropoffEnd'] || tableTimeSettings['dropoffStart'] || tableTimeSettings['dropoffExact'])) {
    topDivStyle = {backgroundColor:theme.palette.white.main, border:'solid ' + theme.palette.primary.main, borderWidth:'2px 2px 0px 2px', borderRadius:'1rem 1rem 0rem 0rem', margin:'15px 15px 0px 15px'}
  }

  return (<>
    {tableTimeSettings && <Dialog onClose={handleClose} onClick={(event) => event.stopPropagation()} open={open}>
      <DialogTitle>Select time settings</DialogTitle>
      <DialogContent >
        <div style={{minHeight:'300px'}}>
      <>
          <Stack direction="row" spacing={2} style={{padding:'5px'}}>
            <FormControlLabel control={<Checkbox checked={timeUseTimeWindows} onChange={handleUseTimeWindowsChange}/>} 
            label={tableTimeSettings && tableTimeSettings['description']}/>
          </Stack>
        </>
        <Collapse in={timeUseTimeWindows} unmountOnExit={true}>
                <FormControl>
                <FormLabel style={{margin:'0px'}} id="distance-calculation-group-label">Select time type</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="distance-calculation-group-label"
                  name="row-radio-buttons-group"
                  onChange={handleTimeUnitChange}
                  value={timeUnit}
                  style={{margin:'0px'}}
                >
                  <FormControlLabel value="clock" control={<Radio />} label="Clock time (i.e. 11:00 am)" />
                  <FormControlLabel value="integer" control={<Radio />} label="Integer (i.e. 660 minutes)" />
                  
                </RadioGroup>
                </FormControl>
        </Collapse>
        <Collapse in={timeUseTimeWindows && (timeUnit == 'clock')} unmountOnExit>
            <Stack direction="row" spacing={2} style={{padding:'5px'}}>
            <FormControlLabel control={<Checkbox defaultChecked={timePeriodSpansDays} onChange={handleTimePeriodSpanChange}/>} label="Time periods span multiple days"/>
          </Stack>        
        </Collapse>

        <Collapse in={timeUseTimeWindows} unmountOnExit>
          <Stack direction="row" spacing={2} style={{padding:'5px'}}>
            <FormGroup>
              <FormLabel style={{margin:'0px'}} id="distance-calculation-group-label">Time Window Usage</FormLabel>
              <div style={topDivStyle}>
                <FormControlLabel style={{margin:'0px'}}
                  control={<Checkbox style={{color:theme.palette.primary.main}} checked={usePickupEnd} onChange={() => handleTimeWindowChange('usePickupEnd', usePickupEnd)}/>} 
                  label={tableTimeSettings['pickupEnd']['description']} />
                <FormControlLabel style={{margin:'0px'}}
                  control={<Checkbox style={{color:theme.palette.primary.main}} checked={usePickupStart} onChange={() => handleTimeWindowChange('usePickupStart', usePickupStart)}/>} 
                  label={tableTimeSettings['pickupStart']['description']} />
                {tableTimeSettings['pickupExact'] && <FormControlLabel style={{margin:'0px'}}
                  control={<Checkbox style={{color:theme.palette.primary.main}} checked={usePickupExact} onChange={() => handleTimeWindowChange('usePickupExact', usePickupExact)}/>} 
                  label={tableTimeSettings['pickupExact']['description']} />}
              </div>
              {(tableTimeSettings['dropoffEnd'] || tableTimeSettings['dropoffStart'] || tableTimeSettings['dropoffExact']) && 
              <div style={{backgroundColor:theme.palette.primaryComplement.light, border:'solid ' + theme.palette.primaryComplement.dark, borderWidth:'0px 2px 2px 2px', borderRadius:'0rem 0rem 1rem 1rem', margin:'0px 15px 15px 15px'}}>
              {tableTimeSettings['dropoffEnd'] && <FormControlLabel style={{margin:'0px'}}
                  control={<Checkbox style={{color:theme.palette.primaryComplement.dark}} checked={useDropoffEnd} onChange={() => handleTimeWindowChange('useDropoffEnd', useDropoffEnd)}/>} 
                  label={tableTimeSettings['dropoffEnd']['description']} />}
                {tableTimeSettings['dropoffStart'] && <FormControlLabel style={{margin:'0px'}}
                  control={<Checkbox style={{color:theme.palette.primaryComplement.dark}} checked={useDropoffStart} onChange={() => handleTimeWindowChange('useDropoffStart', useDropoffStart)}/>} 
                  label={tableTimeSettings['dropoffStart']['description']} />}
                {tableTimeSettings['dropoffExact'] && <FormControlLabel style={{margin:'0px'}}
                  control={<Checkbox style={{color:theme.palette.primaryComplement.dark}} checked={useDropoffExact} onChange={() => handleTimeWindowChange('useDropoffExact', useDropoffExact)}/>} 
                  label={tableTimeSettings['dropoffExact']['description']} />}                
              </div>}
            </FormGroup>
          </Stack>        
        </Collapse>
      </div>
      <Stack direction='column'>
          </Stack>
          <Stack direction='column'>
            <Grid container >
                <Grid item xs={12} sm={8}>
                    <MyMuiButton variant={costMetric ? "contained": "outlined"} color={'primary'}
                        onClick={(event) => {event.stopPropagation(); handleClose()}} fullWidth
                        >
                        Save and close
                    </MyMuiButton>
                </Grid>
                <Grid item xs={12} sm={4} padding={'0px'}>
                    <MyMuiButton variant={"contained"} color='warning'
                            onClick={(event) => {event.stopPropagation(); handleCancel()}}
                            fullWidth 
                    >
                      Cancel
                    </MyMuiButton>                
                </Grid>
            </Grid>
        </Stack>
        </DialogContent>
    </Dialog>}</>
  );
}

TimeSettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired
};


