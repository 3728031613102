const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']

//json file should have locations and costss
async function updateRunName(run_id, newName) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ 'run_id': run_id, 'new_name': newName })
    };
    var response
    try {
        response = await fetch(BASE_ROUTE + '/update_run_name', requestOptions)
        if (!response.ok) {
            throw 'Invalid response received from get_run_summaries_by_user'
        }
        const retJson = await response.json()
        return retJson
    } catch (err) {
        throw err
    }
    

}

export default updateRunName