const componentStyles = (theme) => ({
  badgeContainedDefault: {
    backgroundColor: theme.palette.dark.main,
    borderColor: theme.palette.dark.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.dark.dark,
      borderColor: theme.palette.dark.dark,
    },
  },
  badgeContainedPrimary: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
  badgeContainedInfo: {
    backgroundColor: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
      borderColor: theme.palette.info.dark,
    },
  },
  badgeContainedSuccess: {

    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      borderColor: theme.palette.success.dark,
    },
  },
  badgeContainedError: {
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
    },
  },
  badgeContainedWarning: {
    borderWidth:'3px',
    borderStyle: 'solid',
    backgroundColor: theme.palette.white.main,
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
      borderColor: theme.palette.warning.dark,
    },
  },
  badgeContainedGray: {

    '& .MuiBadge-badge': {
      position:'relative',
      transform:'none',
      borderWidth:'2px',
      borderStyle: 'solid',
      backgroundColor: theme.palette.badgeGray.backgroundColor,
      borderColor: theme.palette.badgeGray.backgroundColor,
      color: theme.palette.badgeGray.color,
      "&:hover": {
      backgroundColor: theme.palette.badgeGray.backgroundColor,
      borderColor: theme.palette.badgeGray.borderColorHover,
      },
    },
  },
  badgeContainedSuccessRelative: {
    '& .MuiBadge-badge': {
      position:'relative',
      transform:'none',
      borderWidth:'2px',
      borderStyle: 'solid',
      backgroundColor: theme.palette.success.main,
      borderColor: theme.palette.success.main,
      color: theme.palette.white.main,
      "&:hover": {
      backgroundColor: theme.palette.success.dark,
      borderColor: theme.palette.success.main,
      },
    },
  },
  badgeOutlinePrimary: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main
    },
  },
  badgeOutlineDefault: {
    color: theme.palette.dark.main,
    borderColor: theme.palette.dark.main,
    "&:hover": {
      backgroundColor: theme.palette.dark.main,
      color: theme.palette.white.main
    },
  },
  badgeOutlineInfo: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    "&:hover": {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.white.main
    },
  },
  badgeOutlineSuccess: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.white.main
    },
  },
  badgeOutlineError: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.white.main
    },
  },
  badgeOutlineWarning: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.white.main
    },
  },
  badgeContainedPrimaryGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.primary.main +
      ",#2dcecc)!important",
    border:'2px solid',
    borderColor: 'inherit',
    color: theme.palette.white.main,
    "&:hover": {
      background:
      "linear-gradient(87deg," +
      theme.palette.primary.main +
      ",#2dcecc)!important",
      borderColor: theme.palette.primary.main,
    },
  },
  badgeContainedWarningGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.warning.main +
      "," + theme.palette.warning.gradientEnd + ")!important",
    border:'2px solid',
    borderColor: 'inherit',
    color: theme.palette.white.main,
    "&:hover": {
      background:
      "linear-gradient(87deg," +
      theme.palette.warning.main +
      "," + theme.palette.warning.gradientEnd + ")!important",
      borderColor: theme.palette.warning.main,
      color: theme.palette.white.main
    },
  },
  badgeContainedErrorGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.error.main +
      "," + theme.palette.error.gradientEnd + ")!important",
    border:'2px solid',
    borderColor: 'inherit',
    color: theme.palette.white.main,
    "&:hover": {
      background:
      "linear-gradient(87deg," +
      theme.palette.error.main +
      "," + theme.palette.error.gradientEnd + ")!important",
      borderColor: theme.palette.error.main,
      color: theme.palette.white.main
    },

  },
  badgeContainedSuccessGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.success.main +
      "," + theme.palette.success.gradientEnd  + ")!important",
    border:'2px solid',
    borderColor: 'inherit',
    color: theme.palette.white.main,
    "&:hover": {
      background:
      "linear-gradient(87deg," +
      theme.palette.success.main +
      "," + theme.palette.success.gradientEnd  + ")!important",
      borderColor: theme.palette.success.dark,
      color: theme.palette.white.main
    },
  },
  badgeContainedInfoGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      "," + theme.palette.info.gradientEnd + ")!important",
    border:'2px solid',
    borderColor: 'inherit',
    color: theme.palette.white.main,
    "&:hover": {
      background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      ","  + theme.palette.info.gradientEnd + ")!important",
      borderColor: theme.palette.info.dark,
      color: theme.palette.white.main
    },
  },
  badgeContainedDefaultGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.dark.main +
      ",#2dcecc)!important",
    border:'2px solid',
    borderColor: 'inherit',
    color: theme.palette.white.main,
    "&:hover": {
      background:
      "linear-gradient(87deg," +
      theme.palette.dark.main +
      ",#2dcecc)!important",
      borderColor: theme.palette.dark.main,
      color: theme.palette.white.main
    },
  },
  badgeActive: {
    backgroundColor: theme.palette.dark.dark,
    borderColor: theme.palette.dark.dark,
  },
  badgeActivePrimary: {
    backgroundColor: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
  },
  badgeActiveSecondary: {
    backgroundColor: theme.palette.secondary.btnActive,
    borderColor: theme.palette.secondary.btnActive,
  },
  badgeActiveInfo: {
    backgroundColor: theme.palette.info.dark,
    borderColor: theme.palette.info.dark,
  },
  badgeActiveSuccess: {
    backgroundColor: theme.palette.success.dark,
    borderColor: theme.palette.success.dark,
  },
  badgeActiveError: {
    backgroundColor: theme.palette.error.dark,
    borderColor: theme.palette.error.dark,
  },
  badgeActiveWarning: {
    backgroundColor: theme.palette.warning.dark,
    borderColor: theme.palette.warning.dark,
  },
  badgeDisabled: {
    opacity: ".65",
    boxShadow: "none!important",
    pointerEvents: "none",
  },
  butttonInfoContainedGroup: {
    backgroundColor: theme.palette.info.main + "!important",
    borderColor: theme.palette.info.main + "!important",
    color: theme.palette.white.main + "!important",
    "&:hover": {
      backgroundColor: theme.palette.info.dark + "!important",
      borderColor: theme.palette.info.dark + "!important",
    },
  },
  // use this when working with small badges
  letterSpacingInherit: {
    letterSpacing: "inherit",
  },
  badgeContainedFacebook: {
    backgroundColor: theme.palette.facebook.main,
    borderColor: theme.palette.facebook.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.facebook.dark,
      borderColor: theme.palette.facebook.dark,
    },
  },
  badgeContainedTwitter: {
    backgroundColor: theme.palette.twitter.main,
    borderColor: theme.palette.twitter.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.twitter.dark,
      borderColor: theme.palette.twitter.dark,
    },
  },
  badgeContainedGoogle: {
    backgroundColor: theme.palette.google.main,
    borderColor: theme.palette.google.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.google.dark,
      borderColor: theme.palette.google.dark,
    },
  },
  badgeContainedInstagram: {
    backgroundColor: theme.palette.instagram.main,
    borderColor: theme.palette.instagram.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.instagram.dark,
      borderColor: theme.palette.instagram.dark,
    },
  },
  badgeContainedPinterest: {
    backgroundColor: theme.palette.pinterest.main,
    borderColor: theme.palette.pinterest.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.pinterest.dark,
      borderColor: theme.palette.pinterest.dark,
    },
  },
  badgeContainedYoutube: {
    backgroundColor: theme.palette.youtube.main,
    borderColor: theme.palette.youtube.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.youtube.dark,
      borderColor: theme.palette.youtube.dark,
    },
  },
  badgeContainedVimeo: {
    backgroundColor: theme.palette.vimeo.main,
    borderColor: theme.palette.vimeo.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.vimeo.dark,
      borderColor: theme.palette.vimeo.dark,
    },
  },
  badgeContainedSlack: {
    backgroundColor: theme.palette.slack.main,
    borderColor: theme.palette.slack.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.slack.dark,
      borderColor: theme.palette.slack.dark,
    },
  },
  badgeContainedDribbble: {
    backgroundColor: theme.palette.dribbble.main,
    borderColor: theme.palette.dribbble.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.dribbble.dark,
      borderColor: theme.palette.dribbble.dark,
    },
  },
  badgeContainedGithub: {
    backgroundColor: theme.palette.github.main,
    borderColor: theme.palette.github.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.github.dark,
      borderColor: theme.palette.github.dark,
    },
  },
  badgeIconOnly: {
    width: "2.375rem",
    height: "2.375rem",
    padding: "0",
    minWidth: "unset",
  },
});

export default componentStyles;
