// validators
import latitudeValidator from 'views/admin/Forms/functions/latitude_validator.js'
import longitudeValidator from 'views/admin/Forms/functions/longitude_validator.js'
import uniqueValidator from 'views/admin/Forms/functions/unique_validator.js'
import numericValidator from 'views/admin/Forms/functions/numeric_validator.js'

const validatorFromName = (validatorName) => {
    
    if (validatorName == 'latitude') {
      const retFunc = (args) => {
        return latitudeValidator(args);
      }
      return retFunc
    }
  
    else if (validatorName == 'longitude') {
      const retFunc = (args) => {
        return longitudeValidator(args);
      }
      return retFunc
    }
  
    else if (validatorName == 'unique') {
      const retFunc = (args) => {
        return uniqueValidator(args);
      }
      return retFunc
    }
  
    else if (validatorName == 'numeric') {
      const retFunc = (args) => {
        return numericValidator(args)
      }
      return retFunc
    }

    else if (validatorName == 'non-negative') {
      const retFunc = (args) => {
        args['requireNonNegative'] = true;
        return numericValidator(args)
      }
      return retFunc      
    }

    else if (validatorName == 'non-negative integer') {
      const retFunc = (args) => {
        args['requireInteger'] = true;
        args['requireNonNegative'] = true;
        return numericValidator(args)
      }
      return retFunc      
    }
  
    else {
      const retFunc = (args) => {
        return {valid:true}
      }
      return retFunc
    }
  
    const retFunc = (newValue) => {
      return latitudeValidator(newValue);
    }
    // return latitudeValidator(newValue);
    return retFunc;
  
    if (validatorName == 'id') {
      return {valid:true}
    }
  
  
    if (validatorName == 'boolean') {
      return {valid:true}
    }
  
    if (validatorName == 'text') {
      return {valid:true}
    }
  }

export default validatorFromName;