import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    marginBottom: "30px",
    transition: "all .15s ease",
    boxShadow: boxShadows.boxShadow,
    // "&:hover": {
    //   transform: "translateY(-20px)",
    // },
  },
  redBgGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.error.main +
      "," + theme.palette.error.gradientEnd + ")!important",
  },
  errorBgGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.error.main +
      "," + theme.palette.error.gradientEnd + ")!important",
  },
  blueBgGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      "," + theme.palette.info.gradientEnd + ")!important",
  },
  infoBgGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      "," + theme.palette.info.gradientEnd + ")!important",
  },
  greenBgGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.success.main +
      "," + theme.palette.success.gradientEnd + ")!important",
  },
  successBgGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.success.main +
      "," + theme.palette.success.gradientEnd + ")!important",
  },
  orangeBgGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.warning.main +
      "," + theme.palette.warning.gradientEnd + ")!important",
  },
  warningBgGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.warning.main +
      "," + theme.palette.warning.gradientEnd + ")!important",
  },
  primaryBgGradient: {
    background:
      "linear-gradient(87deg," +
      theme.palette.primary.main +
      "," + theme.palette.primary.gradientEnd + ")!important",
  },
  redText: {
    color: theme.palette.error.main,
  },
  blueText: {
    color: theme.palette.info.main,
  },
  greenText: {
    color: theme.palette.success.main,
  },
  orangeText: {
    color: theme.palette.warning.main,
  },
  title: {
    textTransform: "uppercase",
  },
  marginRight2: {
    marginRight: ".5rem",
  },
});

export default componentStyles;
