//https://www.ag-grid.com/react-data-grid/provided-cell-editors/#example---provided-cell-editors

import React from 'react';

export default (props) =>
  props.value != null && (
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>
      <span
        style={{
          borderLeft: '10px solid ' + props.value,
          paddingRight: '5px',
        }}
      ></span>
      {props.reverseColors && props.reverseColors[props.value] || props.value}
    </div>
  );