import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import MyGradientIcon from 'views/admin/Components/MyComponents/MyGradientIcon.js'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MyButton from 'views/admin/Components/MyComponents/MyButton.js'
import CircularProgress from '@mui/material/CircularProgress';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import SettingsIcon from '@mui/icons-material/Settings';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';

import Tooltip from '@mui/material/Tooltip';
import {
    solveOptimization,
    setOptimizationPending,
    getOptimizationPending,
    selectActiveResultsShape,
    addNotification,
    dashboardIsValid
  } from 'store/reducers/inputReducer';

const iconStyle = {height:'2rem', width:'2rem'}

const MAX_TOOLTIP_CHARS = 200;
const OptimizeButton = (props) => {
    const isLoading = useSelector(getOptimizationPending);
    const activeResultsShape = useSelector(selectActiveResultsShape);
    const dispatch = useDispatch();
    const buttonText = props.buttonText || isLoading ? 'Running...' : activeResultsShape > 0 ? 'Re-run optimization' : 'Run optimization'
    const tableIsValid = useSelector(dashboardIsValid);

    const handleOptimizeClick = () => {
      if (tableIsValid.valid) {
          dispatch(setOptimizationPending({'pending': true}));
          dispatch(solveOptimization());
      } else {
        dispatch(addNotification({
          notificationMsg: 'Unable to run optimization until table errors are fixed.', 
          notificationType:'error'
          }));
      }
    }
    console.log('tableIsValid', JSON.stringify(tableIsValid))
    return (<>
    {
      props.simple &&    
      // <Tooltip >    
      <MyButton label='Run Optimization' variant='contained' color={tableIsValid.valid ? 'success': 'error'}
      buttonProps={{ onClick: handleOptimizeClick}} buttonStyle={{whiteSpace:'nowrap'}}
        startIcon={<>
          {isLoading && <SettingsIcon style={{...iconStyle, animation: "spin 3s linear infinite"}}/>}

          {!isLoading && <>
          {activeResultsShape > 0 ? <ReplayCircleFilledIcon style={iconStyle} />
          : <PlayCircleFilledIcon style={iconStyle}/>}
          </>}   
          
          </>
        }
        tooltipTitle={tableIsValid.valid ? '' : <>
        <ul style={{margin:'0px', padding:'5px', justifyContent:'left', alignItems:'left'}}>
        {tableIsValid.invalidMessages.map((elem, idx) => {
          if (elem.length > MAX_TOOLTIP_CHARS) {
            elem = elem.substring(0, MAX_TOOLTIP_CHARS) + '...';
          }
          return <li style={{}}>{elem}</li>
        })}
        </ul></>}
      />
    }
    
    {!props.simple && !props.collapsed && (
    <Tooltip title={tableIsValid.valid ? '' : <>
      <ul style={{margin:'0px', padding:'5px', justifyContent:'left', alignItems:'left'}}>
      {tableIsValid.invalidMessages.map((elem, idx) => {
        if (elem.length > MAX_TOOLTIP_CHARS) {
          elem = elem.substring(0, MAX_TOOLTIP_CHARS) + '...';
        }
        return <li style={{}}>{elem}</li>
      })}
      </ul></>}
      placement='bottom'>
        <MyButton variant='contained' color={tableIsValid.valid ? 'success' : 'error'} fullWidth={false} gradient={true}
        onClick={handleOptimizeClick} buttonProps={{ onClick: handleOptimizeClick}}
        startIcon={<>
          {isLoading && <SettingsIcon style={{...iconStyle, animation: "spin 3s linear infinite"}}/>}

          {!isLoading && <>
          {activeResultsShape > 0 ? <ReplayCircleFilledIcon style={iconStyle} />
          : <PlayCircleFilledIcon style={iconStyle}/>}
          </>}   
          
          </>
          }
        style={{height:'4rem'}}
        >
          {buttonText} 
        </MyButton>
    </Tooltip>)}
    {!props.collapsed && props.collapsed && (
    <div >
    <Tooltip title={activeResultsShape > 0 ? 'Re-run optimization' : 'Run optimization'} placement='right'>
    <div id='run-button-collapsed-div' style={{textAlign: 'center', flexWrap: 'wrap', overflowX:'hidden'}}>
        
    <MyGradientIcon 
    icon={isLoading  ? <SettingsIcon style={{animation: "spin 4s linear infinite", height:'100%', width:'100%'}}/> : 
      activeResultsShape > 0 ? <ReplayCircleFilledIcon style={{height:'100%', width:'100%'}} /> : <PlayArrowIcon style={{height:'100%', width:'100%'}}/>}
     iconStyleComponent={isLoading ? null : null}
     onClick={handleOptimizeClick} color={tableIsValid.valid ? 'green': 'red'}
      {...props.iconProps}
     />

           {isLoading && <CircularProgress
            // size={68}
            style={{height:props.iconProps && props.iconProps.height || '56px', width:props.iconProps && props.iconProps.width || '56px'}}
            sx={{
              color: 'green',
              position: 'absolute',
              top: 11,
              left: 0,
              zIndex: 1,
              height: '100%',
              width:'100%',
              ...props.spinnerStyle
            }}
          />}
            </div>
    </Tooltip>

    </div>)}
    <style>{`
        @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
        }
        `}
    </style>
    </>)

};

export default OptimizeButton;