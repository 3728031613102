import numericValidator from 'views/admin/Forms/functions/numeric_validator.js'
// require("views/admin/Tables/functions/numeric_validator.js");

export default function latitudeValidator (args) {
  const newValue = args.newValue;
  const numericValidation = numericValidator({...args})
  if (!numericValidation.valid) {
    return numericValidation
  }
  if ((newValue >= -90 && newValue <= 90) ) {
    return {valid: true}
  }
  return {
    valid: false,
    message: 'Latitude input must be between -90 and 90'
  }
}

