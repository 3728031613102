import boxShadows from "assets/theme/box-shadow.js";

const marginTopBottom = '5px';

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    margin: '0px'
  },
  accordionSummary: {
    borderRadius:'0.375rem',
    border: '1px solid',
    margin:'2px',
    marginTop: marginTopBottom,
    marginBottom: marginTopBottom,
    padding: '2px',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      border: '3px solid',
      padding:'0px',
      borderColor: theme.palette.primary.extraLight
    }
  },
  accordionSummary_warning: {
    '&:hover': {
      backgroundColor: theme.palette.warning.extraLight,
    }
  },
  accordionSummary_dark: {
    '&:hover': {
      backgroundColor: theme.palette.dark.extraLight,
    }
  },
  accordionSummary_success: {
    '&:hover': {
      backgroundColor: theme.palette.success.extraLight
    }
  },
  accordionSummary_info: {
    '&:hover': {
      backgroundColor: theme.palette.info.extraLight
    }
  },
  accordionSummary_error: {
    '&:hover': {
      backgroundColor: theme.palette.error.extraLight
    }
  },
  accordionSummary_primary: {
    '&:hover': {
      backgroundColor: theme.palette.primary.extraLight
    }
  },

});

export default componentStyles;
