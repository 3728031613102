import { useState, useRef } from "react";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import Chip from '@mui/material/Chip';
import { IconButton } from '@mui/material';

import TextField from '@mui/material/TextField';

import FormControl from '@mui/material/FormControl';

const ImageUploadIcon = (props) => {
    const [selectedChips, setSelectedChips] = useState([]);
    const [updateNum, setUpdateNum] = useState(0)
    const inputRef = useRef(null);

    const handleUploadClick = event => {
        if (event.target.files.length === 0) {}
        else {
            const label = event.target.files[0].name;
            props.selectedFileNames.push(label);
            props.selectedFiles.push(event.target.files[0]);
            selectedChips.push(
                <Chip label={String(label)} variant="outlined"
                    onDelete={(event) => handleDelete(event, String(label))} style={{margin:'2px'}}
                />)
            setUpdateNum(updateNum + 1);
            event.stopPropagation();
        }
      };


    const handleDelete = (event, label) => {
        if (props.selectedFiles.length === 1) {
            props.setSelectedFiles([]);
            setSelectedChips([]);
            props.setSelectedFileNames([]);
            return;
        }
        const delIndex = props.selectedFileNames.indexOf(label);
        props.selectedFileNames.splice(delIndex, 1);
        props.selectedFiles.splice(delIndex, 1);
        selectedChips.splice(delIndex, 1);
        setUpdateNum(updateNum + 1);
    }
    

    return (<>
            <FormControl variant="standard" 
                style={{paddingTop:'5px', maxWidth:'100%', width:'100%', overflowY:'scroll'}}
                onClick={() => inputRef.current.click()}
            > 
                <TextField
                label={'Upload screenshot'}
                id="input-with-icon-adornment"
                variant="outlined"
                InputProps={{startAdornment:<>
                    <div style={{display:'flex', flexWrap:'wrap'}}>
                    <IconButton >
                        <AddPhotoAlternateIcon style={{height:'1.6rem', width:'1.6rem'}} onClick={() => inputRef.current.click()}/>
                    </IconButton>
                    <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}} key={updateNum}>
                        {selectedChips}
                    </div>
                    
                    </div>
                    </>
                , style:{minWidth:'150px'},
                sx:{'& .MuiInputBase-input': {'maxWidth':'0px'}}
                }}
                />
            </FormControl>
            <input
                ref={inputRef}
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={(event) => {handleUploadClick(event)}}
                hidden={true}
            />

    </>
    )
}

export default ImageUploadIcon