/**
 * This component is a button that allows the user to filter the layers on the map.
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Popover from '@mui/material/Popover';
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import LayersIcon from '@mui/icons-material/Layers';
import Tooltip from "@material-ui/core/Tooltip";
import LayerFilterGroup from "views/admin/Maps/MapFilters/LayerFilterGroup";


import componentStyles from "assets/theme/components/cards/dashboard/card-add-data.js";

const useStyles = makeStyles(componentStyles);

const MAX_WIDTH = '250px'

export default function LayerFilterButton(props) {

  const classes = useStyles();
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [layerAnchorEl, setLayerAnchorEl] = React.useState(null);
  const layerOpen = Boolean(layerAnchorEl);
  const layerId = layerOpen ? 'layer-simple-popover' : undefined;


  const handleLayerClick = (event) => {
    setLayerAnchorEl(event.currentTarget);
  };

  const handleLayerClose = () => {
    setLayerAnchorEl(null);
  };

  return (<>
    <Tooltip title='Layer filters'>
        <Button style={{zIndex:'3'}}
            size="small" variant="contained" onClick={handleLayerClick} aria-describedby={layerId}>
                <Box
                component={LayersIcon}
                position="relative"
                
                ></Box>
            </Button>
    </Tooltip>
    <Popover
    id={id}
    open={layerOpen}
    anchorEl={layerAnchorEl}
    onClose={handleLayerClose}
    anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
    }}
    transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
    }}
    >
        <Card classes={{ root: classes.cardRoot }} style={{margin:'0px', maxWidth:MAX_WIDTH}}>
            <CardHeader
            style={{padding: '5px'}}
            className={classes.cardHeader}
            title="Toggle Layers"
            titleTypographyProps={{
                component: Box,
                marginBottom: "0!important",
                variant: "h3",
            }}
            ></CardHeader>
            <CardContent style={{padding: '5px'}}>
            <LayerFilterGroup />
            </CardContent>
        </Card>
    </Popover>
    </>
  );
}