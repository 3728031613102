import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from '@mui/material/Grid';

import componentStyles from "assets/theme/components/cards/dashboard/card-map-options.js";

import OptimizationChoice from "views/admin/Forms/Cost/OptimizationChoice.jsx";

const useStyles = makeStyles(componentStyles);
const costSettingParams = require('store/data/cost_setting_params.json')

export default function WizardOptimizationChoices() {
    const classes = useStyles();

    return (<>
    <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
            <Card classes={{ root: classes.collapsibleCard }}>
                <CardHeader classes={{ root: classes.collapsibleCardHeader }}       
                > 
                </CardHeader>
                <CardContent classes={{ root: classes.collapsibleCardContent }} >
                    <OptimizationChoice 
                        costMetric={'distance'}
                        optimizationLabel={costSettingParams['distance']['optimizationLabel']}
                        tooltipTitle={costSettingParams['distance']['tooltipTitle']}
                    />
                    <OptimizationChoice 
                       costMetric={'duration'}
                       optimizationLabel={costSettingParams['duration']['optimizationLabel']}
                        tooltipTitle={costSettingParams['duration']['tooltipTitle']} />
                </CardContent>
            </Card>
        </Grid>

    </Grid>
    </>
    );
}