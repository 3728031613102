import React, {useState} from "react";
import { useSelector } from 'react-redux';


import 'material-icons/iconfont/material-icons.css';
import MyBadge from 'views/admin/Components/MyComponents/MyBadge.js'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import NewFormEntryDialog from 'views/admin/Forms/NewFormEntryDialog.js';
import ExitExampleModeDialog from 'views/admin/Notifications/ExitExampleModeDialog.js'
import { useTheme } from "@material-ui/core/styles";
import {
    selectFormCanAddRow,
    getInExampleMode
  } from 'store/reducers/inputReducer';

const allStaticTableSettings = require('store/data/table_settings_static.json')

const NewFormEntryButton = (props) => {
    const formCanAddRow = useSelector(selectFormCanAddRow(props.dataTable));
    const staticTableSettings = allStaticTableSettings[props.dataTable];
    const inExampleMode = useSelector(getInExampleMode);
    const theme = useTheme();
    const [exampleDialogOpen, setExampleDialogOpen] = useState(false);
    
    const tooltipText = formCanAddRow.valid ? staticTableSettings['addRowTitle'] : formCanAddRow['message'];

    const exitExampleModeDialogClose = (success=false) => {
        setExampleDialogOpen(false);
        if (success) {
            setOpenForm(true);
        }
    }

    const [openForm, setOpenForm] = useState(false);
    const handleClickOpenForm = (event) => {
        event.stopPropagation();
        if (props.onOpenForm) {
            props.onOpenForm();
        }
        if (inExampleMode) {
            setExampleDialogOpen(true);
        } else {
            if (formCanAddRow.valid) {
                setOpenForm(true);
            }
        }
    };

    var buttonProps = props.buttonProps || {}
    if (!buttonProps.hasOwnProperty('color')) {
        buttonProps['color'] = "primary"
    }
    if (!buttonProps.hasOwnProperty('variant')) {
        buttonProps['variant'] = "contained"
    }
    if (!buttonProps.hasOwnProperty('size')) {
        buttonProps['size'] = "small"
    }


        return (<>
        {props.useBadge &&
            <MyBadge  {...props.badgeProps} badgeProps={{pill: true, onClick:(event) => handleClickOpenForm(event), style:{...props.badgeProps.style, zIndex:'0'}}}>
                {props.badgeLabel}
            </MyBadge>}
        {!props.useBadge && 
        <Tooltip title={tooltipText}>
        <IconButton id='new-form-entry-icon-button' disabled={!formCanAddRow} {...buttonProps} onClick={(event) => {event.stopPropagation(); handleClickOpenForm(event)}}>
            <AddCircleIcon style={formCanAddRow.valid ? {color:theme.palette.success.main} : {color:theme.palette.gray[500]}}
            />
        </IconButton>
        </Tooltip>
        }
        <NewFormEntryDialog tableName={props.dataTable} open={openForm} setOpenForm={setOpenForm} />
        <ExitExampleModeDialog onClose={exitExampleModeDialogClose} open={exampleDialogOpen}/>
        

        </>
    )
}


export default NewFormEntryButton;