import React from "react";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";


import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
// @material-ui/icons components
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LanguageIcon from '@mui/icons-material/Language';
import PricingIcon from 'components/Cards/Pricing/PricingIcon.js'
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import FunctionsIcon from '@mui/icons-material/Functions';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Notes from "@material-ui/icons/Notes";
import SchemaIcon from '@mui/icons-material/Schema';

// internal components
import CaptureEmailDialog from "views/admin/Components/Dialogs/CaptureEmailDialog";

// core components
import componentStyles from "assets/theme/components/cards/pricing/card-pricing-gradient.js";
import buttonsStyles from "assets/theme/components/button.js";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(buttonsStyles);

const icons = [
  <FunctionsIcon />, 
  <BatteryChargingFullIcon  fontSize='small' />,
  <LanguageIcon  fontSize='small'  />,
  <Notes />,
  <SaveAltIcon fontSize='small'/>, 
  <AutoFixHighIcon  fontSize='small' />,
  <SchemaIcon  fontSize='xs' />
]

const subtitles = [
  'All models included',
  'No limits on problem size',
  'Unlimited solves on our web app (up to 5 solves per minute)',
  'Access to online documentation and forum support',
  'Save unlimited models for later use',
  'Auto population travel times',
  'Access to our REST API'
]

function CardPricingGradientProfessional() {
  const classes = {
    ...useStyles(),
    ...useStylesButtons(),
  };
  const theme = useTheme();

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleClickOpen = () => {
    /**
     * This function is used to open the dialog.
     */
    setDialogOpen(true);
  }

  const handleClose = () => {
    /**
     * This function is used to close the dialog.
     */
    setDialogOpen(false);
  }

  return (
    <>
      <Card className={clsx(classes.cardRoot, classes.cardRootGradientInfo)}>
        <CardHeader
          title="Professional"
          className={classes.bgInherit}
          titleTypographyProps={{
            className: classes.titleWhite,
            component: "h4",
            variant: "h4",
          }}
          style={{paddingBottom:'0px'}}
        ></CardHeader>
        <CardContent className={classes.cardContentRoot} style={{paddingLeft:'3rem', paddingTop:'0px', paddingRight:'3rem'}}>
          <Box className={classes.display1} color={theme.palette.white.main}>
            <i>Coming soon</i>
          </Box>
          <Box component="span" color={theme.palette.white.main}>
            Join our waitlist
          </Box>
          <Box
            component="ul"
            paddingLeft="0"
            className={classes.listStyleNone}
            margin="1.5rem 0"
          >
          {icons.map((icon, idx) => {
            return <PricingIcon style={{padding:'0px'}} icon={icon} subtitle={subtitles[idx]} />
          })}
        </Box>
          <Box marginBottom="1rem" textAlign="center">
            <Button
              
              variant="contained"
              classes={{
                root: classes.buttonRootWhite,
              }}
              onClick={() => handleClickOpen()}
            >
              Join Waitlist
            </Button>
          </Box>
        </CardContent>
      </Card>
      <CaptureEmailDialog open={dialogOpen} handleClose={handleClose} 
      title={'Professional Plan Waitlist'}
      content={'Enter your information to join our Professional Plan waitlist'}
      useMessage={false}
      useOrganization={true}
      />
    </>
  );
}

export default CardPricingGradientProfessional;
