import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import InfiniteScroll from "react-infinite-scroller";
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import Box from "@material-ui/core/Box";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import componentStyles from "assets/theme/components/cards/dashboard/card-table-summary.js";
import componentStylesAccordion from "assets/theme/components/cards/dashboard/table-summary-list-items.js";
import componentStylesTypography from "assets/theme/components/typography.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";


import DataEntryForm from "views/admin/Forms/DataEntryForm.js"
import {
  selectTableData,
  selectRow,
  } from 'store/reducers/inputReducer';
import {
    getValueAt
} from 'store/functions/inputFunctions.js'
import getIconFromName from 'views/admin/Forms/icon_selector/icon_mapping.js'

const useStyles = makeStyles(componentStyles);
const useStylesTypography = makeStyles(componentStylesTypography);
const useStylesListItems = makeStyles(componentStylesAccordion)

const allStaticTableSettings = require('store/data/table_settings_static.json')

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

/**
 * helper function that generates each individual list item. A bit hacky in that I had to pull in the vehicle type data regardless
 * of the table name. Might be good to pull the vehicle type icon into a separate component to avoid doing this.
 * @param {*} props must have tableName, uuid
 */
function SummaryListItem(props) {
  const vehicleTypeData = useSelector(selectTableData('vehicle_types'));
  const staticTableSettings = allStaticTableSettings[props.tableName];
  const classes = {...useStyles(), ...useStylesTypography(), ...useStylesListItems()};
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  
  const buttonStyle = {
    color: theme.palette.warning.main,
    background: theme.palette.white.main,
    pointerEvents: 'all',
    boxShadow: 'none',
    '&:hover':{
        color: theme.palette.white.main,
        backgroundColor: theme.palette.warning.main,
        pointerEvents: 'all',
    }
  }

  const row = useSelector(selectRow(props.tableName, props.uuid)).value;
  if (!row) {
    return <></>
  }

  var iconFunctionName = staticTableSettings['summary']['iconFunctionName'];
  var headerName = staticTableSettings['summary']['headerName'];
  var headerField = staticTableSettings['summary']['headerField'];
  var subFields = staticTableSettings['summary']['subFields'];
  var icon = getIcon(iconFunctionName, {'dataRow': row});
  var iconColor = row['Color'] ? row['Color'] : row['Valid'] ? theme.palette.valid.main : theme.palette.invalid.main;

  function getIcon (functionName, functionArgs) {
    var icon;
    if (!functionArgs) {
      return icon
    }
    if (functionName === 'vehicleType') {
        if (!functionArgs['dataRow']) {
          return icon;
        }
        const vehicleType = functionArgs['dataRow']['Vehicle Type']
        const iconName = getValueAt(vehicleTypeData, null, ['Vehicle Type'], [vehicleType], 'Icon').value
        if (props.tableName == 'vehicles') {
        }
        try {
            icon = getIconFromName(iconName);
        } catch {
            icon = null;
        }
    }

    if (functionName === 'location') {
        icon = getIconFromName('LocationPin');
    }

    if (functionName === 'trip') {
        icon = getIconFromName('Trip');
    }

    if (functionName === 'travelCosts') {
        icon = getIconFromName('Cost');
    }

    return icon;
    }

    const handleCloseForm = () => {
      setOpenDialog(false);
      };

    const handleDialogClick = e => {
        e.stopPropagation();
    }; 

    /**
     * Formats the value in row, column to match what is in table settings
     * @param {Object} row The row to apply the formatting to
     * @param {*} columnName Name of the column to apply the formatting to
     */
    const getFormattedRowItem = (row, columnName) => {
      const dataType = staticTableSettings['dataType'][columnName];
      var formattedItem;
      if (dataType.substring(0,5) == 'float') {
        const numDecimals = dataType.substring(5);
        formattedItem = parseFloat(row[columnName]).toFixed(numDecimals)
      } else {
        formattedItem = row[columnName]
      }

      return formattedItem
    }


  return (
    <ListItem alignItems="flex-start" style={{ paddingLeft:'6px', paddingRight:'2px' }}
    classes={{root:classes['accordionSummary_' + staticTableSettings.summary.color]}}
    >
      <ListItemAvatar style={{margin:'0px'}}>
      <Avatar alt={"Vehicle" + row[headerField]}  
          style={{margin:'0px'}} 
          sx={{bgcolor: 'inherit', border: '2px solid', borderColor:iconColor, color: iconColor}} >
          {icon || <FiberManualRecordIcon />}
      </Avatar>
      </ListItemAvatar>
      <ListItemText style={{margin:'0px'}} primaryTypographyProps={{overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize:'0.8rem'}}
      primary={row[headerField] ? headerName + " " + row[headerField] : null}
      secondary={
          <React.Fragment>
              <div>
              {subFields.map((subField) => {
              return (
                  <>
                  <div id='subfield-div' style={{'marginTop':'-2px', 'lineHeight': '1'}}>
                  <Box
                      component="small"
                      fontSize="80%"
                      fontWeight="600"
                      color={theme.palette.gray[600]}
                      className={classes.textUppercase}
                  >
                      {subField + ":"}
                  </Box>
                  {(row[subField] || row[subField] == 0) && 
                  <Box
                      component="small"
                      fontSize="80%"
                      fontWeight="300"
                      color={theme.palette.gray[600]}
                      className={classes.textUppercase}
                  >
                      {" " + getFormattedRowItem(row, subField)}
                  </Box>}
                  </div>
                  </>)})}
              </div>
          </React.Fragment>
              
          }
          />
          {staticTableSettings['allowInsertRow'] &&
            <div style={{background:'inherit', marginRight:'2px'}}>
            <Tooltip title='Edit' >
              <IconButton aria-label="edit" sx={buttonStyle} onClick={(event) => {
                  event.stopPropagation();
                  setOpenDialog(true);
              }} size='xs' style={{marginTop:'-5px'}}>
                <EditIcon style={{height:'1rem', width:'1rem'}} />
                </IconButton>
            </Tooltip>
            </div>
            }

        <Dialog
            maxWidth="sm"
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted={false}
            onClose={handleCloseForm}
            onClick={handleDialogClick}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                sx: {
                  width: "auto",
                  maxWidth: "720px!important",
                  margin:'0px'
                },
                style: {width:'fit-content'}
              }}
            
            >
            <DialogContent style={{padding:'0px'}}>
                <DataEntryForm tableName={props.tableName} onClose={()=>{handleCloseForm()}} uuid={props.uuid}></DataEntryForm>
            </DialogContent>
        </Dialog> 

      </ListItem>
  )

}

/**
 * This component generates the scrollable list items for each element in the table 
 * @param {*} props : must have tableName
 */
function TableSummaryListItems(props) {
    const data = useSelector(selectTableData(props.tableName));
    const [activeData, setActiveData] = useState([]);

  useEffect(() => {
    handleFetch({ _limit: 10 });
  }, []);


  function handleFetch(params) {
    setActiveData(data.slice(0,params['_limit']));
  }

  return (
    <div style={{maxHeight: '300px', overflowY:'scroll'}} id='summary-list-items-div'>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => handleFetch({ _limit: activeData.length + 10 })}
        hasMore={activeData.length < data.length}
        useWindow={false}
        loader={
          <div key="loading" className="loader">
            Loading ...
          </div>
        }
      >
          {activeData.map((row, idx) => {
            var uuid = row['UUID']
            return (
            <>
            <SummaryListItem tableName={props.tableName} uuid={uuid} />
            <Divider  />
            </>
          )})}
      </InfiniteScroll>
      </div>
  );
}

export default TableSummaryListItems;
