import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Badge from '@mui/material/Badge';
import Tooltip from "@material-ui/core/Tooltip";

import componentStyles from "assets/theme/components/my_components/my-badge.js";

const useStyles = makeStyles(componentStyles);



const MyBadge = (props) => {

  const tooltipTitle = props.tooltipTitle || ''
  const classes = { ...useStyles()};

  const getBadgeClassName = () => {
      if (props.color == 'primary') {
        return {badge: classes.badgeContainedPrimary}
      }
      if (props.color == 'default') {
        return {badge: classes.badgeContainedDefault}
      }
      if (props.color == 'info' || props.color == 'blue') {
        return {badge: classes.badgeContainedInfo}
      }
      if (props.color == 'success' || props.color == 'green') {
        return {badge: classes.badgeContainedSuccess}
      }
      if (props.color == 'error') {
        return {badge: classes.badgeContainedError}
      }
      if (props.color == 'warning') {
        return {badge: classes.badgeContainedWarning}
      }
      if (props.color == 'gray') {
        return {root: classes.badgeContainedGray}
      }
      if (props.color == 'success-rel') {
        return {root: classes.badgeContainedSuccessRelative}
      }
    }
  

  return (<>
    
    <Tooltip title={tooltipTitle}>
    <Badge classes={getBadgeClassName()} badgeContent={props.badgeContent} overlap='circular' {...props.badgeProps}>
      {props.innerComponent}
    </Badge>
    </Tooltip>
    
    </>)
};

export default MyBadge;
