import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ImageIcon from '@mui/icons-material/Image';
import Tooltip from "@material-ui/core/Tooltip";

const MapDownloadIcon = React.memo(function MapZoomToFitButton(props) {
    return (
        <Tooltip title='Download map as image'>
            <Button style={{zIndex:'3'}}
            size="small" variant="contained" onClick={props.onClick}>
                <Box
                component={ImageIcon}
                position="relative"
                
                ></Box>
            </Button>
        </Tooltip>
    );
});

export default MapDownloadIcon;