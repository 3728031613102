import React from "react";
import Box from "@material-ui/core/Box";
import ColumnMappingButton from "./column-mapping/ColumnMappingButton";
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import AutoPopulateCostMatrixButton from 'views/admin/Buttons/AutoPopulateCostMatrixButton.jsx'
import NewFormEntryButton from 'views/admin/Forms/NewFormEntryButton.js';
import DownloadTableButton from 'views/admin/Buttons/DownloadTableButton.jsx'
import CostSettingsButton from 'views/admin/Buttons/CostSettingsButton.jsx'
import TimeSettingsButtonLocations from 'views/admin/Buttons/TimeSettingsButtonLocations.jsx'
import SampleUploadButton from 'views/admin/Buttons/SampleUploadButton.jsx';
import { Progress } from 'antd';

import ClearTableButton from 'views/admin/Buttons/ClearTableButton.jsx';
import {
  selectDataSize,
    selectActiveModelSettings,
    getInExampleMode,
    selectUserRowLimit
  } from 'store/reducers/inputReducer';
import { Tooltip } from "@mui/material";

const allStaticTableSettings = require('store/data/table_settings_static.json')

const boxProps = {display:"inline-block"}
const buttonProps = {}

const conicColors = {
  '0%': '#87d068',
  '50%': '#ffe58f',
  '100%': '#ffccc7'
};

function DataEntryTableSummary(props) {
    const tableSize = useSelector(selectDataSize(props.tableName), isEqual);
    const inExampleMode = useSelector(getInExampleMode);
    const modelSettings = useSelector(selectActiveModelSettings);
    const staticTableSettings = allStaticTableSettings[props.tableName];
    const rowDescriptor = tableSize == 1 ? tableSize + ' row': tableSize + ' rows';
    const autoPopulateCostsButton = staticTableSettings['autoPopulateCostsButton']
    const addItem = staticTableSettings['allowAddRow']
    const allowUpload = staticTableSettings['allowUpload']
    const maxRows = useSelector(selectUserRowLimit(props.tableName));

    return (<>
        <Box {...boxProps} style={{display:'flex', flexDirection:'row', flex:1}}>
          <Box {...boxProps} style={{marginRight:'1rem', width:'100px', textAlign:'left', marginTop:'auto', marginBottom:'auto'}}>
            {rowDescriptor}
          </Box>
          <div id='accordion-summary-button-box' style={{display:'flex', width:'250px', marginTop:'auto', marginBottom:'auto'}}>
          {addItem && <Box {...boxProps}><NewFormEntryButton dataTable={props.tableName} buttonProps={buttonProps} /></Box>}
          {!inExampleMode && allowUpload && <Box {...boxProps}><ColumnMappingButton label='Upload Data' tableName={props.tableName}></ColumnMappingButton></Box>}
          {!inExampleMode && allowUpload && tableSize == 0 && <SampleUploadButton tableName={props.tableName}/>}
          {tableSize > 0 && <DownloadTableButton downloadFile={props.downloadFile} />}
          {autoPopulateCostsButton && <CostSettingsButton tableName={props.tableName} />}
          {autoPopulateCostsButton && <AutoPopulateCostMatrixButton tableName={props.tableName} />}
          {modelSettings.toggleTimeWindows &&  modelSettings.toggleTimeWindows[props.tableName] && props.tableName === 'trips' && <TimeSettingsButtonLocations tableName={props.tableName} />}
          {modelSettings.toggleTimeWindows &&  modelSettings.toggleTimeWindows[props.tableName] &&  props.tableName === 'locations' && <TimeSettingsButtonLocations tableName={props.tableName} />}
          {modelSettings.toggleTimeWindows &&  modelSettings.toggleTimeWindows[props.tableName] &&  props.tableName === 'vehicles' && <TimeSettingsButtonLocations tableName={props.tableName} />}
          {staticTableSettings.allowClearTable && <ClearTableButton tableName={props.tableName}/>}
          </div>
          {maxRows && !inExampleMode &&
          <Tooltip title='Current usage / your usage limits (number of rows)' placement='top'>
          <div style={{display:'flex', flex:1, flexDirection: 'row', whiteSpace:'nowrap', gap:'5px', marginTop:'auto', marginBottom:'auto', paddingRight:'10px'}}>
            
            <Progress percent={tableSize * 100 / maxRows} strokeColor={conicColors} showInfo={false}/>
            <div style={{flex:1, fontSize:'0.9rem', marginTop:'auto', marginBottom:'auto', fontWeight:'600'
            }}>
              <span style={{color: tableSize > .9*maxRows ? conicColors['100%'] : tableSize > .3*maxRows ? conicColors['50%'] : conicColors['0%']}}>
              {tableSize}</span> / <span style={{color:'inherit'}}>{maxRows}</span></div>
          </div>
          </Tooltip>
          }
        </Box>
    </>)
}

export default DataEntryTableSummary;