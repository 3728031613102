import React from "react";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
// @material-ui/icons components
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LanguageIcon from '@mui/icons-material/Language';
import PricingIcon from 'components/Cards/Pricing/PricingIcon.js'
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import FunctionsIcon from '@mui/icons-material/Functions';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Notes from "@material-ui/icons/Notes";
import { Link } from "react-router-dom";

// core components
import componentStyles from "assets/theme/components/cards/pricing/card-pricing-gradient.js";
import buttonsStyles from "assets/theme/components/button.js";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(buttonsStyles);

const icons = [
  <FunctionsIcon />, 
  <BatteryCharging60Icon fontSize='small' />,
  <LanguageIcon  fontSize='small' />,
  <Notes />,
  <SaveAltIcon fontSize='small'/>, 
  <AutoFixHighIcon  fontSize='small' />,
  null
]

const subtitles = [
  <>All models included</>,
  <>Solve up to 50 locations at a time</>,
  <>Unlimited solves on our web app (up to 5 solves per minute)</>,
  <>Access to online documentation and forum support'</>,
  <>Save up to 5 models for later use<sup>*</sup></>,
  <>Auto population travel times<sup>*</sup></>,
  <><sup>*</sup><i>with free profile</i></>
]

function CardPricingGradient() {
  const classes = {
    ...useStyles(),
    ...useStylesButtons(),
  };
  const theme = useTheme();

  return (
    <>
      <Card className={clsx(classes.cardRoot, classes.cardRootGradientWarning)}>
        <CardHeader
          title="Starter"
          className={classes.cardContentRoot}
          titleTypographyProps={{
            className: classes.titleWhite,
            component: "h4",
            variant: "h4",
          }}
          style={{paddingBottom:'0px', borderRadius:'5px 0px 0px 0px'}}
        ></CardHeader>
        <CardContent className={classes.cardContentRoot} style={{paddingLeft:'3rem', paddingTop:'0px', paddingRight:'3rem'}}>
          <Box className={classes.display1} color={theme.palette.white.main}>
            $0
          </Box>
          <Box component="span" color={theme.palette.white.main}>
            Always free
          </Box>
          <Box
            component="ul"
            paddingLeft="0"
            className={classes.listStyleNone}
            margin="1.5rem 0"
          >
          {icons.map((icon, idx) => {
            return <PricingIcon style={{padding:'0px'}} icon={icon} subtitle={subtitles[idx]} />
          })}
        </Box>
          <Box marginBottom="1rem" textAlign="center">
            <Button
              variant="contained"
              classes={{
                root: classes.buttonRootWhite,
              }}
            ><Link className={'linkText'} to='/model-builder'>Get started now</Link>
              
            </Button>
          </Box>
        </CardContent>

      </Card>
    </>
  );
}

export default CardPricingGradient;
