/*
This page is the main page for the use cases. It displays all the use cases 
in the store/data/use_case_descriptions/use_case_descriptions.json file.
*/

import React, {useState, useEffect} from "react";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardUseCase from 'components/Cards/Cards/CardUseCase.js'
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Select } from 'antd';

import componentStyles from "assets/theme/components/headers/auth-header.js";
import componentStylesTypography from "assets/theme/components/typography.js";
const useStyles = makeStyles(componentStyles);
const useStylesTypography = makeStyles(componentStylesTypography);


const allUseCases = require('store/data/use_case_descriptions/use_case_descriptions.json');
function UseCasesPage() {
  const title = "Explore our use cases";
  const description = "Start from one of our pre-built models or create your own.";
  const classes = {...useStyles(), ...useStylesTypography()};
  const theme = useTheme();
  const [filteredTags, setFilteredTags] = useState([]);
  const [useCases, setUseCases] = useState(allUseCases);
  const allTags = [...new Set(Object.keys(allUseCases).map(item => allUseCases[item].tags).flat())];


  useEffect(() => {
    const newUseCases = [];
    Object.keys(allUseCases).map((key, idx) => {
      if (filteredTags.length == 0) {
        newUseCases.push(allUseCases[key]);
      }
      else {
        if (filteredTags.every(r=> allUseCases[key].tags.includes(r))) {
          newUseCases.push(allUseCases[key]);
        }
      }
    })
    setUseCases(newUseCases);
  }, [filteredTags]);

  function removeFilter (filter) {
    const newFilteredTags = filteredTags.filter((elem) => elem != filter);
    setFilteredTags(newFilteredTags);
  }

  function addFilter (filter) {
    const newFilteredTags = [...filteredTags, filter];
    setFilteredTags(newFilteredTags);
  }

  function handleChange (value) {
    setFilteredTags(value);
  }

  return (
    <>

      <Box
        id='UseCasesPage-Box1'
        className={classes.header}
        position="relative"
        paddingTop="2rem"
        paddingBottom="8rem"
        style={{paddingTop:"0.5rem"}}
      >
        <Container maxWidth="xl" id='UseCasesPage-Container1'>
          <Box 
            id='UseCasesPage-Box2'
            marginBottom="1rem" 
            textAlign="center" 
            paddingTop='1rem'
          >
            <Box
              id='UseCasesPage-Box3'
              component={Grid}
              container
              justifyContent="center"
              color={theme.palette.white.main}
            >
              <Grid item lg={8} md={8} xs={12} id='UseCasesPage-Grid1'>
                {title && (
                  <Typography component="h1" variant="h1" color="inherit">
                    {title}
                  </Typography>
                )}
                {description && (

                  <Box
                  id='UseCasesPage-Box4'
                  component="p"
                  color={theme.palette.gray[200]}
                  className={classes.lead}
                  style={{marginTop: '0px', marginBottom:'0px', fontWeight:400}}
                >
                  {description}
                </Box>
                )}
              </Grid>
              <Grid item xs={8} id='UseCasesPage-Grid2'>
                <Select
                  className={'customSelect'}
                  mode="tags"
                  style={{ width: '100%', backgroundColor:'inherit', border:'2px solid white', borderRadius:'5px'}}
                  placeholder="Filter scenarios"
                  onChange={handleChange}
                  options = {allTags.map((elem) => {return {value:elem, label:elem}})}
                />
              </Grid>
            </Box>
          </Box>
          <Grid container xs={12} lg={12} style={{display:'inline-flex'}} id='UseCasesPage-Grid3'>
            <Grid item xs={12} lg={6} id='UseCasesPage-Grid4'>
              <Grid container style={{justifyContent:"center"}} id='UseCasesPage-Grid5'>
                {Object.keys(useCases).map((key, idx) => {
                  if (idx % 2 == 0) {
                    return (
                        <Grid item xs={12} md={12} lg={12} id='UseCasesPage-Grid6'>
                          <CardUseCase useCase={useCases[key]} removeFilter={removeFilter} addFilter={addFilter} filteredTags={filteredTags}/>
                        </Grid> 
                    )
                  }
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6} id='UseCasesPage-Grid7'>
              <Grid container style={{justifyContent:"center"}} id='UseCasesPage-Grid8'>
                {Object.keys(useCases).map((key, idx) => {
                  if (idx % 2 == 1) {
                    return (
                        <Grid item xs={12} md={12} lg={12} id='UseCasesPage-Grid9'>
                          <CardUseCase useCase={useCases[key]} removeFilter={removeFilter} addFilter={addFilter} filteredTags={filteredTags}/>
                        </Grid> 
                    )}
                })}
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Box
          id='UseCasesPage-Box5'
          position="absolute"
          zIndex="100"
          height="70px"
          top="auto"
          bottom="0"
          pointerEvents="none"
          left="0"
          right="0"
          width="100%"
          overflow="hidden"
          transform="translateZ(0)"
        >
          <Box
            id='UseCasesPage-Box6'
            bottom="0"
            position="absolute"
            pointerEvents="none"
            component="svg"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <Box
              id='UseCasesPage-Box7'
              component="polygon"
              fill={theme.palette.dark.main}
              points="2560 0 2560 100 0 100"
            />
          </Box>
        </Box>
      </Box>
      <Container
        id='UseCasesPage-Container2'
        component={Box}
        maxWidth="xl"
        paddingBottom="1rem"
        position="relative"
        zIndex="101"
      >
      </Container>
    </>
  );
}

export default UseCasesPage;
