import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector, useDispatch } from 'react-redux';

// import {

// } from 'store/reducers/geoJsonFilterReducer';

import {
  selectActiveModelType,
  selectModelSettings,
  selectGeneralSettings,
  updateFilter,
  selectFilterState
} from 'store/reducers/inputReducer';


const TripLayerToggleCheckbox  = (props) => {
    const modelType = useSelector(selectActiveModelType);
    const modelSettings = useSelector(selectModelSettings);
    const mapMode = useSelector(selectGeneralSettings)['mapping']['mapMode'];
    const filterState = useSelector(selectFilterState('tripLayerVisible'))
    const dispatch = useDispatch();


    const handleClick = () => {
        dispatch(updateFilter({newValue:!filterState, filterName:'tripLayerVisible'}));
    }

    return (<>
      {modelSettings[modelType]['input']['enabled']['trips'] &&
        <FormGroup style={{display:'inline-block', margin:'0px'}}>
          
          {mapMode !== 'trips' && 
            <FormControlLabel label={'Show Trips'} style={{display:'inlineBlock', marginBottom:'0px'}}
            control={<Checkbox checked={filterState} onClick={handleClick} />} 
            />
          }
          {mapMode == 'trips' && 
            <FormControlLabel label={'Show Trips'} style={{display:'inlineBlock', marginBottom:'0px'}}
            control={<Checkbox checked={true} disabled={true} onClick={handleClick} />} 
            />
          }
        </FormGroup>
      }</>
    );
}

export default TripLayerToggleCheckbox;
