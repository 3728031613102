import * as React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import Box from "@material-ui/core/Box";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import getIconFromName from 'views/admin/Forms/icon_selector/icon_mapping.js'

import MyButton from 'views/admin/Components/MyComponents/MyButton.js'
import componentStyles from "assets/theme/views/admin/wizard/model-description-card.js";
import componentStylesButtons from "assets/theme/components/button.js";

import { selectModelSettings } from 'store/reducers/inputReducer';

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);


export default function ModelDescriptionCard(props) {
  /**
   * This is a card that displays a model's description, image, and a button to select it.
   * It is used in the model selector page.
   */
  const theme = useTheme();
  const classes = { ...useStyles(), ...useStylesButtons};
  const modelConfigs = useSelector(selectModelSettings)
  const title = modelConfigs[props.modelType]['title'];

  const selectButtonPress = () => {
      props.setSelectedModel(props.modelType);
  }

  return (
    <Card classes={props.selected ? { root: classes.rootCardSelected } : { root: classes.rootCardUnselected }}
    style={{margin:'0px', height:'100%'}}
    >
      <CardHeader
        avatar={<Avatar style={{background:props.selected ? theme.palette.info.main : theme.palette.dark.main}}>
        {getIconFromName(modelConfigs[props.modelType]['iconName'], {})}
      </Avatar>
        }
        title={title}
        titleTypographyProps={{'fontSize':'1rem', 'fontWeight':'400'}}
      />
      <CardMedia
        component="img"
        height="194"
        src={modelConfigs[props.modelType]['example_image']}
        alt={title}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
            {modelConfigs[props.modelType]['description']}
        </Typography>
      </CardContent>
      <Box textAlign='center'>
        <CardActions disableSpacing classes={{root: classes.cardActions}}>
                <MyButton 
                  label={props.selected ? "Selected" : "Select"}
                  variant={props.selected ? "contained" : "outlined"} 
                  color={props.selected ? "info" : 'default'}
                  buttonProps={{
                    onClick:selectButtonPress
                  }} />

        </CardActions>
      </Box>
      
    </Card>
  );
}
