const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']

//json file should have locations and costss
async function deleteOptimizationResult(run_id) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ 'run_id': run_id})
    };
    var response
    try {
        response = await fetch(BASE_ROUTE + '/delete_optimization_result', requestOptions)
        if (!response.ok) {
            throw 'Invalid response received from delete_optimization_results'
        }
        const retJson = await response.json()
        return retJson
    } catch (err) {
        throw err
    }
    

}

export default deleteOptimizationResult