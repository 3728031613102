/**
 * This is the main page for the API section of the website. It contains a form for users to 
 * submit their email to be notified of the API release. Eventually, it will contain the
 * home page for the API documentation.
 */

import React, {useState} from "react";
import { useDispatch } from 'react-redux';
import classnames from "classnames";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import {joinEmailList} from 'store/reducers/inputReducer.js'

import 'assets/theme/views/admin/about/about.css';
import 'assets/theme/views/admin/about/about.min.css';


const API = () =>{

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [nameFocused, setNameFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const submitForm = () => {
    dispatch(joinEmailList({email: email, name: name, source: 'api'}))
    setEmail('');
    setName('');
    setSubmitted(true);
  }

    return (
      <>
        <main id='API-Main-1' style={{display: 'flex', flexDirection: 'column', height:'100%', flex: 1}}>

          <section className="section section-lg bg-gradient-default"
            style={{paddingTop:'2rem', paddingBottom:'2rem'}}
            id='API-Section-1'
            >
            <Container className="pt-lg pb-300" id='API-Container-1'>
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  
                  <h2 className="display-3 text-white">API</h2>

                </Col>
              </Row>
            </Container>

            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container id='API-Container-2'>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Be the first to find out!</h4>
                      <p className="mt-0">
                      API is coming soon. Please submit our form below to be notified of the release.
                      </p>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: nameFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          <Input
                            placeholder="Your name"
                            type="text"
                            value={name}
                            onChange={ (event) => setName(event.target.value)}
                            onFocus={() => setNameFocused(true)}
                            onBlur={() => setNameFocused(false)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: emailFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          <Input
                            placeholder="Email address"
                            type="email"
                            onFocus={() => setEmailFocused(true)}
                            onBlur={() => setEmailFocused(false)}
                            value={email}
                            onChange={ (event) => setEmail(event.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>

                      <div>
                        {!submitted && <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          onClick={submitForm}
                        >
                          Submit
                        </Button>}
                        {submitted && <div style={{color:'green', textAlign:'center', marginTop:'10px'}}>Thank you for submitting your email!</div>}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }


export default API;
