
import DataDashboard from "views/admin/Dashboards/DataDashboard.js";
import Wizard from 'views/admin/Wizard/Wizard.jsx';
import ApiIcon from '@mui/icons-material/Api';

import InfoIcon from '@mui/icons-material/Info';
import SummarizeIcon from '@mui/icons-material/Summarize';

import Pricing from "views/auth/Pricing.js";
import React from "react";

import BuildIcon from '@mui/icons-material/Build';
import ArticleIcon from '@mui/icons-material/Article';
import RocketIcon from '@mui/icons-material/Rocket';
import SellIcon from '@mui/icons-material/Sell';
import WorkIcon from '@mui/icons-material/Work';
import SecurityIcon from '@mui/icons-material/Security';
import PermMedia from "@material-ui/icons/PermMedia";
import About from "views/admin/Pages/About.js"
import Careers from "views/admin/Pages/Careers.js"
import API from "views/admin/Pages/API.js"
import UseCasesPage from "./views/admin/Pages/UseCasesPage";
import Policy from "views/admin/Pages/Policy.js";

var terms_of_service = require('store/data/admin/terms_of_service.json');
var privacy_policy = require('store/data/admin/privacy_policy.json');

const TermsOfUse = () => {
  return <Policy policyInfo={terms_of_service}></Policy>;
}

const PrivacyPolicy = () => {
  return <Policy policyInfo={privacy_policy}></Policy>;
}


class PickupDropoffDashboard extends React.Component {
  render() {
    return <DataDashboard modelType={'vr_pickup_deliveries'}></DataDashboard>;
  }
}

class VehicleRoutingDashboard extends React.Component {
  render() {
    return <DataDashboard modelType={'vehicle_routing'}></DataDashboard>;
  }
}

class LabServicingVehicleTypeExampleDashboard extends React.Component {
  render() {
    return <DataDashboard modelType={'vehicle_routing'} isExample={true} scenarioName={'lab-servicing-vehicle-types'}></DataDashboard>;
  }
}

class TechnicianRoutingTimeWindowsExampleDashboard extends React.Component {
  render() {
    return <DataDashboard modelType={'vehicle_routing'} isExample={true} scenarioName={'technician-routing-time-windows'}></DataDashboard>;
  }
}

class EuropeanAirports1ExampleDashboard extends React.Component {
  render() {
    return <DataDashboard modelType={'vr_pickup_deliveries'} isExample={true} scenarioName={'european-airports1'}></DataDashboard>;
  }
}

class CourierDashboard extends React.Component {
  render() {
    return <DataDashboard modelType={'vehicle_routing'} isExample={true} scenarioName={'courier'}></DataDashboard>;
  }
}

class UsaCapitalsExampleDashboard extends React.Component {
  render() {
    return <DataDashboard modelType={'vehicle_routing'} isExample={true} scenarioName={'usa-capitals'}></DataDashboard>;
  }
}

class ElectricianRoutingExampleDashboard extends React.Component {
  render() {
    return <DataDashboard modelType={'vehicle_routing'} isExample={true} scenarioName={'indianapolis-electrician-routing'}></DataDashboard>;
  }
}



var routes = [
  {
    path: "/",
    name: "Model Builder",
    icon: BuildIcon,
    iconColor: "Default",
    component: Wizard,
    layout: "/model-builder",
    // state: "dashboardsCollapse",
    // views: [
    //   {
    //     path: "/model-builder",
    //     name: "Model Builder",
    //     miniName: "B",
    //     component: ModelWizard,
    //     layout: "/admin",
    //   },
    // ],
  },
  {
    collapse: true,
    name: "Optimizers",
    icon: RocketIcon,
    iconColor: "Primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/vehicle-routing",
        name: "Vehicle Routing",
        miniName: "VR",
        component: VehicleRoutingDashboard,
        layout: "/models",
      },
      {
        path: "/pickup-dropoff",
        name: "Pickup / Dropoff Routing",
        miniName: "PD",
        component: PickupDropoffDashboard,
        layout: "/models",
      },
    ],
  },
  {
    collapse: true,
    name: "Examples",
    icon: PermMedia,
    iconColor: "Warning",
    state: "examplesCollapse",
    views: [
      {
        path: '/european-airports',
        name: 'Airplane Scheduling',
        // miniName: 'Air',
        component: EuropeanAirports1ExampleDashboard,
        layout: '/examples'
      },
      {
        path: '/technician-routing',
        name: 'Technician Routing with Time Windows',
        // miniName: 'Lab',
        component: TechnicianRoutingTimeWindowsExampleDashboard,
        layout: '/examples'
      },
      {
        path: '/lab-servicing',
        name: 'Lab Servicing with Vehicle Types',
        // miniName: 'Lab',
        component: LabServicingVehicleTypeExampleDashboard,
        layout: '/examples'
      },
      {
        path: '/courier',
        name: 'Courier Delivery',
        // miniName: 'NYC',
        component: CourierDashboard,
        layout: '/examples'
      },
      {
        path: '/usa-capitals',
        name: 'USA Capitals',
        // miniName: 'USA',
        component: UsaCapitalsExampleDashboard,
        layout: '/examples'
      },
      {
        path: '/indianapolis-electrician-routing',
        name: 'Electrician Routing',
        // miniName: 'ELE',
        component: ElectricianRoutingExampleDashboard,
        layout: '/examples'
      },
      
      // {
      //   path: "/login",
      //   name: "Login",
      //   miniName: "L",
      //   component: Login,
      //   layout: "/auth",
      // },
      // {
      //   path: "/register",
      //   name: "Register",
      //   miniName: "R",
      //   component: Register,
      //   layout: "/auth",
      // },
      // {
      //   path: "/user-profile",
      //   name: "Profile",
      //   miniName: "P",
      //   component: Profile,
      //   layout: "/admin",
      // }
    ],
  },
  {
    collapse: false,
    path: "/",
    name: "Pricing",
    icon: SellIcon,
    iconColor: "Success",
    component: Pricing,
    layout: "/pricing",
  },
  {
    divider: true,
  },
  {
    title: "Documentation",
  },
  {
    path:"/",
    name: "About",
    icon: InfoIcon,
    component: About,
    layout: "/about"
  },
  {
    path:"/",
    name: "Use Cases",
    icon: SummarizeIcon,
    component: UseCasesPage,
    layout: "/use-cases"
  },
  {
    path:"/",
    name: "API",
    icon: ApiIcon,
    component: API,
    layout: "/api"
  },
  {
    path:"/",
    name: "Terms of Use",
    icon: ArticleIcon,
    component: TermsOfUse,
    layout: "/terms-of-use"
  },
  {
    path:"/",
    name: "Privacy Policy",
    icon: SecurityIcon,
    component: PrivacyPolicy,
    layout: "/privacy-policy"
  },
  {
    path: "/",
    name: "Careers",
    icon: WorkIcon,
    component: Careers,
    layout: "/careers"
  }

];
export default routes;
