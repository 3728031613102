import React from 'react';
import BugReportIcon from '@mui/icons-material/BugReport';
import IconButton from '@mui/material/IconButton';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from "@material-ui/core/Tooltip";
import { useTheme } from "@material-ui/core/styles"; 

import BugDialog from 'views/admin/Components/Dialogs/BugDialog.js'
// import SettingsDialog from 'views/admin/Components/Dialogs/SettingsDialog.js'
import InfoDialog from 'views/admin/Components/Dialogs/InfoDialog.js'
import {ConnectedUndoIcon, ConnectedRedoIcon} from 'store/reducers/undoRedo.js';



function DashboardButtonPanel (props)  {

    const theme = useTheme();
    // const [settingsDialogOpen, setSettingsDialogOpen] = React.useState(false);
    const [infoDialogOpen, setInfoDialogOpen] = React.useState(false);
    const [bugDialogOpen, setBugDialogOpen] = React.useState(false);

    const buttonStyle = {
        color: theme.palette.white.main,
        '&:hover':{
            color: theme.palette.primary.main,
        }
    }

    const buttonStyleInactive = {
        color: theme.palette.gray[600],

    }


    return (
        <>
        <ConnectedUndoIcon buttonStyleActive={buttonStyle} buttonStyleInactive={buttonStyleInactive} />
        <ConnectedRedoIcon buttonStyleActive={buttonStyle} buttonStyleInactive={buttonStyleInactive}/>
        {props.modelType && <>
        <Tooltip title='About this model'>
            <IconButton aria-label="info" onClick={() => setInfoDialogOpen(true)} sx={buttonStyle}>
                <InfoIcon />
            </IconButton>
        </Tooltip>
        {/* <Tooltip title='Settings'>
            <IconButton aria-label="settings" onClick={() => setSettingsDialogOpen(true)} sx={buttonStyle}>
                <SettingsIcon />
            </IconButton>
        </Tooltip> */}
        </>
        }
        <Tooltip title='Report bug'>
            <IconButton aria-label="settings" onClick={() => setBugDialogOpen(true)}>
                <BugReportIcon  sx={buttonStyle}/>
            </IconButton>
        </Tooltip>
        {/* <Tooltip title='More options'>
            <IconButton aria-label="settings" >
                <MoreVertIcon sx={buttonStyle}/>
            </IconButton>
        </Tooltip> */}
        {props.modelType && <>
         {/* <SettingsDialog open={settingsDialogOpen} handleClose={() => setSettingsDialogOpen(false)} modelType={props.modelType}/> */}
         <InfoDialog open={infoDialogOpen} handleClose={() => setInfoDialogOpen(false)} modelType={props.modelType}/>
        <BugDialog open={bugDialogOpen} handleClose={() => {setBugDialogOpen(false)}}/>
        </>}
        </>
    )
}

export default DashboardButtonPanel