const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']


//input should just be the inputReducer state
async function renameInstance(state, instance_id, newName) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({'instance_id': instance_id, new_name: newName, state:state})
    };
    var response
    try {
        response = await fetch(BASE_ROUTE + '/update_instance', requestOptions)
        if (!response.ok) {
            throw 'Invalid response received from get_run_summaries_by_user'
        }

    } catch (err) {
        throw err
    }

}

export default renameInstance