/**
 * This component is a radio button that allows the user to select 
 * which cost metric to optimize on. This is used as part of the
 * CostSettings component.
 */

import React from 'react';
import { useTheme } from "@material-ui/core/styles";
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import 'material-icons/iconfont/material-icons.css';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from "react-redux";

import { selectCostSetting, updateTableSetting, updateGeneralSetting } from 'store/reducers/inputReducer';
import {
    radioSx,
    infoIcon,
    formControlLabelStyle
} from 'views/admin/Forms/Cost/SharedCostStyles.js'

const OptimizationChoice = (props) => {
    // A radio checkbox for selecting which cost metric to optimize on.
    const theme = useTheme();
    const dispatch = useDispatch();
    const costMetric = useSelector(selectCostSetting({settingName:'costMetric'}));

    const handleListItemClick = (event, value) => {
        event.stopPropagation();

        dispatch(updateGeneralSetting({settingName:'costs', subSettingName:'costMetric', value:value}));
        if (value == 'duration') {
            dispatch(updateTableSetting({tableName: 'travel_costs', settingName: 'isViewable', newValue: true, columnName: 'Travel Duration'}))
            dispatch(updateTableSetting({tableName: 'travel_costs', settingName: 'isViewable', newValue: false, columnName: 'Travel Distance'}))
        }

        if (value == 'distance') {
            dispatch(updateTableSetting({tableName: 'travel_costs', settingName: 'isViewable', newValue: true, columnName: 'Travel Distance'}))
        }
    };

    return <>
    <Stack direction="row" spacing={2} id={'optimize-on-' + props.costMetric + '-stack'} >
        <RadioGroup
            row
            aria-labelledby={props.costMetric + " -calculation-group-label"}
            style={{margin:'0px'}}
            size='xs'
            >
            <FormControlLabel style={formControlLabelStyle} value={props.costMetric} 
            control={<Radio sx={radioSx(theme)} onClick={(event) => handleListItemClick(event, props.costMetric)}/> } 
            checked={costMetric==props.costMetric}
            label={props.optimizationLabel} />
            <Tooltip title={props.tooltipTitle} placement='top'>
                {infoIcon}
            </Tooltip>    
        </RadioGroup>
    </Stack>
    </>
}

export default OptimizationChoice