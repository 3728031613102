/**
 * https://stackoverflow.com/a/32538867/11342048
 * @param {*} obj 
 */
export default function isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }