/*
This component manages the form for adding or editing a single row of data.
It uses the AgDataEntryForm component to display the data in a horizontal table format.
Additionally, it adds a title and buttons for submitting, adding a new row, and deleting
the current entry.
*/


import React, {useState} from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from "@material-ui/core/Grid";
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import AgDataEntryForm from 'views/admin/Tables/Ag-grid/AgDataEntryForm.js';
import { useDispatch } from 'react-redux';
import {
    addRow,
    updateTableMulti,
    deleteRow,
    deleteRowGeoJson
  } from 'store/reducers/inputReducer';

import {generateUUID} from "store/functions/geoJsonFunctions.js"
import componentStyles from "assets/theme/views/admin/data-entry-form.js";
const useStyles = makeStyles(componentStyles);

const allStaticTableSettings = require('store/data/table_settings_static.json')

const DataEntryFrom = (props) => {
  /*
  Component for adding or editing a single row of data.
  props:
    - tableName: The name of the table
    - uuid: The uuid of the row to edit (if editing, otherwise null)
    - onClose: Function to call when the form is closed
    - onSubmit: Function to call when the form is submitted
    - onDelete: Function to call when the row is deleted
    - title: The title of the form
    - backgroundColor: The background color of the form
    - containerStyle: The style of the container
    - skinny: Whether the form should be skinny
  */
  const classes = { ...useStyles() };
  const editMode = props.uuid ? true : false;
  const [uuid, setUuid] = useState(props.uuid);
  const [reset, setReset]= useState(0);
  const staticTableSettings = allStaticTableSettings[props.tableName];
  const cardStyle = props.backgroundColor ? {background:props.backgroundColor} : {}
  const title = props.title ? props.title : props.uuid ? staticTableSettings['editRowTitle'] : staticTableSettings['addRowTitle']
  const dispatch = useDispatch();
  const [nomineeEntries, setNomineeEntries] = useState({});

  const resetForm = () => {
    /** Resets the form (to be used when adding a new entry) */
    setUuid(null);
    setNomineeEntries({});
    setReset(reset+1);
  }

  function deleteRecord () {
    /** Deletes the current record when editing */
    resetForm()
    props.onClose()
    if (props.onDelete) {
      props.onDelete();
    }
    if (!uuid) {} 
    else {
      const payload = {uuid:props.uuid, tableName:props.tableName};
      dispatch(deleteRowGeoJson(payload));
      dispatch(deleteRow(payload));
    }
  }


const submitForm = (close) => {
  /*
  Submits the form. If the form is in edit mode, it updates the row in the table.
  Otherwise, it adds a new row.
  - close: Whether to close the form after submitting
  */
  const isNomineeEntriesEmpty = Object.keys(nomineeEntries).length === 0 && nomineeEntries.constructor === Object;
  const isNomineeEntriesNull = nomineeEntries === null;
  
  if (isNomineeEntriesEmpty || isNomineeEntriesNull) {

  } else if (editMode) {
    const uuids = Object.values(nomineeEntries).map(() => props.uuid);
    dispatch(updateTableMulti({
      tableName: props.tableName,
      uuids: uuids,
      newValues: Object.values(nomineeEntries),
      updateColumnNames: Object.keys(nomineeEntries)
    }));
  } else {
    const uuid = generateUUID();
    dispatch(addRow({newRow:nomineeEntries, tableName: props.tableName, uuid:uuid}));
  }

  if (props.onSubmit) {
    props.onSubmit();
  }    
  if (close) {
    props.onClose()
  }
  resetForm();
}
  return (
    <>
        <Grid
          container
          justifyContent="center"
          style={{margin:'0px', padding:'0px', ...props.containerStyle}}
        >
          
          <Grid item xs={12} lg={12} style={{padding:'0px', margin:'0px'}}>
            <Card classes={{ root: classes.cardRoot }} id='DataEntryFormCard' 
              style={{...cardStyle, padding: props.skinny ? '2px': '10px', maxWidth:'320px'}}>
              {props.onClose && <div style={{position:'absolute', top:props.skinny ? '0px' : '10px', right:props.skinny ? '0px' : '10px', zIndex: '4'}}>
                  <IconButton aria-label="Cancel" color="warning" onClick={() => props.onClose()}>
                      <CancelIcon ></CancelIcon>
                  </IconButton>
              </div>
              }
              <CardHeader
                style={{paddingTop: props.skinny ? '5px': 'inherit', paddingBottom: props.skinny ? '5px': 'inherit', textAlign:'center'}}
                className={classes.cardHeader}
                title={title}
                titleTypographyProps={{
                  component: Box,
                  marginBottom: "0!important",
                  variant: "h3",
                }}
              ></CardHeader>
              <CardContent style={{padding:'20px', paddingTop:'0px', paddingBottom: props.skinny ? '5px': '20px'}}>
                <Grid container style={{padding:'0px', margin:'0px', overflow:'scroll'}} spacing={2}>
                <AgDataEntryForm tableName={props.tableName} uuid={uuid} setUuid={setUuid} reset={reset}
                setEntries={setNomineeEntries} key={reset}
                />
                </Grid>

                <Grid container justify="center" alignItems="center" style={{paddingTop:'10px'}}>
                  {<Grid item xs={12} sm={12} md={12} xl={6} style={{padding:'5px', textAlign:'center'}}>
 
                        <Button classes={{root: classes.buttonContainedSuccess}}
                        variant='contained'
                          startIcon={<AddCircleIcon />}
                          onClick={() => submitForm()}
                          size={'small'}
                          fullWidth>
                            {!editMode && "Submit and Add New"}
                            {editMode && "Submit"}
                        </Button>
            
                  </Grid>}

                  {!editMode && <Grid item xs={12} sm={12} md={12} xl={6} style={{padding:'5px', textAlign:'center'}}>
 
                    <Button classes={{root: classes.buttonContainedSuccess}}
                    variant='contained'
                      startIcon={<AddCircleIcon />}
                      onClick={() => submitForm(true)}
                      size={'small'}
                      fullWidth>
                      {"Submit and Close"}
                    </Button>

                    </Grid>}


                  { <Grid item xs={12} sm={12} md={12} xl={6} style={{padding:'5px', textAlign:'center'}}>
                      <Button variant="contained"  
                                startIcon={<DeleteIcon />}
                                classes={{root: classes.buttonContainedError}}
                                onClick={() => {deleteRecord()}}
                                size={'small'}
                                fullWidth
                      >
                        {editMode && "Delete"}
                        {!editMode && "Cancel"}
                      </Button>
                  </Grid>}
                </Grid>
                
              </CardContent>
            </Card>

          </Grid>

        </Grid>
    </>
  );
};

export default DataEntryFrom;
