import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    margin: '0px'
  },
  collapsibleCard: {
    padding: '2px',
    border: '1px solid',
    margin:'0px',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      border: '3px solid',
      padding: '0px',
      borderColor: theme.palette.primary.light,
    }
  },

  collapsibleCard_warning: {
    padding: '2px',
    border: '1px solid',
    margin:'0px',
    borderColor: theme.palette.warning.main,
    '&:hover': {
      border: '3px solid',
      padding:'0px',
      borderColor: theme.palette.warning.main,
    }
  },
  collapsibleCard_success: {
    padding: '2px',
    border: '1px solid',
    margin:'0px',
    borderColor: theme.palette.success.main,
    '&:hover': {
      padding: '0px',
      border: '3px solid',
      borderColor: theme.palette.success.main
    }
  },

  collapsibleCard_info: {
    padding: '2px',
    border: '1px solid',
    margin:'0px',
    borderColor: theme.palette.info.main,
    '&:hover': {
      border: '3px solid',
      padding:'0px',
      borderColor: theme.palette.info.main,
    }
  },

  collapsibleCard_error: {
    padding: '2px',
    border: '1px solid',
    margin:'0px',
    borderColor: theme.palette.error.main,
    '&:hover': {
      padding:'0px',
      border: '3px solid',
      borderColor: theme.palette.error.main,
    }
  },

  collapsibleCard_primary: {
    padding: '2px',
    border: '1px solid',
    margin:'0px',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      padding: '0px',
      border: '3px solid',
      borderColor: theme.palette.primary.main,
    }
  },
  collapsibleCardHeader_primary: {
    margin: '0px',
    padding: '5px',
    backgroundColor: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  collapsibleCardHeader_warning: {
    margin: '0px',
    padding: '5px',
    backgroundColor: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.light
    }
  },
  collapsibleCardHeader_error: {
    margin: '0px',
    padding: '5px',
    backgroundColor: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light
    }
  },
  collapsibleCardHeader_info: {
    margin: '0px',
    padding: '5px',
    backgroundColor: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.info.light
    }
  },
  collapsibleCardHeader_success: {
    margin: '0px',
    padding: '5px',
    backgroundColor: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light
    }
  },
  collapsibleCardHeader_dark: {
    margin: '0px',
    padding: '5px',
    backgroundColor: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.dark.light
    }
  },
  collapsibleCardHeader: {
    margin: '0px',
    padding: '5px',
  },
  collapsibleCardContent: {
    margin: '0px',
    padding: '5px',
    paddingLeft:'10px',
    paddingRight: '10px'
  },
  cardHeader: {
    padding:'5px',
    margin:'0px',
    backgroundColor: "initial",
  },
  cardHeaderSecond: {
    display: "flex",
    alignItems: "center",
  },
  dNoneDSmBlock: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  primaryColor: {
    color: theme.palette.primary.main + "!important",
  },
  anchor: {
    marginTop: ".25rem",
    display: "inline-flex",
    alignItems: "center",
    marginRight: ".75rem",
    color: theme.palette.dark.main,
    fontSize: ".875rem",
    textDecoration: "none",
  },
  justifyContentSmEnd: {
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
  },
  avatarRootXS: {
    width: "24px",
    height: "24px",
  },
  mediaRoot: {
    "&:hover $avatarRootMedia": {
      transform: "scale(1.1)",
    },
  },
  avatarRootMedia: {
    marginTop: "-1rem",
    marginRight: "-2rem",
    position: "relative",
    zIndex: 1,
    border: "4px solid " + theme.palette.white.main,
    transition: "all .15s ease",
    width: "58px",
    height: "58px",
  },
  avatarRootMediaComment: {
    width: "58px",
    height: "58px",
    marginRight: "1.5rem",
  },
  mediaComment: {
    backgroundColor: theme.palette.gray[100],
  },
  boxImg: {
    verticalAlign: "middle",
    borderStyle: "none",
  },
  avatarRounded: {
    borderRadius: ".375rem!important",
  },
  listItemPrimary: {
    backgroundColor: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  cardHeaderPrimary: {
    backgroundColor: theme.palette.white.main,
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }
});

export default componentStyles;
