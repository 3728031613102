
import React, {useState, useEffect} from "react";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from 'react-redux';
// import {submitMessage} from 'store/reducers/inputReducer.js'
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

const JobCard = (props) => {
/**
 * This is a Material-UI card component that displays a job listing. It includes the job title, 
 * description, and a button to apply.
 * @param {*} props 
 */

    return (
        <Card style={{height:'100%'}}>
            <CardHeader title={props.title} subheader={props.subheader} />
            <CardContent>
                <p>{props.description}</p>
                <p>Email <a href="mailto:careers@optimiciti.com">careers@optimiciti.com</a> with your resume and any supporting materials</p>
            </CardContent>
        </Card>
    )

}

const Careers = (props) => {
    /**
     * This component is the careers page for the website. It includes a form for users to submit
     * a resume and a message to the company.
     */
  const theme = useTheme();
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [firstMount, setFirstMount] = useState(true);
  // const [nameFocused, setNameFocused] = useState(null);
  // const [emailFocused, setEmailFocused] = useState(null);
  
  useEffect(()=>{
    if (firstMount) {
      setFirstMount(false);
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      // this.refs.main.scrollTop = 0;
    }
  })

  // const dispatchSubmitMessage = () => {
  //   dispatch(submitMessage({email: email, name: name, message: message}))
  //   setEmail('');
  //   setName('');
  //   setMessage('')
  // }

    return (
      <>
          <div className="position-relative" >
            <section className="section section-lg section-shaped" style={{paddingTop:'4rem', paddingBottom:'1rem !important'}}>
              <div className="shape shape-style-1 shape-default" style={{borderBottom:'2px solid white'}}>
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="8">
                      <h1 className="display-3 text-white">
                        We're on the lookout for top talent
                      </h1>
                      <p className="lead text-white">
                        Submit your resume and we'll contact you if we think you're a good fit for our team.
                      </p>
                      <div className="btn-wrapper" style={{display:'flex', justifyContent: 'flex-start'}}>

                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <Container className="py-lg-md d-flex" style={{marginTop:'-3rem'}}>
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
              <Grid container style={{padding:'2rem', display:'inline-flex', maxWidth:'1040px', alignItems: 'center'}}>
                    <Grid item xs={12} md={8} lg={6}>
                        <JobCard title="Data Scientist" 
                        subheader="Full-time | Remote" 
                        description="We are looking for a data scientist to join our team. The ideal candidate will have experience with optimization, machine learning, and data analysis."
                        href="/careers/data-scientist"/>
                    </Grid>
                    <Grid item xs={12} md={8} lg={6}>
                        <JobCard title="Front-end Developer"
                        subheader="Full-time | Remote"
                        description="We are looking for a front-end developer to join our team. The ideal candidate will have experience with React and Material-UI."
                        href="/careers/front-end-developer"/>
                    </Grid>
                    <Grid item xs={12} md={8} lg={6}>
                        <JobCard title="Back-end Developer"
                            subheader="Full-time | Remote"
                            description="We are looking for a back-end developer to join our team. The ideal candidate will have experience with Django and PostgreSQL."
                            href="/careers/back-end-developer"/>
                    </Grid>
                </Grid>
                </Container>
            </section>
          </div>
          
          {/* <section className="section section-lg" style={{paddingTop:'0rem', paddingBottom:'2rem',}}>
            <Container style={{marginBottom:'0px important!'}}>
              <Row className="justify-content-center text-center" style={{marginBottom:'0px !important'}}>
                <Col lg="8">
                  <h2 className="display-3" style={{margin:'0px'}}>The Team</h2>
                </Col>
              </Row>
              <Row>
                <p className="description mt-3" style={{marginBottom:'0px !important'}}>
                            Optimiciti is a small team of data scientists who believe that many common routing, scheduling, and other optimization problems
                            should be solveable on an ad-hoc basis by non-technical users. No need for expensive consultants with long delivery times. We know
                            because we used to be those consultants.
                </p>
              </Row>
              <Row style={{paddingTop:'10px'}}>
                <Col className="mb-5 mb-lg-0" lg="6" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={'https://optimiciti-images.s3.us-east-2.amazonaws.com/about/dan.jpg'}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Dan Kinn</span>
                        <small className="h6 text-muted">Optimization Lead</small>
                      </h5>
                    </div>
                  </div>
                </Col>

              </Row>
            </Container>

          </section> */}

          <section style={{paddingTop:'0rem', paddingBottom:'0rem', textAlign:'center',
            background:"linear-gradient(87deg," + theme.palette.warning.main + "," + theme.palette.warning.gradientEnd + ")"}}>
          {/* <div className="separator separator-bottom separator-skew"> */}
          <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    style={{strokeWidth:1, 
                      fill: theme.palette.white.main}} 
                    points="0 0 2560 100 2560 0"
                  />
                </svg>
                

            <Grid container style={{padding:'2rem', display:'inline-flex', maxWidth:'1040px', alignItems: 'center'}}>
                <Grid item xs={12} sm={12} md={8}>
                
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    
                  </Grid>
              </Grid>
          </section>

      </>
    );
};


export default Careers;