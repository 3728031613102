import React from "react";
import Grid from "@material-ui/core/Grid";
import ModelDescriptionCard from 'views/admin/Wizard/ModelDescriptionCard.jsx';
import {useSelector, useDispatch} from "react-redux";
import {
    selectActiveModelType,
    updateActiveModelType
} from 'store/reducers/inputReducer.js'

export default function ModelSelectorCards(props) {
    const selectedModel = useSelector(selectActiveModelType)
    const dispatch = useDispatch();

    const updateSelectedModel = (newModel) => {
        if (newModel) {
            dispatch(updateActiveModelType({modelType: newModel, clearResults: true}))
        }
    }
    
    var gridComponents = [];
    for (const modelType of props.modelTypes) {
        const card =
            <Grid item xs={12} md={12} lg={6} xl={4} >
                <ModelDescriptionCard modelType={modelType} 
                    setSelectedModel={updateSelectedModel} selected={selectedModel === modelType}>
                </ModelDescriptionCard>
            </Grid>
        gridComponents.push(card);
    }

    return (
    <Grid container spacing={2}>
        {gridComponents}
    </Grid>
    );
}