import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const getIcon = (status, iconProps) => {
    if (status === 'valid') {
        return <CheckCircleIcon {...iconProps} />
    }
    else if (status === 'disabled') {
        return <HighlightOffIcon {...iconProps} />
    } 
    else if (status === 'temporarily_disabled') {
        return <RemoveCircleOutlineIcon {...iconProps} />
    }
    else if (status === 'warning') {
        return <WarningIcon {...iconProps} />
    }
    else if (status === 'error') {
        return <ErrorIcon {...iconProps} />
    } 
    else if (status === 'loading') {
        return (
            <Box sx={{ display: 'flex' }} >
              <CircularProgress {...iconProps}/>
            </Box>
          );
    }

}

function DataTableStatusIcon(props) {

    const fontSize = props.fontSize || '1.2rem'
    const height = props.fontSize || '1.2rem'
    const width = props.fontSize || '1.2rem'

    const getColor = () => {
        if (props.status === 'valid') {
            return 'green'
        }

        else if (props.status === 'warning') {
            return 'orange'
        }

        else if (props.status === 'error') {
            return 'red'
        }

        else {
            return 'grey'
        }
    };

    const iconProps = {style:{color:getColor(), fontSize:fontSize, width: width, height: height}};
    const icon = getIcon(props.status, iconProps);
    if (props.tooltip) {
        return (
            <Tooltip title={props.tooltip}>{icon}</Tooltip>
        );
    }
    else {
        return (
            <>{icon}</>
        );        
    }
}

export default DataTableStatusIcon;