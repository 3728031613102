export function radioSx(theme)  {return {
    padding:'5px',
    '& .MuiSvgIcon-root': {
      fontSize: 20,
      color: theme.palette.primary.main,
      '&.Mui-checked': {
        color: theme.palette.primary.main,
      },
    }
  }}

export function checkboxSx(theme) {
    return {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
    '&.MuiSvgIcon-root': {
      width:'0.8rem',
      height:'0.8rem'
    },
    '&.MuiSvgIcon': {
      width:'0.8rem',
      height:'0.8rem'
    }
  }
}

export const infoIcon = 
    <span style={{margin:0, marginLeft:'0px', marginRight:'15px', marginTop:'0px'}}>
    <i class={"helpIcon material-icons"}>info</i>
    </span>  

export function formControlStyle(theme) {return {border:'1px solid ' + theme.palette.primary.main, borderRadius:'5px', padding:'5px'}};

export const formControlLabelStyle = {
  marginBottom:'2px',
  marginLeft:'0px',
  marginRight:'0px'
};

export function formLabelStyle(theme) {return {
  color:theme.palette.primary.main,
  fontWeight:'700',
  fontVariant:'small-caps'
}}

export function formLabelStyleBasic(theme) {return {
  fontWeight:'500',
  margin:'0px',
  whiteSpace:'nowrap',
}}