import Button from '@mui/material/Button';
import { useTheme } from "@material-ui/core/styles";




const MyMuiButton = (props) => {
    const theme = useTheme();


    const sx = {
        '&.MuiButton-root': {
        '&.MuiButton-contained': {
            backgroundColor:theme.palette.primary.main,
            '&.MuiButton-containedError': {
                backgroundColor:theme.palette.error.main            
            },
            '&.MuiButton-containedWarning': {
                backgroundColor:theme.palette.warning.main            
            },
            '&.MuiButton-containedSuccess': {
                backgroundColor:theme.palette.success.main            
            }
        }},
        '&.MuiButton-outlined': {
            color:theme.palette.primary.main,
            borderColor:theme.palette.primary.main,
            '&:hover' : {
                backgroundColor:theme.palette.primary.main,
                color: theme.palette.white.main
            }
        },
        '&.Mui-focusVisible': {
            borderColor:theme.palette.primary.dark,
        },
        '&.MuiButton-outlinedError': {
            color:theme.palette.error.main,
            borderColor:theme.palette.error.main            
        }
    }

    const gradientSx = {
        '&.MuiButton-root': {
            fontFamily: "Open Sans,sans-serif",
            fontWeight: "600",
            lineHeight: "1.5",
            'textTransform': 'none',
            '&:hover': {
                transform:'none'
            },
        '&.MuiButton-contained': {
            background:
            "linear-gradient(87deg," +
            theme.palette.primary.main +
            ",#2dcecc)!important",
            border:'2px solid',
            borderColor: 'inherit',
            color: theme.palette.white.main,
            "&:hover": {
                background:
                "linear-gradient(87deg," +
                theme.palette.primary.main +
                ",#2dcecc)!important",
                borderColor: theme.palette.primary.main,
            },
            '&.MuiButton-containedError': {
                background:
                  "linear-gradient(87deg," +
                  theme.palette.error.main +
                  "," + theme.palette.error.gradientEnd + ")!important",
                border:'2px solid',
                borderColor: 'inherit',
                color: theme.palette.white.main,
                "&:hover": {
                  background:
                  "linear-gradient(87deg," +
                  theme.palette.error.main +
                  "," + theme.palette.error.gradientEnd + ")!important",
                  borderColor: theme.palette.error.main,
                  color: theme.palette.white.main
                },
            
              },
            '&.MuiButton-containedWarning': {
                background:
                  "linear-gradient(87deg," +
                  theme.palette.warning.main +
                  "," + theme.palette.warning.gradientEnd + ")!important",
                border:'2px solid',
                borderColor: 'inherit',
                color: theme.palette.white.main,
                "&:hover": {
                  background:
                  "linear-gradient(87deg," +
                  theme.palette.warning.main +
                  "," + theme.palette.warning.gradientEnd + ")!important",
                  borderColor: theme.palette.warning.main,
                  color: theme.palette.white.main
                },
              },
            '&.MuiButton-containedSuccess': {
                background:
                  "linear-gradient(87deg," +
                  theme.palette.success.main +
                  "," + theme.palette.success.gradientEnd  + ")!important",
                border:'2px solid',
                borderColor: 'inherit',
                color: theme.palette.white.main,
                "&:hover": {
                  background:
                  "linear-gradient(87deg," +
                  theme.palette.success.main +
                  "," + theme.palette.success.gradientEnd  + ")!important",
                  borderColor: theme.palette.success.dark,
                  color: theme.palette.white.main
                },
              }
            },
        '&.MuiButton-outlined': {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.white.main
            },
        },
        '&.Mui-focusVisible': {
            borderColor:theme.palette.primary.dark,
        },
        '&.MuiButton-outlinedError': {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            "&:hover": {
              backgroundColor: theme.palette.error.main,
              color: theme.palette.white.main
            },    
        }
    }}


    return (
        <Button variant='contained' color='success' {...props} sx={props.gradient ? gradientSx : sx}/>
    )
}

export default MyMuiButton;