import {useState} from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExportFormatDialog from 'views/admin/Components/Dialogs/ExportFormatDialog.js';

import {
    selectDownloadTypeKeepSelection
} from 'store/reducers/inputReducer.js'

function DownloadTableButton(props) {
    const [open, setOpen] = useState(false);
    const downloadTypeKeepSelection = useSelector(selectDownloadTypeKeepSelection);
    const theme = useTheme();

    const handleClose = () => {
        setOpen(false);
        props.downloadFile();
    };

    const handleButtonClick = (event) => {
        event.stopPropagation();
        if (downloadTypeKeepSelection) {
            props.downloadFile();
        }
        else {
            setOpen(true);
        }
    }

    const handleCancel = () => {
        setOpen(false);
    }
    

    return (<>
        <Tooltip title={'Download table as file'}>
            <IconButton id='download-table-icon-button' onClick={(event) => handleButtonClick(event)}>
                <FileDownloadIcon style={{color:theme.palette.primary.main}} />
            </IconButton>
        </Tooltip>
        <ExportFormatDialog open={open} onClose={handleClose} handleCancel={handleCancel}/>
        
        
        </>)
}


export default DownloadTableButton