
export default function uniqueValidator (args) {
    const newValue = args.newValue;
    const data = args.data;
    const row = args.row;
    const columnName = args.columnName;
    const entered = args.entered || false;


    if (!newValue) {
        return {valid:true};
    }

    if (newValue == '') {
        return {valid:true};
    }

    if (row) {
        const rowValue = row[columnName]
        if (rowValue == newValue) {
            return {valid: true}
        }
    }

    if (!data) {
        return {valid: true}
    }

    const columnValues = data.map(item => item[columnName]);
    var valueCount = columnValues.reduce((n, x) => n + (x === newValue), 0);
    if (entered) {
        valueCount -= 1;
    }
    if (valueCount <= 0) {
        return {valid: true}
    } else
        return {
            valid: false,
            message: 'Cannot have two items with the same name in this column'
        }

}

