// import latitudeValidator from "views/admin/Forms/functions/latitude_validator.js"
// import longitudeValidator from "views/admin/Forms/functions/longitude_validator.js"
import hexToRgb, {parseColor} from 'functions/general/color_conversion.js'
const GLOBAL_CONFIGS = require('store/data/global_configs.json');


function increaseBrightness(color, percent) {
  // strip the leading # if it's there
  var r;
  var g;
  var b;
  try {
    r = color[0];
    g = color[1];
    b = color[2];
  } catch (error) {
    var hex = color.replace(/^\s*#|\s*$/g, '');

    if(hex.length == 3){
        hex = hex.replace(/(.)/g, '$1$1');
    }

    r = parseInt(hex.substr(0, 2), 16);
    g = parseInt(hex.substr(2, 2), 16);
    b = parseInt(hex.substr(4, 2), 16);
  }

  return '#' +((0|(1<<8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
     ((0|(1<<8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
     ((0|(1<<8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
}

//https://stackoverflow.com/a/8809472/11342048
export function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {//Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

function getColors (color) {
  var rgbColor;
  var hexColor;
  if (color) {
    const parsedColor = parseColor(color);
    rgbColor = parsedColor.rgb;
    hexColor = parsedColor.hex;
    // rgbColor = hexToRgb(color);
  } else {
    rgbColor = hexToRgb(GLOBAL_CONFIGS.mapping.colors.location);
  }

  var rgbColorHover;
  var hexColorHover;
  if (rgbColor) {
    const parsedColorHover = parseColor(increaseBrightness(rgbColor, 50));
    rgbColorHover = parsedColorHover.rgb;
    hexColorHover = parsedColorHover.hex;
    // rgbColorHover = hexToRgb(increaseBrightness(rgbColor, 50));
  } else {
    hexColorHover = GLOBAL_CONFIGS.mapping.colors['location-hover']
    rgbColorHover = hexToRgb(hexColorHover);
  }

  return {'rgbColor': rgbColor, 'hexColor': hexColor, 'hexColorHover': hexColorHover, 'rgbColorHover': rgbColorHover}
}


export default function createPointGeoJson({locationName, latitude, longitude, uuid, color=null, 
  colorHover=null, global=true, isDepot=false, dataRow=null, isResult=false, type_='point'}) {
  
  if (!uuid) {
    const err_msg = 'Expecting an input uuid in createPointGeoJson in the geoJsonFunctions.js script'
    throw err_msg;
  }
  // if (global) {
  //   const latValid = latitudeValidator({newValue:latitude});
  //   if (!latValid.valid) {
  //     return
  //   }
  //   const lngValid = longitudeValidator({newValue:longitude});
  //   if (!lngValid.valid) {
  //     return
  //   } 
  // }
  const colors = getColors(color);

  var geoJson = {          
      type: 'Feature', 
      properties: {
        'marker-color': '#ff0000', 
        "fill": "#66ff66", 
        "marker-size": "small",
        name: locationName,
        color: colors.rgbColor,
        defaultColor: colors.rgbColor,
        colorHover: colors.rgbColorHover,
        colorHex: colors.hexColor,
        colorRgb: colors.rgbColor,
        colorHoverHex: colors.hexColorHover,
        colorHoverRgb: colors.rgbColorHover,
        uuid: uuid,
        type: type_,
        isDepot: isDepot,
        isResult: isResult
      },
      geometry: {
          type: 'Point',
          coordinates: [parseFloat(longitude), parseFloat(latitude)]
      }
  }

  dataRow && Object.keys(dataRow).map((key, idx) => {
    geoJson.properties[key] = dataRow[key];
  })

  return geoJson
}

export function createDropsGeoJson({drops}) {
  var retGeoJson = [];
  drops.map((elem, idx) => {
    if (elem['Trip ID']) {
      const dropTrip = createTripGeoJson({
        fromLat:elem['From Latitude'] || elem['From Location Latitude'], 
        fromLng: elem['From Longitude'] || elem['From Location Longitude'], 
        toLat: elem['To Latitude'] || elem['To Location Latitude'],
        toLng: elem['To Longitude'] || elem['To Location Longitude'],
        fromName: elem['From Location'],
        toName: elem['To Location'],
        uuid: elem['UUID'],
        fromUuid: elem['From UUID'],
        toUuid: elem['To UUID'],
        vehicleId: null,
        color: 'red',
        type_: 'drop',
        dataRow: elem,
        isResult: true
      })
      retGeoJson.push(dropTrip.geoJson);
    } 
    else {
    const dropPoint = createPointGeoJson({
      locationName: elem['Location Name'], 
      latitude: elem['Latitude'],
      longitude: elem['Longitude'], 
      uuid: elem['UUID'],
      isResult: true,
      isDepot: false,
      dataRow: elem,
      type_: 'drop',
      color: 'red'
    })
    retGeoJson.push(dropPoint);
    }
  })
  return retGeoJson;
}

//showRetours - show a line string where from and to locations are the same
export function createRoutesGeoJson({vehiclePaths, showRetours=false}) {
  var retGeoJson = [];
  vehiclePaths.map((elem, idx) => {
    const vehicleId = elem['vehicle_id']
    const pathJson = JSON.parse(elem['path']);
    pathJson.map((path, path_idx) => {
      const fromLat = path['From Latitude'];
      const fromLng = path['From Longitude'];
      const toLat = path['To Latitude'];
      const toLng = path['To Longitude'];
      const fromName = path['From Location Name'];
      const toName = path['To Location Name'];
      const color = path['Color']
      const uuid = path['UUID'];
      const fromUuid = path['From UUID'];
      const toUuid = path['To UUID'];
      var action;
      if (path_idx == 0) {
        action = path['From Action'];
      } else {
        action = [...pathJson[path_idx-1]['To Action'], ...path['From Action']]
      }
      if (showRetours || (fromUuid != toUuid)) {
        const tripResults = createTripGeoJson({
          fromLat:fromLat, 
          fromLng: fromLng, 
          toLat: toLat,
          toLng: toLng,
          fromName: fromName,
          toName: toName,
          uuid: uuid,
          fromUuid: fromUuid,
          toUuid: toUuid,
          vehicleId: vehicleId,
          color: color,
          type_: 'routeLeg',
          dataRow: path,
          isResult: true
        })
        if (tripResults.valid) {
          retGeoJson.push(tripResults.geoJson)
        }
      }
      const geoJsonPoint = createPointGeoJson({
        locationName: fromName,
        latitude: fromLat,
        longitude: fromLng,
        global: true,
        color: color,
        type_: 'routePoint',
        uuid: generateUUID(),
        isDepot: false,
        isResult: true,
        dataRow: {...path, 'Stop': path_idx, 'vehicleId': vehicleId, 'Action': action}
      })
      retGeoJson.push(geoJsonPoint)

      if (path_idx == pathJson.length - 1) {
        if (fromName != toName) {
          const geoJsonPoint = createPointGeoJson({
            locationName: toName,
            latitude: toLat,
            longitude: toLng,
            global: true,
            color: color,
            type_: 'routePoint',
            uuid: generateUUID(),
            isDepot: false,
            isResult: true,
            dataRow: {...pathJson[path_idx+1], 'Stop': path_idx+1, 'vehicleId': vehicleId, 'Action': path['To Action']}
          })
          retGeoJson.push(geoJsonPoint)
        }
      }
    });
  });

  return retGeoJson;
}

export function createTripGeoJson({fromLat, fromLng, toLat, toLng, fromName, toName, uuid, fromUuid, toUuid, type_='trip',
  tripType='trip', vehicleId=null, color=null, colorHover=null, isResult=false, dataRow={}}) {

  if (!uuid) {
    const err_msg = 'Expecting an input uuid in createTripGeoJson in the geoJsonFunctions.js script'
    throw err_msg;
  }

  var valid = true;
  if (!fromLat | !fromLng | !toLat | !toLng) {
    valid = false;
  }

  const colors = getColors(color);
  var geoJson
  if (valid) {
    geoJson = {
      type: "Feature",
      properties: {
        toLoc: toName,
        fromLoc: fromName,
        toLat: toLat,
        toLng: toLng,
        fromLat: fromLat,
        fromLng: fromLng,
        name: fromName + ' to ' + toName,
        vehicleId: vehicleId,
        color: colors.rgbColor,
        defaultColor: colors.rgbColor,
        colorHover: colors.rgbColorHover,
        colorHex: colors.hexColor,
        colorRgb: colors.rgbColor,
        colorHoverHex: colors.hexColorHover,
        colorHoverRgb: colors.rgbColorHover,
        uuid: uuid,
        toUuid: toUuid,
        fromUuid: fromUuid,
        tripType: tripType,
        type: type_,
        isResult: isResult
      },
      geometry:
        {
          type: "LineString",
          coordinates: [[fromLng, fromLat],[toLng, toLat]]
        }
      }
    }
  else {
    geoJson = {
      type: "Feature",
      properties: {
        toLoc: toName,
        fromLoc: fromName,
        toLat: toLat,
        toLng: toLng,
        fromLat: fromLat,
        fromLng: fromLng,
        color: colors.rgbColor,
        defaultColor: colors.rgbColor,
        colorHover: colors.rgbColorHover,
        colorHex: colors.hexColor,
        colorRgb: colors.rgbColor,
        colorHoverHex: colors.hexColorHover,
        colorHoverRgb: colors.rgbColorHover,
        name: fromName + ' to ' + toName,
        uuid: uuid || generateUUID(),
        tripType: tripType,
        type: type_,
        isResult: isResult
      },
      geometry:
        {
          type: "LineString",
          coordinates: [[fromLng, fromLat],[toLng, toLat]]
        }
    }
  }    
  Object.keys(dataRow).map((key, idx) => {
    geoJson.properties[key] = dataRow[key]
  })

  return {geoJson:geoJson, valid:valid}
}

function getValuesFromUuid(geoJson, uuid) {
  for (var i=0; i < geoJson['features'].length; i++) {
    if (geoJson['features'][i].properties.uuid == uuid) {
      return geoJson['features'][i]
    }
  }
}


export function updateGeoJsonFromUUID(geoJson, uuid, newField, newValue) {
  for (var i=0; i < geoJson['features'].length; i++) {
    if (geoJson['features'][i].properties.uuid == uuid) {
      if (newField == 'name') {
        geoJson['features'][i].properties.name = newValue;
      }
      else if (newField == 'lat') {
        geoJson['features'][i].geometry.coordinates = [geoJson['features'][i].geometry.coordinates[0], newValue]
      }
      else if (newField == 'lng') {
        geoJson['features'][i].geometry.coordinates = [newValue, geoJson['features'][i].geometry.coordinates[1]]
      }
      else if (newField == 'fromCoords') {
        geoJson['features'][i].geometry.coordinates = [newValue, geoJson['features'][i].geometry.coordinates[1]];
      }
      else if (newField == 'toCoords') {
        geoJson['features'][i].geometry.coordinates = [geoJson['features'][i].geometry.coordinates[0], newValue];
      }
      else if (newField == 'fromLoc') {
        geoJson['features'][i].properties.fromLoc = newValue;
        geoJson['features'][i].properties.name = newValue + ' to ' + geoJson['features'][i].properties.toLoc;
      }
      else if (newField == 'toLoc') {
        geoJson['features'][i].properties.toLoc = newValue;
        geoJson['features'][i].properties.name = geoJson['features'][i].properties.fromLoc + ' to ' + newValue;
      }
      else if (newField == 'isDepot') {
        geoJson['features'][i].properties.isDepot = newValue;
      } 
      else if (newField == 'color' || newField == 'Color') {
        const colors = getColors(newValue);
        geoJson['features'][i].properties['color'] = colors.rgbColor;
        geoJson['features'][i].properties['defaultColor'] = colors.rgbColor;
        geoJson['features'][i].properties['colorHover'] = colors.rgbColorHover;
        geoJson['features'][i].properties['colorHex'] = colors.hexColor;
        geoJson['features'][i].properties['colorRgb'] = colors.rgbColor;
        geoJson['features'][i].properties['colorHoverHex'] = colors.hexColorHover;
        geoJson['features'][i].properties['colorHoverRgb'] = colors.rgbColorHover;

      }
      else {
        geoJson['features'][i].properties[newField] = newValue;
      }
    }
  }

}


export function updateGeoJsonPointFromName(geoJson, name, newCoords=null, newName=null) {

  var retGeoJson = {...geoJson};
  for (var i=0; i<retGeoJson['features'].length; i++) {
    if(retGeoJson['features'][i].geometry.type == 'Point') {
      if (retGeoJson['features'][i].properties.name == name) {
        if (newCoords) {
          retGeoJson['features'][i].geometry.coordinates = newCoords
        }
        if (newName) {
          retGeoJson['features'][i].properties.name = newName
        }
        break
      }
    }
  }
  return retGeoJson;
}

const updateGeoJsonTripFromLocationUuid = (geoJson, uuid, updateCol, updateValue) => {

  /**
   * helper function for updating a matched row on each iteration.
   * @param {*} feature 
   * @param {*} updateCol 
   * @param {*} updateValue 
   * @param {*} matchedField Whether the matched field is "from" or "to"
   */
  const updateField = (feature, updateCol, updateValue, matchedField) => {
    var newFeature = {...feature}
    var actualUpdateCol = updateCol;
    if (updateCol == 'name') {
      if (matchedField == 'from') {
        actualUpdateCol = 'fromLoc';
        newFeature['name'] = updateValue + ' to ' + newFeature.properties['toLoc']
      } else {
        actualUpdateCol = 'toLoc';
        newFeature.properties['name'] = newFeature.properties['fromLoc'] + ' to ' + updateValue
      }
    }

    if (updateCol == 'latitude') {
      if (matchedField == 'from') {
        actualUpdateCol = 'fromLat';
        newFeature.geometry.coordinates[0][1] = updateValue
      } else {
        actualUpdateCol = 'toLat';
        newFeature.geometry.coordinates[1][1] = updateValue
      }
    }
    
    if (updateCol == 'longitude') {
      if (matchedField == 'from') {
        actualUpdateCol = 'fromLng';
        newFeature.geometry.coordinates[0][0] = updateValue
      } else {
        actualUpdateCol = 'toLng';
        newFeature.geometry.coordinates[1][0] = updateValue
      }
    }  

    newFeature.properties[actualUpdateCol] = updateValue;
    return newFeature
  }

  var retGeoJson = {...geoJson};
  for (var i=0; i<retGeoJson['features'].length; i++) {
    if(retGeoJson['features'][i].geometry.type == 'LineString') {
      if (retGeoJson['features'][i].properties.toUuid == uuid) {
        retGeoJson['features'][i] = updateField(retGeoJson['features'][i], updateCol, updateValue, 'to');
      }

      if (retGeoJson['features'][i].properties.fromUuid == uuid) {
        retGeoJson['features'][i] = updateField(retGeoJson['features'][i], updateCol, updateValue, 'from');
      }
    }
  }
  return retGeoJson
}

export function updateGeoJsonTripFromNames(geoJson, fromName, toName, 
  newFromName=null, newToName=null, newFromCoords=null, newToCoords=null) {

  var retGeoJson = {...geoJson};
  var entryFound = false;
  for (var i=0; i<retGeoJson['features'].length; i++) {
    if(retGeoJson['features'][i].geometry.type == 'LineString') {
      if (retGeoJson['features'][i].properties.fromLoc == fromName) {
        if (retGeoJson['features'][i].properties.toLoc == toName) {
          
          var newCoords = []
          if (newFromCoords) {
            newCoords.push(newFromCoords)
          } else {
            newCoords.push(retGeoJson['features'][i].geometry.coordinates[0]);
          }
          if (newToCoords) {
            newCoords.push(newToCoords);
          } else {
            newCoords.push(retGeoJson['features'][i].geometry.coordinates[1]);
          }
          retGeoJson['features'][i].geometry.coordinates = newCoords;
          if (newFromName) {
            retGeoJson['features'][i].properties.fromLoc = newFromName;
            const newFromCoords = getCoordsFromLocationName(geoJson, newFromName)
            retGeoJson['features'][i].geometry.coordinates[0] = newFromCoords;
          }
          if (newToName) {
            retGeoJson['features'][i].properties.toLoc = newToName;
            const newToCoords = getCoordsFromLocationName(geoJson, newToName)
            retGeoJson['features'][i].geometry.coordinates[1] = newToCoords;
          }
          retGeoJson['features'][i].properties.name = retGeoJson['features'][i].properties.fromLoc + " to " + retGeoJson['features'][i].properties.toLoc;
          entryFound = true;
          break
        }
      }
    }
  }
  if (!entryFound) {
    var newEntryFromName = fromName;
    if (newFromName) {
      newEntryFromName = newFromName;
    }
    var newEntryToName = toName;
    if (newToName) {
      newEntryToName = newToName
    }
    const newFromCoords = getCoordsFromLocationName(geoJson, newEntryFromName);
    const newToCoords = getCoordsFromLocationName(geoJson, newEntryToName)
    const tripResponse = createTripGeoJson({
      fromLat: newFromCoords[1], 
      fromLng: newFromCoords[0],
      toLat: newToCoords[1],
      toLng: newToCoords[0],
      fromName: newEntryFromName,
      toName: newEntryToName,
      uuid: null,
      fromUuid: null,
      toUuid: null
    })

    // if (tripResponse.valid) {
    retGeoJson['features'].push(tripResponse.geoJson);
    // }
    // else {
    //   retGeoJson['invalidFeatures'].push(tripResponse.geoJson)
    // }
  }
  return retGeoJson;
}

export function updateInvalidEntries(geoJson, newLoc, newLat, newLng) {
  //checks the invalidFeatures to see if any are now valid after adding
  //a new location
  var moveIndices = [];


  geoJson['invalidFeatures'].map((elem, elemIdx) => {
    if (elem.properties.toLoc == newLoc) {
      geoJson['invalidFeatures'][elemIdx].properties.toLat = newLat;
      geoJson['invalidFeatures'][elemIdx].properties.toLng = newLng;
    }
    if (elem.properties.fromLoc == newLoc) {
      geoJson['invalidFeatures'][elemIdx].properties.fromLat = newLat;
      geoJson['invalidFeatures'][elemIdx].properties.fromLng = newLng;
    }
    const fromLat = elem.properties.fromLat;
    const fromLng = elem.properties.fromLng;
    const toLat = elem.properties.toLat;
    const toLng = elem.properties.toLng;
    if (fromLat && fromLng && toLat && toLng) {
      geoJson['invalidFeatures'][elemIdx].geometry.coordinates = [[fromLng, fromLat], [toLng, toLat]]
      moveIndices.unshift(elemIdx)
    }
  });

  moveIndices.map((elemIdx) => {
    const feature = geoJson['invalidFeatures'][elemIdx]
    geoJson['features'].push(feature);
    geoJson['invalidFeatures'].splice(elemIdx, 1);
  });

  return geoJson
}


export function getCoordsFromLocationName(geoJson, locName) {
  var coords = undefined;
  for (var i=0; i < geoJson['features'].length; i++) {
    const elem = geoJson['features'][i]
    if (elem.geometry.type == 'Point') {
      if (elem.properties.name == locName) {
        coords = elem.geometry.coordinates;
        break;
      }
    }
  };
  return coords;
}

export function getFieldFromLocationName(geoJson, locName, field) {
  var val = undefined;
  for (var i=0; i < geoJson['features'].length; i++) {
    const elem = geoJson['features'][i]
    if (elem.geometry.type == 'Point') {
      if (elem.properties.name == locName) {
        val = elem.properties[field];
        break;
      }
    }
  };
  return val;
}

export function checkIfNameExists(geoJson, locName, maxIter=100) {
  const nameExists = (locName) => {
    var exists = false;
    for (var i = 0; i < geoJson['features'].length; i++) {
      const elem = geoJson['features'][i]
      if (elem.properties.name == locName) {
        exists = true;
        break
      }
    }
    return exists;
  }
  var idx = 1;
  var curLocName = locName;
  while (idx < maxIter) {
    const exists = nameExists(curLocName);
    if (!exists) {
      break
    }
    curLocName = locName + ' (' + String(idx) + ')'
    idx += 1
  }
  return curLocName
}

//removes all optimization results;
export function resetOptimizationResults(geoJson) {
  var features = [...geoJson['features']]
  features = features.filter(function(item) { 
      return !(item.properties.vehicleId);  
   });  
   var newGeoJson = {...geoJson, features: features}
   return newGeoJson;
}

//finds a location's uuid from its coordinates
export function getUuidFromCoords(geoJson, lng, lat) {
  for (var i = 0; i < geoJson['features'].length; i++) { 
    const feature = geoJson['features'][i]
    if (feature['geometry']['type'] == 'Point') {
      const featureCoords = feature.geometry.coordinates;
      if (featureCoords[0] == lng) {
        if (featureCoords[1] == lat) {
          return feature['properties']['id']
        }
      }
    }
  }
}


export const initializeGeoJson = (data) => {
  var initGeoJson = {
      type: "FeatureCollection", 
      features:[],
      invalidFeatures: []
  }
  if (data) {
    for (var i=0; i<data['locations'].length; i++) {
        const loc = data['locations'][i]['Location Name'];
        const lat = data['locations'][i]['Latitude'];
        const lng = data['locations'][i]['Longitude'];
        const uuid = data['locations'][i]['UUID'];
        const isDepot = data['locations'][i]['Depot']
        const color = data['locations'][i]['Color']

        const geoJsonPoint = createPointGeoJson({
          locationName: loc, 
          latitude: lat,
          longitude: lng, 
          uuid: uuid, 
          color: color,
          colorHover: null, 
          global: true,
          isDepot: isDepot,
          dataRow: data['locations'][i]
        });
        initGeoJson['features'].push(geoJsonPoint);
    }
    for (var i=0; i<data['trips'].length; i++) {
        const fromLat = data['trips'][i]['From Location Latitude'];
        const toLat = data['trips'][i]['To Location Latitude'];
        const fromLng = data['trips'][i]['From Location Longitude'];
        const toLng = data['trips'][i]['To Location Longitude'];
        const fromName = data['trips'][i]['From Location'];
        const toName = data['trips'][i]['To Location'];
        const uuid = data['trips'][i]['UUID'];
        const fromUuid = data['trips'][i]['From UUID'];
        const toUuid = data['trips'][i]['To UUID'];
        const color = data['trips'][i]['Color'];
        const geoJsonTrip = createTripGeoJson({
            fromLat: fromLat, 
            fromLng: fromLng, 
            toLat: toLat, 
            toLng: toLng,
            fromName: fromName, 
            toName: toName,
            uuid: uuid,
            fromUuid: fromUuid,
            toUuid: toUuid,
            color: color,
            type: 'trip'});

        initGeoJson['features'].push(geoJsonTrip.geoJson);
    }
  }

  return initGeoJson;
}


export const addRowGeoJsonHelper = (state, uuid, newRow, tableName) => {
  const newState = {...state};

  if (!uuid) {
      throw 'no uuid recieved in addRow geoJsonReducer';
  }
  if (tableName == 'locations') {
      // if (newRow['Latitude'] && newRow['Longitude']) {
          const lat = newRow['Latitude'];
          const lng = newRow['Longitude'];
          var locName = newRow['Location Name'];
          locName = checkIfNameExists(newState, locName);
          const isDepot = newRow['Depot']
          const color = newRow['Color']
          const pointGeoJson = createPointGeoJson({
            locationName: locName,
            latitude: lat,
            longitude: lng,
            uuid: uuid,
            color: color, 
            colorHover: null, 
            global: true,
            isDepot: isDepot, 
            dataRow: newRow});
          if (!pointGeoJson) {
              return newState
          }
          newState['features'].push(pointGeoJson);
      // }
  }

  if (tableName == 'trips') {
      const fromName = newRow['From Location'];
      var fromLng = newRow['From Location Longitude'];
      var fromLat = newRow['From Location Latitude'];

      if (!fromLng | !fromLat) {
          var fromCoords = getCoordsFromLocationName(newState, fromName);
          if (fromCoords) {
              fromLat = fromCoords[1];
              fromLng = fromCoords[0];
          }
      }

      const toName = newRow['To Location'];
      var toLng = newRow['To Location Longitude'];
      var toLat = newRow['To Location Latitude'];
      
      if (!toLat | !toLng) {
          var toCoords = getCoordsFromLocationName(newState, toName);
          if (toCoords) {
              toLat = toCoords[1];
              toLng = toCoords[0];
          }
      }
      
      const fromUuid = newRow['From UUID']
      const toUuid = newRow['To UUID']
      var tripGeoJson;

      const tripResponse = createTripGeoJson({
          fromLat: fromLat,
          fromLng: fromLng,
          toLat: toLat,
          toLng: toLng,
          fromName: fromName,
          toName: toName,
          uuid: uuid,
          fromUuid: fromUuid,
          toUuid: toUuid
      });
      tripGeoJson = tripResponse['geoJson'];
      const valid = tripResponse['valid'];
      // if (valid) {
      newState['features'].push(tripGeoJson)
          // } 
      // else {
      //     newState['invalidFeatures'].push(tripGeoJson)
      // }
  }
  return newState;
}


export const deleteRowGeoJsonHelper = (state, uuid, tableName) => {
  // const newState = {...state};
  if (tableName == 'locations') {
      state['features'] = state['features'].filter(function(item) { 
          return (item.properties.uuid !== uuid);  
       });
       state['features'] = state['features'].filter(function(item) { 
          return (item.properties.toUuid !== uuid);  
       });
       state['features'] = state['features'].filter(function(item) { 
          return (item.properties.fromUuid !== uuid);  
       });
       state['invalidFeatures'] = state['invalidFeatures'].filter(function(item) { 
          return (item.properties.uuid !== uuid);
       });
       state['invalidFeatures'] = state['invalidFeatures'].filter(function(item) { 
          return (item.properties.toUuid !== uuid);
       });
       state['invalidFeatures'] = state['invalidFeatures'].filter(function(item) { 
          return (item.properties.fromUuid !== uuid);  
       });
      }

  if (tableName == 'trips') {
    state['features'] = state['features'].filter(function(item) { 
          return (item.properties.uuid !== uuid);  
      });
  }
  
  if (tableName.search('results') >= 0) {
    state['features'] = state['features'].filter(function(item) { 
          return (item.properties.uuid !== uuid);  
      });
  }
  return state
}

export const clearGeoJsonResults = (state) => {
  state['features'] = state['features'].filter(function(item) { 
    return  !(item.properties.type == 'routeLeg' || item.properties.type == 'routePoint' || item.properties.type == 'drop');
  })
  // state['features'] = state['features'].filter(function(item) { 
  //   return  !(item.properties.type == 'routePoint');
  // })
  return state
}

export const addGeoJsonTripHelper = (state, uuid, toUuid, fromUuid, newTrip) => {

  if (!uuid) {
      throw 'no uuid recieved in addRow geoJsonReducer';
  }
  if (!toUuid) {
      throw 'no toUuid recieved in addRow geoJsonReducer';
  }
  if (!fromUuid) {
      throw 'no fromUuid recieved in addRow geoJsonReducer';
  }


  const toLoc = state['features'].filter(function(item) {
      return ((item.geometry.coordinates[0] == newTrip.geometry.coordinates[1][0]) & (item.geometry.coordinates[1] == newTrip.geometry.coordinates[1][1]))
  });

  const fromLoc = state['features'].filter(function(item) {
      return ((item.geometry.coordinates[0] == newTrip.geometry.coordinates[0][0]) & (item.geometry.coordinates[1] == newTrip.geometry.coordinates[0][1]))
  });


  const coords = newTrip.geometry.coordinates;
  const fromLat = coords[0][1];
  const fromLng = coords[0][0];
  const toLat = coords[1][1];
  const toLng = coords[1][0]
  const myNewTrip = createTripGeoJson({
      fromLat: fromLat, 
      fromLng: fromLng,
      toLat: toLat, 
      toLng: toLng,
      fromName: fromLoc[0].properties.name, 
      toName: toLoc[0].properties.name,
      uuid: uuid,
      fromUuid: fromUuid,
      toUuid: toUuid
  })
  state['features'].push(myNewTrip.geoJson);
  return state;
}

export const addGeoJsonResults = (state, action) => {
  const vehiclePaths = action.payload['results']['vehiclePaths'];

  if (state['features'].length > 0) {
    state['features'] = state['features'].filter(function(item) { 
        return !(item.properties.type == 'routeLeg');  
    });
  }

  
  const routeGeoJson = createRoutesGeoJson({vehiclePaths: vehiclePaths, showRetours:true });


  state['features'].push.apply(state['features'], routeGeoJson)
  var drops = action.payload['results']['drops'];
  if (typeof drops === 'string' || drops instanceof String) {
    drops = JSON.parse(drops)
  }
  if (drops) {
    const dropGeoJson = createDropsGeoJson({drops: drops})
    state['features'].push.apply(state['features'], dropGeoJson)
  }
  return state
}

/**
 * 
 * @param {*} state the geojson state
 * @param {*} locationUuid the UUID of the location to modify
 * @param {*} depotUuid the UUID of the depot location
 * @param {*} add boolean, indicating whether to add or remove this as a depot
 */
export const updateDepotHelper = (state, locationUuid, depotUuid, add) => {
  const locationGeoJson = getValuesFromUuid(state, locationUuid)
  if (locationGeoJson) {
    const curDepotValues = locationGeoJson.properties.isDepot;
    if (curDepotValues) {
      const curIdx = curDepotValues.indexOf(depotUuid);
      if (add) {
        // if (curIdx == -1) {
        curDepotValues.push(depotUuid);
        // }
      } else {
        curDepotValues.splice(curIdx, 1)
      }
      // const newDepotValues = curDepotValues.push(newValue);
      updateGeoJsonFromUUID(state, locationUuid, 'isDepot', curDepotValues);
    } else {
      if (add) {
        const newDepotValues = [depotUuid];
        updateGeoJsonFromUUID(state, locationUuid, 'isDepot', newDepotValues);
      } else {
        updateGeoJsonFromUUID(state, locationUuid, 'isDepot', []);
      }
    }
  }
  else {
    if (add) {
      const newDepotValues = [depotUuid]
      updateGeoJsonFromUUID(state, locationUuid, 'isDepot', newDepotValues);
    }
  }

  return state
}


export const updateTableHelper = (state, tableName, columnName, uuid, newValue) => {

  if (!uuid) {
        const err_msg = 'no UUID provided in the dispatch payload for updateTable.';
        throw err_msg;
      }

      if (!(tableName == 'locations' || tableName == 'trips')) {
          return
      }

      if (tableName == 'locations') {
          if (columnName == 'Location Name') {
              updateGeoJsonFromUUID(state, uuid, 'name', newValue);
              state = updateGeoJsonTripFromLocationUuid(state, uuid, 'name', newValue)
          }
          if (columnName == 'Latitude') {
              updateGeoJsonFromUUID(state, uuid, 'lat', parseFloat(newValue));
              state = updateGeoJsonTripFromLocationUuid(state, uuid, 'latitude', newValue)
          }
          if (columnName == 'Longitude') {
              updateGeoJsonFromUUID(state, uuid, 'lng', parseFloat(newValue));
              state = updateGeoJsonTripFromLocationUuid(state, uuid, 'longitude', newValue)
          }
          if (columnName == 'isDepot') {
            updateGeoJsonFromUUID(state, uuid, 'isDepot', newValue);     
          }
          else{
            updateGeoJsonFromUUID(state, uuid, columnName, newValue); 
          }
          
      }
      if (tableName == 'trips') {
          if (columnName == 'From Location') {
              updateGeoJsonFromUUID(state, uuid, 'fromLoc', newValue);
              const newCoords = getCoordsFromLocationName(state, newValue);
              updateGeoJsonFromUUID(state, uuid, 'fromCoords', newCoords);
          }
          if (columnName == 'To Location') {
              updateGeoJsonFromUUID(state, uuid, 'toLoc', newValue);
              const newCoords = getCoordsFromLocationName(state, newValue);
              updateGeoJsonFromUUID(state, uuid, 'toCoords', newCoords);
              const toUuid = getFieldFromLocationName(state, newValue, 'uuid')
              updateGeoJsonFromUUID(state, uuid, 'toUuid', toUuid);
          }                    
      }
    return state;
}


export const onLocationDataChangeGeoJsonHelper = (state, action) => {
  const updatedState = action.payload.newState;
  if (updatedState.cellEdit) {
      var newState = {...state}
      const oldState = action.payload.oldState
      const tableName = action.payload.tableName;
      const uuid = updatedState.cellEdit.rowId;
      const newValueField = updatedState.cellEdit.dataField;
      const newValue = updatedState.cellEdit.newValue;

      if (tableName == 'locations') {
          var item = oldState[tableName].find(x => x['UUID'] == uuid);
          const locName = item['Location Name'];
          var lat = item['Latitude'];
          if (newValueField  == 'Latitude') {
              lat = newValue
          }
          var lng = item['Longitude']
          if (newValueField  == 'Longitude') {
              lng = newValue
          }
          var newName = null;
          if (newValueField == 'Location Name') {
              newName = newValue
          }
          const newCoords = [parseFloat(lng), parseFloat(lat)]
          newState = updateGeoJsonPointFromName(newState, locName, newCoords, newName);
          state = newState;
      }

      if (tableName == 'trips') {
          var item = oldState[tableName].find(x => x['UUID'] == uuid);
          const fromName = item['From Location'];
          const toName = item['To Location'];
          var newFromName = null;
          if (newValueField == 'From Location') {
              newFromName = newValue;
          }
          var newToName = null;
          if (newValueField == 'To Location') {
              newToName = newValue;
          }
          var newFromLat = item['From Location Latitude']
          if (newValueField == 'From Location Latitude') {
              newFromLat = newValue;
          }
          var newFromLng = item['From Location Longitude']
          if (newValueField == 'From Location Longitude') {
              newFromLng = newValue;
          }    
          var newToLat = item['To Location Latitude']
          if (newValueField == 'To Location Latitude') {
              newToLat = newValue;
          }    
          var newToLng = item['To Location Longitude']
          if (newValueField == 'To Location Longitude') {
              newToLng = newValue;
          }    
          const newFromCoords = [newFromLng, newFromLat];
          const newToCoords = [newToLng, newToLat]

          newState = updateGeoJsonTripFromNames(newState, fromName, toName, newFromName, 
              newToName, newFromCoords, newToCoords);
          state = newState
      }
  }  
}