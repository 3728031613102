
import { Layer, Source} from "react-map-gl";
import { useSelector, shallowEqual } from 'react-redux';
import {
    selectFilteredData
  } from 'store/reducers/inputReducer.js';
import {pointProps} from 'views/admin/Maps/geomapping/Layers/common_props.js';

const PointHighlightLayer = (props) => {
    const data = useSelector(selectFilteredData({'excludeTrips': true, 'excludeResults':true}, shallowEqual)).geoJsonData;

    const unclusteredPointHighlightLayerProps = {
      ...pointProps, 
      id: 'unclustered-point-highlight',
      source: 'point-highlight-source',
      filter: ['all', ['!', ['has', 'point_count']], ['==', ['get', 'uuid'], props.selectedUuid]],
      paint: {
        ...pointProps.paint,
        'circle-color': ['get', "colorHoverHex"],
        'circle-stroke-width': 2,
        'circle-stroke-color': ['get', "colorHex"]
      }
    };

    return (<>
          <Source
            id="point-highlight-source"
            type="geojson"
            data={data}
            cluster={true}
            clusterMaxZoom={25}
            clusterRadius={20}
          >
            <Layer {...unclusteredPointHighlightLayerProps} />
          </Source>
    </>)
}

export default PointHighlightLayer;