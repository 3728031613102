import React from "react";
import { useTheme } from "@material-ui/core/styles";  
import GoogleAutoComplete from 'views/admin/Forms/GoogleAutoComplete.jsx'

const MapLocationSearchBar = React.memo(function MapLocationSearchBar(props) {
    /**
     * This component is a search bar that allows the user to search for a location on the map.
     * It is a GoogleAutoComplete component that is styled to fit the map. It gets called
     * from the Map component.
     * 
     * @param {Object} props - The props of the component.
     * @param {Object} props.bounds - The bounds of the map, a dictionary of the form:
     * {_ne: {lat: number, lng: number}, _sw: {lat: number, lng: number}
     */
    const theme = useTheme();
    const bounds = props.bounds;
    const sw_lat = bounds && bounds._sw.lat;
    const sw_lng = bounds && bounds._sw.lng;
    const ne_lat = bounds && bounds._ne.lat;
    const ne_lng = bounds && bounds._ne.lng;
    return (
            <div style={{zIndex:'3', maxWidth:'300px', marginTop:'auto'}}>
                <GoogleAutoComplete searchId={'map-search-bar'} boxStyle={{
                    backgroundColor: theme.palette.white.main,
                    border: '2px solid ' + theme.palette.primary.main,
                    borderRadius: '5px',
                    marginTop:'3px'
                    }}
                    searchBoxStyle={{
                        border: '2px solid ' + theme.palette.primary.main,
                        width:'100%',
                    }}
                    searchCollapse
                    flipArrow
                    bounds={bounds && {sw: {lat: sw_lat, lng: sw_lng}, ne: {lat: ne_lat, lng: ne_lng}}}
                    />
            </div>
    );
});

export default MapLocationSearchBar;