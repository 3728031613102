const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']

//json file should include, at a minimum, "From Location Latitude", "From Location Longitude",
//"To Location Latitude", "To Location Longitude", "Travel Cost"
async function table_to_excel(tableArray, filename) {

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ tableArray })
    };
    // const response = await fetch(BASE_ROUTE + '/to_excel', requestOptions)
    // const retJson = await response.json()

    // const filename = 'test.xlsx'
    fetch(BASE_ROUTE + '/to_excel' , requestOptions)
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))
    .then(uril => {
    var link = document.createElement("a");
    link.href = uril;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    });

}

export default table_to_excel

export async function table_to_json(tableArray, filename) {

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ tableArray })
    };
    // const response = await fetch(BASE_ROUTE + '/to_excel', requestOptions)
    // const retJson = await response.json()

    // const filename = 'test.xlsx'
    fetch(BASE_ROUTE + '/to_json' , requestOptions)
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))
    .then(uril => {
    var link = document.createElement("a");
    link.href = uril;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    });

}