export const tripProps = {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-width': {
        'base': 2,
        'stops': [
          [1, 2],
          [8, 3],
          [15, 6],
          [22, 8]
        ]
      }
    }
};


export const arrowProps = {
    type: 'symbol',
    filter: ['==', '$type', 'LineString'],
    layout: {
        'symbol-placement': 'line',
        'symbol-spacing': 100,
        'icon-allow-overlap': true,
        'icon-image': 'arrow',
        'icon-size': 0.7,
        'visibility': 'visible'
    },
    paint: {
        "icon-color": '#000000',
        "icon-halo-color": "#000000",
        "icon-halo-width": 0
    }
}

export const pointProps = {
    type: 'circle',
    paint: {
      'circle-color': '000000',
      'circle-radius': 8,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff'
    }
}

export const clusterProps = {
    type: 'circle',
    filter: ['all', ['has', 'point_count']],
    paint: {
      'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
      'circle-radius': ["min", ["+", 10 ,['get', 'point_count']], 20],
      'circle-stroke-width': 3,
      'circle-stroke-color': '#000000'
    }
}