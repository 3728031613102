import { configureStore } from '@reduxjs/toolkit';
import inputReducer from 'store/reducers/inputReducer';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

//persist instructions: https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {DEFAULT_NOTIFICATIONS, DEFAULT_OPTIMIZATION} from 'store/reducers/inputReducer.js'

const transformer = createTransform(
  /**
   * Use this function to transform the state being rehydrated. There is a method for doing
   * this for both inbound and outbound states. Currently only using the outbound state.
   * 
   * https://github.com/rt2zz/redux-persist#transforms
   */

  // transform state being rehydrated
  (outboundState, key) => {
    var i  = DEFAULT_NOTIFICATIONS;
    if (key === 'present' ) {
      return { ...outboundState, notifications: DEFAULT_NOTIFICATIONS, loading: {}, optimization: DEFAULT_OPTIMIZATION};
    } else {
      return outboundState
    }
  },

);

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  transforms: [transformer],
 };


const store = configureStore({
  reducer: {
    inputData: persistReducer(persistConfig, inputReducer)
  },
});


export const persistor = persistStore(store
);
// persistor.purge();
export default store

