import './column-mapping.css';
import React, { useState, useEffect} from 'react'
import { useDrag, DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import MaterialTooltip from "@material-ui/core/Tooltip";
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Tooltip } from 'react-tooltip'


import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";

import {
    selectTableSettings,
    selectColumnMappingSettings,
    updateGeneralSetting
  } from 'store/reducers/inputReducer';

import componentStyles from "assets/theme/views/admin/buttons.js";
import componentStylesButtons from "assets/theme/components/button.js";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const allStaticTableSettings = require('store/data/table_settings_static.json')

/*
This is a widget that allows the user to map items from one list on the left side to another list on
the right side. The primary use for this is to map column names from one table to the column names
of another table.
*/
//http://jsfiddle.net/alexburr/CvDzr/


const targetFieldTooltip = 'The field names to match in the Optimiciti table. Required fields are indicated with an asterisk.'
const sourceFieldTooltip = "The field names from the input data table. Drag and drop the labels below to the appropriate field names for the table in Optimiciti. Not all fields are required."
const iconStyle = {'maxWidth':'200', width:'20', display:'inline-block', paddingLeft:'5px', float:'none'}

const DraggableLabel = function DraggableLabel({ name })  {

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'box',
        item: { name },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                dropResult.dropFunction(item.name);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }));

    return (

            <Grid item xs={12} sm={12} style={{padding:'2px'}}>
                <Button ref={drag} variant="contained" size={'small'} className={'draggableButton'}>{name}</Button>
            </Grid>
    )
}


const TargetField = (props) => {

    const classes = { ...useStyles(), ...useStylesButtons() };
    const [entry, setEntry] = useState(null);
    const [nullified, setNullified] = useState(false);

    const setValue = function(value) {
        props.setEntry(props.labelValue, value)
        setEntry(value);
        if (value) {
            setNullified(false)
        } else {
            setNullified(true)
        }
    }

    function getVariant() {
        if (isOver) {
            return 'contained'
        }
        if (!props.defaultValue && !entry) {
            return 'outlined'
        }
        if (nullified) {
            return 'outlined'
        }
        return 'contained'
    }

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: 'box',
        drop: () => ({ name: 'TargetField', dropFunction:setValue}),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    const isActive = canDrop && isOver;

    if (isActive) {
    }
    else if (canDrop) {
    }

    const getColor = () => {
        if (((!props.defaultValue && !entry) || nullified)  && props.required) {
            return 'error'
        }
        else {
            if (isOver) {
                return 'default'
            } else {
                return 'primary'
            }
        }
    }

    const getClassName = () => {
        const variant = getVariant();
        if (variant === 'outlined') {

            if (((!props.defaultValue && !entry) || nullified)  && props.required) {
                return classes.buttonOutlineError
            }
            
            return classes.buttonOutlineDefault
 
        }

        if (((!props.defaultValue && !entry) || nullified)  && props.required) {
            return classes.buttonContainedError;
        }

        return classes.buttonContainedDefault; 

    }       


    return (
    <div>
        <Grid container>
            <Grid item xs={5}>
                <MaterialTooltip title={props.label}>
                <label className={'targetLabel'}>{props.label}</label>
                </MaterialTooltip>
            </Grid>

            <Grid item xs={5} style={{paddingLeft:'0px', paddingRight:'0px', overflow:'hidden'}}>
                <MaterialTooltip title={(!nullified && (entry || props.defaultValue)) || '(drag here)'} placement='right' arrow>
                <Button ref={drop}  color={getColor()} variant={getVariant()} size='small' className={isOver ? classes.buttonContainedPrimary : getClassName()} 
                style={{whiteSpace:'nowrap'}}>
                    {(!nullified && (entry || props.defaultValue)) || '(drag here)'}
                </Button>
                </MaterialTooltip>
            </Grid>
            <Grid item xs={2}>
                {(entry || props.defaultValue) && !nullified &&
                <IconButton size='small' onClick={(event) => {event.stopPropagation(); setValue(null);}} style={{float:'right', marginTop:'-5px'}}><CancelIcon /></IconButton>
                  }
            </Grid>
        </Grid>
        
    </div>
    );

};



const ColumnMapping = (props) => {
    const tableSettings = useSelector(selectTableSettings(props.targetTable));
    const staticTableSettings = allStaticTableSettings[props.targetTable];
    const columnMappingClearExisting = useSelector(selectColumnMappingSettings).clearExistingData
    const dispatch = useDispatch();

    const classes = { ...useStyles(), ...useStylesButtons() };
    const [loaded, setLoaded] = useState(false);
    const [entries, setEntries] = React.useState(null);
    const [targetFields, setTargetFields] = React.useState(null);
    const [sourceLabels, setSourceLabels] = React.useState(null);

    
    const initializeTargetData = () => {
        var initEntries = {}
        var initTargetFields = []
        tableSettings['isViewable'] && Object.keys(tableSettings['isViewable']).map((columnName, idx) => {
            if (columnName && tableSettings['isViewable'][columnName]) {
                const required = staticTableSettings['isRequired'][columnName]
                var defaultValue = null;
                if (entries && entries[columnName]) {
                    defaultValue = entries[columnName]
                }
                else if (props.sourceData.includes(columnName)) {
                    defaultValue = columnName;
                }
                else if ( props.sourceData.some(x => x && x.toLowerCase() === columnName.toLowerCase())) {
                    defaultValue = props.sourceData.find(x => x && x.toLowerCase() === columnName.toLowerCase())
                }
                var label = staticTableSettings['columnHeader'] && staticTableSettings['columnHeader'][columnName] || columnName;
                if (required) {
                    label = '*' + label
                }
                initEntries[columnName] = defaultValue
                initTargetFields.push(<TargetField setEntry={setEntry} required={required} label={label} labelValue={columnName}  defaultValue={defaultValue} ></TargetField>)
            }
        });
        const newLabels = [];
        {props.sourceData.map( (colName, idx) =>{
            newLabels.push(<DraggableLabel name={colName} ></DraggableLabel>)
        })}


        setEntries(initEntries);
        setTargetFields(initTargetFields);
        setSourceLabels(newLabels);

    }


    const toggleColumnMappingClearExisting = () => {
        dispatch(updateGeneralSetting({settingName: 'columnMapping', subSettingName: 'clearExistingData', value: !columnMappingClearExisting}))
    }


    var setEntry = (label, value) => {

        setEntries(oldEntries => {
            oldEntries[label] = value;
            return oldEntries;
        });

        
    }


    useEffect(() => {
        initializeTargetData();
      }, [props])

      useEffect(() => {
          if (!loaded) {
              setLoaded(true);
              initializeTargetData();
          }
      });

      
    return <>
        <DndProvider backend={HTML5Backend}>
        <Grid container spacing={12}>
            <Grid item xs={5} style={{paddingRight:'5px'}}>
                    <Card>
                        <CardHeader style={{paddingTop:'0px', paddingBottom:'10px'}}
                            title={<div>Source Fields<MaterialTooltip title={sourceFieldTooltip} placement='top'><span>
                                <i class="material-icons helpIcon" style={iconStyle} 
                                >info</i></span>
                                </MaterialTooltip></div>}>
                            </CardHeader>
                        <CardContent style={{paddingTop:'0px', padding:'5px'}}>
                                <Box>{sourceLabels}
                                
                                </Box>
                        </CardContent>
                    </Card>
                    
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem style={{marginRight:"-2px"}}/>
            <Grid item xs={7} style={{paddingLeft:'5px'}}>
                <Card>
                    <CardHeader style={{paddingTop:'0px', paddingBottom:'10px'}}
                    title={<div>Target Fields
                        <MaterialTooltip title={targetFieldTooltip} placement='top'><span><i class="material-icons helpIcon" style={iconStyle} 
                                >info</i></span>
                    </MaterialTooltip>
                    </div>}>
                    </CardHeader>
                    <CardContent style={{paddingTop:'0px', padding:'5px'}}>
                        {targetFields}
                    </CardContent>
                </Card>
 
            </Grid>
        </Grid>
        <Grid container>
            <Grid item>
            <FormControlLabel control={<Checkbox checked={columnMappingClearExisting} onChange={toggleColumnMappingClearExisting}/>} label="Clear existing data" />
            </Grid>
        </Grid>
        <Grid container style={{textAlign: "center"}}>
            <Grid item xs={8}>
                <Button style={{width:'100%'}} variant={'contained'} color='primary'
                onClick={() => {props.onSubmitPress(entries); setEntries(null)}}>
                    Submit Entries
                </Button>
            </Grid>
            <Grid item xs={4}>
                <Button style={{width:'100%'}}  className={classes.buttonContainedWarning} variant={'contained'} onClick={(event)=> {props.onCancel(event)}} >Cancel</Button>
            </Grid>
        </Grid>
      
        </DndProvider>
        <Tooltip  multiline={true} className={'tooltip'} id={"column-mapping-tooltip"}/>  
        <textarea id="output"></textarea>
    </>
}

export default ColumnMapping
