import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectFilterState,
    selectTableData,
    addFilter,
    removeFilter
  } from 'store/reducers/inputReducer';


/*
 * The component controls the checkboxes that toggle on/off vehicle filters for each Vehicle in the vehicle table. 
 Generally, this component should only be viewable when results are available. 
 */
const VehicleFilterCheckboxes  = () => {

    const vehicleData = useSelector(selectTableData('vehicles'));
    const vehicleFilters = useSelector(selectFilterState('vehicleIds'));
    const dispatch = useDispatch();

    const handleClick = (event) => {
        const idx = event.target.value;
        const value = vehicleData[idx]['Vehicle ID'];
        const checked = event.target.checked;
        if (checked) {
            dispatch(removeFilter({'field': 'vehicleId', 'value': value}));
        } else {
            dispatch(addFilter({'field': 'vehicleId', 'value': value}));

        }
    }

    const handleAllClick = (event) => {
        const checked = event.target.checked;

        if (checked) {
            dispatch(removeFilter({'field': 'vehicleId', 'value': vehicleFilters}));
        } else {
            const values = [];
            vehicleData.map((elem, idx) => {
                const newValue = elem['Vehicle ID'];
                if (!vehicleFilters.includes(newValue)) {
                    values.push(newValue);
                }
            });
            dispatch(addFilter({'field': 'vehicleId', 'value': values}));
        }
    }

    return (

        <FormGroup style={{display:'inline-block', margin:'0px'}}>
            {vehicleData.length > 1 && 
            <FormControlLabel control={
                <Checkbox checked={vehicleFilters.length == 0} 
                onClick={(event) => handleAllClick(event)} style={{ color: 'gray' }}/>
            } label={vehicleFilters.length > 0 ? 'Toggle All On' : 'Toggle All Off'} style={{display:'inlineBlock', marginBottom:'0px'}}/>}

        {vehicleData.map((elem, idx) => {
            const checkbox = <Checkbox checked={!vehicleFilters.includes(elem['Vehicle ID'])} value={idx}
            onClick={(event) => handleClick(event)} style={{ color: elem['Color'] }}/>
            return (<FormControlLabel control={checkbox} label={elem['Vehicle ID']} style={{display:'inlineBlock', marginBottom:'0px'}}/>)
        })}
        </FormGroup>
  
    );
}

export default VehicleFilterCheckboxes;
