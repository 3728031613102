import React, {useState} from 'react';
import ColorSwatch from 'views/admin/Forms/colorbar/ColorSwatch.js'
import ColorPicker from "react-simple-colorpicker";
import 'views/admin/Forms/colorbar/theme/color-bar.scss'
import get_contrast_color from 'functions/general/color_contrast.js'
const input_colors = require("views/admin/Forms/colorbar/colors.json");


const CustomColorPicker = (props) => {
    const [colors, setColors] = useState(input_colors);
    const [selected, setSelected ] = useState(0);

    const handleColorSelect = (selected) => {
      setSelected(selected);
      props.setColor(colors[selected]);
    }
    
    const handleColorChange = (color) => {
      colors[selected] = color;
      setColors(colors);
      props.setColor(colors[selected]);
    }

    return (
      <div>
        <div className="picker picker-left">
          <ColorSwatch
            colors={colors}
            selected={props.color}
            onSelect={handleColorSelect}
          />
        </div>
        <div className="picker picker-right">
          <ColorPicker color={props.color} onChange={handleColorChange} opacitySlider />
        </div>
        <button onClick={() => props.onClose()} style={{backgroundColor: props.color,
          color: get_contrast_color(props.color),
          width:'100%', border:'none', borderRadius:'5px'}}>Save and Close</button>
      </div>
    );

}

export default CustomColorPicker;