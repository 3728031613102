import {useState, useEffect}from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTheme } from "@material-ui/core/styles";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import MyMuiButton from 'views/admin/Components/MyComponents/MyMuiButton.jsx'
import Stack from '@mui/material/Stack';
import 'material-icons/iconfont/material-icons.css';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import {
  selectGeneralSetting,
  selectCostSetting,
  updateGeneralSetting,
  fetchTravelCosts
} from 'store/reducers/inputReducer.js'
import CostSettings from 'views/admin/Forms/Cost/CostSettings'
import OverwriteCostsToggle from 'views/admin/Forms/Cost/OverwriteCostsToggle';

import {
  infoIcon
} from 'views/admin/Forms/Cost/SharedCostStyles.js'



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


export default function CostSettingsTabbedForm(props) {
  const theme = useTheme();
  const { open } = props;
  const ogCostMetricsSelector = useSelector(selectGeneralSetting("costs"));
  const [ogCostMetrics, setOgCostMetrics] = useState(null);
  const [ogCostMetricsSet, setOgCostMetricsSet] = useState(false);
  const costMetric = useSelector(selectCostSetting({settingName:'costMetric'}));
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch()

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    setOgCostMetricsSet(false);
    dispatch(fetchTravelCosts())
    props.onClose();
  };

  const handleCancel = () => {
    dispatch(updateGeneralSetting({settingName: 'costs', value: ogCostMetrics}));
    props.onClose();
  }

  useEffect(() => {
    if (!ogCostMetricsSet) {
      setOgCostMetricsSet(true);
      setOgCostMetrics(JSON.parse(JSON.stringify(ogCostMetricsSelector)))
    }
  })

  return (
    <>
    <Card onClose={handleClose} onClick={(event) => event.stopPropagation()} open={open}>
      
      <CardHeader style={{padding:'15px', paddingBottom:'10px'}}>
        <div style={{display:'inline-flex', flexDirection:'row'}}>
          <span>Cost settings</span>
          <Tooltip title='Select the model cost setting. That is, what is the cost metric you want to optimize on (e.g. minimize the total distance traveled, in miles).' placement='top'>
                    {infoIcon}
        </Tooltip>
        </div>
        </CardHeader>

      <CardContent style={{paddingTop:'0px', paddingLeft:'15px', paddingRight:'15px'}}>

      <Stack direction='column' spacing={2} style={{minHeight:'300px', paddingTop:'0px'}}>

          <Box id={'table-panel-box'} sx={{ borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example"
              sx={{'.MuiButtonBase-root': {'color':'inherit', '&.Mui-selected': {'color':theme.palette.primary.main}},
              '.MuiTabs-indicator': {'backgroundColor': theme.palette.primary.main}
            }}
            >
              <Tab label="Cost" {...a11yProps(0)} />
              <Tab label="Duration" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TabPanel id='distance-tab-panel' value={tabValue} index={0} style={{margin:'-14px'}}>
            <CostSettings costMetric='distance' toggleChoice/>
          </TabPanel>


          <TabPanel id='duration-tab-panel' value={tabValue} index={1} style={{margin:'-14px'}}>
            <CostSettings costMetric='duration' toggleChoice/>
          </TabPanel>
            <OverwriteCostsToggle />
          </Stack>
          <Stack direction='column'>
            <Grid container style={{paddingTop:'10px'}}>
                <Grid item xs={12} sm={8}>
                    <MyMuiButton variant={costMetric ? "contained": "outlined"}
                        onClick={(event) => {event.stopPropagation(); handleClose()}} fullWidth
                        >
                        Save and calculate
                    </MyMuiButton>
                </Grid>
                <Grid item xs={12} sm={4} padding={'0px'}>
                    <MyMuiButton variant={"contained"} color='warning'
                            onClick={(event) => {event.stopPropagation(); handleCancel()}} 
                            fullWidth 
                    >
                      Close
                    </MyMuiButton>                
                </Grid>
            </Grid>
        </Stack>
        </CardContent>
    </Card>
    </>
  );
}

