import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Clear from "@material-ui/icons/Clear";

import React, {useRef, useState, useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from "@material-ui/core/Grid";
import IconButton from '@mui/material/IconButton';
import componentStyles from "assets/theme/views/admin/google-auto-complete.js";
import Script from 'react-load-script';
import { useDispatch } from 'react-redux';
import FormItem from 'views/admin/Forms/FormItem.js';
import FormOptionsButtonPanel from 'views/admin/Forms/FormOptionsButtonPanel.js';
import {
    addRow,
    addRowGeoJson
  } from 'store/reducers/inputReducer';
import {generateUUID} from "store/functions/geoJsonFunctions.js"
import 'views/admin/Forms/google-auto-complete.css'
const useStyles = makeStyles(componentStyles);


const defaultTooltip = 'Add location to table.'


const GoogleAutoComplete  = (props) => {
    const dispatch = useDispatch();

    const classes = { ...useStyles() };
    const [googleScriptLoaded, setGoogleScriptLoaded] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [inputsValid, setInputsValid] = useState([true, false, true]);
    const [buttonValid, setButtonValidator] = useState(true);
    const [addButtonTooltip, setButtonTooltip] = useState(defaultTooltip);
    const locRef = useRef(null);
    const latRef = useRef(null);
    const lngRef = useRef(null);
    const searchId = props.searchId || 'google-autocomplete'

    var autocomplete;



  const handleScriptLoad = () => {
    // Declare Options For Autocomplete
    const options = {}
    
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(searchId),
      options,
    );

    if (props.bounds) {
        autocomplete.setBounds(new google.maps.LatLngBounds(
            new google.maps.LatLng(props.bounds.sw.lat, props.bounds.sw.lng),
            new google.maps.LatLng(props.bounds.ne.lat, props.bounds.ne.lng)
        ));
    }

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    autocomplete.setFields(['geometry/location']);

    // Fire Event when a suggested name is selected
    autocomplete.addListener('place_changed', handlePlaceSelect);
    setGoogleScriptLoaded(true);
  }
  
  const handlePlaceSelect = () => {
    const addressObject = autocomplete.getPlace();

    // Check if address is valid
    if (addressObject) {
        if (addressObject.geometry && addressObject.geometry.location) {
            const lat = addressObject.geometry.location.lat;
            const lng = addressObject.geometry.location.lng;
            setInputsValid([true, true, true]);
            setButtonValidator(true)
            setButtonTooltip(defaultTooltip)

            locRef.current.setValue(addressObject.name || addressObject.formatted_address)
            latRef.current.setValue(lat());
            lngRef.current.setValue(lng());
            setExpanded(true);
            }
        }
    }

    const submitLocation = () => {
        if (checkAllValid()) {
            const newRow = {};
            newRow['Location Name'] = locRef.current.getValue();
            newRow['Latitude'] = latRef.current.getValue();
            newRow['Longitude'] = lngRef.current.getValue();
            const uuid = generateUUID();
            dispatch(addRow({'tableName': 'locations', 'newRow': newRow, 'uuid':uuid}));
            dispatch(addRowGeoJson({newRow:newRow, tableName: 'locations', uuid:uuid}));
            clearValues();
        } else {
            setButtonValidator(false)
            setButtonTooltip('Missing information. Please make valid entries for Location name, Latitude and Longitude.')
        }
    }

    const clearValues = () => {
        locRef.current.cleanState();
        latRef.current.cleanState();
        lngRef.current.cleanState();
        document.getElementById(searchId).value = '';
    }

    const checkAllValid = () => {
        const locValid = locRef.current.getValid();
        const latValid = latRef.current.getValid();
        const lngValid = lngRef.current.getValid();
        if (locValid
        && latValid
        && lngValid) {
            setButtonValidator(true)
            setButtonTooltip(defaultTooltip)
            return true
        } else {return false}
    }


    const setValidByIndex = (idx, valid) => {
        var newInputsValid = [...inputsValid];
        newInputsValid[idx] = valid;
        setInputsValid(newInputsValid);
    }

    useEffect(() => {
        if (googleScriptLoaded) {
            handleScriptLoad();
        }
    }, [props.bounds]);


    return (
      <>
        <Script
          url={"https://maps.googleapis.com/maps/api/js?key=" + process.env.REACT_APP_GOOGLE_MAPS_API + "&libraries=places"}
          onLoad={() => {handleScriptLoad()}} 
        />
        <Box style={{display:'inline-flex', flexDirection:'column'}}>

        <Box
            display="flex"
            alignItems="center"
            className={clsx(classes.searchBox, {
            [classes.searchBoxShow]: showSearch,
            })}
            style={{width:'100%', ...props.searchBoxStyle}}
            onClick={(event) => event.stopPropagation()}
            
        >   
            <SearchIcon className={classes.searchIcon} style={{marginLeft:'0.3rem', marginRight:'0.3rem'}}/>
            <InputBase
            id={searchId}
            placeholder="Add location from search"
            classes={{
                input: classes.searchInput,
            }}
            style={{width:'100%'}}
            onClick={(event) => event.stopPropagation()}
            />
            <Hidden smUp implementation="css">
            <Clear
                className={classes.searchClose}
                onClick={() => setShowSearch(false)}
            />
            </Hidden>
            <IconButton style={{ marginRight:'0px'}} onClick={() => setExpanded(!expanded)} >
                <ExpandMoreIcon 
                style={props.flipArrow ? (expanded? {} : {transform: "rotate(-180deg)"}) : expanded? {transform: "rotate(-180deg)"} : {}}/>
            </IconButton>
        </Box>
        <Collapse in={expanded} >
            <Grid container style={{width:'100%', margin:'0px', ...props.boxStyle}}>
                <Grid item xs={12} style={{padding:'5px', paddingTop:'15px'}}>
                    <FormItem tableName={'locations'} 
                                columnName={'Location Name'} 
                                ref={locRef} 
                                formIdx={0}
                                setValidByIndex={setValidByIndex}
                                setDefaultByIndex={()=>{}}
                                isRequired={true}
                    />
                </Grid>
                <Grid item xs={12} lg={6} style={{padding:'5px'}}>
                    <FormItem tableName={'locations'} 
                                columnName={'Longitude'} 
                                ref={lngRef} 
                                formIdx={1}
                                setValidByIndex={setValidByIndex}
                                setDefaultByIndex={()=>{}}
                                isRequired={true}
                    />
                </Grid>
                <Grid item xs={12} lg={6} style={{padding:'5px'}}>
                    <FormItem tableName={'locations'} 
                                columnName={'Latitude'} 
                                ref={latRef}
                                formIdx={2}
                                setValidByIndex={setValidByIndex}
                                setDefaultByIndex={()=>{}}
                                isRequired={true}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormOptionsButtonPanel
                    title={'Add location'}
                    valid={inputsValid.every(v => v === true)}
                    submitForm={submitLocation}
                    resetForm={clearValues}
                    addButtonTooltip="Add location to map"
                    />
                </Grid> 

            </Grid>
        </Collapse>
        </Box>
      </>
    );
}

export default GoogleAutoComplete;