import React from "react";
import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Collapse from '@mui/material/Collapse';
import { StepLabel, StepContent, Step } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Grid from "@material-ui/core/Grid";
import componentStyles from "assets/theme/components/cards/dashboard/card-map-options.js";
import TimeSettingsForm from 'views/admin/Forms/TimeWindows/TimeSettingsForm.js'
import {
    selectModelSetting,
    selectTimeWindowsEnabled,
    selectGeneralSetting
  } from 'store/reducers/inputReducer';
import TableUseTimeWindowsCheck from "views/admin/Forms/TimeWindows/TableUseTimeWindowsCheck";
import ChangeTimeType from "views/admin/Forms/TimeWindows/ChangeTimeType";
import WizardActionButtons from "./WizardActionButtons";


const useStyles = makeStyles(componentStyles);

export default function ModelTimeWindowSettingsCard(props) {
    const timeWindowTables = useSelector(selectModelSetting(props.modelType, 'toggleTimeWindows'));
    const timeWindowsEnabled = useSelector(selectTimeWindowsEnabled());
    const generalTimeSettings = useSelector(selectGeneralSetting('time'))
    const [activeStep, setActiveStep] = React.useState(0);
    const classes = useStyles();
    const theme = useTheme();

    const handleNext = () => {
        if (timeWindowsEnabled) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            props.handleNext()
        }
      };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

  const getStepIconProps = (step, icon, disabled) => {
    return {style:{
      color: disabled ? theme.palette.gray[500] : activeStep <= step ? theme.palette.warning.main : theme.palette.primary.main,
      fontSize:'1.5rem',
        },
        icon: icon}
  }

    return (
        
    <Grid container>
        <Stepper activeStep={activeStep} orientation="vertical">
            <Step key={'time-settings-step-a'} >
                <StepLabel StepIconProps={getStepIconProps(0, 'a')}>
                {'Select whether each table below will have time window restrictions for optimization'}
                </StepLabel>
                <StepContent>
                    <Grid item xs={12}>
                        <Card >
                            <CardContent classes={{ root: classes.collapsibleCardContent }} >
                                <Grid container spacing={1}>
                                    {Object.keys(timeWindowTables).map((timeWindowTable) => {
                                            return (<>
                                            {timeWindowTables[timeWindowTable] && generalTimeSettings[timeWindowTable] && 
                                            <Grid item xs={12} lg={6} xl={6} style={{maxWidth:'600px'}}>
                                                <Card classes={{ root: classes.collapsibleCard}} style={{padding:'10px'}}>
                                                <TableUseTimeWindowsCheck tableName={timeWindowTable}/>
                                                <Collapse in={generalTimeSettings[timeWindowTable] && generalTimeSettings[timeWindowTable]['useTimeWindows']}
                                                    unmountOnExit={true}>   
                                                    <TimeSettingsForm tableName={timeWindowTable} minHeight='1px' borderWidth='0.25px' padding='5px'/>
                                                </Collapse>
                                                </Card>
                                            </Grid>}
                                            </>
                                            )
                                        })}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <WizardActionButtons handleNext={handleNext} handleBack={props.handleBack}/>
                </StepContent>
            </Step>
            <Step key={'step2'} disabled={!timeWindowsEnabled}>
                <StepLabel 
                    StepIconProps={getStepIconProps(1, 'b', !timeWindowsEnabled)}
                >

                    {'Select time measurement unit'}
                </StepLabel>
                <StepContent>
                    {timeWindowsEnabled && <>
                        <ChangeTimeType />
                    </>}
                    <WizardActionButtons handleNext={props.handleNext} handleBack={handleBack}/>
                </StepContent>
            </Step>
        </Stepper>
    </Grid>
    );
}