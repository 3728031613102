import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import MyButton from 'views/admin/Components/MyComponents/MyButton.js'

const problemConfigs = require("store/data/model_configs.json")

function InfoDialog (props) {

    return (
      <div>
        <Dialog open={props.open} onClose={props.handleClose}>
          <DialogTitle>{problemConfigs[props.modelType]['title']}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {problemConfigs[props.modelType]['description']}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MyButton color='info' variant='contained' label='Close' 
                buttonProps={{
                    size: 'small',
                    onClick: () => props.handleClose()
                }}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }

export default InfoDialog