/**
 * This is a generic dialog that can be used to capture a user's email address and
 * name. It is used to capture user emails for update notifications.
 */

import React from "react";
import PropTypes from 'prop-types';
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from "react-redux";

import {
    FormGroup,
    Input,
    InputGroupText,
    InputGroup,
  } from "reactstrap";

import MyButton from 'views/admin/Components/MyComponents/MyButton.js'
import dialogStyles from 'assets/theme/components/dialog.js'
import componentStyles from "assets/theme/views/admin/components.js";
import {joinEmailList} from 'store/reducers/inputReducer.js'


const useStylesComponent = makeStyles(componentStyles);
const useStylesDialog = makeStyles(dialogStyles);

const DEFAULT_SUBMIT_BUTTON_TITLE = 'Submit';

function CaptureEmailDialog (props) {
    /**
     * This function is used to create a dialog that captures a user's email address and name.
     * 
     * @param {props} props - The props for the dialog.
     * @param {boolean} props.open - Whether the dialog is open.
     * @param {function} props.handleClose - The function to close the dialog.
     * @param {string} props.content - The content of the dialog.
     * @param {string} props.source - The source of the email capture.
     * @param {string} props.submitButtonTitle - The title of the submit button. If not provided,
     *  the default is 'Submit'
     * @param {boolean} props.useOrganization - Whether to use the organization field. Default is false.
     * @param {boolean} props.useMessage - Whether to use the message field. Default is false.
     */

    CaptureEmailDialog.propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        source: PropTypes.string.isRequired,
        submitButtonTitle: PropTypes.string,
        useOrganization: PropTypes.bool,
        useMessage: PropTypes.bool
    };


    if (process.env.NODE_ENV !== 'production') {
        CaptureEmailDialog.propTypes = {
            ...CaptureEmailDialog.propTypes,
            open: (props, propName, componentName) => {
                if (props[propName] === undefined) {
                    return new Error(`The prop '${propName}' is required in '${componentName}', but its value is undefined.`);
                }
            },
            handleClose: (props, propName, componentName) => {
                if (props[propName] === undefined) {
                    return new Error(`The prop '${propName}' is required in '${componentName}', but its value is undefined.`);
                }
            },
            title: (props, propName, componentName) => {
                if (props[propName] === undefined) {
                    return new Error(`The prop '${propName}' is required in '${componentName}', but its value is undefined.`);
                }
            },
            content: (props, propName, componentName) => {
                if (props[propName] === undefined) {
                    return new Error(`The prop '${propName}' is required in '${componentName}', but its value is undefined.`);
                }
            },
            source: (props, propName, componentName) => {
                if (props[propName] === undefined) {
                    return new Error(`The prop '${propName}' is required in '${componentName}', but its value is undefined.`);
                }
            }
        };
    }

    const classes = { ...useStylesDialog(), ...useStylesComponent()};
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [organization, setOrganization] = useState('');
    const [message, setMessage] = useState('');
    const [nameFocused, setNameFocused] = useState(false);
    const [emailFocused, setEmailFocused] = useState(false);
    const [organizationFocused, setOrganizationFocused] = useState(false);
    const [messageFocused, setMessageFocused] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const clearForm = () => {
        /**
         * This function is used to clear the form.
         */
        setEmail('');
        setName('');
        setOrganization('');
        setMessage('');
    }

    const submitForm = () => {
        dispatch(joinEmailList({email: email, name: name, source: props.source}))
        clearForm();
        setSubmitted(true);
      }

    const handleClose = () => {
        setSubmitted(false);
        clearForm();
        props.handleClose();
    }

    return (
      <div>
        <Dialog open={props.open} onClose={props.handleClose} className={classes.dialogTitle}>
          <DialogTitle>{props.title}</DialogTitle>
          <DialogContent><>
            {<>
                <DialogContentText style={{paddingBottom:'1rem'}}>
                {props.content}
                </DialogContentText>
                      <FormGroup
                        className={classnames({
                          focused: nameFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          <Input
                            placeholder="Your name"
                            type="text"
                            value={name}
                            onChange={ (event) => setName(event.target.value)}
                            onFocus={() => setNameFocused(true)}
                            onBlur={() => setNameFocused(false)}
                          />
                        </InputGroup>
                      </FormGroup>
                      {props.useOrganization && <FormGroup
                        className={classnames({
                          focused: organizationFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          <Input
                            placeholder="Your organization"
                            type="text"
                            value={organization}
                            onChange={ (event) => setOrganization(event.target.value)}
                            onFocus={() => setOrganizationFocused(true)}
                            onBlur={() => setOrganizationFocused(false)}
                          />
                        </InputGroup>
                      </FormGroup>}
                      <FormGroup
                        className={classnames({
                          focused: emailFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          <Input
                            placeholder="Email address"
                            type="email"
                            onFocus={() => setEmailFocused(true)}
                            onBlur={() => setEmailFocused(false)}
                            value={email}
                            onChange={ (event) => setEmail(event.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                      {props.useMessage && <FormGroup
                        className={classnames({
                          focused: messageFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          <Input
                            placeholder="Additional message (optional)"
                            type="textarea"
                            onFocus={() => setMessageFocused(true)}
                            onBlur={() => setMessageFocused(false)}
                            value={message}
                            onChange={ (event) => setMessage(event.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>}

                      <div>
                        {submitted && <div style={{color:'green', textAlign:'center', marginTop:'10px'}}>Thank you for submitting your information!</div>}
                      </div>
            </>}
          </></DialogContent>
          <DialogActions>
            {!submitted && <>
            <MyButton label={'Cancel'} color={'warning'} variant='contained' 
              buttonProps={{
                onClick:() => {
                    props.handleClose();
                },
                size:'small'
            }}           
            />
            <MyButton label={props.submitButtonTitle || DEFAULT_SUBMIT_BUTTON_TITLE} color={'success'} variant='contained' 
              buttonProps={{
                onClick:() => {submitForm()},
                size:'small'
            }}           
            />
            </>}
            {submitted &&
            <MyButton label={'Close'} color={'success'} variant='contained'
              buttonProps={{  
                onClick:() => handleClose(),
                size:'small' 
            }}
            />}
          </DialogActions>
        </Dialog>
      </div>
    );
  }

export default CaptureEmailDialog