import React, {useState, useRef, useCallback, useEffect} from "react";
import Card from "@material-ui/core/Card";

import Tooltip from "@material-ui/core/Tooltip";
import Map, { NavigationControl, FullscreenControl, GeolocateControl, Popup } from "react-map-gl";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { useTheme } from "@material-ui/core/styles";  
import MapModeSelector from 'views/admin/Maps/geomapping/MapModeSelector.js';
import MapEditorOverlay from 'views/admin/Maps/geomapping/MapEditorOverlay.js';
import {generateUUID} from 'store/functions/geoJsonFunctions.js';
import updateBounds from 'views/admin/Maps/shared_mapping_functions/utility_functions.js';
import MapZoomToFitButton from 'views/admin/Maps/geomapping/MapZoomToFitButton.jsx'
import MapDownloadIcon from 'views/admin/Maps/geomapping/MapDownloadIcon.jsx'
import MapLocationSearchBar from 'views/admin/Maps/geomapping/MapLocationSearchBar.jsx';
import CircleIcon from '@mui/icons-material/Circle';
import IconButton from '@mui/material/IconButton';
import 'views/admin/Maps/geomapping/SimpleMapQuickRender4.css';
import {
  fetchAddress,
  selectFilteredData,
  selectActiveResultsShape,
  selectMapMode,
  selectGeoJsonFilter,
  addRow,
  selectTripLayerEnabled,
  selectScenarioChange,
  getInExampleMode,
  selectTableSetting,
  remainingRows
} from 'store/reducers/inputReducer.js';
import 'mapbox-gl/dist/mapbox-gl.css';

import AddTripLayer from "views/admin/Maps/geomapping//Layers/AddTripLayer";
import TripLayer from 'views/admin/Maps/geomapping/Layers/TripLayer.jsx'
import TripHighlightLayer from 'views/admin/Maps/geomapping/Layers/TripHighlightLayer.jsx'
import PointLayer from 'views/admin/Maps/geomapping/Layers/PointLayer.jsx'
import PointHighlightLayer from 'views/admin/Maps/geomapping/Layers/PointHighlightLayer.jsx'
import ResultTripLayer from 'views/admin/Maps/geomapping/Layers/ResultTripLayer.jsx'
import ResultPointLayer from 'views/admin/Maps/geomapping/Layers/ResultPointLayer.jsx'
import ResultDropLayer from 'views/admin/Maps/geomapping/Layers/ResultDropLayer.jsx'
import ClusterCard from 'views/admin/Maps/geomapping/ClusterCard.jsx'
import ClusterCardTripOrigin from 'views/admin/Maps/geomapping/ClusterCardTripOrigin.jsx'
import OptimizeButton from "views/admin/Components/OptimizeButton";
import LayerFilterButton from "components/Cards/Dashboard/LayerFilterButton";
import VehicleFilterButton from "components/Cards/Dashboard/VehicleFilterButton";

const zoom_ = 7;
const longitude_ = -75;
const latitude_ = 40;

const INITIAL_VIEW_STATE = {
  latitude: latitude_,
  longitude: longitude_,
  zoom: zoom_,
  bearing: 0,
  pitch: 0,
  transitionDuration: 500
};

const includeLayerFilters = true; //set to false to remove layer filters 

const SimpleMapQuickRenderMap = function SimpleMapQuickRenderMap(props) {
  const mapRef = useRef(null);
  const mapMode = useSelector(selectMapMode);
  const tripLayerEnabled = useSelector(selectTripLayerEnabled);
  const remainingLocations = useSelector(remainingRows('locations'));
  const theme = useTheme();
  const [hoverInfo, setHoverInfo] = useState(null);
  const [clickInfo, setClickInfo] = useState(null);
  const [clusterFeatures, setClusterFeatures] = useState(null);

  const data = useSelector(selectFilteredData({'excludeTrips': !(tripLayerEnabled), 'excludeResults':false}, shallowEqual)).geoJsonData;
  const lastLocationUpload = useSelector(selectTableSetting('locations', 'lastUpload'));
  const scenarioChange = useSelector(selectScenarioChange);
  const exampleMode = useSelector(getInExampleMode);
  const resultsSize = useSelector(selectActiveResultsShape, shallowEqual);

  const [viewPort, setViewState] = useState(INITIAL_VIEW_STATE)
  const dispatch = useDispatch();
  const [boundsSet, setBoundsSet] = useState(false);
  const deckRef = useRef(null);
  const editorRef = useRef(null);
  const [cursor, setCursor] = useState(null);
  const [selectedUuid, setSelectedUuid] = useState(null);
  const [pointTripVisible, setPointTripVisible] = useState(false);
  const [pointTripUuid, setPointTripUuid] = useState(false);
  const [addlayerTrip, setAddLayerTrip] = useState(null);

  const dropLayerVisible = useSelector(selectGeoJsonFilter('dropLayerVisible'));
  function openEditor(info) {
    if (editorRef.current) {
      
      if (info.properties.type === 'routeLeg') {}
      else {
        const rowUuid = info.properties.uuid;
        const geoJsonType = info.geometry.type;
        var tableName = 'trips';
        if (geoJsonType === 'Point') {
          tableName = 'locations';
        }
        editorRef.current.setTableName(tableName);
        editorRef.current.setRowId(rowUuid);
        editorRef.current.setOpen({open:true});
      }
    }
  }


  async function addLocationFromGeoJson (lat, lng) {
    const uuid = generateUUID();
    var newRow = {'Latitude': lat, 'Longitude': lng}
    await dispatch(addRow({newRow:newRow, tableName: 'locations', uuid:uuid}))
    if ((!(resultsSize && resultsSize > 0)) && remainingLocations > 0) {
      await dispatch(fetchAddress({lat:lat, lng:lng, uuid:uuid, zoom:viewPort.zoom}), 10);
    }
  }

  useEffect(() => {
    if (!boundsSet) {
      updateBounds(deckRef, viewPort, setViewState, data, true);
      setBoundsSet(true);
    }
  }, [])

  useEffect(() => {
    updateBounds(deckRef, viewPort, setViewState, data, true);
  }, [lastLocationUpload])

  //if the scenario changes, then reset bounds
  useEffect(() => {
    updateBounds(deckRef, viewPort, setViewState, data, true);
    setBoundsSet(true);
  }, [scenarioChange])

    useEffect(() => {
      if (mapMode == 'location' || 'addTrips') {
        setCursor('crosshair');
      } else {
        setCursor('grab')
      }

      setClickInfo(null);

    }, [mapMode])

    React.useEffect(() => {
      const url = "https://optimiciti-images.s3.us-east-2.amazonaws.com/chevron_right.png"
      if (mapRef.current) {
        const map = mapRef.current.getMap();
  
        map.loadImage(url, (error, image) => {
          if (error) throw error;
          map.addImage('arrow', image, { sdf: true });
        });
      }
    }, [mapRef.current]);

  

  const onHover = useCallback(event => {
    if (mapMode === 'addTrips') {
      if (event.features && event.features.length > 0 && event.features[0].layer.id === 'unclustered-point') {
        setAddLayerTrip({
          longitude: event.lngLat.lng,
          latitude: event.lngLat.lat,
          hoverPointLatitude: event.features && event.features.length > 0 && event.features[0].layer.id === 'unclustered-point' && event.features[0].geometry.coordinates[1],
          hoverPointLongitude: event.features && event.features.length > 0 && event.features[0].layer.id === 'unclustered-point' && event.features[0].geometry.coordinates[0],
        })
      } 
      else {
        setAddLayerTrip({
          longitude: event.lngLat.lng,
          latitude: event.lngLat.lat,
          hoverPointLatitude: null,
          hoverPointLongitude: null
        })
      }
    }
    if (event.features && event.features.length > 0) {
      
      if (mapMode === 'view') {
        setCursor('pointer')
      }
      if (mapMode === 'location') {
        setCursor('crosshair')
      }
      const uuid = event.features[0].properties.uuid;
      var hoverInfo = {
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        uuid: uuid
      }
      if (!pointTripVisible) {
        setPointTripUuid(uuid);
      }
      if (event.features[0].layer.id === 'clusters') {
        const pointCount = event.features[0].properties.point_count;

        setHoverInfo({...hoverInfo,
        title: <><div>{pointCount + ' items'}</div>{mapMode === 'view' && <div>Click on cluster for details</div>}</>
        })
        setSelectedUuid(null);
      } 
      else if (event.features[0].layer.id === 'clustered-result-point')  {
        const pointCount = event.features[0].properties.point_count;
        setHoverInfo({...hoverInfo,
        title: <><div>{pointCount + ' items'}</div>{mapMode === 'view' && <div>Click on cluster for details</div>}</>
        })
        setSelectedUuid(null);
      }
      else if (event.features[0].layer.id === 'unclustered-result-point') {
        const item = event.features[0];
        const locName = item.properties['Location Name']|| item.properties.name;
        const vehicleLeg = item.properties.Stop;
        const action = item.properties.Action && JSON.parse(item.properties.Action);

        setHoverInfo({...hoverInfo,
        title: <>

              <div style={{display:'inline-flex', flexDirection:'column', overflowY:'scroll', maxHeight:'10rem', maxWidth:'250px'}}>
                <div style={{display:'inline-flex', flexDirection:'row'}}>
                  <IconButton aria-label="delete" size="small" disabled={true}
                  style={{height:'1rem', width:'1rem', float:'right', marginLeft:'5px', top:'2px'}}
                    >
                      <CircleIcon fontSize="inherit" style={{color: item.properties.colorHex}}/>
                  </IconButton>
                      <Tooltip title={locName} >
                        <div style={{display:'inline-flex', flexDirection:'column', maxHeight:'10rem', overflowY:'scroll', maxWidth:'180', maxHeight:'200px'}}>
                            <div style={{fontWeight:'700', width:'calc(100% - 20px)', minWidth:'100px', maxHeight:'1.25rem', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                                {vehicleLeg + ') '}
                                    {locName}
                            </div>
                              {action && 
                              (<ul style={{listStyleType: 'disc', paddingLeft:'5px', 
                                  fontWeight:'normal', fontSize:'0.7rem', lineHeight:'0.7rem', 
                                  paddingBottom:'0.5rem'}}>
                                  {action.map((elem, idx) => {
                                  return <li style={{marginLeft:'10px'}}><span style={{marginLeft:'-5px'}}>{elem}</span></li>
                                  })} 
                              </ul>)}
                        </div>
                   </Tooltip>
                </div>
              
              </div>
            </>
        })
        setSelectedUuid(event.features[0].properties.uuid)
      }
      else if (event.features[0].layer.id === 'unclustered-result-drop') {

        const locName = event.features[0].properties['Location Name']
        setHoverInfo({...hoverInfo,
        title: <>{locName}<span style={{color:theme.palette.error.main}}>{" (dropped)"}</span></>
        })
        setSelectedUuid(event.features[0].properties.uuid)
      }
      else {
        hoverInfo = {...hoverInfo,
          uuid: event.features[0].properties.uuid
        }
        if (event.features[0].properties.type === 'point') {
          hoverInfo['title'] = event.features[0].properties['Location Name']
        }
        if (event.features[0].properties.type === 'trip') {
          hoverInfo['title'] = event.features[0].properties['name']
        }
        setHoverInfo({
          ...hoverInfo
        });

        setSelectedUuid(event.features[0].properties.uuid)
      }
    } 
    else {
      setHoverInfo(null);
      setSelectedUuid(null);
      if (mapMode === 'view') {
        setCursor('grab')
      }
      if (mapMode === 'location') {
        setCursor('crosshair')
      }
    }
  }, [mapMode, pointTripVisible, tripLayerEnabled]);

  const setResultsClusterPopup = (event) => {
    const clusterSource = mapRef.current.getSource('results-cluster-enabled');
    const clusterId = event.features[0].properties.cluster_id;
    const pointCount = event.features[0].properties.point_count;
    clusterSource.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
      setClusterFeatures(features)
      });
    setClickInfo({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    })    
  }

  const setClusterPopup = (event) => {
    const clusterId = event.features[0].properties.cluster_id;
    const pointCount = event.features[0].properties.point_count;
    var clusterSource
    if (event.features[0].layer.id === 'clusters') {
      clusterSource = mapRef.current.getSource('data-cluster-enabled');
    }
    clusterSource.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
      setClusterFeatures(features)
      });
    setClickInfo({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    })
  }

  const onClick = useCallback(event => {

    if (mapMode == 'addTrips') {
      if (event.features && event.features.length > 0 && event.features[0].layer.id === 'unclustered-point') {
        setClickInfo({
          longitude: event.features[0].geometry.coordinates[0],
          latitude: event.features[0].geometry.coordinates[1],
          uuid: event.features[0].properties.uuid
        })
      }
      return
    }

    setClickInfo(null);
    setClusterFeatures(null);
    setPointTripVisible(false);

    if (mapMode === 'location') {
      const lngLat = event.lngLat;
      addLocationFromGeoJson(lngLat.lat, lngLat.lng)
    } 


    else if (mapMode === 'view') {
      if (event.features.length >= 1) {
        if (event.features[0].layer.id === 'clusters' || event.features[0].layer.id === 'clustered-result-point') {

          if (event.features[0].layer.id === 'clustered-result-point') {
            setResultsClusterPopup(event);
          } else {
            setClusterPopup(event);
          }
        }
        else if (tripLayerEnabled) {
          if (event.features[0].properties.type === 'trip') {
            openEditor(event.features[0])
          } else {
            setClickInfo({
              longitude: event.lngLat.lng,
              latitude: event.lngLat.lat
            })
            setPointTripUuid(event.features[0].properties.uuid)
            setPointTripVisible(true);
          }
        }
        else if (event.features[0].layer.id === 'unclustered-result-point') {}
        else {
          openEditor(event.features[0])
        }
      }
    }
  }, [mapMode, tripLayerEnabled])

  const clusterEditorHandler = (features) => {
    if (!features) {}
    else {
      openEditor(features)
    }
    setClickInfo(null);
    setClusterFeatures(null);
  }

  const interactiveLayerIds = ['unclustered-point', 'trips', 'clusters', 'clustered-result-point', 'unclustered-result-point'];
  if (dropLayerVisible) {
    interactiveLayerIds.push('unclustered-result-drop')
  }

  function saveMapAsPng(filename) {
    const link = document.createElement("a");
    const mapUrl = mapRef.current.getCanvas().toDataURL();
    link.href = mapUrl;
    link.download = filename;
    link.click();
  }

  const callUpdateBounds = () => {
    updateBounds(deckRef, viewPort, setViewState, data, true)
  }

  return (<>
    
    <Card style={{padding:'0px', margin:'0px',
      height:String(props.containerHeight) + 'px',
      minHeight:String(props.containerHeight) + 'px',
      maxHeight:String(props.containerHeight + 'px')}} ref={deckRef}>

            <Map 
            key={props.heightChangeKey}
            {...viewPort}
            onMove={evt => setViewState(evt.viewState)}
            style={{width:'100%', height:'100%'}}
            width='100%'
            height='100%'
            onRender={(event) => event.target.resize()}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_API} 
            attributionControl={false}
            onMouseMove={onHover}
            onClick={onClick}
            mapStyle="mapbox://styles/mapbox/light-v9"
            cursor={cursor}
            ref={mapRef}
            preserveDrawingBuffer={true}
            interactiveLayerIds={interactiveLayerIds}
            >

              <TripLayer />
              {mapMode !== 'addTrips' && <TripHighlightLayer selectedUuid={selectedUuid} />}
              <PointLayer />
              <PointHighlightLayer selectedUuid={selectedUuid} />
              <ResultTripLayer />
              {<ResultPointLayer selectedUuid={selectedUuid}/>}
              <ResultDropLayer selectedUuid={selectedUuid}/>
              {<AddTripLayer mouseCoords={addlayerTrip} lastClickInfo={clickInfo}/>}
              

              {false && <GeolocateControl position="top-left" />}
              {true && <FullscreenControl position="top-left" />}
              
              {<NavigationControl position="top-left" showCompass={false} showZoom={true}/>}
              <div style={{position: 'absolute', top: 10, right: 10, display:'flex', flexDirection:'column', gap:'5px'}}>
              {<MapZoomToFitButton updateBounds={() => callUpdateBounds()} />}
              {<MapDownloadIcon onClick={() => {saveMapAsPng('map.png')}} /> }
              {includeLayerFilters && <LayerFilterButton />}
              {includeLayerFilters && <VehicleFilterButton />}
              </div>
              <div id='map-bottom-div' 
                style={{display:'flex', flexFlow:'row wrap', gap:'5px', 
                    position: 'absolute', bottom: 30, left: 10, marginRight:'10px',
                    whiteSpace:'nowrap', width:'calc(100% - 20px)', justifyContent:'space-between'}}>
                
                {<MapModeSelector style={{zIndex:'100'}} 
                onClick={() => {setClickInfo(null); editorRef.current.setOpen({open:false});}}
                />}
                {!exampleMode && 
                  <MapLocationSearchBar bounds={mapRef && mapRef.current && mapRef.current.getBounds()}
                  style={{textAlign:'right', marginTop:'auto'}} 
                />}

              </div>
              {<div id={'example-mode-optimize-outer-div'} style={{zIndex:'100', position: 'absolute', bottom: 10, left: 10}} >
                <OptimizeButton collapsed={true} spinnerStyle={{left:0, top:0}} 
                iconProps={{height:'4rem', width:'4rem', innerBoxHeight:'2rem', innerBoxWidth:'2rem'}}/></div>}
              {<MapEditorOverlay tableName={'locations'} ref={editorRef} open={false}></MapEditorOverlay>}
              
              {hoverInfo && 
                <Popup
                  longitude={hoverInfo.longitude}
                  latitude={hoverInfo.latitude}
                  offset={{'top': [0, 10], 'bottom': [0, -10], 'right': [-10, 0], 'left': [10, 0]}}
                  closeButton={false}
                >
                  {hoverInfo.title}
                </Popup>
              }
              {(clickInfo && clusterFeatures) && 
                <Popup
                anchor='bottom'
                longitude={clickInfo.longitude}
                latitude={clickInfo.latitude}
                offset={[0, -5]}
                closeButton={false}
              >
                {<ClusterCard items={clusterFeatures} clusterEditorHandler={clusterEditorHandler}/>}
              </Popup>              
              }
              {(clickInfo && pointTripVisible) && 
                <Popup
                anchor='bottom'
                longitude={clickInfo.longitude}
                latitude={clickInfo.latitude}
                offset={[0, -5]}
                closeButton={false}
              >
                <ClusterCardTripOrigin selectedUuid={pointTripUuid} openEditor={openEditor} onDelete={() => setPointTripVisible(false)}/>
              </Popup>              
              }
            </Map>
    </Card>
  </>);
};

export default SimpleMapQuickRenderMap;