import {useState} from "react";

const SimpleLink = (props) => {
    const [isHovered, setIsHovered] = useState(false) 
  
    const hoverStyle = {
      color: props.hoverColor,
      textDecoration: 'underline',
      textDecorationColor: props.hoverColor,
      fontWeight:700
    }
  
    const nonHoverStyle = { 
      color: props.color
    }
  
    return (
    <a style={isHovered ? hoverStyle : nonHoverStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={props.onClick} {...props.aProps}>
      {props.text}
    </a>
    )
  }


  export default SimpleLink