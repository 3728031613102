import React, {useState} from "react";
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import LoginModal from 'views/admin/Notifications/LoginModal.jsx';
import MyMuiButton from 'views/admin/Components/MyComponents/MyMuiButton.jsx'
import { useTheme } from "@material-ui/core/styles";

const LogInRequiredListItem = (props) => {
    const [loginOpen, setLoginOpen] = useState(false);
    const theme = useTheme();

    return <>
    <Divider />
    <ListItem alignItems="flex-start" style={{ paddingLeft:'6px', paddingRight:'6px', display:'inline-flex', flexDirection:'column' }}>
      <ListItemText style={{margin:'0px'}} primary={<div style={{fontWeight:500, color:theme.palette.warning.main}}>{"User not logged in"}</div>}
      secondary={"You must be logged in to access these items"} />
      <div style={{paddingTop: '5px', paddingBottom: '5px', width:'100%'}}>
      <MyMuiButton style={{height:'1.5rem'}} color='primary' fullWidth variant='outlined' onClick={() => setLoginOpen(true)}>Log In</MyMuiButton>
      </div>
    </ListItem>
    <LoginModal open={loginOpen} closeForm={() => {setLoginOpen(false)}}/>
    </>
}

export default LogInRequiredListItem