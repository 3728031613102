import * as React from 'react';
import { Tooltip } from 'antd';


/**
 * Simple renderer for including the data table status icon, which shows whether
 * the row is valid, invalid, or warning
 * @param {*} props this should include the row of interest
 */
function RowStatusRenderer (props) {
    return (      
      <div id='row-status-renderer-outer-div' style={{textAlign:'center'}}>
       {props.value === true && 
          <span class="material-icons dataTableRowStatusIconSuccess" style={{fontSize:'inherit', backgroundColor:'inherit'}}>
          check_circle
          </span>
        }
       {props.value === 'warning' && 
          <>
          <Tooltip title={props.tooltipText} placement="right">
            <div onMouseEnter={() => props.setTooltipText(props.rowIndex)} onClick={() => props.setTooltipText(props.rowIndex)}>
          <span class="material-icons dataTableRowStatusIconWarning" style={{fontSize:'inherit', backgroundColor:'inherit'}}>
          warning_amber
          </span>
          </div>
          </Tooltip>
          </>
        }
       {!props.value && <>
         <Tooltip title={props.tooltipText} placement="right" style={{marginLeft:'-25px'}}>
          <div onMouseEnter={() => props.setTooltipText(props.rowIndex)} onClick={() => props.setTooltipText(props.rowIndex)}>
          <span class="material-icons dataTableRowStatusIconError " style={{fontSize:'inherit', backgroundColor:'inherit'}}>
          error
          </span>
          </div>
          </Tooltip>
          </>
        }
      </div>
    );
}

export default RowStatusRenderer;