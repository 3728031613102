//https://codesandbox.io/s/aggrid-multiselect-celleditor-ypdj4s?file=/src/AgGridMultiSelectEditor.tsx:0-1258

import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { MultiSelect } from "@mantine/core";
import './styles.css';


export const AgGridMultiSelectEditor = React.forwardRef((props, _ref) => {
  const [value, setValue] = useState(props.value);
  const refInput = useRef(null);
  useEffect(() => {
    // focus on input
    refInput.current?.focus();
  }, []);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(_ref, () => {
    return {
      getValue: () => {
        return value;
      },
      isPopup: () => {
        return false;
      }
    };
  });

  const onChange = (value) => {
    setValue(value);
  }

  return (
    <div style={{position:'inherit', height:'200px'}}>
      <MultiSelect
        ref={refInput}
        data={props.options.map((option) => ({ label: option, value: option }))}
        value={value ? Array.isArray(value) ? value : [value] : []}
        onChange={onChange}
        searchable
      />
    </div>
  );
  }
)
