/**
 * This is the main navigation bar that appears at the top of the page. This component 
 * is used to navigate to the main pages of the application, as well as to log in and out.
 */

import * as React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import getIconFromName from 'views/admin/Forms/icon_selector/icon_mapping.js'
import {bindMenu } from 'material-ui-popup-state';
import componentStyles from "assets/theme/components/navbars/main-navbar.js";
import componentStylesTypography from "assets/theme/components/typography.js";
import { useSelector, useDispatch } from 'react-redux';
import LoginModal from 'views/admin/Notifications/LoginModal.jsx';
import { googleLogout } from '@react-oauth/google';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CalculateIcon from '@mui/icons-material/Calculate';
import BuildIcon from '@mui/icons-material/Build';
import InfoIcon from '@mui/icons-material/Info';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ApiIcon from '@mui/icons-material/Api';
import RouteIcon from '@mui/icons-material/Route';
import PolylineIcon from '@mui/icons-material/Polyline';

import {
  selectActiveModelType,
  addNotification,
  logout,
  selectIsLoggedIn,
  selectLogIn
} from 'store/reducers/inputReducer';


import HoverMenu from 'material-ui-popup-state/HoverMenu'
import {
  usePopupState,
  bindHover
} from 'material-ui-popup-state/hooks'
import DashboardButtonPanel from 'views/admin/Dashboards/DashboardButtonPanel.js'
import { Tooltip } from 'antd';


const ModelPopupMenu = () => {
  const classes = useStyles();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  })

  return (
    <React.Fragment>
      <Button variant="contained" {...bindHover(popupState)} 
        style={{backgroundColor:'inherit', boxShadow:'none', padding:'0px'}} 
        className={classes.menuTrigger}>
        Models
      </Button>
      <HoverMenu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
          <MenuItem onClick={popupState.close}><Link className={'linkText'} to='/model-builder'>Model Builder</Link></MenuItem>
          <MenuItem onClick={popupState.close}><Link className={'linkText'} to='/models/vehicle-routing'>Vehicle Routing</Link></MenuItem>
          <MenuItem onClick={popupState.close}><Link className={'linkText'} to='/models/pickup-dropoff'>Pickup / Delivery</Link></MenuItem>
      </HoverMenu>
    </React.Fragment>
  )
}

const buttonSx = {my: 2, color: 'white', display: 'block' }


const useStyles = makeStyles(componentStyles);
const useStylesTypography = makeStyles(componentStylesTypography);

const pages = [
  {'title': 'Models', 
    'icon': <CalculateIcon />,
    'subpages': [
    {'title': 'Vehicle Routing', 'link': '/models/vehicle-routing', 'icon': <PolylineIcon />},
    {'title': 'Pickup / Dropoff', 'link': '/models/pickup-dropoff', 'icon': <RouteIcon />},
    ]
  },
  {'title': 'Model Builder', 'link': '/model-builder', 'icon': <BuildIcon />},
  {'title': 'About', 'link': '/about', 'icon': <InfoIcon />},
  {'title': 'Pricing', 'link': '/pricing', 'icon': <LocalOfferIcon />},
  {'title': 'API', 'link': '/api', 'icon': <ApiIcon />},
];

const MainNavBar = (props) => {
  const classes = {...useStyles(), ...useStylesTypography()};
  const dispatch = useDispatch();
  var modelType = useSelector(selectActiveModelType);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const loginDetails = useSelector(selectLogIn);
  const [isModelPage, setIsModelPage] = React.useState(null);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  })

  var avatarIcon;
  if (modelType) {
    avatarIcon = getIconFromName(modelType, {});
  }

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [loginOpen, setLoginOpen] = React.useState(false);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenLogin = (event) => {
    setLoginOpen(true);
  };

  const handleAccountAvatarClick = (event) => {
    if (anchorElUser) {
      setAnchorElUser(null)
    } else {
      setAnchorElUser(event.currentTarget)
    }
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUserLogoutFromMenu = () =>{
    googleLogout();
    setAnchorElUser(null);
    dispatch(logout());
    dispatch(addNotification({notificationType:'info', notificationMsg:'You have been successfully logged out.'}))
  }



  React.useEffect(() => {
    const url = window.location.href
    if (url.search('models') > 0) {
      setIsModelPage(true);
    }
    else {
      setIsModelPage(false);
    }
  }, [window.location.href])


  return (
    <AppBar position="static"  classes={{root: classes.appBarRoot}}>
      <Container classes={{root: classes.appBarRoot}} style={{maxWidth:"100%"}}>
        <Toolbar disableGutters>
            <img
                style={{height:'2rem', width: '2rem'}}
                alt="..."
                src={"https://optimiciti-images.s3.us-east-2.amazonaws.com/brand/logo_teal_navy-cropped.svg"}
            />
          <Box id='left-side-small' sx={{ flexGrow: 1 }}>
            <Tooltip title="Main navigation menu items" placement='right'>
            <IconButton
              size="large"
              aria-label="Main navigation menu items"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <>
                  {!page.subpages &&
                    <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                      <Link to={page.link} className={classes.menuLinkText} style={{display:'flex'}}> 
                        <ListItemIcon>
                          {page.icon}
                        </ListItemIcon>
                        <Typography textAlign="center">{page.title}</Typography>
                      </Link>
                    </MenuItem>
                  }
                  {page.subpages &&
                  <>
                  <MenuItem key={page.title}>
                  <ListItemIcon>
                    {page.icon}
                  </ListItemIcon>
                  <Typography 
                        className={classes.menuLinkText}
                        textAlign="center">
                        {page.title}
                  </Typography>
                  </MenuItem>
                    {page.subpages.map((subpage) => (
                        <Link className={'linkText'} to={subpage.link}>
                        <MenuItem key={subpage.title} onClick={popupState.close}>
                          <ListItemIcon style={{paddingLeft:'20px'}}>
                            {subpage.icon}
                          </ListItemIcon>
                          <ListItemText className={classes.subMenuLinkText} style={{paddingLeft:'10px'}}>{subpage.title}</ListItemText>
                        </MenuItem>
                        </Link>
                      ))}
                  
                  </>
                  }
                </>
              ))}
              
            </Menu>
          </Box>

          <Box id='left-side-large' sx={{ flexGrow: 1 }}>

              <Button
                component={Link} to="/index"
                onClick={handleCloseNavMenu}
                sx={buttonSx}
              >
                <Link to='/index' className={classes.linkText}>Home</Link>
              </Button>

              <ModelPopupMenu></ModelPopupMenu>


              <Button
                component={Link} to="/about"
                onClick={handleCloseNavMenu}
                sx={buttonSx}
              >
                <Link to='/about' className={classes.linkText}>About</Link>
              </Button>

              <Button
                component={Link} to="/use-cases"
                onClick={handleCloseNavMenu}
                sx={buttonSx}
              >
                <Link to='/use-cases' className={classes.linkText}>Examples</Link>
              </Button>

              <Button
                component={Link} to='/pricing'
                onClick={handleCloseNavMenu}
                sx={buttonSx}
              >
                <Link to='/pricing' className={classes.linkText}>Pricing</Link>
              </Button>

              <Button
                component={Link} to='/api'
                onClick={handleCloseNavMenu}
                sx={buttonSx}
              >
                <Link to='/api' className={classes.linkText}>API</Link>
              </Button>

          </Box>

          {/* {isModelPage && modelType && 
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{alignItems:'center'}}>
          <Avatar style={{background:"inherit"}}>
              {avatarIcon}
          </Avatar>
          <Button
                
                sx={{ color: 'white', display: 'block' }}
              >
                {problemConfigs[modelType]['title']}
              </Button>

          </Box>} */}
          {isModelPage && <DashboardButtonPanel modelType={modelType}/>}
          <Box sx={{ flexGrow: 0 }}>
          
            {!isLoggedIn && 
            <Button className={classes.linkText} sx={buttonSx} onClick={handleOpenLogin}>Login</Button>
            }

            {isLoggedIn && 
            
              <IconButton sx={{ p: 0 }} onClick={handleAccountAvatarClick}>
                <Avatar alt={loginDetails['name']} src={loginDetails['picture']} />
              </IconButton>
            
            }
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >

                {/* <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{"Account"}</Typography>
                </MenuItem> */}
                <MenuItem onClick={handleUserLogoutFromMenu}>
                  <Typography textAlign="center">{"Logout"}</Typography>
                </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <LoginModal open={loginOpen} closeForm={() => {setLoginOpen(false)}}/>
        <style>
        {`
          @media (max-width: 1000px) {
            #left-side-large {
              display: none;
            }
            #left-side-small {
              display: flex;
            }
          }
          @media (min-width: 1000px) {
            #left-side-large {
              display: flex;
            }
            #left-side-small {
              display: none;
            }
          }
        `}
        </style>
    </AppBar>
  );
};

export default MainNavBar;