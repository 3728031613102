const componentStyles = (theme) => ({
  rootCardSelected: {
    border: '3px solid',
    borderColor: theme.palette.info.main,
    padding: '0px'
  },
  rootCardUnselected: {
    border: '1px solid',
    borderColor: theme.palette.success.primary,
    padding:'2px'
  },
  cardActions: {
    justifyContent: 'center'
  }
});

export default componentStyles;
