const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']


async function get_previous_trial(run_id) {

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ run_id:run_id})
    };

    const response = await fetch(BASE_ROUTE + '/run_results_by_run_id', requestOptions)
    const retJson = await response.json()
    return retJson;
}

export default get_previous_trial