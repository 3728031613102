import React, {useRef} from 'react';
import { useTheme } from "@material-ui/core/styles";  
import Box from '@mui/material/Box';
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Stepper from '@mui/material/Stepper';
import Grid from "@material-ui/core/Grid";
import { StepLabel, StepContent, Step } from '@mui/material';
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';

import WizardOptimizationChoices from 'views/admin/Wizard/WizardOptimizationChoices.jsx';
import ModelSelectorCards from 'views/admin/Wizard/ModelSelectorCards.jsx';
import MyButton from 'views/admin/Components/MyComponents/MyButton.js'
import ModelTimeWindowSettingsCard from 'views/admin/Wizard/ModelTimeWindowSettingsCard.jsx';
import WizardCostCalculation from 'views/admin/Wizard/WizardCostCalculation.jsx';
import { useSelector } from 'react-redux';
import { selectModelSettings } from 'store/reducers/inputReducer';
import WizardActionButtons from 'views/admin/Wizard/WizardActionButtons.jsx';
import WizardColumnChoices from 'views/admin/Wizard/WizardColumnChoices.jsx';
import { selectActiveModelType } from 'store/reducers/inputReducer';
var modelPages = require('store/data/model-pages.json');


const steps = [
  {
    label: 'Select model',
    description: '',
    component: <ModelSelectorCards modelTypes={['vehicle_routing', 'vr_pickup_deliveries']}/>
  },
  {
    label: "Select time window settings",
    description: '',
    component: <ModelTimeWindowSettingsCard />
  },
  {
    label: 'Select model settings',
    description:'',
    component: <div />
  },
  {
    label: 'Ready to go',
    description: 'Everything is configured and ready to go. If this is your first time using Optimiciti, consider our quick tutorial. Otherwise, select Build Model, add some data, and get optimized.',
    component: <div />
  },
];



function Wizard () {
  const theme = useTheme();
  const modelLinkRef = useRef(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const modelSelected = useSelector(selectActiveModelType)
  const modelSettings = useSelector(selectModelSettings)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleBuildModel = () => {
    modelLinkRef.current.click()
  }

  const getStepIconProps = (step) => {
    return {style:{
      color: activeStep <= step ? theme.palette.warning.main : theme.palette.primary.main,
      fontSize:'2rem'
    }}
  }

  const background = 'linear-gradient(87deg,' + theme.palette.primary.extraLight + ',' + theme.palette.primary.light + ')'
  return (
    <>
    <Container
    id='wizard-container'
    maxWidth={false}
    component={Box}
    marginBottom="0px"
    style={{
      marginBottom:'0px',
      background:background,
      flex: 'auto'
    }}
    
  >
    <Box component={Grid} container justifyContent="center" >
      <Grid item xs={12} xl={12}>
      <Card  style={{padding:'10px'}}>
    <Stepper activeStep={activeStep} orientation="vertical"> 

      {/*step 1: choose a model */}
          <Step key={steps[0].label}>
            <StepLabel StepIconProps={getStepIconProps(0)}>
              {steps[0].label}
            </StepLabel>
            <StepContent>
              <Typography>{steps[0].description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <ModelSelectorCards modelTypes={Object.keys(modelSettings)}/>
                  <div style={{paddingTop:'10px', paddingBottom:'10px', paddingRight: '10px'}}>
                  <MyButton
                    variant={modelSelected ? 'contained' : 'outlined'}
                    color='primary'
                    label='Continue'
                    tooltipTitle={modelSelected ? '' : 'Select model to continue'}
                    buttonProps={{
                    disabled:!modelSelected,
                    onClick:handleNext,
                    }}
                  />
                  </div>
                </div>
              </Box>
            </StepContent>
          </Step>
 

          {/*step 2: choose time window settings*/}
          <Step key={'Time window settings'}>

            <StepLabel StepIconProps={getStepIconProps(1)}>
              {'Time window settings'}
            </StepLabel>
            <StepContent>
              <ModelTimeWindowSettingsCard modelType={modelSelected} handleNext={handleNext} handleBack={handleBack}/>
              {/* <WizardActionButtons handleNext={handleNext} handleBack={handleBack}/> */}
            </StepContent>
          </Step>

          {/*step 2: choose model-specific needs */}
          <Step key={'Optimization settings'}>
            <StepLabel StepIconProps={getStepIconProps(2)}>
              {'Optimization settings'}
            </StepLabel>
            <StepContent>
              <Typography>{steps[1].description}</Typography>
              <div style={{paddingTop:'10px', paddingBottom:'10px'}}>
              <WizardOptimizationChoices modelType={modelSelected}/>
              </div>
              <WizardActionButtons handleNext={handleNext} handleBack={handleBack}/>
            </StepContent>
          </Step>

          {/*step 2: choose model-specific needs */}
          <Step key={'Cost calculation settings'}>
            <StepLabel StepIconProps={getStepIconProps(3)}>
              {'Cost calculation settings'}
            </StepLabel>
            <StepContent>
              {/* <Typography>{"Select method for calculating costs."}</Typography> */}
              <div style={{paddingTop:'10px', paddingBottom:'10px'}}>
              <WizardCostCalculation />
              </div>
              <WizardActionButtons handleNext={handleNext} handleBack={handleBack}/>
            </StepContent>
          </Step>

                  
          <Step key={'Choose column settings'}>
            <StepLabel StepIconProps={getStepIconProps(4)}>
              {'Data settings'}
            </StepLabel>
            <StepContent>
              <WizardColumnChoices modelType={modelSelected}/>
              {/* <WizardActionButtons handleNext={handleNext} handleBack={handleBack}/> */}
              <WizardActionButtons handleNext={null} handleBack={handleBack} handleBuildModel={handleBuildModel}/>
            </StepContent>
          </Step>

          {/* step 3 get optimized
          <Step key={steps[2].label}>
            <StepLabel StepIconProps={getStepIconProps(5)}>
              {steps[2].label}
            </StepLabel>
            <StepContent>
              <Typography>{steps[2].description}</Typography>
              <Box sx={{ mb: 2 }}>
                <WizardActionButtons handleNext={null} handleBack={handleBack} handleBuildModel={handleBuildModel}/>
              </Box>

            </StepContent>
          </Step> */}
  
      </Stepper>
      </Card>
      </Grid>
      </Box>
      <Link ref={modelLinkRef} to={modelPages[modelSelected]} hidden={true} ></Link>
    </Container>

    </>
  );
}

export default Wizard;