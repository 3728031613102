const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']

//json file should include, at a minimum, "From Location Latitude", "From Location Longitude",
//"To Location Latitude", "To Location Longitude", "Travel Cost"
async function submit_optimization_request(state, userId) {

    var json = {state: state, user_id:userId}
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ json })
    };
    const response = await fetch(BASE_ROUTE + '/solver', requestOptions)
    const retJson = await response.json()
    return retJson;
}

export default submit_optimization_request