const GLOBAL_CONFIGS = require('store/data/global_configs.json');
const BASE_ROUTE = GLOBAL_CONFIGS['back-end-server'] + ':' + GLOBAL_CONFIGS['back-end-port']

//json file should have locations and costss
async function decipher_uploaded_travel_costs({uploadedData, existingData, costSettings, overwriteExisting=false}={}) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ 'uploaded_data': uploadedData, 'existing_data': existingData, 'overwrite': overwriteExisting, 'cost_settings': costSettings })
    };
    const response = await fetch(BASE_ROUTE + '/decipher_uploaded_travel_costs', requestOptions)
    const retJson = await response.json()
    
    return retJson;
}

export default decipher_uploaded_travel_costs