import EditIcon from '@mui/icons-material/Edit';
import HeightIcon from '@mui/icons-material/Height';
import InputAdornment from "@material-ui/core/InputAdornment";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import NumbersIcon from '@mui/icons-material/Numbers';
// import Person from "@material-ui/icons/Person";


const CustomInputAdornment = (props) => {

    var component;
    var style = {height:'1rem'};

    if (props.adornment == 'location') {
        component = <LocationOnOutlinedIcon style={style}/>
    }
    else if (props.adornment == 'number') {
        component = <NumbersIcon style={style}/>
    }
    else if (props.adornment == 'longitude') {
        component = <HeightIcon style={style}/>
    }
    else if (props.adornment == 'latitude') {
        style = {...style, transform: "rotate(90deg)"}
        component = <HeightIcon style={style} />
    }
    else {
        component = <EditIcon style={style}/>
    }


    return (
        <InputAdornment position="start" style={{margin:'0px'}}>
            {component}
        </InputAdornment>
    )
}

export default CustomInputAdornment;