import {useState} from 'react';
import { useTheme } from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimeSettingsDialog from 'views/admin/Components/Dialogs/TimeSettingsDialog.js';


function TimeSettingsButton(props) {
    const [open, setOpen] = useState(false);

    const theme = useTheme();

    const handleClose = () => {
        setOpen(false);
    };

    const handleButtonClick = (event) => {
        event.stopPropagation();
        setOpen(true);

    }

    const handleCancel = () => {
        setOpen(false);
    }
    

    return (<>
        <Tooltip title={'Time window settings'}>
            <IconButton id='cost-settings-icon-button' onClick={(event) => handleButtonClick(event)}>
                <AccessTimeIcon style={{color:theme.palette.tertiary.main}} />
            </IconButton>
        </Tooltip>
        <TimeSettingsDialog tableName={props.tableName} open={open} onClose={handleClose} handleCancel={handleCancel}/>
        
        
        </>)
}


export default TimeSettingsButton