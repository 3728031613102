import boxShadows from "assets/theme/box-shadow.js";
import hexToRgb from "assets/theme/hex-to-rgb.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    marginBottom: '0px'
  },
  cardHeader: {
    backgroundColor: "initial",
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  mb0: {
    marginBottom: "0!important",
  },
  borderWarning: {
    borderColor: theme.palette.warning.main + "!important",
  },
  borderSuccess: {
    borderColor: theme.palette.success.main + "!important",
  },
  textWarning: {
    color: theme.palette.warning.main + "!important",
  },
  buttonContainedPrimary: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
  buttonContainedError: {
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
    },
  },
  formGroup: {
    margin: '0px'
  },
  formLabel: {
    margin: '5px'
  },
  searchBoxShow: {
    display: "flex!important",
    marginRight: "0",
    "& div:first-of-type": {
      flex: 1,
    },
  },
  searchBox: {
    borderColor: theme.palette.adminNavbarSearch.main,
    borderRadius: "2rem",
    border: "2px solid",
    backgroundColor: "rgba(" + hexToRgb(theme.palette.white.main) + ",0.9)",
    boxShadow: boxShadows.inputBoxShadow,
    transition: "box-shadow .15s ease",
    [theme.breakpoints.down("xs")]: {
      display: "none",
      width: "100%",
    },
  },
  searchIcon: {
    color: theme.palette.gray[800],
    marginRight: "0.5rem",
    marginLeft: "1rem",
  },
  searchClose: {
    color: theme.palette.gray[800],
    marginRight: "1rem",
    marginLeft: "-.5rem",
  },
  searchInput: {
    color: theme.palette.gray[900],
    width: "100%",
    backgroundColor: "initial",
    border: 0,
    boxShadow: "none",
    padding: "0",
  },

  expanded: {
    margin: "0 auto"
  }


});

export default componentStyles;
