import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from "@material-ui/core/styles";

import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

import {
  updateTimeSettings,
  selectTimeSetting
} from 'store/reducers/inputReducer.js'

import TableUseTimeWindowsCheck from 'views/admin/Forms/TimeWindows/TableUseTimeWindowsCheck.jsx';
import ChangeTimeType from 'views/admin/Forms/TimeWindows/ChangeTimeType.jsx';

const staticTableSettings = require('store/data/table_settings_static.json')

export default function TimeSettingsForm(props) {

    const theme = useTheme();
    const timeUnit = useSelector(selectTimeSetting("timeUnit"));
    const usePickupEnd = useSelector(selectTimeSetting("usePickupEnd", props.tableName));
    const usePickupStart = useSelector(selectTimeSetting("usePickupStart", props.tableName));
    const usePickupExact = useSelector(selectTimeSetting("usePickupExact", props.tableName));
    const useDropoffEnd = useSelector(selectTimeSetting("useDropoffEnd", props.tableName));
    const useDropoffStart = useSelector(selectTimeSetting("useDropoffStart", props.tableName));
    const useDropoffExact = useSelector(selectTimeSetting("useDropoffExact", props.tableName));
    const timeUseTimeWindows = useSelector(selectTimeSetting("useTimeWindows", props.tableName));

    const tableTimeSettings = staticTableSettings[props.tableName]['timeWindows'];
    const dispatch = useDispatch()

  const topBorderWidth = props.borderWidth && props.borderWidth + ' ' + props.borderWidth + ' ' + props.borderWidth + ' ' + props.borderWidth || '2px 2px 2px 2px';
  const bottomBorderWidth = props.borderWidth && '0px ' + props.borderWidth + ' ' + props.borderWidth + ' ' + props.borderWidth || '0px 2px 2px 2px';


const handleTimeWindowChange = (timeWindow, val) => {
  dispatch(updateTimeSettings({settingNames:[timeWindow], tableName:props.tableName, settingValues: [!val]}));
  var columnName;
  var offColumnName;
  if (tableTimeSettings) {
    if (timeWindow === 'usePickupEnd') {
      if (timeUnit === 'clock') { 
        columnName = tableTimeSettings['pickupEnd']['columnNameTime'];
        offColumnName = tableTimeSettings['pickupExact'] && [tableTimeSettings['pickupExact']['columnNameTime']];
      }
      else {
        columnName = tableTimeSettings['pickupEnd']['columnNameInt'];
        offColumnName = tableTimeSettings['pickupExact'] && [tableTimeSettings['pickupExact']['columnNameInt']];
      }
      if (!val) {
        if (!offColumnName) {
          dispatch(updateTimeSettings({settingNames:['usePickupExact'], tableName:props.tableName, settingValues: [false]}));
        }
        if (offColumnName) {
          dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [false], columnNames: offColumnName, settingNames:['usePickupExact'], settingValues:[false]}));
        }
      }
    }

   if (timeWindow === 'usePickupStart') {
      if (timeUnit === 'clock') {
        columnName = tableTimeSettings['pickupStart']['columnNameTime'];
        offColumnName = tableTimeSettings['pickupExact'] && [tableTimeSettings['pickupExact']['columnNameTime']];
      }
      else {
        columnName = tableTimeSettings['pickupStart']['columnNameInt'];
        offColumnName = tableTimeSettings['pickupExact'] && [tableTimeSettings['pickupExact']['columnNameInt']];
      }
      if (!val) {
        if (!offColumnName) {
          dispatch(updateTimeSettings({settingNames:['usePickupExact'], tableName:props.tableName, settingValues: [false]}));
        }
        if (offColumnName) {
          dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [false], columnNames: offColumnName, settingNames:['usePickupExact'], settingValues:[false]}));
        }
      }
    }

    if (timeWindow === 'usePickupExact') {
      if (timeUnit === 'clock') {
        columnName = tableTimeSettings['pickupExact']['columnNameTime'];
        offColumnName = [tableTimeSettings['pickupStart']['columnNameTime'], tableTimeSettings['pickupEnd']['columnNameTime']];
      }
      else { 
        
        columnName = tableTimeSettings['pickupExact']['columnNameInt'];
        offColumnName = [tableTimeSettings['pickupStart']['columnNameInt'], tableTimeSettings['pickupEnd']['columnNameInt']];
      }
      if (!val) {
        dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [false, false], columnNames: offColumnName, settingNames:['usePickupStart', 'usePickupEnd'], settingValues:[false, false]}));
      }
    }

    if (timeWindow === 'useDropoffEnd') {
      if (timeUnit === 'clock') { 
        columnName = tableTimeSettings['dropoffEnd']['columnNameTime'];
        offColumnName = tableTimeSettings['dropoffExact'] && [tableTimeSettings['dropoffExact']['columnNameTime']];
      }
      else {
        columnName = tableTimeSettings['dropoffEnd']['columnNameInt'];
        offColumnName = tableTimeSettings['dropoffExact'] && [tableTimeSettings['dropoffExact']['columnNameInt']];
      }
      if (!val) {
        if (!offColumnName) {
          dispatch(updateTimeSettings({settingNames:['useDropoffExact'], tableName:props.tableName, settingValues: [false]}));
        }
        if (offColumnName) {
          dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [false], columnNames: offColumnName, settingNames:['useDropoffExact'], settingValues:[false]}));
        }
      }
    }
    
    if (timeWindow === 'useDropoffStart') {
      if (timeUnit === 'clock') {
        columnName = tableTimeSettings['dropoffStart']['columnNameTime'];
        offColumnName = tableTimeSettings['dropoffExact'] && [tableTimeSettings['dropoffExact']['columnNameTime']];
      }
      else {
        columnName = tableTimeSettings['dropoffStart']['columnNameInt'];
        offColumnName = tableTimeSettings['dropoffExact'] && [tableTimeSettings['dropoffExact']['columnNameInt']];
      }
      if (!val) {
        if (!offColumnName) {
          dispatch(updateTimeSettings({settingNames:['useDropoffExact'], tableName:props.tableName, settingValues: [false]}));
        }
        if (offColumnName) {
          dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [false], columnNames: offColumnName, settingNames:['useDropoffExact'], settingValues:[false]}));
        }
      }
    }

    if (timeWindow === 'useDropoffExact') {
      if (timeUnit === 'clock') {
        columnName = tableTimeSettings['dropoffExact']['columnNameTime'];
        offColumnName = [tableTimeSettings['dropoffStart']['columnNameTime'], tableTimeSettings['dropoffEnd']['columnNameTime']];
      }
      else { 
        columnName = tableTimeSettings['dropoffExact']['columnNameInt'];
        offColumnName = [tableTimeSettings['dropoffStart']['columnNameInt'], tableTimeSettings['dropoffEnd']['columnNameInt']];
      }
      if (!val) {
        dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [false, false], columnNames: offColumnName, settingNames:['useDropoffStart', 'useDropoffEnd'], settingValues:[false, false]}));
      }
    }

    dispatch(updateTimeSettings({tableName: props.tableName, columnValues: [!val], columnNames: [columnName]}))
  }
  
  }

  var topDivStyle = {backgroundColor:'inherit', border:'solid ' + theme.palette.primary.main, borderWidth: topBorderWidth, borderRadius:'1rem 1rem 1rem 1rem', margin:'15px 15px 15px 15px', padding: props.padding || '0px'}
  if (tableTimeSettings && (tableTimeSettings['dropoffEnd'] || tableTimeSettings['dropoffStart'] || tableTimeSettings['dropoffExact'])) {
    topDivStyle = {backgroundColor:'inherit', border:'solid ' + theme.palette.primary.main, borderWidth:topBorderWidth, borderRadius:'1rem 1rem 0rem 0rem', margin:'15px 15px 0px 15px', padding: props.padding || '0px'}
  }

  return (<>
      <div >
        <div style={{minHeight:props.minHeight || '300px'}}>
      <>
        {props.addTableSwitches && <TableUseTimeWindowsCheck tableName={props.tableName} />}
        </>
        {props.addChangeTimeType && <ChangeTimeType />}


        <Collapse in={timeUseTimeWindows} unmountOnExit>
          <Stack direction="row" spacing={2} style={{padding:'5px'}}>
            <FormGroup>
              <div id='time-settings-form-group' style={topDivStyle}>
                <FormControlLabel style={{margin:'0px'}}
                  control={<Checkbox style={{color:theme.palette.primary.main}} checked={usePickupEnd} onChange={() => handleTimeWindowChange('usePickupEnd', usePickupEnd)}/>} 
                  label={tableTimeSettings['pickupEnd']['description']} />
                <FormControlLabel style={{margin:'0px'}}
                  control={<Checkbox style={{color:theme.palette.primary.main}} checked={usePickupStart} onChange={() => handleTimeWindowChange('usePickupStart', usePickupStart)}/>} 
                  label={tableTimeSettings['pickupStart']['description']} />
                {tableTimeSettings['pickupExact'] && <FormControlLabel style={{margin:'0px'}}
                  control={<Checkbox style={{color:theme.palette.primary.main}} checked={usePickupExact} onChange={() => handleTimeWindowChange('usePickupExact', usePickupExact)}/>} 
                  label={tableTimeSettings['pickupExact']['description']} />}
              </div>
              {(tableTimeSettings['dropoffEnd'] || tableTimeSettings['dropoffStart'] || tableTimeSettings['dropoffExact']) && 
              <div style={{backgroundColor:'inherit', border:'solid ' + theme.palette.primaryComplement.dark, borderWidth:bottomBorderWidth, borderRadius:'0rem 0rem 1rem 1rem', margin:'0px 15px 15px 15px'}}>
              {tableTimeSettings['dropoffEnd'] && <FormControlLabel style={{margin:'0px'}}
                  control={<Checkbox style={{color:theme.palette.primaryComplement.dark}} checked={useDropoffEnd} onChange={() => handleTimeWindowChange('useDropoffEnd', useDropoffEnd)}/>} 
                  label={tableTimeSettings['dropoffEnd']['description']} />}
                {tableTimeSettings['dropoffStart'] && <FormControlLabel style={{margin:'0px'}}
                  control={<Checkbox style={{color:theme.palette.primaryComplement.dark}} checked={useDropoffStart} onChange={() => handleTimeWindowChange('useDropoffStart', useDropoffStart)}/>} 
                  label={tableTimeSettings['dropoffStart']['description']} />}
                {tableTimeSettings['dropoffExact'] && <FormControlLabel style={{margin:'0px'}}
                  control={<Checkbox style={{color:theme.palette.primaryComplement.dark}} checked={useDropoffExact} onChange={() => handleTimeWindowChange('useDropoffExact', useDropoffExact)}/>} 
                  label={tableTimeSettings['dropoffExact']['description']} />}                
              </div>}
            </FormGroup>
          </Stack>        
        </Collapse>
      </div>

      </div>
    </>
  );
}



