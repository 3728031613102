import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import componentStyles from "assets/theme/components/footers/admin-footer.js";

const useStyles = makeStyles(componentStyles);

const Footer = () => {
  const classes = useStyles();
  return (
    <Box component="footer" width="100%">
      <Grid container classes={{ root: classes.justifyContentCenter }}>
        <Box
          item
          xs={12}
          sm={6}
          component={Grid}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: "center", sm: "flex-start" }}
        >
          <Box className={classes.copyrightWrapper}
          justifyContent={{ xs: "center", sm: "flex-start" }}>
            © {new Date().getFullYear()}{" "}
              Optimiciti
          </Box>
        </Box>

        <Grid
          item
          sm={6}
          component={Box}
          display="flex"
          justifyContent="flex-end"
        >
          <Box
            component={List}
            display="flex"
            justifyContent={{xs: "center", sm: "flex-end"}}
            alignItems="center"
            className={classes.flexDirectionColumn}
          >
            <ListItem
              component="a"
              href="/careers"
              // rel="noopener noreferrer"
              target="_blank"
              classes={{
                root: classes.listItemRoot,
              }}
            >
              Careers
            </ListItem>

            <ListItem
              component="a"
              href="/about"
              // rel="noopener noreferrer"
              target="_blank"
              classes={{
                root: classes.listItemRoot,
              }}
            >
              About
            </ListItem>

          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;