import React, {useState, useEffect} from "react";

import FormControl from "@mui/material/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { isEqual } from 'lodash';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { Select } from "antd";
import dayjs from 'dayjs';

import { TimePicker, DatePicker} from 'antd';
import moment from 'moment';

import validatorFromName from "views/admin/Forms/functions/validator_from_name.js"
import ColorBarToolTipButton from "views/admin/Forms/colorbar/ColorBarToolTipButton.js"
import CustomInputAdornment from "views/admin/Forms/InputHelpers/CustomInputAdornment.js";
import IconSelector from 'views/admin/Forms/icon_selector/IconSelector.js'
import NoContentFound from 'views/admin/Forms/NoContentFound.jsx'
import { useSelector } from 'react-redux';
import {
    selectTableData,
    selectDefaultValue,
    selectDataTableValue,
    getDropdownValuesFromColumn
  } from 'store/reducers/inputReducer';
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

const errorStyle = {    
  fontSize:'0.5rem',
  overflow:'hidden',
  whiteSpace:'nowrap',
  textOverflow: 'ellipsis',
  marginTop:'0px',
  "&:hover": {
    overflow: 'hidden',
 },};

const { Option } = Select;
const allStaticTableSettings = require('store/data/table_settings_static.json')

const requiredEntryError = 'Required field is empty'

export default class FormItem extends React.Component {
  
  constructor(props) {
    super(props);

   
    this.state = {
        value: props.defaultValue ?  props.defaultValue : null,
        valid: props.defaultValid ? props.defaultValid : true,
        resetIndicator: true,
        helperMessage: ''
    }
  
    this.setValue = this.setValue.bind(this);
    this.setValid = this.setValid.bind(this);
    this.setHelperMessage = this.setHelperMessage.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getValid = this.getValid.bind(this);
    this.cleanState = this.cleanState.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleMultiInputChange = this.handleMultiInputChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.isDefaultValue = this.isDefaultValue.bind(this);
    this.setDefault = this.setDefault.bind(this);
  }

  cleanState() {
    this.setState({value: null, valid: true, resetIndicator:!this.state.resetIndicator});
  }

  setValid(valid) {
    this.setState({valid: valid});
    this.props.setValidByIndex(this.props.formIdx, valid)
  }

  setValue(value) {
    this.setState({value: value});
  }

  setHelperMessage(newMessage) {
    this.setState({helperMessage: newMessage})
  }

  setDefault(isDefault) {
    this.props.setDefaultByIndex(this.props.formIdx, isDefault)
  }

  getValid() {
    if (this.props.isRequired) {
      if (!this.state.value) {
        this.setState({valid:false, helperMessage:requiredEntryError})
        return false;
      }
    }
    if (this.state.value == this.props.defaultValue) {
      return true;
    }
    return this.state.valid;
  }

  isDefaultValue = () => {
    if (this.state.value == this.props.defaultValue) {
      return true;
    } return false;
  }

  getValue() {
    return this.state.value
  }

  handleInputChange(newValue) {
    if (newValue) {
        this.setState({value: newValue});
    } else {
        this.setState({value: null})
    }
  }

  handleMultiInputChange(newValue) {
    if (newValue) {
        this.setState({value: newValue});
    } else {
        this.setState({value: null})
    }
    this.setDefault(false);
  }

  handleColorChange(newColor) {
    this.setState({value: newColor});
    this.setDefault(false);
  }


  render() { return (
                    <FormItemHelper 
                      key={this.state.resetIndicator}
                      setValid={this.setValid} 
                      setDefault={this.setDefault}
                      handleInputChange={this.handleInputChange}
                      handleMultiInputChange={this.handleMultiInputChange}
                      handleColorChange={this.handleColorChange}
                      valid={this.state.valid}
                      value={this.state.value}
                      helperMessage={this.state.helperMessage}
                      setHelperMessage={this.setHelperMessage}
                      {...this.props}
                      closeForm={() => {this.props.closeForm()}}
                    />
                    )
  }
}


const FormItemHelper = (props) => {
  const defaultValueFromUuid = useSelector(selectDataTableValue(props.tableName, props.columnName, props.uuid))
  const [itemHelperLoaded, setItemHelperLoaded] = useState(false);
  const tableData = useSelector(selectTableData(props.tableName), isEqual);
  const dropdownValues = useSelector(getDropdownValuesFromColumn(props.tableName, props.columnName));
  const defaultValue = useSelector(selectDefaultValue(props.tableName, props.columnName));
  const staticTableSettings = allStaticTableSettings[props.tableName];
  const validator = validatorFromName(staticTableSettings['validation'][props.columnName])
  const columnHeading = staticTableSettings['columnHeader'] && staticTableSettings['columnHeader'][props.columnName] || props.columnName;

  const handleColorChange = (newColor) => {
    props.handleColorChange(newColor);
  }

  const getAdornment = () => {
    if (staticTableSettings['inputAdornment']) {
      return staticTableSettings['inputAdornment'][props.columnName];
    }
  }


  const validateInput = (newValue) => {
    if (newValue == props.defaultValue) {
      props.setDefault(true);
      props.setValid(true);
      props.setHelperMessage(null);
      return;
    }

    props.setDefault(false);
    if (props.isRequired) {
      if (!newValue) {
        props.setValid(false);
        props.setHelperMessage(requiredEntryError);
        return
      }
    }
    if (props.dataType == 'dropdown') {
        props.setValid(true)
    } else {
        const validatorArgs = {
            'newValue': newValue,
            'data': tableData,
            'row': null,
            'columnName': props.columnName
        }
        const {valid, message} = validator(validatorArgs);
        props.setValid(valid);
        if (valid) {props.setHelperMessage(null)}
        else {
            props.setHelperMessage(message);
        }
    }
  }

  const handleInputChange = (newValue) => {
    if (newValue === props.value) {} 
    var formattedNewValue = newValue;
    validateInput(formattedNewValue)
    props.handleInputChange(formattedNewValue);

  }

  const handleMultiInputChange = (event, value, allValues) => {

    const valueList = [];
    value.map((elem, idx) => {
      valueList.push(elem.value)
    })

    if (valueList.indexOf('Select All') > -1) {
      props.handleMultiInputChange(allValues);
    } else {
      props.handleMultiInputChange(valueList);
    }

  }

  useEffect(() => {
    if (!itemHelperLoaded) {
      if (defaultValue) {
      setItemHelperLoaded(true);
      if (props.uuid && defaultValueFromUuid) {
        handleInputChange(defaultValueFromUuid)
      }
      if (defaultValue) {
        if (!props.defaultValue) {
          if (staticTableSettings['defaultValue'] && staticTableSettings['defaultValue'][props.columnName]) {
            handleInputChange(defaultValue);
          }
        }
      }
    }

  }

  if (!props.dataType == 'multi-dropdown' && props.uuid && props.value !== defaultValueFromUuid) {
    handleInputChange(defaultValueFromUuid)
  }
  }, [itemHelperLoaded, defaultValue, defaultValueFromUuid])

  const infoIcon = <>{staticTableSettings['headerTooltip'] && staticTableSettings['headerTooltip'][props.columnName] && 
          <Tooltip title={staticTableSettings['headerTooltip'][props.columnName]} >
            <IconButton style={{marginTop:'-0.5rem', padding:'5px'}}><InfoIcon style={{height:'0.8rem', width:'0.8rem'}}/></IconButton>
          </Tooltip>}</>


  if (props.dataType === 'color') {
    if (!props.value) {
      props.handleColorChange("#1ABC9C");
    }
    return (
        <>
        <ColorBarToolTipButton color={props.value} size='medium' text={props.columnName} handleColorChange={handleColorChange} ></ColorBarToolTipButton>
        </>
    )
  }

  if (props.dataType == 'vehicleIcon') {
    return (
        <>
        <FormGroup style={{'marginBottom':'0px'}}>
        <FormControl error={!props.valid} style={{'maxWidth':'100%'}}>
        <InputLabel htmlFor="vehicle-icon-selector" style={{backgroundColor:'white', fontSize:'14px', fontFamily:'inherit', pointerEvents:'auto'}} shrink={true}>
        {props.columnName}{infoIcon}
        </InputLabel>
        <OutlinedInput id='vehicle-icon-selector' inputComponent={IconSelector
        }
        inputProps={{handleInputChange: handleInputChange, padding:'10px'}}
        ></OutlinedInput>
        </FormControl>
        </FormGroup>
        </>
  )
  }

  if (props.dataType == 'time') {
    const format = 'HH:mm a'
      return (
          <FormControl error={!props.valid} style={{'width':'100%'}}>
        <InputLabel htmlFor="component-outlined" shrink={true} style={{backgroundColor:'white', fontSize:'14px', fontFamily:'inherit', pointerEvents:'auto'}}>
          &nbsp;{columnHeading}&nbsp;{infoIcon}
        </InputLabel>
          <TimePicker value={props.value ? moment(props.value, format) : null} 
          format={format} popupStyle={{zIndex:9999999}}
          changeOnBlur={true}
          placeholder={staticTableSettings['columnHeader'] && staticTableSettings['columnHeader'][props.columnName] || props.columnName}
          onSelect={(time) => {
            if (time) {
              const timeString = moment(time).format(format);
            handleInputChange(timeString)
          } else {
            handleInputChange(null);
          }
          }}
          onChange={(time) => {
            if (time) {
              const timeString = moment(time).format(format);
              handleInputChange(timeString)
          } else {
            handleInputChange(null);
          }
          }}
            
            />
            </FormControl>
      );
    }

    if (props.dataType == 'date') {
      const format = 'YYYY-MM-DD'
        return (
            <FormControl error={!props.valid} style={{'width':'100%'}}>
          <InputLabel htmlFor="component-outlined" shrink={true} style={{backgroundColor:'white', fontSize:'14px', fontFamily:'inherit', pointerEvents:'auto'}}>
            &nbsp;{columnHeading}&nbsp;{infoIcon}
          </InputLabel>
            <DatePicker defaultValue={props.value ? dayjs(props.value, format) : null} 
            format={format} popupStyle={{zIndex:9999999}}
            placeholder={staticTableSettings['columnHeader'] && staticTableSettings['columnHeader'][props.columnName] || props.columnName}
            onChange={(date) => {
              if (date) {
              handleInputChange(moment.unix(date/1000).format(format))
            } else {
              handleInputChange(null);
            }
            
            }}
              
              />
              </FormControl>
        );
      }

  if (props.dataType == 'boolean') {
    return ( 
      <FormControlLabel
      control={<Checkbox />}
      label={<>{columnHeading}{infoIcon}</>}
      labelPlacement="start"
      value={props.value}
      onChange={(event) => handleInputChange(event.target.checked ? true : false)}
    />
    )
  }
  

  if (props.dataType == 'dropdown') {

    // if (dropdownValues.length == 0) { return null }
      return (
        <FormGroup style={{'marginBottom':'0px'}}>
        <FormControl error={!props.valid} style={{'maxWidth':'100%'}}>
        <InputLabel htmlFor="component-outlined" shrink={true} style={{backgroundColor:'white', fontSize:'14px', fontFamily:'inherit', pointerEvents:'auto'}}>
          &nbsp;{columnHeading}&nbsp;{infoIcon}
        </InputLabel>
              <Select
                showSearch
                getPopupContainer={node => node.parentNode}
                // placement={'bottomLeft'}
                style={{maxWidth:200}}
                value={props.value}
                onChange={(event, value) => {handleInputChange(value.value)}}
                options={dropdownValues}
                dropdownStyle={{top:'36px !important'}}
                notFoundContent={<NoContentFound tableName={props.tableName} columnName={props.columnName} onOpenForm={() => {props.closeForm()}}/>}
              >
              </Select> 
        {!props.valid && <Tooltip title={props.helperMessage}><FormHelperText id="component-error-text" style={errorStyle}>{props.helperMessage}</FormHelperText></Tooltip>}
        </FormControl>
        </FormGroup>  

      )
  }

  if (props.dataType == 'multi-dropdown') {
      const children = [<Option key={'Select All'}>{'Select All'}</Option>];
      const allValues = []
      dropdownValues.map((elem, idx) => {
        const option = elem.value
        children.push(<Option key={option}>{option}</Option>);
        allValues.push(option)
      })

      if (allValues.length == 0) {return null}
      return   (
        <>

        <FormGroup style={{'marginBottom':'0px'}}>
        <FormControl error={!props.valid} style={{'maxWidth':'100%'}}>
        <InputLabel htmlFor="component-outlined" shrink={true} style={{backgroundColor:'white', fontSize:'14px', fontFamily:'inherit', pointerEvents:'auto'}}>
          &nbsp;{columnHeading}&nbsp;{infoIcon}
        </InputLabel>
              <Select
                allowClear
                mode="multiple"
                showSearch
                getPopupContainer={node => node.parentNode}
                placement={'bottomLeft'}
                style={{maxWidth:200}}
                value={props.value || []}
                onChange={(event, value) => handleMultiInputChange(event, value, allValues)}
                dropdownStyle={{top:'36px'}}
              >
                {children}
              </Select> 
        {!props.valid && <Tooltip title={props.helperMessage}><FormHelperText id="component-error-text" style={errorStyle}>{props.helperMessage}</FormHelperText></Tooltip>}
        </FormControl>
        </FormGroup>    
        </>
      )
  }

  return (
    <FormGroup style={{'marginBottom':'0px'}}>
    <FormControl error={!props.valid} style={{'maxWidth':'100%'}}>
    <InputLabel htmlFor="component-outlined" shrink={true} style={{backgroundColor:'white', fontSize:'14px', fontFamily:'inherit', pointerEvents:'auto'}}>
      &nbsp;{columnHeading}&nbsp;{infoIcon}
    </InputLabel>
    <OutlinedInput
      onChange={(event) => {handleInputChange(event.target.value)}}
      value={props.value}
      id="component-outlined"
      fullWidth
      type="text"
      aria-describedby="component-error-text"
      style={{height:'2rem', paddingLeft:'0px'}}
      startAdornment={<CustomInputAdornment adornment={getAdornment()} />}
    />
    {!props.valid && <Tooltip title={props.helperMessage}><FormHelperText id="component-error-text" style={errorStyle}>{props.helperMessage}</FormHelperText></Tooltip>}
    </FormControl>
  </FormGroup>    
  )
};